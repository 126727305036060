import React from "react";
import AdminProtected from "../../components/protected/AdminProtected";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardHeader, CardFooter } from "best-common-react";
import ExportSettingsFooter from "../../components/reports/exportSettings/ExportSettingsFooter";
import ExportSettingsBody from "../../components/reports/exportSettings/ExportSettingsBody";

class ExportSettingsContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>Export Settings</CardHeader>
          <CardBody>
            <ExportSettingsBody />
          </CardBody>
          <CardFooter>
            <ExportSettingsFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default AdminProtected(ExportSettingsContainer);
