import React from "react";
import { inject, observer } from "mobx-react";
import { AccordionTableWrapper } from "best-common-react";
import EventGameLinkFormatter from "../../events/admin/EventGameLinkFormatter";
import EventDateLinkFormatter from "../../events/admin/EventDateLinkFormatter";
import NumberFormatter from "../../../utilities/NumberFormatter";
import ReportEventReservationsAccordion from "./ReportEventReservationsAccordion";
import HigherOrderComponent from "../../../utilities/HigherOrderComponent";

const URL_PATH = "reservations";

let DateLinkFormatter = HigherOrderComponent(EventDateLinkFormatter);
DateLinkFormatter.defaultProps = {
  path: URL_PATH
};

let GameLinkFormatter = HigherOrderComponent(EventGameLinkFormatter);
GameLinkFormatter.defaultProps = {
  path: URL_PATH
};

const cols = [
  { key: "date", name: "Date", width: 200, sortable: true, formatter: DateLinkFormatter },
  { key: "gameStr", name: "Game", width: 175, sortable: true, formatter: GameLinkFormatter },
  { key: "venue", name: "Venue", width: 220, sortable: true },
  { key: "reservations", name: "Reservations", width: 150, sortable: true, formatter: NumberFormatter },
  { key: "ticketsReserved", name: "Tickets Reserved", sortable: true, formatter: NumberFormatter }
];

class ReportEventReservationsTable extends React.Component {
  render() {
    const { reportEventReservationsStore } = this.props.rootStore;
    return (
      <div className="row">
        <div className="col-12">
          <AccordionTableWrapper
            id="event-reservations-report-table"
            data={reportEventReservationsStore.currentEvents}
            columns={cols}
            accordion={ReportEventReservationsAccordion}
            height={400}
            sortFunc={reportEventReservationsStore.setSortDirection}
            sortColumn={reportEventReservationsStore.sortFilters["key"]}
            sortDirection={reportEventReservationsStore.sortFilters["direction"]}
          />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ReportEventReservationsTable));
