import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Alert, EnvIndicator, PrimaryButton } from "best-common-react";
import logo from "../../assets/logo/comptix-login-logo@2x.png";

const LoginHolder = styled.div`
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
  background-color: #f2f3f4;
  border: solid 1.25rem #ffffff;
  border: solid 1.25rem var(--white);
`;

const LoginFormHolder = styled.div.attrs({
  className: "d-flex justify-content-center"
})``;

const LogoHolder = styled.div`
  width: 300px;
`;

const Logo = styled.img.attrs({
  src: logo,
  alt: "CompTix Logo"
})`
  height: 66px;
`;

const EnvHolder = styled.div.attrs({
  className: "d-flex flex-row justify-content-center mt-1"
})`
  height: 1.25rem;
`;

const HR = styled.hr.attrs({})`
  width: 300px;
`;

const AlertHolder = styled.div.attrs({
  className: "mb-2"
})`
  min-height: 30px;
  width: 300px;
`;

const LoginLabel = styled.label`
  color: #000000;
  font-size: 0.875rem;
  font-weight: bold;
`;

const LoginInput = styled.input`
  width: 300px;
`;

// const ForgotPassword = styled.button.attrs({
//   className: "btn btn-link"
// })`
//   padding-left: 0;
//   font-size: 0.875rem;
// `;

const LoginButton = styled(PrimaryButton).attrs({
  type: "submit"
})`
  width: 115px;
`;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    if (this.username) {
      this.username.focus();
    }
  }

  login(event) {
    event.preventDefault();
    this.props.rootStore.authStore.login(this.username.value, this.password.value);
  }

  render() {
    const { authStore } = this.props.rootStore;
    return (
      <LoginHolder>
        <LoginFormHolder>
          <div className="px-3">
            <div className="d-flex flex-row mt-4 justify-content-center">
              <LogoHolder>
                <Logo />
              </LogoHolder>
            </div>
            <EnvHolder>
              <EnvIndicator />
            </EnvHolder>
            <div className="d-flex flex-row">
              <HR />
            </div>
            <div className="d-flex flex-row justify-content-center">
              {authStore.loginError ? (
                <AlertHolder>
                  <Alert type="danger">username/email/password are incorrect</Alert>
                </AlertHolder>
              ) : null}
            </div>
            <div className="d-flex flex-row justify-content-center">
              <form onSubmit={this.login}>
                <div className="form-group">
                  <LoginLabel htmlFor="username">Username or Email</LoginLabel>
                  <LoginInput
                    type="text"
                    id="username"
                    name="username"
                    ref={input => (this.username = input)}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <LoginLabel htmlFor="password">Password</LoginLabel>
                  <LoginInput
                    type="password"
                    id="password"
                    name="password"
                    ref={input => (this.password = input)}
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  {/*<div>*/}
                  {/*<ForgotPassword id={"forgot-password"}>Forgot Password?</ForgotPassword>*/}
                  {/*</div>*/}
                  <div>
                    <LoginButton disabled={authStore.isLoggingIn} id="login-button">
                      Login
                    </LoginButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </LoginFormHolder>
      </LoginHolder>
    );
  }
}

export default inject("rootStore")(observer(LoginForm));
