import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Accordion, AttributeHeader, AttributeValue, Icon, BestLink } from "best-common-react";

const UserHeaderName = styled.span.attrs({ className: "mx-3", id: "user-header-name" })``;

class UserAccordionMobile extends React.Component {
  render() {
    const { userAdminStore } = this.props.rootStore;
    return (
      <div>
        <Accordion id={this.props.index.toString()} {...this.props}>
          <Accordion.Header isToggle={true}>
            <div id="user-accordion-mobile">
              <BestLink to={this.props.data.editLink}>
                <Icon iconName="fa-pencil-alt" className="fa-lg d-inline-block mx-1" />
              </BestLink>
              <Icon
                id="edit-key"
                iconName="fa-key"
                className="fa-lg d-inline-block mx-1"
                onClick={() => {
                  userAdminStore.openChangePassword(this.props.data.userId);
                }}
              />
              <UserHeaderName>
                {this.props.data.name}
                {this.props.data.manuallyManaged ? <span className="fa fa-exclamation-circle ml-2" /> : null}
              </UserHeaderName>
            </div>
          </Accordion.Header>

          <Accordion.Body>
            <AttributeHeader>Username</AttributeHeader>
            <AttributeValue>{this.props.data.username}</AttributeValue>

            <AttributeHeader>Email</AttributeHeader>
            <AttributeValue>{this.props.data.email}</AttributeValue>

            <AttributeHeader>Department</AttributeHeader>
            <AttributeValue>{this.props.data.department}</AttributeValue>

            <AttributeHeader>User Role</AttributeHeader>
            <AttributeValue>{this.props.data.userRole}</AttributeValue>

            <AttributeHeader>Home (Fam/Frnd)</AttributeHeader>
            <AttributeValue>{this.props.data.homeAllotment}</AttributeValue>

            <AttributeHeader>Away (Fam/Frnd)</AttributeHeader>
            <AttributeValue>{this.props.data.awayAllotment}</AttributeValue>

            <AttributeHeader>Active</AttributeHeader>
            <AttributeValue>{this.props.data.active ? "Yes" : "No"} </AttributeValue>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

export default inject("rootStore")(observer(UserAccordionMobile));
