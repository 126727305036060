import React from "react";
import { inject, observer } from "mobx-react";

class OrgSettingsBody extends React.Component {
  render() {
    const { orgSettingsStore } = this.props.rootStore;
    return (
      <div className="row">
        <div className="col">
          <table>
            <thead>
              <tr>
                <th>Org</th>
                <th class="text-center">Enabled</th>
              </tr>
            </thead>
            <tbody>
              {orgSettingsStore.orgSettingsData.map(data => {
                return (
                  <React.Fragment key={data.orgCode}>
                    <tr>
                      <td>{data.orgCode}</td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          checked={data.useHHDropdown}
                          onChange={d => orgSettingsStore.updateOrg(data.orgCode, !data.useHHDropdown)}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(OrgSettingsBody));
