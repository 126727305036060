import React from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { BestLink, Icon } from "best-common-react";

class EventReservationsEditIconFormatter extends React.Component {
  render() {
    const { eventReservationsStore, authStore } = this.props.rootStore;
    let filterElement = eventReservationsStore.currentReservations.find(r => r.id === this.props.value);
    let isBefore = moment().isBefore(filterElement && filterElement.game.eventCutoff);
    if (isBefore || authStore.isAdmin) {
      let to = "/reservations/edit/" + this.props.value + "?from=Report";
      return (
        <div id="event-reservations-edit-formatter">
          <BestLink to={to}>
            <Icon iconName="fa-pencil-alt" />
          </BestLink>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default inject("rootStore")(observer(EventReservationsEditIconFormatter));
