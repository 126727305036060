import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, PrimaryButton, StickyFooterButtons } from "best-common-react";

class VenueEditFooter extends React.Component {
  render() {
    const { venueEditStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <PrimaryButton
          onClick={venueEditStore.save}
          id="save"
          disabled={!venueEditStore.isValidPLSave || !venueEditStore.isValidSectionSave || venueEditStore.isSaving}
        >
          Save
        </PrimaryButton>
        <LightButton onClick={venueEditStore.setShowVenueImportModal} id="import">
          Import
        </LightButton>
        <LightButton onClick={venueEditStore.cancel} id="cancel">
          Cancel
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(VenueEditFooter));
