import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { PulseLoader } from "react-spinners";
import { Theme } from "best-common-react";

class LoadingSpinner extends React.Component {
  render() {
    const { sizeUnit, size, color } = this.props;
    const { loadingStore } = this.props.rootStore;
    return (
      <div className="d-flex align-items-center">
        {loadingStore.loading ? (
          <PulseLoader sizeUnit={sizeUnit} size={size} color={color} loading={loadingStore.loading} />
        ) : null}
      </div>
    );
  }
}

LoadingSpinner.defaultProps = {
  sizeUnit: "px",
  size: 16,
  color: Theme["mlb-blue"]
};

LoadingSpinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  sizeUnit: PropTypes.string
};

export default inject("rootStore")(observer(LoadingSpinner));
