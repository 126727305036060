import React from "react";
import { inject, observer } from "mobx-react";
import { StickyFooterButtons, PrimaryButton, LightButton } from "best-common-react";

const ExportSettingsFooter = ({ rootStore }) => {
  const { exportSettingsStore } = rootStore;
  return (
    <StickyFooterButtons>
      <PrimaryButton
        onClick={exportSettingsStore.onSave}
        disabled={!exportSettingsStore.isEdited || exportSettingsStore.isSaving}
      >
        Save
      </PrimaryButton>
      <LightButton onClick={exportSettingsStore.onCancel}>Cancel</LightButton>
    </StickyFooterButtons>
  );
};

export default inject("rootStore")(observer(ExportSettingsFooter));
