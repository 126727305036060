import axios from "axios";
import { AlertConstants } from "../constants/AlertConstants";

function createErrorMessage(error) {
  switch (error.response.status) {
    case 403:
      return "You do not have the correct permission to do this action";
    case 404:
      return "Resource not found";
    default:
      return "Comptix error please contact support: " + error.message;
  }
}

class HttpInterceptor {
  constructor(authStore, alertStore) {
    axios.interceptors.request.use(
      config => {
        // Do something before request is sent
        if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
          config.headers.Authorization = `Bearer ${authStore.accessToken}`;
        }
        return config;
      },
      error => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        if (response && response.data && response.data.alerts) {
          alertStore.createResponseAlerts(response.data.alerts);
        }
        return response;
      },
      error => {
        if (error) {
          if (error.response && error.response.data && "invalid_token" === error.response.data.error) {
            authStore.refresh();
            return Promise.reject(error);
          } else if (error.response && error.response.data && "invalid_grant" === error.response.data.error) {
            authStore.logout();
            return Promise.reject(error);
          } else {
            if (error) {
              if (error.response && error.response.data) {
                if (error.response.data.alerts) {
                  alertStore.createResponseAlerts(error.response.data.alerts);
                } else {
                  let message = createErrorMessage(error);
                  alertStore.addAlert({
                    type: AlertConstants.TYPES.DANGER,
                    text: message
                  });
                }
              }
              return Promise.reject(error);
            }
          }
        }
      }
    );
  }
}

export default HttpInterceptor;
