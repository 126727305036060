import React from "react";
import styled from "styled-components";
import bg from "../../assets/login/bg.png";
import LoginForm from "./LoginForm";

const LoginBG = styled.div.attrs({})`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
`;

const LoginWrapper = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center"
})`
  height: calc(100vh - 30px);
`;

class Login extends React.Component {
  render() {
    return (
      <div>
        <LoginBG />
        <LoginWrapper>
          <LoginForm />
        </LoginWrapper>
      </div>
    );
  }
}

export default Login;
