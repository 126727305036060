import React from "react";
import { inject, observer } from "mobx-react";
import UserAccordionMobile from "./UserAccordionMobile";
import UserEditPasswordFormatter from "./UserEditPasswordFormatter";
import { AccordionTableWrapper, IconFormatter } from "best-common-react";
import styled from "styled-components";
import CheckFormatter from "../../elements/CheckFormatter";
import UserNameFormatter from "./UserNameFormatter";
import HigherOrderComponent from "../../../utilities/HigherOrderComponent";

let PencilFormatter = HigherOrderComponent(IconFormatter);
PencilFormatter.defaultProps = {
  icon: "fa-pencil-alt",
  id: "user-edit-formatter"
};

let ExclamationFormatter = HigherOrderComponent(IconFormatter);
ExclamationFormatter.defaultPros = {
  icon: "fa-exclamation-triangle",
  id: "user-manually-managed"
};

const SelectedUsers = styled.span`
  font-size: 0.8rem;
  color: ${props => props.theme["dark-grey"]};
  font-style: italic;
`;
const ManuallyManaged = styled.span`
  font-size: 0.8rem;
  color: ${props => props.theme["dark-grey"]};
  font-style: italic;
`;

const cols = [
  {
    key: "editLink",
    name: "",
    width: 35,
    formatter: PencilFormatter,
    locked: true
  },
  // Workaround to allow two columns to have the same key
  {
    key: "userId1",
    name: "",
    width: 45,
    formatter: UserEditPasswordFormatter,
    locked: true
  },
  { key: "name", name: "Name", width: 175, sortable: true, locked: true },
  { key: "username", name: "Username", width: 150, sortable: true, formatter: UserNameFormatter },
  {
    key: "email",
    name: "Email",
    width: 225,
    sortable: true
  },
  { key: "department", name: "Department", width: 125, sortable: true },
  { key: "userRoles", name: "User Role", width: 85, sortable: true },
  { key: "homeAllotment", name: "Home (Fam/Frnd)", cellClass: "text-center", width: 165 },
  { key: "awayAllotment", name: "Away (Fam/Frnd)", cellClass: "text-center", width: 145 },
  { key: "statsId", name: "Stats ID", width: 90, sortable: true },
  { key: "active", name: "Active", sortable: true, formatter: CheckFormatter }
];

class UserTable extends React.Component {
  constructor(props) {
    super(props);
    this.userAdminStore = props.rootStore.userAdminStore;
  }

  onRowsSelected = rows => {
    this.userAdminStore.addUserstoUpdateAllotment(rows.map(row => row.row));
  };

  onRowsDeselected = rows => {
    this.userAdminStore.removeUserstoUpdateAllotment(rows.map(row => row.row));
  };

  render() {
    let selectedUserCount = this.userAdminStore.userIdsToUpdateAllotment.length;
    return (
      <div className="row">
        <div className="col-12">
          <SelectedUsers>{selectedUserCount} users selected</SelectedUsers>
          <ManuallyManaged className="pull-left">
            <span className="fa fa-exclamation-circle mr-1 ml-2" />
            Manually Managed User
          </ManuallyManaged>
          <AccordionTableWrapper
            id="user-table"
            data={this.userAdminStore.displayedUsers}
            columns={cols}
            sortFunc={this.userAdminStore.setSortDirection}
            accordion={UserAccordionMobile}
            height={400}
            sortColumn={this.userAdminStore.sortFilters["key"]}
            sortDirection={this.userAdminStore.sortFilters["direction"]}
            rowSelection={{
              showCheckbox: true,
              onRowsSelected: this.onRowsSelected,
              onRowsDeselected: this.onRowsDeselected,
              selectBy: {
                isSelectedKey: "isSelected"
              },
              enableShiftSelect: true
            }}
          />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(UserTable));
