import { action, autorun, computed, decorate, extendObservable } from "mobx";
import _ from "lodash";
import queryString from "query-string";
import { RouteConstants } from "../../constants/RouteConstants";

class VenueAdminStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      venues: [],
      selectedYear: { value: -1, label: "" },
      sortFilters: {
        direction: "ASC",
        key: "venueName"
      },
      year: new Date().getFullYear()
    };

    extendObservable(this, {
      venues: this.defaults["venues"],
      selectedYear: this.defaults["selectedYear"],
      sortFilters: this.defaults["sortFilters"],
      setVenues: action(value => {
        this.venues = value;
      }),
      setSelectedYear: action(value => {
        this.selectedYear = value;
        this.routerStore.history.push({
          pathname: this.routerStore.location.pathname,
          search: "?year=" + this.selectedYear.value
        });
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      resetStore: action(() => {
        this.selectedYear = this.defaults["selectedYear"];
        this.venues = this.defaults["venues"];
        this.sortFilters = this.defaults["sortFilters"];
      }),
      updateFromUrlParams: action(search => {
        const params = queryString.parse(search);
        this.year = params["year"] * 1 || this.defaults["year"];
        this.selectedYear = params["year"] ? { value: this.year, label: this.year } : this.defaults["selectedYear"];
      }),
      bulkOverridePrices: action(() => {
        this.routerStore.history.push(RouteConstants.VENUE_PRICES_BULK_OVERRIDE);
      })
    });

    autorun(() => {
      if (this.routerStore.isVenueAdminTab && this.commonStore.currentOrgId) {
        this.getVenues(this.commonStore.currentOrgId);
        this.updateFromUrlParams(this.routerStore.location.search);
      } else {
        this.resetStore();
      }
    });
  }

  getVenues = orgId => {
    this.compTixApi.getVenues(orgId).then(data => {
      this.setVenues(data);
    });
  };

  sort(users, searchFilters) {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(users, "venueName");
    } else if (direction === "ASC") {
      return _.sortBy(users, searchFilters.key);
    } else if (direction === "DESC") {
      return _.sortBy(users, searchFilters.key).reverse();
    } else {
      return users;
    }
  }

  get currentVenues() {
    let seasonsToDisplay = this.commonStore.seasons
      .filter(season => season.year === this.getSelectedYear.value)
      .map(season => season.seasonId);
    if (this.venues && this.venues.length) {
      let venues = _.sortBy(
        this.venues.filter(venue => _.includes(seasonsToDisplay, venue.season.seasonId)),
        "season.seasonId"
      );
      return venues.reduce((group, venueSeason) => {
        let seasonTypeName = venueSeason.season.seasonType.seasonTypeName;
        group[seasonTypeName] = group[seasonTypeName] || [];
        let venue = {
          editLink: "venue/edit?id=" + venueSeason.venueSeasonId + "&year=" + this.selectedYear.value,
          seasonId: venueSeason.season.seasonId,
          venueId: venueSeason.venue.venueId,
          venueName: venueSeason.venue.venueName,
          location: this.getLocation(venueSeason),
          homePickupLocations: this.getHomePickupLocations(venueSeason),
          awayPickupLocations: this.getAwayPickupLocations(venueSeason),
          homeInventory: this.getHomeInventory(venueSeason),
          awayInventory: this.getAwayInventory(venueSeason)
        };
        group[seasonTypeName].push(venue);
        return group;
      }, Object.create(null));
    } else {
      return [];
    }
  }

  getAwayInventory(venueSeason) {
    return venueSeason.venueSections
      .filter(section => {
        return section.homeAwayType.homeAwayTypeId === 2;
      })
      .map(section => {
        return section.inventoryQuantity;
      })
      .reduce((a, b) => a + b, 0);
  }

  getHomeInventory(venueSeason) {
    return venueSeason.venueSections
      .filter(section => {
        return section.homeAwayType.homeAwayTypeId === 1;
      })
      .map(section => {
        return section.inventoryQuantity;
      })
      .reduce((a, b) => a + b, 0);
  }

  getHomePickupLocations(venueSeason) {
    return _.sortBy(
      venueSeason.pickupLocations.filter(pickupLocation => {
        return pickupLocation.type.homeAwayTypeId === 1;
      }),
      "sequence"
    )
      .map(pickupLocation => {
        return pickupLocation.pickupLocationName;
      })
      .join(", ");
  }

  getAwayPickupLocations(venueSeason) {
    return _.sortBy(
      venueSeason.pickupLocations.filter(pickupLocation => {
        return pickupLocation.type.homeAwayTypeId === 2;
      }),
      "sequence"
    )
      .map(pickupLocation => {
        return pickupLocation.pickupLocationName;
      })
      .join(", ");
  }

  getLocation(venueSeason) {
    let location = "";
    if (venueSeason.venue.city) {
      location += venueSeason.venue.city;
    }
    if (venueSeason.venue.state) {
      if (location) {
        location += ", ";
      }
      location += venueSeason.venue.state;
    }
    return location ? location : "N/A";
  }

  get getSelectedYear() {
    return this.selectedYear.value === -1 && this.commonStore.yearsDropdownList.length > 0
      ? this.commonStore.yearsDropdownList.find(y => {
          return y.value === this.commonStore.currentSeason.year;
        })
      : this.selectedYear;
  }
}

decorate(VenueAdminStore, {
  currentVenues: computed,
  getSelectedYear: computed
});

export default VenueAdminStore;
