import { action, autorun, computed, decorate, extendObservable, reaction } from "mobx";
import _ from "lodash";
import convertUser from "./ConvertUser";
import createUserDropdown from "./CreateUserDropdown";
import validate from "validate.js";
import { CommonConstants } from "../../constants/CommonConstants";

class RequestStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      allotmentsAndRequests: {},
      comments: "",
      gamePK: -1,
      game: {},
      firstName: "",
      lastName: "",
      pickupLocations: [],
      quantity: { value: 0, label: "" },
      selectedPickupLocation: { value: "", label: "" },
      selectedRelation: commonStore.friendsAndFamilyDropdown[0],
      selectedHousehold: { value: "", label: "" },
      sectionInventory: [],
      selectedSection: { value: "", label: "" },
      users: [],
      user: null,
      alert: "",
      alerts: [],
      returnToReservations: null,
      message: null,
      isSending: false,
      deliveryEmail: "",
      deliveryMobilePhone: "",
      dialCode: 1,
      validPhone: false,
      countryCode: ""
    };

    extendObservable(this, {
      allotmentsAndRequests: this.defaults["allotmentsAndRequests"],
      comments: this.defaults["comments"],
      gamePK: this.defaults["gamePK"],
      game: this.defaults["game"],
      firstName: this.defaults["firstName"],
      lastName: this.defaults["lastName"],
      pickupLocations: this.defaults["pickupLocations"],
      quantity: this.defaults["quantity"],
      selectedRelation: this.defaults["selectedRelation"],
      selectedHousehold: this.defaults["selectedHousehold"],
      selectedPickupLocation: this.defaults["selectedPickupLocation"],
      sectionInventory: this.defaults["sectionInventory"],
      selectedSection: this.defaults["selectedSection"],
      users: this.defaults["users"],
      user: this.defaults["user"],
      alert: this.defaults["alert"],
      alerts: this.defaults["alerts"],
      returnToReservations: this.defaults["returnToReservations"],
      message: this.defaults["message"],
      deliveryEmail: this.defaults["deliveryEmail"],
      deliveryMobilePhone: this.defaults["deliveryMobilePhone"],
      dialCode: this.defaults["dialCode"],
      validPhone: this.defaults["validPhone"],
      countryCode: this.defaults["countryCode"],
      isSending: this.defaults["isSending"],
      setGamePK: action(gamePK => {
        this.gamePK = gamePK;
      }),
      setGame: action(game => {
        this.game = game;
      }),
      setFirstName: action(firstName => {
        this.firstName = firstName;
      }),
      setLastName: action(lastName => {
        this.lastName = lastName;
      }),
      setUser: action(user => {
        this.user = user;
      }),
      setUsers: action(users => {
        this.users = users;
      }),
      setRelation: action(relation => {
        this.selectedRelation = relation;
        this.selectedSection = this.defaults["selectedSection"];
        this.quantity = this.defaults["quantity"];
      }),
      setPickupLocations: action(locations => {
        this.pickupLocations = locations;
      }),
      setPickupLocation: action(pl => {
        this.selectedPickupLocation = pl;
      }),
      setComments: action(comments => {
        this.comments = comments;
      }),
      setHousehold: action(option => {
        this.selectedHousehold = option;
      }),
      setAllotmentsAndRequests: action(allotmentAndRequests => {
        this.allotmentsAndRequests = allotmentAndRequests;
      }),
      setQuantity: action(quantity => {
        this.quantity = quantity;
      }),
      setSectionInventory: action(values => {
        this.sectionInventory = values;
      }),
      setSelectedSection: action(value => {
        this.selectedSection = value;
      }),
      setReturnToReservations: action(value => {
        this.returnToReservations = value;
      }),
      setMessage: action(message => {
        this.message = message;
      }),
      setIsSending: action(value => {
        this.isSending = value;
      }),
      setDeliveryEmail: action(value => {
        this.deliveryEmail = value;
      }),
      setDeliveryMobilePhone: action((value, valid, countryInfo) => {
        this.deliveryMobilePhone = value;
        this.validPhone = valid && (countryInfo.iso2 === "us" || countryInfo.iso2 === "ca");
        this.dialCode = countryInfo.dialCode;
        this.countryCode = countryInfo.iso2;
      }),
      resetStore: action(() => {
        this.gamePK = this.defaults["gamePK"];
        this.game = this.defaults["game"];
        this.comments = this.defaults["comments"];
        this.user = this.defaults["user"];
        this.userId = this.defaults["userId"];
        this.firstName = this.defaults["firstName"];
        this.lastName = this.defaults["lastName"];
        this.selectedPickupLocation = this.defaults["selectedPickupLocation"];
        this.selectedRelation = this.defaults["selectedRelation"];
        this.selectedHousehold = this.defaults["selectedHousehold"];
        this.pickupLocations = this.defaults["pickupLocations"];
        this.quantity = this.defaults["quantity"];
        this.selectedRelation = this.defaults["selectedRelation"];
        this.sectionInventory = this.defaults["sectionInventory"];
        this.returnToReservations = this.defaults["returnToReservations"];
        this.message = this.defaults["message"];
        this.isSending = this.defaults["isSending"];
        this.deliveryEmail = this.defaults["deliveryEmail"];
        this.deliveryMobilePhone = this.defaults["deliveryMobilePhone"];
        this.validPhone = this.defaults["validPhone"];
      }),
      saveRequest: action(() => {
        this.setIsSending(true);
        this.compTixApi.saveTicketRequest(this.ticketRequest).then(data => {
          if (data.alerts && data.alerts[0].severity === "ERROR" && data.alerts[0].key === "101") {
            //Refresh total available tickets for all sections
            this.getSectionInventoryByUserIdAndGamePk(this.user.userId, this.gamePK);
            return; //If error is insufficient quantity (error code 101) stay on this page
          } else if (!this.returnToReservations) {
            this.routerStore.history.push("/tickets/events");
          } else if (this.returnToReservations) {
            this.routerStore.history.push("/events/" + this.gamePK + "/reservations");
          }
          this.setIsSending(false);
          this.resetStore();
        });
      }),
      cancel: action(() => {
        if (!this.returnToReservations) {
          this.routerStore.history.push("/tickets/events");
        } else {
          this.routerStore.history.push("/events/" + this.gamePK + "/reservations");
        }
        this.resetStore();
      })
    });

    autorun(() => {
      if (this.routerStore.isRequestTab && this.gamePK !== -1) {
        this.getEventInformation();
        this.getUsersByOrg();
        this.compTixApi.getOrgSettings(this.commonStore.selectedOrgId).then(data => {
          if (data) {
            this.commonStore.setUseHHDropdown(data.useHHDropdown);
          }
        });
      } else {
        this.resetStore();
      }
    });

    reaction(
      () => this.user,
      () => {
        this.setPickupLocation(null);
        this.selectedSection = this.defaults["selectedSection"];
        this.quantity = this.defaults["quantity"];
        if (this.user && this.routerStore.isRequestTab && this.gamePK !== -1) {
          this.getSectionInventoryByUserIdAndGamePk(this.user.userId, this.gamePK);
          this.getEventMessages(this.gamePK, this.user.userId);
          this.compTixApi.getAllotmentAndRequests(this.gamePK, this.user.userId).then(data => {
            this.setAllotmentsAndRequests(data);
          });
        }
      }
    );
  }

  getEventInformation() {
    if (this.gamePK !== -1) {
      this.compTixApi
        .getEventInformation(this.gamePK)
        .then(data => {
          this.setGame(data);
          this.compTixApi.getPickupLocations(data.venueSeasonId).then(data => {
            this.setPickupLocations(data);
          });
        })
        .catch(err => {
          this.setGame({});
        });
    }
  }

  getUsersByOrg() {
    if (this.commonStore.currentOrgId) {
      this.compTixApi.getUsersByOrg(this.commonStore.currentOrgId, false, false).then(data => {
        let currentUserInOrg = data.filter(user => {
          return user.userId === this.authStore.userData.userId;
        });
        let sortedUsers = _.sortBy(data, [user => user.lastName.toLowerCase(), user => user.firstName.toLowerCase()]);
        let filteredUsers = _.filter(sortedUsers, user => user.active);
        this.setUsers(filteredUsers);

        if (currentUserInOrg.length) {
          this.setUser(currentUserInOrg[0]);
        } else if (filteredUsers.length) {
          this.setUser(filteredUsers[0]);
        }
      });
    }
  }

  getSectionInventoryByUserIdAndGamePk(userId, gamePk) {
    this.compTixApi.getSectionInventoryByUserIdAndGamePk(userId, gamePk).then(data => {
      this.setSectionInventory(data);
    });
  }

  getEventMessages(gamePk, userId) {
    this.compTixApi.getEventMessageForUser(gamePk, userId).then(data => {
      if (data.length) {
        this.setMessage(data[0].message);
      }
    });
  }

  sortSections(a, b) {
    let quantityOfA = a.quantity - a.requestTotal;
    let quantityOfB = b.quantity - b.requestTotal;
    if (quantityOfA === 0 && quantityOfB !== 0) {
      return 1;
    } else if (quantityOfB === 0 && quantityOfA !== 0) {
      return -1;
    } else {
      if (a.price > b.price) {
        return 1;
      } else {
        return -1;
      }
    }
  }

  get currentGame() {
    if (this.game) {
      return this.game;
    } else {
      return {};
    }
  }

  get userDropdown() {
    if (
      this.authStore.isAdmin ||
      (this.authStore.isBoxOfficeAdmin && this.commonStore.currentOrgId === CommonConstants.KEYS.UMP_ID)
    ) {
      return createUserDropdown(this.users);
    } else {
      return [];
    }
  }

  get selectedUser() {
    return convertUser(this.user);
  }

  get pickupLocationDropdown() {
    if (this.pickupLocations.length && this.user) {
      const otherOrg = this.user.userOrgInfo[this.commonStore.currentOrgId];
      let homeAway =
        this.user.org.statsId === this.game.teams.home.team.id || otherOrg.org.statsId === this.game.teams.home.team.id
          ? "Home"
          : this.user.org.statsId == null
          ? "Umpires"
          : "Away";

      let departmentTypeId;
      if (this.user.department) {
        departmentTypeId = this.user.department.departmentType.departmentTypeId;
      } else {
        departmentTypeId = 0;
      }

      return _.sortBy(this.pickupLocations, "sequence")
        .filter(pl => {
          let departmentTypeIds = pl.departments.map(d => d.department.departmentTypeId);
          return _.includes(departmentTypeIds, departmentTypeId) && pl.type.homeAwayTypeName === homeAway;
        })
        .map(pl => {
          return { label: pl.pickupLocationName, value: pl };
        });
    }
    return this.pickupLocations.toJSON();
  }

  get pickupLocation() {
    if (this.selectedPickupLocation) {
      return this.selectedPickupLocation;
    }
    return this.defaults["selectedPickupLocation"];
  }

  get section() {
    if (this.selectedSection) {
      return this.selectedSection;
    }
    return this.defaults["selectedSection"];
  }
  get household() {
    if (this.selectedHousehold) {
      return this.selectedHousehold;
    }
    return this.defaults["selectedHousehold"];
  }

  get allotmentRemaining() {
    let allotment = 0;
    if (this.allotmentsAndRequests.allotment && this.user) {
      if (this.selectedRelation.value === "family") {
        if (this.user.org.statsId === this.game.teams.home.team.id) {
          allotment =
            this.allotmentsAndRequests.allotment.homeFamilyAllotment -
            this.allotmentsAndRequests.totalFamilyRequests +
            this.allotmentsAndRequests.totalFamilyTransfersReceived -
            this.allotmentsAndRequests.totalFamilyTransfersGiven;
        } else {
          allotment =
            this.allotmentsAndRequests.allotment.awayFamilyAllotment -
            this.allotmentsAndRequests.totalFamilyRequests +
            this.allotmentsAndRequests.totalFamilyTransfersReceived -
            this.allotmentsAndRequests.totalFamilyTransfersGiven;
        }
      } else {
        if (this.user.org.statsId === this.game.teams.home.team.id) {
          allotment =
            this.allotmentsAndRequests.allotment.homeFriendsAllotment -
            this.allotmentsAndRequests.totalFriendsRequests +
            this.allotmentsAndRequests.totalFriendsTransfersReceived -
            this.allotmentsAndRequests.totalFriendsTransfersGiven;
        } else {
          allotment =
            this.allotmentsAndRequests.allotment.awayFriendsAllotment -
            this.allotmentsAndRequests.totalFriendsRequests +
            this.allotmentsAndRequests.totalFriendsTransfersReceived -
            this.allotmentsAndRequests.totalFriendsTransfersGiven;
        }
      }
    }
    return Math.max(allotment, 0);
  }

  get allotmentDropdown() {
    // Will also depend on inventory
    let dropdown = [];
    let quantity = this.returnToReservations
      ? Math.max(0, this.inventoryRemaining)
      : Math.min(this.allotmentRemaining, this.inventoryRemaining);
    for (let i = 1; i <= quantity; i++) {
      dropdown.push({ value: i, label: i });
    }
    return dropdown;
  }

  get inventoryRemaining() {
    return this.selectedSection
      ? Math.max(0, this.selectedSection.value.quantity - this.selectedSection.value.requestTotal)
      : 0;
  }

  get sectionInventoryOptions() {
    return this.sectionInventory
      .filter(si => si.relation.toLowerCase() === this.selectedRelation.value.toLowerCase())
      .filter(si => {
        const otherOrg = this.user.userOrgInfo[this.commonStore.currentOrgId];
        let homeAway =
          this.user.org.statsId === this.game.teams.home.team.id ||
          otherOrg.org.statsId === this.game.teams.home.team.id
            ? 1
            : 2;
        homeAway = this.user.org.orgId === 31 ? 3 : homeAway;
        return si.homeAwayTypeId === homeAway;
      })
      .sort(this.sortSections)
      .map(si => {
        let quantity = Math.max(0, si.quantity - si.requestTotal);
        let label = `${si.sectionNumber} @ $${si.price} (${quantity} left)`;
        si.left = quantity;
        return { label: label, value: si };
      });
  }

  get ticketRequest() {
    let homeAway = this.user.org.statsId === this.game.teams.home.team.id ? 1 : 2;
    const otherOrg = this.user.userOrgInfo[this.commonStore.currentOrgId];
    return {
      submitterUser: { userId: this.authStore.currentUserId },
      requesterUser: { userId: this.user.userId },
      requesterDepartment: { departmentId: otherOrg.department.departmentId },
      requesterOrg: { orgId: otherOrg.org.orgId },
      homeAwayType: { homeAwayTypeId: homeAway },
      recipientFirstName: this.firstName,
      recipientLastName: this.lastName,
      relation: this.selectedRelation.value,
      householdType: this.selectedHousehold.value !== "" ? this.selectedHousehold.value : null,
      section: { sectionId: this.selectedSection.value.sectionId },
      quantity: this.quantity.value,
      pickupLocation: { pickupLocationId: this.pickupLocation.value.pickupLocationId },
      gamePk: this.gamePK,
      venueSeason: { venueSeasonId: this.game.venueSeasonId },
      comments: this.comments,
      deliveryEmail: this.deliveryEmail,
      deliveryMobilePhone: this.deliveryMobilePhone ? this.deliveryMobilePhone.trim() : this.deliveryMobilePhone,
      dialCode: this.dialCode,
      countryCode: this.countryCode,
      isDeleted: false
    };
  }

  get isValidRequest() {
    return (
      this.firstName &&
      this.firstName.length &&
      this.lastName &&
      this.lastName.length &&
      this.selectedRelation &&
      this.selectedSection &&
      this.quantity &&
      this.pickupLocation &&
      (!this.commonStore.useHHDropdown || this.selectedHousehold) &&
      (!this.pickupLocation.value.digitalDelivery ||
        !this.pickupLocation.value.requireEmail ||
        !validate.validate({ from: this.deliveryEmail }, { from: { email: true } })) &&
      (!this.pickupLocation.value.digitalDelivery ||
        !this.pickupLocation.value.requireMobilePhone ||
        this.validPhone) &&
      (!this.pickupLocation.value.digitalDelivery ||
        !this.pickupLocation.value.allowEitherContactMethod ||
        (this.validPhone && this.deliveryMobilePhone) ||
        !validate.validate({ from: this.deliveryEmail }, { from: { email: true } }))
    );
  }
}

decorate(RequestStore, {
  currentGame: computed,
  userDropdown: computed,
  selectedUser: computed,
  pickupLocationDropdown: computed,
  pickupLocation: computed,
  section: computed,
  household: computed,
  allotmentRemaining: computed,
  allotmentDropdown: computed,
  inventoryRemaining: computed,
  sectionInventoryOptions: computed,
  isValidRequest: computed
});

export default RequestStore;
