import styled from "styled-components";

const Date = styled.div.attrs({
  id: "date"
})`
  &&& {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: -0.5rem;
    margin-top: -0.25rem;
  }
`;

export default Date;
