import { action, extendObservable, autorun } from "mobx";

class UserBulkAllotmentStore {
  constructor(authStore, commonStore, compTixApi, routerStore, userAdminStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.userAdminStore = userAdminStore;

    this.defaults = {
      showModal: false,
      allotments: [],
      selectedYear: { label: "2024", value: 20204 },
      years: [
        {
          label: "2023",
          value: 2023
        },
        {
          label: "2024",
          value: 2024
        }
      ],
      isSaving: false
    };

    extendObservable(this, {
      showModal: this.defaults["showModal"],
      allotments: this.defaults["allotments"],
      selectedYear: this.defaults["selectedYear"],
      years: this.defaults["years"],
      toggleModal: action(value => {
        this.showModal = !this.showModal;
      }),
      isSaving: this.defaults["isSaving"],
      onOpen: action(() => {
        this.selectedYear = {
          label: this.commonStore.curSeason.year.toString(),
          value: this.commonStore.curSeason.year
        };
        this.generateAllotments();
        this.toggleModal();
      }),
      setAllotments: action(allotments => {
        this.allotments = allotments;
      }),
      setSelectedYear: action(year => {
        this.selectedYear = year;
      }),
      onYearChange: action(year => {
        this.setSelectedYear(year);
        this.generateAllotments();
      }),
      cancelUpdateAllotment: action(() => {
        this.toggleModal();
        this.resetStore();
      }),
      saveAllotments: action(() => {
        this.isSaving = true;
        compTixApi
          .bulkUpdateUserAllotments(
            this.userAdminStore.userIdsToUpdateAllotment,
            this.selectedYear.value,
            this.allotments
          )
          .then(data => {
            this.userAdminStore.updateUsers(this.commonStore.currentOrgId);
            this.userAdminStore.resetUserToUpdateAllotment();
            this.toggleModal();
            this.isSaving = false;
          })
          .catch(error => {
            this.isSaving = false;
          });
      }),
      resetStore: action(() => {
        this.showModal = this.defaults["showModal"];
        this.allotments = this.defaults["allotments"];
      })
    });

    autorun(() => {
      if (!this.routerStore.isUserAdminTab) {
        this.resetStore();
      }
    });
  }

  onAllotmentChange = (allotment, key, value) => {
    allotment[key] = value;
  };

  generateAllotments = () => {
    let seasons = this.commonStore.seasons.filter(season => season.year === this.selectedYear.value);
    this.setAllotments(
      seasons.map(season => {
        return {
          awayFamilyAllotment: "",
          awayFriendsAllotment: "",
          homeFamilyAllotment: "",
          homeFriendsAllotment: "",
          season: season
        };
      })
    );
  };
}

export default UserBulkAllotmentStore;
