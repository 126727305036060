import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, PrimaryButton, StickyFooterButtons } from "best-common-react";

class DepartmentEditFooter extends React.Component {
  render() {
    const { departmentEditStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <PrimaryButton
          onClick={departmentEditStore.save}
          disabled={!departmentEditStore.isValidSave || departmentEditStore.isSaving}
        >
          Save
        </PrimaryButton>
        <LightButton onClick={departmentEditStore.cancel}>Cancel</LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(DepartmentEditFooter));
