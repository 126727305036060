import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import AdminProtected from "../../components/protected/AdminProtected";
import EventAdminBody from "../../components/events/admin/EventAdminBody";
import { CardBody, CardContainer, CardHeader } from "best-common-react";
import LoadingHeader from "../../components/loading/LoadingHeader";
import AdminOrBoxOfficeProtected from "../../components/protected/AdminOrBoxOfficeProtected";

class AdminEventsContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle />
        <CardContainer>
          <CardHeader>
            <LoadingHeader title="Events" />
          </CardHeader>
          <CardBody>
            <EventAdminBody />
          </CardBody>
        </CardContainer>
      </div>
    );
  }
}

export default AdminOrBoxOfficeProtected(AdminEventsContainer);
