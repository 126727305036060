import React from "react";
import styled from "styled-components";

const PriceInput = styled.input.attrs({
  className: "form-control text-right",
  type: "text"
})`
  height: 28px;
`;

class OverridePriceFormatter extends React.Component {
  render() {
    const { overridePrice, onChange } = this.props.value;
    return (
      <div>
        <PriceInput
          value={overridePrice}
          placeholder="$"
          onChange={event => onChange(event.target.value)}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
        />
      </div>
    );
  }
}

export default OverridePriceFormatter;
