import React from "react";
import TeamTitle from "../../../components/title/TeamTitle";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import EventReservationsBody from "../../../components/reservations/reports/EventReservationsFilter";
import AdminProtected from "../../../components/protected/AdminProtected";
import EventReservationsTable from "../../../components/reservations/reports/EventReservationsTable";
import ReservationsDeleteModal from "../../../components/reservations/delete/ReservationsDeleteModal";
import EventReservationsFooter from "../../../components/reservations/reports/EventReservationsFooter";
import AssignDigitalTicketsModal from "../../../components/reservations/AssignDigitalTicketsModal";
import RevokeDigitalTicketsModal from "../../../components/reservations/RevokeDigitalTicketsModal";
import ReservationsCopyModal from "../../../components/reservations/copy/ReservationsCopyModal";

class EventReservationsReportContainer extends React.PureComponent {
  render() {
    const { eventReservationsStore } = this.props.rootStore;

    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>Event Reservations Report</CardHeader>
          <CardBody>
            <ReservationsDeleteModal callback={() => eventReservationsStore.getReservations()} />
            <ReservationsCopyModal callback={() => eventReservationsStore.getReservations()} />
            <AssignDigitalTicketsModal callback={() => eventReservationsStore.getReservations()} />
            <RevokeDigitalTicketsModal callback={() => eventReservationsStore.getReservations()} />
            <EventReservationsBody gamePk={this.props.match.params.gamePk} />
            <EventReservationsTable />
          </CardBody>
          <CardFooter>
            <EventReservationsFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default AdminProtected(EventReservationsReportContainer);
