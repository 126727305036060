import React from "react";
import VenueEditPickupLocations from "./VenueEditPickupLocations";
import VenueEditFilters from "./VenueEditFilters";
import { DesktopTabletView, MobileView } from "best-common-react";
import VenueEditPickupMobile from "./VenueEditPickupMobile";
import VenueEditTicketInventories from "./VenueEditTicketInventories";
import VenueImportModal from "./VenueImportModal";
import SectionCategoryModal from "./SectionCategoryModal";

class VenueEditBody extends React.Component {
  render() {
    return (
      <div>
        <VenueEditFilters />
        <VenueImportModal />
        <SectionCategoryModal />
        <DesktopTabletView>
          <VenueEditPickupLocations />
          <VenueEditTicketInventories />
        </DesktopTabletView>
        <MobileView>
          <VenueEditPickupMobile />
          <VenueEditTicketInventories />
        </MobileView>
      </div>
    );
  }
}

export default VenueEditBody;
