import React from "react";
import { TableHeader } from "best-common-react";

const ExportSettingsTableHeader = ({ displayLabelColumn }) => {
  return (
    <TableHeader>
      <tr>
        <th className="p-1">
          <div className="d-flex d-column">
            <div className="col-2" />
            <div className="col-5">Display Info</div>
            {displayLabelColumn ? <div className="col-5">Display Style</div> : null}
          </div>
        </th>
      </tr>
    </TableHeader>
  );
};

export default ExportSettingsTableHeader;
