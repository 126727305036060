import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { BestDropdown } from "best-common-react";

const GameSelectedHolder = styled.div.attrs({ className: "d-flex align-items-end" })`
  @media (max-width: 767px) {
    height: 0px;
  }
  @media (min-width: 768px) {
    height: 63px;
  }
`;

const GamesSelected = styled.span`
  font-size: 0.8rem;
  color: ${props => props.theme["dark-grey"]};
  font-style: italic;
`;

class VenueBulkOverrideFilters extends React.Component {
  render() {
    const { venueBulkOverrideStore, commonStore } = this.props.rootStore;
    return (
      <div className="row">
        <div className="col-xs-12 col-md-3">
          <BestDropdown
            id="Season"
            title="Season"
            required={true}
            options={commonStore.seasonDropdownList}
            value={venueBulkOverrideStore.selectedSeason}
            onChange={venueBulkOverrideStore.setSelectedSeason}
          />
        </div>
        <div className="col-xs-12 col-md-3">
          <BestDropdown
            id="Venue"
            title="Venue"
            required={true}
            options={venueBulkOverrideStore.venueOptions}
            value={venueBulkOverrideStore.selectedVenue}
            onChange={venueBulkOverrideStore.setSelectedVenue}
          />
        </div>
        <div className="col-sm-hidden col-md-4">
          <GameSelectedHolder>
            <GamesSelected>
              {venueBulkOverrideStore.gamesSelected ? (
                <span>{venueBulkOverrideStore.gamesSelected} games selected</span>
              ) : null}
            </GamesSelected>
          </GameSelectedHolder>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(VenueBulkOverrideFilters));
