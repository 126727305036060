import React from "react";
import { CardBody, CardContainer, CardHeader } from "best-common-react";
import LoadingHeader from "../../components/loading/LoadingHeader";
import AdminProtected from "../../components/protected/AdminProtected";
import AdminSupportFilters from "../../components/support/AdminSupportFilters";
import AdminSupportBody from "../../components/support/AdminSupportBody";

const FEATURE_SUPPORT = process.env.FEATURE_SUPPORT === "true";

class AdminSupportContainer extends React.Component {
  render() {
    return FEATURE_SUPPORT ? (
      <div>
        <CardContainer>
          <CardHeader>
            <LoadingHeader title="Ticket Requests" />
          </CardHeader>
          <CardBody>
            <AdminSupportFilters />
            <AdminSupportBody />
          </CardBody>
        </CardContainer>
      </div>
    ) : null;
  }
}

export default AdminProtected(AdminSupportContainer);
