import React from "react";
import { BestLink } from "best-common-react";

class EventGameLinkFormatter extends React.Component {
  render() {
    const { path } = this.props;
    const { gameStr, gamePk } = this.props.value;
    const to = `/events/${gamePk}/${path}`;

    return (
      <BestLink to={to}>
        <div>{gameStr}</div>
      </BestLink>
    );
  }
}

export default EventGameLinkFormatter;
