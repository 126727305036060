import React from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import GameTimeFormatter from "../../elements/tables/GameTimeFormatter";
import RequestDateFormatter from "../../elements/tables/RequestDateFormatter";
import { Accordion, AttributeHeader, AttributeValue, Icon, BestLink } from "best-common-react";

const TicketsLeft = styled.div.attrs({ className: "mt-2", id: "tickets-left" })`
  font-size: 0.8rem;
  color: ${props => props.theme["dark-grey"]};
`;

const DayTime = styled.div.attrs({
  id: "day-time"
})`
  color: ${props => props.theme["dark-grey"]};
  font-size: 0.875rem;
  line-height: ${props => props.theme.lineHeight["eventGrid"]};
`;

const Game = styled.div.attrs({
  id: "game"
})`
  &&& {
    font-size: 0.875rem;
    font-weight: bold;
    line-height: ${props => props.theme.lineHeight["eventGrid"]};
  }
`;
const SmallerDate = styled.div.attrs({
  id: "smaller-date"
})`
  &&& {
    font-size: 1rem;
    font-weight: bold;
    line-height: ${props => props.theme.lineHeight["eventGrid"]};
  }
`;

class ReservationsAccordionMobile extends React.Component {
  render() {
    const { data, ...rest } = this.props;
    const { reservationDeleteStore } = this.props.rootStore;

    let gameDate = data.gameDate.tbd
      ? moment(data.gameDate.gameDate).utc()
      : moment(data.gameDate.gameDate).tz(data.gameDate.tzId);
    return (
      <div id="reservation-accordion-mobile" {...rest}>
        <Accordion id={this.props.index.toString()}>
          <Accordion.Header>
            <div className="d-flex justify-content-between align-items-center">
              <BestLink to={"/reservations/edit/" + data.ticketRequestId}>
                <Icon iconName="fa-pencil-alt" className="fa-lg " id="edit-icon" />
              </BestLink>
              <Icon
                id="delete-icon"
                iconName="fa-trash-alt"
                className="fa-lg"
                onClick={() => {
                  reservationDeleteStore.setReservationToDelete(data.ticketRequestId);
                }}
              />
            </div>
          </Accordion.Header>
          <Accordion.Header isToggle={true}>
            <div>
              <div className="d-flex">
                <div className="mr-5">
                  <SmallerDate>{gameDate.format("MMM D")}</SmallerDate>
                  <DayTime>{gameDate.format("ddd")}</DayTime>
                </div>
                <div>
                  <Game>{data.game}</Game>
                  <DayTime>
                    <GameTimeFormatter value={data.gameDate} />
                  </DayTime>
                </div>
              </div>
              <TicketsLeft>
                {data.quantity} {data.quantity === 1 ? "ticket" : "tickets"} for {data.recipient}
              </TicketsLeft>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <AttributeHeader>Venue</AttributeHeader>
              <AttributeValue>{data.venue}</AttributeValue>

              <AttributeHeader>Relation</AttributeHeader>
              <AttributeValue>{data.relation}</AttributeValue>

              <AttributeHeader>Section</AttributeHeader>
              <AttributeValue>{data.sectionDescription}</AttributeValue>

              <AttributeHeader>Request Date</AttributeHeader>
              <AttributeValue>
                {" "}
                <RequestDateFormatter value={data.requestDate} />{" "}
              </AttributeValue>
            </div>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ReservationsAccordionMobile));
