import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import {
  AttributeHeader,
  BestLabel,
  BestSelect,
  Input,
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import EventAccess from "../../overrides/EventAccess";
import EventCutoff from "../../overrides/EventCutoff";
import AllocationOverride from "../../overrides/AllocationOverride";

const EventTimingHolder = styled.div.attrs({
  className: "mt-1 p-2"
})`
  border: 1px solid ${props => props.theme["grey"]}
    ${props => (props.disabled ? "background-color: " + props.theme["light-grey"] : "")};
`;

const DepartmentSelect = styled(BestSelect)`
  &&& {
    width: 100%;
  }
`;

class EventDetailsBulkAccessOverrideModal extends React.Component {
  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
  }

  createDepartmentValue(departments) {
    return departments.map(d => {
      return {
        label: d.departmentName,
        value: d.departmentId
      };
    });
  }

  updateValue(value) {
    const { eventDetailsStore } = this.props.rootStore;
    eventDetailsStore.setSelectedEventBulkAccessOverride(value);
  }

  render() {
    const { eventDetailsStore } = this.props.rootStore;
    let access = eventDetailsStore.selectedEventBulkAccessOverride;
    access = !access ? eventDetailsStore.newEventOverride() : access;
    let curDepartments = this.createDepartmentValue(eventDetailsStore.currentDepartments);

    return (
      <Modal
        show={eventDetailsStore.showEventBulkAccessOverrideModal}
        onClose={() => {
          eventDetailsStore.setShowEventBulkAccessOverrideModal(false);
        }}
        size="lg"
      >
        <ModalHeaderWithTitleAndClose
          title="Bulk Override Ticket Access"
          close={() => {
            eventDetailsStore.setShowEventBulkAccessOverrideModal(false);
          }}
        />
        <ModalBody>
          {access ? (
            <div>
              <div className="row">
                <div className="col">
                  <AttributeHeader>Department</AttributeHeader>
                </div>
                <div className="col">
                  {/*eslint-disable-next-line*/}
                  <a className="float-right" href={"#"} onClick={eventDetailsStore.addAllBulkDepartments}>
                    Add All
                  </a>
                </div>
              </div>
              <div className="row" style={{ marginBottom: ".75 rem" }}>
                <div className="col">
                  <DepartmentSelect
                    isMulti
                    id="departments-multi-select"
                    options={curDepartments}
                    defaultValue={eventDetailsStore.bulkOverrideDepartments}
                    value={eventDetailsStore.bulkOverrideDepartments}
                    onChange={(v, e) => {
                      eventDetailsStore.addBulkOverrideDepartment(v, e);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-lg-6">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="bulkEventAccess"
                      onChange={() => {
                        access.useAccess = !access.useAccess;
                      }}
                    />
                    <label className="form-check-label" htmlFor="bulkEventAccess">
                      Event Access
                    </label>
                  </div>
                  <EventTimingHolder disabled={!access.useAccess}>
                    <EventAccess
                      id="event-bulk-access"
                      access={access}
                      onUpdate={value => {
                        this.updateValue(value);
                      }}
                      disabled={!access.useAccess}
                      wrap={true}
                    />
                  </EventTimingHolder>
                </div>
                <div className="col-xs-12 col-lg-6 mt-1 mt-lg-0">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="bulkEventCutoff"
                      onChange={() => {
                        access.useCutoff = !access.useCutoff;
                      }}
                    />
                    <label className="form-check-label" htmlFor="bulkEventCutoff">
                      Event Cutoff
                    </label>
                  </div>
                  <EventTimingHolder disabled={!access.useCutoff}>
                    <EventCutoff
                      id="event-bulk-cutoff"
                      access={access}
                      onUpdate={value => {
                        this.updateValue(value);
                      }}
                      disabled={!access.useCutoff}
                      wrap={true}
                    />
                  </EventTimingHolder>
                </div>
              </div>
              <div className="row" style={{ marginBottom: ".75 rem", marginTop: ".75 rem" }}>
                <div className="col-xs-12 col-lg-6">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="bulkEventAllotment"
                      onChange={() => {
                        access.useAllotment = !access.useAllotment;
                      }}
                    />
                    <label className="form-check-label" htmlFor="bulkEventAllotment">
                      Event Allotment
                    </label>
                  </div>
                </div>
              </div>
              <AllocationOverride allocation={access} disabled={!access.useAllotment} />
            </div>
          ) : (
            <div />
          )}
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton
              className="mr-2"
              onClick={eventDetailsStore.saveEventBulkAccessOverride}
              disabled={
                !eventDetailsStore.bulkOverrideDepartments.length > 0 ||
                !(access.useAccess || access.useCutoff || access.useAllotment)
              }
            >
              Bulk Override
            </PrimaryButton>
            <LightButton
              onClick={() => {
                eventDetailsStore.setShowEventBulkAccessOverrideModal(false);
              }}
            >
              Cancel
            </LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(EventDetailsBulkAccessOverrideModal));
