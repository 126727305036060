import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import VenueEditBody from "../../components/venue/edit/VenueEditBody";
import VenueEditFooter from "../../components/venue/edit/VenueEditFooter";
import VenueEditHeader from "../../components/venue/edit/VenueEditHeader";
import GlobalProtected from "../../components/protected/GlobalProtected";

class VenueEditContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>
            <VenueEditHeader />
          </CardHeader>
          <CardBody>
            <VenueEditBody />
          </CardBody>
          <CardFooter>
            <VenueEditFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default GlobalProtected(VenueEditContainer);
