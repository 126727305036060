import React from "react";
import { inject } from "mobx-react";
import { Icon } from "best-common-react";

class UserEditPasswordFormatter extends React.Component {
  render() {
    const { userAdminStore } = this.props.rootStore;
    return (
      <div id="edit-password-formatter">
        <Icon
          iconName="fa-key"
          onClick={() => {
            userAdminStore.openChangePassword(this.props.value);
          }}
        />
      </div>
    );
  }
}

export default inject("rootStore")(UserEditPasswordFormatter);
