import React from "react";
import PropTypes from "prop-types";
import TicketAllotmentTable from "./TicketAllotmentTable";
import TicketAllotmentFilters from "./TicketAllotmentFilters";
import { Checkbox, FormColumn4Wide } from "best-common-react";

class TicketAllotment extends React.Component {
  render() {
    const {
      allotmentYear,
      allotments,
      onYearChange,
      onAllotmentChange,
      userEditStore,
      commonStore,
      authStore
    } = this.props;

    return (
      <div id="ticket-allotment">
        <div className="row">
          <FormColumn4Wide>
            <TicketAllotmentFilters allotmentYear={allotmentYear} onYearChange={onYearChange} />
          </FormColumn4Wide>
          <FormColumn4Wide className="mt-4">
            {authStore.isGlobal && !commonStore.currentOrg.allStarTeam ? (
              <Checkbox
                checked={userEditStore.user.get("allStar")}
                label="All Star Team"
                onChange={event => {
                  userEditStore.updateUserInfo("allStar", event.target.checked);
                }}
              />
            ) : null}
          </FormColumn4Wide>
        </div>
        <div className="row">
          <FormColumn4Wide>
            <TicketAllotmentTable allotments={allotments} onAllotmentChange={onAllotmentChange} />
          </FormColumn4Wide>
        </div>
      </div>
    );
  }
}

TicketAllotment.propTypes = {
  allotmentYear: PropTypes.number.isRequired,
  allotments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  idKey: PropTypes.any,
  onAllotmentChange: PropTypes.func.isRequired,
  onYearChange: PropTypes.func.isRequired
};

export default TicketAllotment;
