import React from "react";
import { inject, observer } from "mobx-react";
import { Icon, LightButton, Modal, ModalBody, ModalFooter, ModalHeaderWithTitleAndClose } from "best-common-react";
import styled from "styled-components";

const WarningIcon = styled(Icon).attrs({ className: "mr-2" })`
  &&& {
    color: #ff9100;
  }
`;

const Information = styled.div.attrs({ className: "mt-2" })`
  &&& {
    color: #ff9100;
  }
`;
class EventMessageModal extends React.Component {
  render() {
    const { eventsStore } = this.props.rootStore;
    return (
      <Modal
        show={eventsStore.showPopover}
        onClose={() => {
          eventsStore.setShowPopover(false);
        }}
        size="lg"
      >
        <ModalHeaderWithTitleAndClose
          title="Additional Information"
          close={() => {
            eventsStore.setShowPopover(false);
          }}
        />
        <ModalBody>
          {eventsStore.message ? (
            <Information>
              <span>
                <WarningIcon iconName="fa-info-circle" />
              </span>
              {eventsStore.message}
            </Information>
          ) : (
            <div />
          )}
        </ModalBody>
        <ModalFooter>
          <div>
            <LightButton onClick={() => eventsStore.setShowPopover(false)}>Close</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(EventMessageModal));
