import { extendObservable, computed, autorun, action, decorate } from "mobx";
import _ from "lodash";
import moment from "moment";
import { getTeamAbbreviation } from "../../utilities/GameUtility";

class ReservationsStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      reservations: [],
      sortFilters: {
        direction: "ASC",
        key: "gameDate"
      },
      searchTerm: "",
      overrides: new Map()
    };

    extendObservable(this, {
      reservations: this.defaults["reservations"],
      sortFilters: this.defaults["sortFilters"],
      searchTerm: this.defaults["searchTerm"],
      overrides: this.defaults["overrides"],
      setReservations: action(values => {
        this.reservations = values;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      setSearchTerm: action(searchTerm => {
        this.searchTerm = searchTerm.toLowerCase();
      })
    });

    autorun(() => {
      if (this.routerStore.isReservationsTab) {
        if (this.authStore.currentUserId && this.authStore.currentUserId !== -1) {
          if (this.authStore.isGlobal) {
            this.commonStore.setSelectedOrgId(this.authStore.userData.org.orgId);
          }
          this.getReservations();
        }
      }
    });
  }

  get currentReservations() {
    return this.sort(
      this.reservations
        .map(r => {
          let section = r.section ? r.section.sectionNumber : "";
          let sectionPrice = r.section ? r.section.sectionPrice : "";
          if (r.inventoryOverride && r.inventoryOverride.price) {
            sectionPrice = r.inventoryOverride.price;
          }
          let sectionDescription = section + (sectionPrice ? " @ $" + sectionPrice.toFixed(2) : "");
          let game =
            (r.game && (r.game.gameType === "Z" || r.game.gameType === "O" || r.game.gameType === "A")) ||
            !r.game.description
              ? getTeamAbbreviation(r.game, false) + " @ " + getTeamAbbreviation(r.game, true)
              : r.game.description;
          let tzId = r.game.venue && r.game.venue.timeZone ? r.game.venue.timeZone.id : -1;
          let gameDate = r.game ? { gameDate: r.game.gameDate, tzId: tzId, tbd: r.game.status.startTimeTBD } : {};
          let venueName = r.game ? r.game.venue.name : "";
          let requestDate = r.game ? { requestDate: r.requestDate, tzId: this.authStore.userData.org.timeZone } : {};

          return {
            ticketRequestId: r.ticketRequestId,
            ticketRequestId1: r.ticketRequestId,
            game: game,
            gameDate: gameDate,
            venue: venueName,
            relation: _.upperFirst(r.relation),
            quantity: r.quantity,
            section: section,
            sectionPrice: sectionPrice,
            sectionDescription: sectionDescription,
            requestDate: requestDate,
            recipient: r.recipient,
            reservation: r,
            gameTime: gameDate.gameDate,
            comments: r.comments
          };
        })
        .filter(reservation => {
          return (
            _.includes(reservation.sectionDescription.toLowerCase(), this.searchTerm) ||
            _.includes(
              moment(reservation.reservation.game.gameDate)
                .tz(reservation.reservation.game.venue.timeZone.id)
                .format("dddd M/DD - h:mma zz")
                .toLowerCase(),
              this.searchTerm
            ) ||
            _.includes(
              moment(reservation.requestDate)
                .tz(reservation.reservation.game.venue.timeZone.id)
                .format("dddd M/DD - h:mma zz")
                .toLowerCase(),
              this.searchTerm
            ) ||
            _.includes(reservation.venue.toLowerCase(), this.searchTerm) ||
            _.includes(reservation.recipient.toLowerCase(), this.searchTerm) ||
            _.includes(reservation.quantity.toString().toLowerCase(), this.searchTerm) ||
            _.includes(reservation.relation.toLowerCase(), this.searchTerm) ||
            _.includes(reservation.game?.toLowerCase(), this.searchTerm) ||
            _.includes(reservation.comments.toLowerCase(), this.searchTerm)
          );
        }),
      this.sortFilters
    );
  }

  sort(reservations, searchFilters) {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(reservations, "gameTime");
    } else if (direction === "ASC") {
      if (searchFilters.key === "gameDate") {
        return _.sortBy(reservations, "gameTime");
      } else if (searchFilters.key === "requestDate") {
        return _.sortBy(reservations, [reservation => reservation.requestDate.requestDate]);
      } else {
        return _.sortBy(reservations, searchFilters.key);
      }
    } else if (direction === "DESC") {
      if (searchFilters.key === "gameDate") {
        return _.sortBy(reservations, "gameTime").reverse();
      } else if (searchFilters.key === "requestDate") {
        return _.sortBy(reservations, [reservation => reservation.requestDate.requestDate]).reverse();
      } else {
        return _.sortBy(reservations, searchFilters.key).reverse();
      }
    } else {
      return reservations;
    }
  }

  getReservations() {
    return this.compTixApi.getReservationsForUser(this.authStore.currentUserId).then(data => {
      this.setReservations(data);
    });
  }
}

decorate(ReservationsStore, {
  currentReservations: computed
});

export default ReservationsStore;
