import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, PrimaryButton, StickyFooterButtons } from "best-common-react";

class TransferTicketsFooter extends React.Component {
  render() {
    const { transferRequestStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <PrimaryButton
          onClick={transferRequestStore.transferTickets}
          disabled={
            !transferRequestStore.isValidTransfer || transferRequestStore.isSending || transferRequestStore.game.locked
          }
          id="transfer-tickets"
        >
          Transfer Tickets
        </PrimaryButton>
        <LightButton onClick={transferRequestStore.cancel} id="cancel">
          Cancel
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(TransferTicketsFooter));
