import React from "react";
import { Accordion, AnchorLink, AttributeHeader, Icon } from "best-common-react";
import styled from "styled-components";
import OverrideFormatter from "../overrides/OverrideFormatter";
import EventTicketTimeFormatterWithOverride from "../events/admin/details/formatters/EventTicketTimeFormatterWithOverride";
import { inject, observer } from "mobx-react";

const IconHolder = styled.div.attrs({
  className: "d-flex justify-content-between align-items-center",
  id: "icon-holder"
})``;

const Header = styled(AttributeHeader).attrs({
  id: "attribute-header"
})``;

const OverrideTitle = styled.span`
  &&& {
    color: ${props => props.theme["dark-grey"]};
    font-weight: 300;
    font-size: 0.75rem;
  }
`;

const SectionTitle = styled.div.attrs({ className: "align-content-left" })`
  &&& {
    font-weight: 300;
    color: ${props => props.theme["black"]};
  }
`;

const AddOverride = styled.div.attrs({ className: "d-flex justify-content-center" })`
  color: ${props => props.theme["sky-blue"]};
`;

class OverrideAccordion extends React.Component {
  render() {
    const { authStore } = this.props.rootStore;
    let editButtons;
    let accordionTitle =
      this.props.data.overrideType === "department" ? this.props.data.departmentName : this.props.data.section;

    if (authStore.isGlobal) {
      editButtons = this.props.data.editAndDelete.hasOverrides ? (
        <IconHolder>
          <Icon
            iconName="fa-pencil-alt"
            className="fa-lg"
            id="edit-icon"
            onClick={this.props.data.editAndDelete.onEdit}
          />
          <OverrideTitle>Override</OverrideTitle>
          <Icon
            id="delete-icon"
            iconName="fa-trash-alt"
            className="fa-lg"
            onClick={this.props.data.editAndDelete.onDelete}
          />
        </IconHolder>
      ) : (
        <AddOverride>
          <AnchorLink id={this.props.data.id} onClick={this.props.data.editAndDelete.onAdd}>
            <small>Add Override</small>
          </AnchorLink>
        </AddOverride>
      );
    } else {
      editButtons = <span />;
    }
    let accordionBody =
      this.props.data.overrideType === "department" ? (
        <Accordion.Body>
          <Header>Event Access (local time)</Header>
          <EventTicketTimeFormatterWithOverride value={this.props.data.access} />

          <Header>Event Cutoff (local time)</Header>
          <EventTicketTimeFormatterWithOverride value={this.props.data.cutoff} />

          <Header>Home Family</Header>
          <EventTicketTimeFormatterWithOverride value={this.props.data.homeFamily} minutes={false} />

          <Header>Home Friends)</Header>
          <EventTicketTimeFormatterWithOverride value={this.props.data.homeFriends} minutes={false} />

          <Header>Away Family</Header>
          <EventTicketTimeFormatterWithOverride value={this.props.data.awayFamily} minutes={false} />

          <Header>Away Friends</Header>
          <EventTicketTimeFormatterWithOverride value={this.props.data.awayFriends} minutes={false} />
        </Accordion.Body>
      ) : (
        <Accordion.Body>
          <Header># of Seats</Header>
          <OverrideFormatter value={this.props.data.inventory} />

          <Header>Price</Header>
          <OverrideFormatter value={this.props.data.price} />
        </Accordion.Body>
      );
    return (
      <div>
        <Accordion id="accordion-override" {...this.props}>
          <Accordion.Header isToggle={true}>
            {editButtons}
            <SectionTitle className="d-flex">{accordionTitle}</SectionTitle>
          </Accordion.Header>
          {accordionBody}
        </Accordion>
      </div>
    );
  }
}

export default inject("rootStore")(observer(OverrideAccordion));
