import axios from "axios";

const SERVICES_URL = process.env.REACT_APP_SERVICES_URL;
const OAUTH_URL = process.env.REACT_APP_SERVICES_URL + "/oauth/token";

class AuthApi {
  getToken = (body, refresh) => {
    const client = btoa("comptix:secret");
    let request = {
      url: OAUTH_URL,
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: `Basic ${client}`,
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
      },
      data: body
    };
    if (refresh) {
      request.headers.addToken = true;
    }
    return axios(request)
      .then(response => response.data)
      .catch(error => error.response);
  };

  getUserInfo = () => {
    const url = SERVICES_URL + "/login/user";
    return axios.get(url).then(response => response.data.entity);
  };
}

export default AuthApi;
