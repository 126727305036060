import React from "react";
import { StickyFooterButtons, LightButton } from "best-common-react";
import { inject, observer } from "mobx-react";

const UserReservationSummaryReportFooter = ({ rootStore: { userSummaryStore } }) => (
  <StickyFooterButtons>
    <LightButton onClick={() => userSummaryStore.buildUserSummary()}>Export to Excel</LightButton>
  </StickyFooterButtons>
);

export default inject("rootStore")(observer(UserReservationSummaryReportFooter));
