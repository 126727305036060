import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Accordion, AttributeHeader, AttributeValue, Icon, BestLink } from "best-common-react";

const VenueHeaderName = styled.span.attrs({ className: "mx-3" })``;

class VenueAccordionMobile extends React.Component {
  render() {
    const { authStore } = this.props.rootStore;
    let header;
    if (authStore.isGlobal) {
      header = (
        <div id="venue-accordion-mobile">
          <BestLink to={this.props.data.editLink}>
            <Icon iconName="fa-pencil-alt" className="fa-lg d-inline-block mx-1" id="edit-icon" />
          </BestLink>
          <VenueHeaderName>{this.props.data.venueName}</VenueHeaderName>
        </div>
      );
    } else {
      header = (
        <div>
          <VenueHeaderName>{this.props.data.venueName}</VenueHeaderName>
        </div>
      );
    }

    return (
      <div>
        <Accordion id={this.props.index.toString()}>
          <Accordion.Header isToggle={true}>{header}</Accordion.Header>
          <Accordion.Body>
            <AttributeHeader>Location</AttributeHeader>
            <AttributeValue>{this.props.data.location}</AttributeValue>

            <AttributeHeader>Home Inventory</AttributeHeader>
            <AttributeValue>{this.props.data.homeInventory}</AttributeValue>

            <AttributeHeader>Away Inventory</AttributeHeader>
            <AttributeValue>{this.props.data.awayInventory}</AttributeValue>

            <AttributeHeader>Home Pickup Locations</AttributeHeader>
            <AttributeValue>{this.props.data.homePickupLocations}</AttributeValue>

            <AttributeHeader>Away Pickup Locations</AttributeHeader>
            <AttributeValue>{this.props.data.awayPickupLocations}</AttributeValue>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

export default inject("rootStore")(observer(VenueAccordionMobile));
