import React from "react";
import AccordionTableMobileEx from "./AccordionTableMobileEx";
import { AccordionTableWrapper, Icon } from "best-common-react";

class AccordionTableExample extends React.Component {
  constructor(props) {
    super(props);
    let temp = [];
    for (let i = 0; i < 50; i++) {
      temp.push({
        col1: (
          <a href="/">
            <Icon iconName="fa-key" />
          </a>
        ),
        col2: (
          <a href="/">
            <Icon iconName="fa-pencil-alt" />
          </a>
        ),
        col3: "Cody, Dan",
        col4: i,
        col5: i,
        col6: i,
        col7: i
      });
    }
    const cols = [
      { key: "col1", name: "", width: 35, resizable: false, locked: true },
      { key: "col2", name: "", width: 35, resizable: false, locked: true },
      {
        key: "col3",
        name: "Name",
        locked: true
      },
      { key: "col4", name: "Col 4", sortable: true },
      { key: "col5", name: "Col 5", sortable: true },
      { key: "col6", name: "Col 6", sortable: true },
      { key: "col7", name: "Col 7", sortable: true }
    ];
    this.state = { data: temp, sortedData: temp, cols: cols };
  }

  render() {
    return (
      <div className="mt-4">
        <h4>Accordion Table Example</h4>
        <AccordionTableWrapper
          columns={this.state.cols}
          data={this.state.data}
          accordion={AccordionTableMobileEx}
          height={400}
        />
      </div>
    );
  }
}

export default AccordionTableExample;
