import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { BestDropdown, BestLabel, FormColumn4Wide, Icon, SearchBox } from "best-common-react";

const ClearableInput = styled.div.attrs({ className: "btn-group" })`
  width: 100%;
`;

const ClearUserSearch = styled.span`
  position: absolute;
  right: 6px;
  top: 8px;
`;

class UserFilters extends React.Component {
  render() {
    const { commonStore, userAdminStore } = this.props.rootStore;
    return (
      <div className="row">
        <FormColumn4Wide>
          <div className="form-group">
            <BestDropdown
              id="seasons"
              title="Seasons"
              options={commonStore.activeSeasonDropdownList}
              value={userAdminStore.getSeason}
              onChange={userAdminStore.setSeason}
            />
          </div>
        </FormColumn4Wide>
        <FormColumn4Wide>
          <div className="form-group">
            <BestDropdown
              id="departments"
              title="Departments"
              options={userAdminStore.departmentsDropdownList}
              value={userAdminStore.selectedDepartment}
              onChange={userAdminStore.setDepartment}
            />
          </div>
        </FormColumn4Wide>
        <FormColumn4Wide>
          <div className="form-group">
            <BestDropdown
              id="activeness"
              title="Active/Inactive"
              options={userAdminStore.activenessDropdownList}
              value={userAdminStore.selectedActiveness}
              onChange={userAdminStore.setSelectedActiveness}
            />
          </div>
        </FormColumn4Wide>
        <FormColumn4Wide>
          <div className="form-group">
            <BestLabel htmlFor="search-box">Search Users</BestLabel>
            <ClearableInput>
              <SearchBox
                id="search-box"
                placeholder="begin typing to filter users"
                value={userAdminStore.searchTerm}
                onChange={event => userAdminStore.setSearchTerm(event.target.value)}
              />
              {userAdminStore.searchTerm.length > 0 ? (
                <ClearUserSearch>
                  <Icon iconName="fa-times-circle" onClick={() => userAdminStore.clearSearchTerm()} />
                </ClearUserSearch>
              ) : null}
            </ClearableInput>
          </div>
        </FormColumn4Wide>
      </div>
    );
  }
}

export default inject("rootStore")(observer(UserFilters));
