import { action, autorun, computed, decorate, extendObservable } from "mobx";
import { RouteConstants } from "../../constants/RouteConstants";
import _ from "lodash";
import { ReportTypesConstants } from "../../constants/ReportTypesConstants";

class ExportSettingsStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      labelFields: [],
      labelSelected: [],
      boxOfficeFields: [],
      boxOfficeSelected: [],
      displayStyles: [],
      isEdited: false,
      isSaving: false
    };

    extendObservable(this, {
      labelFields: this.defaults["labelFields"],
      labelSelected: this.defaults["labelSelected"],
      boxOfficeFields: this.defaults["boxOfficeFields"],
      boxOfficeSelected: this.defaults["boxOfficeSelected"],
      displayStyles: this.defaults["displayStyles"],
      isEdited: this.defaults["isEdited"],
      isSaving: this.defaults["isSaving"],
      onEdit: action(() => {
        this.isEdited = true;
      }),
      setLabelFields: action(values => {
        this.labelFields = values;
      }),
      setLabelSelected: action(values => {
        this.labelSelected = values;
      }),
      setBoxOfficeFields: action(values => {
        this.boxOfficeFields = values;
      }),
      setBoxOfficeSelected: action(values => {
        this.boxOfficeSelected = values;
      }),
      setDisplayStyles: action(values => {
        this.displayStyles = values;
      }),
      onSave: action(() => {
        this.isSaving = true;
        let boxOfficeSelected = this.boxOfficeSelected.toJSON().map((field, index) => ({
          org: this.commonStore.currentOrg,
          reportMapping: field.reportMapping,
          sequence: index,
          displayStyle: field.displayStyle
        }));
        let labelSelected = this.labelSelected.toJSON().map((field, index) => ({
          org: this.commonStore.currentOrg,
          reportMapping: field.reportMapping,
          sequence: index,
          displayStyle: field.displayStyle
        }));
        this.compTixApi
          .updateOrgReportFields(this.commonStore.selectedOrgId, [...boxOfficeSelected, ...labelSelected])
          .then(() => {
            this.routerStore.history.push(RouteConstants.REPORT_EVENTS);
            this.isSaving = false;
          })
          .catch(error => {
            console.error(error);
            this.isSaving = false;
          });
      }),
      onCancel: action(() => {
        this.routerStore.history.push(RouteConstants.REPORT_EVENTS);
      }),
      reorderSelected: action((type, startIndex, endIndex) => {
        this.onEdit();
        let selected = this[type + "Selected"];
        const [removed] = selected.splice(startIndex, 1);
        selected.splice(endIndex, 0, removed);
        this[type + "Selected"] = selected;
      }),
      updateSelected: action((type, index, selectedOption) => {
        this.onEdit();
        this[type + "Selected"][index].reportMapping = selectedOption.value.reportMapping;
      }),
      updateSelectedStyle: action((type, index, selectedOption) => {
        this.onEdit();
        this[type + "Selected"][index].displayStyle = selectedOption.value;
      }),
      addSelected: action(type => {
        if (this[type + "DropdownFields"].length !== 0 && (type !== "label" || this[type + "Selected"].length < 5)) {
          this.onEdit();
          this[type + "Selected"].push(this[type + "DropdownFields"].shift().value);
        }
      }),
      deleteSelected: action((type, index) => {
        if (this[type + "Selected"].length > 1) {
          this.onEdit();
          let selected = this[type + "Selected"];
          selected.splice(index, 1);
          this[type + "Selected"] = selected;
        }
      }),
      resetStore: action(() => {
        this.labelFields = this.defaults["labelFields"];
        this.labelSelected = this.defaults["labelSelected"];
        this.boxOfficeFields = this.defaults["boxOfficeFields"];
        this.boxOfficeSelected = this.defaults["boxOfficeSelected"];
        this.isEdited = this.defaults["isEdited"];
      })
    });

    autorun(() => {
      if (this.routerStore.isExportSettingsTab) {
        this.getDisplayStyles();
        this.getLabelsFields();
        this.getBoxOfficeFields();

        if (this.commonStore.selectedOrgId !== -1) {
          this.getLabelsSelected(this.commonStore.selectedOrgId);
          this.getBoxOfficeSelected(this.commonStore.selectedOrgId);
        }
      } else {
        this.resetStore();
      }
    });
  }

  getDisplayStyles() {
    this.compTixApi.getDisplayStyles().then(data => {
      this.setDisplayStyles(data);
    });
  }

  getLabelsFields() {
    this.compTixApi.getReportFieldsByType("Labels").then(data => {
      let all = data.map(entity => {
        return { reportMapping: entity, displayStyle: this.displayStyles.length ? this.displayStyles[0] : {} };
      });
      this.setLabelFields(all);
    });
  }

  getLabelsSelected(orgId) {
    this.compTixApi.getReportFieldsByOrgAndType(orgId, "Labels").then(res => {
      let selected = res.map(entity => {
        return { reportMapping: entity.reportMapping, displayStyle: entity.displayStyle };
      });
      this.setLabelSelected(selected);
    });
  }

  getBoxOfficeFields() {
    this.compTixApi.getReportFieldsByType(ReportTypesConstants.BOX_OFFICE).then(data => {
      let all = data.map(entity => {
        return { reportMapping: entity, displayStyle: this.displayStyles.length ? this.displayStyles[0] : {} };
      });
      this.setBoxOfficeFields(all);
    });
  }

  getBoxOfficeSelected(orgId) {
    this.compTixApi.getReportFieldsByOrgAndType(orgId, ReportTypesConstants.BOX_OFFICE).then(res => {
      let selected = res.map(entity => {
        return { reportMapping: entity.reportMapping, displayStyle: entity.displayStyle };
      });
      this.setBoxOfficeSelected(selected);
    });
  }

  get boxOfficeDropdownFields() {
    let selectedFieldIds = this.boxOfficeSelected.map(field => field.reportMapping.reportFieldReportTypeId);
    return this.boxOfficeFields
      .filter(field => !_.includes(selectedFieldIds, field.reportMapping.reportFieldReportTypeId))
      .map(field => ({
        label: field.reportMapping.reportField.reportFieldName,
        value: field
      }));
  }

  get labelDropdownFields() {
    let selectedFieldIds = this.labelSelected.map(field => field.reportMapping.reportFieldReportTypeId);
    return this.labelFields
      .filter(field => !_.includes(selectedFieldIds, field.reportMapping.reportFieldReportTypeId))
      .map(field => ({
        label: field.reportMapping.reportField.reportFieldName,
        value: field
      }));
  }

  get displayStyleDropdownFields() {
    return this.displayStyles.map(field => ({
      label: field.displayStyleName.includes("Upper") ? _.toUpper(field.displayStyleName) : field.displayStyleName,
      value: field
    }));
  }
}

decorate(ExportSettingsStore, {
  labelDropdownFields: computed,
  boxOfficeDropdownFields: computed,
  displayStyleDropdownFields: computed
});

export default ExportSettingsStore;
