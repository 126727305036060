import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { BestSelect, DesktopTabletView, MobileView } from "best-common-react";
import { CommonConstants } from "../../constants/CommonConstants";

const TeamTitleHolder = styled.div.attrs({
  className: "d-flex align-items-center mb-2",
  id: "team-title-holder"
})`
  height: 60px;
  width: 100%;
`;

const Logo = styled.img.attrs({
  id: "logo"
})`
  width: 40px;
  height: 40px;
`;

const TeamName = styled.div.attrs({
  className: "ml-2",
  id: "team-name"
})`
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
  @media (min-width: 768px) {
    font-size: 28px;
  }
  font-weight: 300;
  color: #000000;
`;

const SelectHolder = styled.div.attrs({
  className: "ml-auto",
  id: "select-holder"
})`
  @media (max-width: 767px) {
    width: 767px;
    height: 36px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 768px) {
    width: 179px;
    height: 36px;
  }
`;

class TeamTitle extends React.Component {
  render() {
    const {
      disabled,
      rootStore: { commonStore, authStore }
    } = this.props;
    return (
      <TeamTitleHolder>
        <DesktopTabletView>
          <Logo src={commonStore.currentOrgLogo} />
          <TeamName>{commonStore.currentOrgName}</TeamName>
          {authStore.isGlobal ? (
            <SelectHolder>
              <BestSelect
                id="org-select"
                value={commonStore.currentOrgDropdown}
                onChange={t => commonStore.setSelectedOrgId(t.value)}
                options={
                  authStore.isGlobal
                    ? commonStore.orgDropdownList
                    : [
                        commonStore.orgDropdownList.find(
                          orgOption => orgOption.value === authStore.userData?.org?.orgId
                        ),
                        commonStore.orgDropdownList.find(orgOption => orgOption.value === CommonConstants.KEYS.UMP_ID)
                      ]
                }
                isDisabled={disabled}
              />
            </SelectHolder>
          ) : null}
        </DesktopTabletView>
        <MobileView>
          {authStore.isGlobal ? (
            <SelectHolder>
              <BestSelect
                id="org-select"
                value={commonStore.currentOrgDropdown}
                onChange={t => commonStore.setSelectedOrgId(t.value)}
                options={commonStore.orgDropdownList}
                isDisabled={disabled}
              />
            </SelectHolder>
          ) : (
            <React.Fragment>
              <Logo src={commonStore.currentOrgLogo} />
              <TeamName>{commonStore.currentOrgName}</TeamName>
            </React.Fragment>
          )}
        </MobileView>
      </TeamTitleHolder>
    );
  }
}

TeamTitle.defaultProps = {
  disabled: false
};

TeamTitle.propTypes = {
  disabled: PropTypes.bool
};

export default inject("rootStore")(observer(TeamTitle));
