import React from "react";
import Moment from "moment";
// eslint-disable-next-line
import "moment-timezone";
import styled from "styled-components";
import * as comptixFormatter from "../../../../../utilities/comptixFormatter";
import { AttributeValue, DesktopTabletView, MobileView } from "best-common-react";

const PreviousValueText = styled.span`
  text-decoration: line-through;
  color: ${props => props.theme["grey-two"]};
  font-weight: 300;
`;

function valueConvert(val, isDate, timeZone, minutes = true) {
  if (!isDate) {
    if (minutes) {
      return comptixFormatter.formatMinutes(val);
    } else {
      return val;
    }
  } else {
    let mom = timeZone ? Moment(val).tz(timeZone) : Moment(val);
    return mom.format("MM/DD/YYYY - h:mma") + " " + comptixFormatter.timezone(mom.format("zz"));
  }
}

class EventTicketTimeFormatter extends React.Component {
  render() {
    const { value, isDate, override, timeZone } = this.props.value;
    const minutes = this.props.minutes;
    let eventString = valueConvert(value, isDate, timeZone, minutes);
    let fullEventString;
    let overrideString;
    if (override && override.value != null && override.showOverride) {
      overrideString = valueConvert(override.value, override.isDate, timeZone, minutes);
      eventString = valueConvert(value, isDate, timeZone, minutes);
      fullEventString = (
        <span>
          {overrideString}
          <DesktopTabletView>&nbsp;&nbsp;</DesktopTabletView>
          <MobileView>
            <br />
          </MobileView>
          <PreviousValueText>{eventString}</PreviousValueText>
        </span>
      );
    } else {
      fullEventString = <span>{eventString}</span>;
    }

    return <AttributeValue>{fullEventString}</AttributeValue>;
  }
}

export default EventTicketTimeFormatter;
