import { extendObservable, action } from "mobx";

class ReservationDeleteStore {
  constructor(authStore, commonStore, compTixApi, routerStore, eventReservationsStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.eventReservationsStore = eventReservationsStore;

    this.defaults = {
      showDeleteModal: false,
      reservation: {},
      reservationToDeleteId: null,
      reservationsToDelete: [],
      isEdit: false
    };

    extendObservable(this, {
      showDeleteModal: this.defaults["showDeleteModal"],
      reservation: this.defaults["reservation"],
      reservationToDeleteId: this.defaults["reservationToDeleteId"],
      reservationsToDelete: this.defaults["reservationsToDelete"],
      isEdit: this.defaults["isEdit"],
      setModalVisibility: action(show => {
        this.showDeleteModal = show;
      }),
      setReservation: action(value => {
        this.reservation = value;
      }),
      setIsEdit: action(value => {
        this.isEdit = value;
      }),
      setReservationToDelete: action(id => {
        this.reservationToDeleteId = id;
        this.setModalVisibility(true);
        this.getReservation();
      }),
      setReservationsToDelete: action(requests => {
        this.reservationsToDelete = requests;
      }),
      cancelReservationDelete: action(() => {
        this.setModalVisibility(false);
        this.reservationToDeleteId = null;
      }),
      deleteReservation: action(callback => {
        // Deleting single request
        if (this.reservationToDeleteId) {
          this.compTixApi.deleteReservation(this.reservationToDeleteId).then(response => {
            callback().then(() => {
              this.setModalVisibility(false);
              this.reservationToDeleteId = null;
              this.setReservationsToDelete([]);
              this.eventReservationsStore.setSelectedReservations([]);
            });
          });
        }
        // Deleting multiple requests
        else if (this.reservationsToDelete.length) {
          this.compTixApi
            .deleteReservations(this.reservationsToDelete.map(reservation => reservation.id))
            .then(response => {
              callback().then(() => {
                this.setModalVisibility(false);
                this.setReservationsToDelete([]);
                this.eventReservationsStore.setSelectedReservations([]);
              });
            });
        }
      })
    });
  }

  getReservation = () => {
    this.compTixApi.getReservation(this.reservationToDeleteId).then(data => {
      this.setReservation(data);
    });
  };
}

export default ReservationDeleteStore;
