import React from "react";
import { inject, observer } from "mobx-react";
import UserInfo from "./UserInfo";
import TicketAllotment from "../../tickets/allotment/TicketAllotment";
import { BestTitle } from "best-common-react";

function EditUserBody(props) {
  const { userEditStore, commonStore, authStore } = props.rootStore;
  return (
    <div>
      <UserInfo />
      <BestTitle>Ticket Allotment</BestTitle>
      <TicketAllotment
        id="user-ticket-allotments"
        userEditStore={userEditStore}
        commonStore={commonStore}
        authStore={authStore}
        allotments={userEditStore.userAllotmentsForSelectedSeason}
        allotmentYear={userEditStore.allotmentYear || 2024}
        onYearChange={userEditStore.setAllotmentYear}
        onAllotmentChange={userEditStore.updateAllotment}
      />
    </div>
  );
}

export default inject("rootStore")(observer(EditUserBody));
