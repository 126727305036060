import React from "react";

import SystemSeasonEditInfo from "./SystemSeasonEditInfo";
import SystemSeasonEditPostseason from "./SystemSeasonEditPostseason";

const SystemSeasonEditBody = () => (
  <div>
    <SystemSeasonEditInfo />
    <SystemSeasonEditPostseason />
  </div>
);

export default SystemSeasonEditBody;
