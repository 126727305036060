import { action, extendObservable, autorun, decorate, computed } from "mobx";
import { RouteConstants } from "../../constants/RouteConstants";
import createUserDropdown from "./CreateUserDropdown";
import _ from "lodash";

class TransferRequestStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.allotmentTypeOptions = [{ label: "Family", value: "family" }, { label: "Friends", value: "friends" }];

    this.defaults = {
      allotmentsAndRequests: {},
      allotmentType: this.allotmentTypeOptions[0],
      game: null,
      gamePk: -1,
      selectedUser: null,
      transferQuantity: 0,
      ticketAllotment: null,
      users: [],
      umpires: [],
      isSending: false
    };

    extendObservable(this, {
      allotmentsAndRequests: this.defaults["allotmentsAndRequests"],
      allotmentType: this.defaults["allotmentType"],
      game: this.defaults["game"],
      gamePk: this.defaults["gamePk"],
      selectedUser: this.defaults["selectedUser"],
      ticketAllotment: this.defaults["ticketAllotment"],
      transferQuantity: this.defaults["transferQuantity"],
      users: this.defaults["users"],
      umpires: this.defaults["umpires"],
      isSending: this.defaults["isSending"],
      setAllotmentType: action(value => {
        this.allotmentType = value;
      }),
      setGame: action(value => {
        this.game = value;
      }),
      setGamePk: action(value => {
        this.gamePk = value;
      }),
      setUsers: action(value => {
        this.users = value;
      }),
      setSelectedUser: action(value => {
        this.selectedUser = value;
      }),
      setTransferQuantity: action(value => {
        this.transferQuantity = value;
      }),
      setAllotmentsAndRequests: action(allotmentAndRequests => {
        this.allotmentsAndRequests = allotmentAndRequests;
      }),
      setTicketAllotment: action(value => {
        this.ticketAllotment = value;
      }),
      setUmpires: action(umpires => {
        this.umpires = umpires;
      }),
      setIsSending: action(value => {
        this.isSending = value;
      }),
      cancel: action(() => {
        this.routerStore.history.push("/tickets/events");
        this.resetStore();
      }),
      resetStore: action(() => {
        this.game = this.defaults["game"];
        this.gamePk = this.defaults["gamePk"];
        this.allotmentType = this.defaults["allotmentType"];
        this.selectedUser = this.defaults["selectedUser"];
        this.transferQuantity = this.defaults["transferQuantity"];
        this.users = this.defaults["users"];
        this.umpires = this.defaults["umpires"];
        this.isSending = this.defaults["isSending"];
      }),
      transferTickets: action(() => {
        this.setIsSending(true);
        this.compTixApi.addTicketRequestTransfer(this.ticketRequestTransfer).then(data => {
          this.routerStore.history.push("/tickets/events");
          this.setIsSending(false);
        });
      })
    });

    autorun(() => {
      if (this.routerStore.isTransferRequestTab && this.commonStore.currentOrgId) {
        this.parseUrl();
        this.getGame();
        this.getUsers();
      } else {
        this.resetStore();
      }
    });

    autorun(() => {
      if (
        this.authStore.currentUserId &&
        this.authStore.currentUserId !== -1 &&
        this.routerStore.isTransferRequestTab
      ) {
        this.getUserQuantity();
      }
    });
  }

  parseUrl = () => {
    let params = this.routerStore.getPathParams(RouteConstants.TRANSFER_TICKET_REQUEST);
    this.setGamePk(params["gamePk"] * 1);
  };

  getGame = () => {
    this.compTixApi.getEventInformation(this.gamePk).then(data => {
      this.setUmpires(data.umpires);
      this.setGame(data);
    });
  };

  getUsers = () => {
    this.compTixApi.getUsersByOrg(this.commonStore.currentOrgId, false, false).then(data => {
      this.setUsers(_.filter(data, user => user.active));
    });
  };

  getUserQuantity = () => {
    this.compTixApi.getAllotmentAndRequests(this.gamePk, this.authStore.currentUserId).then(data => {
      this.setAllotmentsAndRequests(data);
    });
    this.compTixApi.getUserEventInfo(this.gamePk, this.authStore.currentUserId).then(data => {
      this.setTicketAllotment(data);
    });
  };

  get currentGame() {
    if (this.game) {
      return this.game;
    } else {
      return {};
    }
  }

  get quantity() {
    if (this.allotmentsAndRequests.allotment && this.authStore.userData.org && this.game) {
      let allotment = 0;
      if (this.allotmentType.value === "family") {
        if (this.authStore.userData.org.statsId === this.game.teams.home.team.id) {
          allotment =
            this.allotmentsAndRequests.allotment.homeFamilyAllotment -
            this.allotmentsAndRequests.totalFamilyRequests +
            this.allotmentsAndRequests.totalFamilyTransfersReceived -
            this.allotmentsAndRequests.totalFamilyTransfersGiven;
        } else {
          allotment =
            this.allotmentsAndRequests.allotment.awayFamilyAllotment -
            this.allotmentsAndRequests.totalFamilyRequests +
            this.allotmentsAndRequests.totalFamilyTransfersReceived -
            this.allotmentsAndRequests.totalFamilyTransfersGiven;
        }
      } else {
        if (this.authStore.userData.org.statsId === this.game.teams.home.team.id) {
          allotment =
            this.allotmentsAndRequests.allotment.homeFriendsAllotment -
            this.allotmentsAndRequests.totalFriendsRequests +
            this.allotmentsAndRequests.totalFriendsTransfersReceived -
            this.allotmentsAndRequests.totalFriendsTransfersGiven;
        } else {
          allotment =
            this.allotmentsAndRequests.allotment.awayFriendsAllotment -
            this.allotmentsAndRequests.totalFriendsRequests +
            this.allotmentsAndRequests.totalFriendsTransfersReceived -
            this.allotmentsAndRequests.totalFriendsTransfersGiven;
        }
      }
      return allotment;
    } else {
      return 0;
    }
  }

  get isHomeTeam() {
    return this.game ? this.game.teams.home.team.name === this.commonStore.currentOrgName : false;
  }

  get quantityOptions() {
    let options = [];
    for (let i = 1; i <= this.quantity; i++) {
      options.push({ label: i, value: i });
    }
    return options;
  }

  get userOptions() {
    if (this.commonStore.currentOrgId === 31) {
      let nonUmpires = this.users.filter(u => u.statsId == null);
      let umpIds = this.umpires.map(u => u.statsId);
      let availableUmpires = this.users.filter(u => umpIds.includes(u.statsId));
      return createUserDropdown(
        availableUmpires.concat(nonUmpires).filter(u => u.userId !== this.authStore.currentUserId)
      );
    }
    return createUserDropdown(this.users.filter(u => u.userId !== this.authStore.currentUserId));
  }

  get isValidTransfer() {
    return (
      this.allotmentType.value &&
      this.selectedUser &&
      this.selectedUser.value &&
      this.transferQuantity &&
      this.transferQuantity.value
    );
  }

  get ticketRequestTransfer() {
    let homeAwayType = this.isHomeTeam ? this.commonStore.homeType : this.commonStore.awayType;
    return {
      toUser: this.selectedUser.value,
      fromUser: this.authStore.userData,
      quantity: this.transferQuantity.value,
      gamePk: this.gamePk,
      venueSeason: this.ticketAllotment.venueSeason,
      homeAwayType: homeAwayType,
      relation: this.allotmentType.value,
      isDeleted: false
    };
  }
}

decorate(TransferRequestStore, {
  currentGame: computed,
  quantity: computed,
  quantityOptions: computed,
  userOptions: computed,
  isValidTransfer: computed,
  ticketRequestTransfer: computed
});

export default TransferRequestStore;
