import React from "react";
import PropTypes from "prop-types";
import OverrideAccordion from "../overrides/OverrideAccordion";
import { AccordionTableWrapper, TableTitle } from "best-common-react";
import OverrideFormatter from "../overrides/OverrideFormatter";
import OverrideIconFormatter from "../overrides/OverrideIconFormatter";
import OverrideEditOnlyIconFormatter from "../overrides/OverrideEditOnlyIconFormatter";

const cols = [
  { key: "editOnly", name: "Override", width: 80, formatter: OverrideEditOnlyIconFormatter },
  { key: "editAndDelete", name: "Override", width: 80, formatter: OverrideIconFormatter },
  { key: "section", name: "Section", sortable: true, width: 200 },
  { key: "inventory", name: "# of Seats", sortable: true, formatter: OverrideFormatter, width: 200 },
  { key: "price", name: "Price", sortable: true, formatter: OverrideFormatter }
];

const HEADER_HEIGHT = 50;

const ROW_HEIGHT = 35;

const SectionsTable = props => (
  <div className="row">
    <div className="col">
      <TableTitle>Ticket Inventory</TableTitle>
      <AccordionTableWrapper
        data={props.sections}
        columns={
          props.isGlobal
            ? cols.slice(1)
            : props.isBoxOfficeAdmin && props.isHome
            ? cols.slice(0, 1).concat(cols.slice(2))
            : cols.slice(2)
        }
        accordion={OverrideAccordion}
        sortFunc={props.setSortDirection}
        sortColumn={props.sortColumn}
        sortDirection={props.sortDirection}
        height={props.sections.length * ROW_HEIGHT * 2 + HEADER_HEIGHT}
      />
    </div>
  </div>
);

SectionsTable.defaultProps = {
  sections: []
};

SectionsTable.propTypes = {
  sections: PropTypes.array
};

export default SectionsTable;
