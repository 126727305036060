import React from "react";
import { inject, observer } from "mobx-react";
import { DateRangePickerWithLabel, Checkbox, FormColumn3Wide, BestSelect, BestLabel } from "best-common-react";

const ReportHistoryFilters = ({ rootStore: { reportHistoryStore, commonStore } }) => {
  return (
    <div className="row">
      <FormColumn3Wide>
        <BestLabel>Org</BestLabel>
        <BestSelect
          id="org-select"
          value={reportHistoryStore.selectedOrg}
          onChange={t => reportHistoryStore.setSelectedOrg(t)}
          options={commonStore.orgDropdownListWithAll}
          isDisabled={false}
        />
      </FormColumn3Wide>
      <FormColumn3Wide>
        <BestLabel>Type</BestLabel>
        <BestSelect
          id="type-select"
          value={reportHistoryStore.selectedTypes}
          onChange={t => reportHistoryStore.setSelectedTypes(t)}
          options={reportHistoryStore.reportTypesWithAll}
          isDisabled={false}
        />
      </FormColumn3Wide>
      <FormColumn3Wide>
        <DateRangePickerWithLabel
          value={reportHistoryStore.dateRangeFilter}
          onChange={reportHistoryStore.setDateRangeFilter}
          label="Report History Date"
        />
      </FormColumn3Wide>
      <FormColumn3Wide>
        <BestLabel>&nbsp;</BestLabel>
        <Checkbox
          label="Toggle Errors"
          onChange={reportHistoryStore.toggleError}
          checked={reportHistoryStore.filterError}
        />
      </FormColumn3Wide>
    </div>
  );
};

export default inject("rootStore")(observer(ReportHistoryFilters));
