import { action, computed, extendObservable, decorate, autorun } from "mobx";
import validate from "validate.js";

class LoginStore {
  constructor(compTixApi, authStore) {
    this.compTixApi = compTixApi;
    this.authStore = authStore;

    this.defaults = {
      password: "",
      confirmPassword: "",
      email: "",
      showResetPassword: false,
      showSetEmail: false
    };

    extendObservable(this, {
      password: this.defaults["password"],
      confirmPassword: this.defaults["confirmPassword"],
      email: this.defaults["email"],
      showResetPassword: this.defaults["showResetPassword"],
      showSetEmail: this.defaults["showSetEmail"],
      setPassword: action(event => {
        this.password = event.target.value;
      }),
      setConfirmPassword: action(event => {
        this.confirmPassword = event.target.value;
      }),
      setEmail: action(event => {
        this.email = event.target.value;
      }),
      setShowResetPassword: action(value => {
        this.showResetPassword = value;
      }),
      updateUserEmail: action(() => {
        this.showSetEmail = true;
      }),
      resetPassword: action(() => {
        this.showResetPassword = true;
      }),
      onCancelEmail: action(() => {
        this.resetStore();
      }),
      onCancelPassword: action(() => {
        this.authStore.logout();
        this.resetStore();
      }),
      onSavePassword: action(() => {
        this.compTixApi
          .resetPassword(this.authStore.userData.userId, this.password, this.confirmPassword)
          .then(data => {
            this.resetStore();
            this.showResetPassword = false;
          });
      }),
      onSaveEmail: action(() => {
        this.compTixApi.getUserById(this.authStore.userData.userId).then(data => {
          data.email = this.email;
          this.compTixApi.updateUser(data).then(() => {
            this.resetStore();
          });
        });
      }),
      resetStore: action(() => {
        this.password = this.defaults["password"];
        this.confirmPassword = this.defaults["confirmPassword"];
        this.email = this.defaults["email"];
        this.showSetEmail = this.defaults["showSetEmail"];
        this.authStore.loginComplete();
      })
    });

    autorun(() => {
      if (this.authStore.loggedInSession && this.authStore.userData) {
        if (this.authStore.userData.defaultPassword) {
          this.setShowResetPassword(true);
        } else if (!this.authStore.userData.email && Math.floor(Math.random() * 9) === 0) {
          this.updateUserEmail(this.authStore.userData.userId);
        } else {
          this.authStore.loginComplete();
        }
      } else {
        this.setShowResetPassword(false);
        this.resetStore();
      }
    });
  }

  get matchingPasswords() {
    return this.password && this.confirmPassword && this.password === this.confirmPassword;
  }

  get isValidEmail() {
    return validate({ from: this.email }, { from: { email: true } });
  }
}

decorate(LoginStore, {
  matchingPasswords: computed,
  isValidEmail: computed
});

export default LoginStore;
