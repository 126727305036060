import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

const ConfirmMessage = styled.div.attrs({ className: "mb-3" })`
  font-size: 1.125rem;
  font-weight: 300;
`;

const Deleted = styled.span`
  font-size: 1.125rem;
  font-weight: bold;
`;

class DeleteOverridesModal extends React.Component {
  render() {
    const { eventDetailsStore } = this.props.rootStore;
    return (
      <Modal show={this.props.show} onClose={() => eventDetailsStore.setSectionToDelete(null)} size="lg">
        <ModalHeaderWithTitleAndClose
          title="Delete Override(s)"
          close={() => eventDetailsStore.setShowDeleteSectionModal(false)}
        />
        <ModalBody>
          <ConfirmMessage>
            Are you sure you want to delete the override(s) for
            {eventDetailsStore.sectionToDelete ? <Deleted> {eventDetailsStore.sectionToDelete.section}</Deleted> : null}
            ?
          </ConfirmMessage>
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton
              className="mr-2"
              onClick={() => eventDetailsStore.deleteSectionOverride(eventDetailsStore.sectionToDelete.overrideId)}
              disabled={eventDetailsStore.isSaving}
            >
              Delete
            </PrimaryButton>
            <LightButton onClick={() => eventDetailsStore.setShowDeleteSectionModal(false)}>Cancel</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(DeleteOverridesModal));
