import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import ReservationsEditFooter from "../../components/reservations/edit/ReservationsEditFooter";
import ReservationEditBody from "../../components/reservations/edit/ReservationEditBody";
import Protected from "../../components/protected/Protected";
import ReservationsDeleteModal from "../../components/reservations/delete/ReservationsDeleteModal";

class ReservationsEditContainer extends React.PureComponent {
  render() {
    const { routerStore } = this.props.rootStore;
    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>Edit Reservations</CardHeader>
          <CardBody>
            <ReservationsDeleteModal
              callback={() =>
                new Promise((resolve, reject) => {
                  routerStore.goBack();
                  resolve();
                })
              }
            />
            <ReservationEditBody />
          </CardBody>
          <CardFooter>
            <ReservationsEditFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(ReservationsEditContainer);
