import { action, computed, decorate, extendObservable, reaction } from "mobx";
import moment from "moment";
import { now } from "mobx-utils";
import { CommonConstants } from "../constants/CommonConstants";
import { updateEnd, updateStart } from "../utilities/DateUtility";

class CommonStore {
  constructor(authStore, compTixApi) {
    this.authStore = authStore;
    this.compTixApi = compTixApi;

    let today = new Date();
    let nextMonth = new Date();

    nextMonth.setMonth(today.getMonth() + 1);
    this.defaults = {
      homeAwayTypes: [],
      householdTypes: [],
      categoryTypes: [],
      digitalDeliveryOptions: [],
      orgs: [],
      seasons: [],
      roles: [],
      departmentTypes: [],
      selectedOrgId: -1,
      selectedOrg: {},
      curSeason: -1,
      userAdminSeason: null,
      useHHDropdown: false,
      dateRangeFilter: { start: today, end: nextMonth }
    };

    extendObservable(this, {
      homeAwayTypes: this.defaults["homeAwayTypes"],
      householdTypes: this.defaults["householdTypes"],
      categoryTypes: this.defaults["categoryTypes"],
      digitalDeliveryOptions: this.defaults["digitalDeliveryOptions"],
      selectedOrgId: this.defaults["selectedOrgId"],
      selectedOrg: this.defaults["selectedOrg"],
      seasons: this.defaults["seasons"],
      orgs: this.defaults["orgs"],
      useHHDropdown: this.defaults["useHHDropdown"],
      roles: this.defaults["roles"],
      departmentTypes: this.defaults["departmentTypes"],
      curSeason: this.defaults["curSeason"],
      userAdminSeason: this.defaults["userAdminSeason"],
      dateRangeFilter: this.defaults["dateRangeFilter"],
      setHomeAwayTypes: action(values => {
        this.homeAwayTypes = values;
      }),
      setHouseholdTypes: action(values => {
        this.householdTypes = values;
      }),
      setCategoryTypes: action(values => {
        this.categoryTypes = values;
      }),
      setDigitalDeliveryTypes: action(values => {
        this.digitalDeliveryOptions = values;
      }),
      setOrgs: action(values => {
        this.orgs = values;
      }),
      setUseHHDropdown: action(value => {
        this.useHHDropdown = value;
      }),
      setSeasons: action(values => {
        this.seasons = values.seasons.sort((s1, s2) => {
          if (s1.year === s2.year) {
            return s1.seasonType.seasonTypeId - s2.seasonType.seasonTypeId;
          }
          return s1.year - s2.year;
        });
        this.curSeason = values.currentSeason;
      }),
      setDepartmentTypes: action(values => {
        this.departmentTypes = values;
      }),
      setRoles: action(values => {
        this.roles = values;
      }),
      setSelectedOrgId: action(value => {
        this.compTixApi.getOrgById(value).then(data => {
          if (data) {
            localStorage.setItem(CommonConstants.KEYS.SELECTED_ORG, JSON.stringify(data));
            this.selectedOrgId = data.orgId;
          }
          this.compTixApi.getOrgSettings(this.selectedOrgId).then(data => {
            if (data) {
              this.setUseHHDropdown(data.useHHDropdown);
            }
          });
        });
      }),
      setUserAdminSeason: action(value => {
        this.userAdminSeason = value;
      }),
      setDateRangeFilterStart: action(value => {
        this.dateRangeFilter = this.onStartChange(value);
      }),
      setDateRangeFilterEnd: action(value => {
        this.dateRangeFilter = this.onEndChange(value);
      })
    });

    reaction(
      () => this.authStore.loggedIn,
      () => {
        if (this.authStore.loggedIn) {
          this.getInitialData();
          if (this.selectedOrgId === -1) {
            this.setSelectedOrgId(this.authStore.userData.org.orgId);
            this.compTixApi.getOrgSettings(this.selectedOrgId).then(data => {
              if (data) {
                this.setUseHHDropdown(data.useHHDropdown);
              }
            });
          }
        }
      }
    );
  }

  get categoryTypesDropdown() {
    return this.categoryTypes.map(category => {
      return {
        value: category.sectionCategoryId,
        label: category.sectionCategoryName
      };
    });
  }

  get orgDropdownList() {
    return this.orgs.map(org => {
      return {
        value: org.orgId,
        label: org.orgCode
      };
    });
  }

  get orgDropdownListWithAll() {
    let orgs = this.orgs.map(org => {
      return {
        value: org.orgId,
        label: org.orgCode
      };
    });
    orgs.unshift({ label: "All", value: "All" });
    return orgs;
  }

  get currentOrg() {
    if (this.selectedOrgId && this.selectedOrgId !== -1) {
      if (!localStorage.getItem(CommonConstants.KEYS.SELECTED_ORG)) {
        localStorage.setItem(CommonConstants.KEYS.SELECTED_ORG, JSON.stringify(this.authStore.currentOrg));
      }
      return JSON.parse(localStorage.getItem(CommonConstants.KEYS.SELECTED_ORG));
    } else {
      return { venue: {} };
    }
  }

  get rolesDropdownList() {
    return this.roles.map(role => {
      return {
        value: role.roleId,
        label: role.roleName
      };
    });
  }

  get currentOrgDropdown() {
    return this.orgDropdownList.find(org => org.value === this.currentOrgId) || { value: "", label: "" };
  }

  get currentOrgId() {
    return this.currentOrg.orgId;
  }

  get currentOrgName() {
    return this.currentOrg.orgName;
  }

  get currentOrgLogo() {
    return this.currentOrg.logoUrl;
  }

  get homeType() {
    return this.homeAwayTypes.find(t => t.homeAwayTypeId === 1);
  }

  get awayType() {
    return this.homeAwayTypes.find(t => t.homeAwayTypeId === 2);
  }

  get homeAwayOptions() {
    return this.homeAwayTypes.map(hat => ({ label: hat.homeAwayTypeName, value: hat }));
  }

  get householdOptions() {
    return this.householdTypes.map(hat => ({ label: hat.householdTypeName, value: hat }));
  }

  get seasonDropdownList() {
    return this.seasons.map(season => {
      return {
        value: season.seasonId,
        label: season.year + " " + season.seasonType.seasonTypeName
      };
    });
  }

  get activeSeasonDropdownList() {
    return this.seasons
      .filter(season => season.active)
      .map(season => {
        return {
          value: season.seasonId,
          label: season.year + " " + season.seasonType.seasonTypeName
        };
      });
  }

  get currentSeasonIndex() {
    return this.curSeason ? this.seasons.findIndex(s => s.seasonId === this.curSeason.seasonId) : -1;
  }

  get currentSeason() {
    return this.curSeason;
  }

  get userSeason() {
    return this.userAdminSeason;
  }

  get yearsDropdownList() {
    let uniqueYears = [
      ...new Set(
        this.seasons.map(season => {
          return season.year;
        })
      )
    ].sort();

    return uniqueYears.map(year => {
      return {
        value: year,
        label: year
      };
    });
  }

  get activeYearsDropdownList() {
    let uniqueYears = [
      ...new Set(
        this.seasons
          .filter(season => season.active)
          .map(season => {
            return season.year;
          })
      )
    ].sort();

    return uniqueYears.map(year => {
      return {
        value: year,
        label: year
      };
    });
  }

  get friendsAndFamilyDropdown() {
    return [{ value: "family", label: "Family" }, { value: "friends", label: "Friends" }];
  }

  get currentTime() {
    return moment(now(10000)).utc();
  }

  getInitialData() {
    this.compTixApi.getOrgs().then(data => {
      if (data) {
        this.setOrgs(data);
      }
    });

    this.getCurrentSeasons();
    this.compTixApi.getRoles().then(data => {
      if (data) {
        this.setRoles(data);
      }
    });
    this.compTixApi.getDepartmentTypes().then(data => {
      if (data) {
        this.setDepartmentTypes(data);
      }
    });
    this.compTixApi.getHomeAwayTypes().then(data => {
      if (data) {
        this.setHomeAwayTypes(data);
      }
    });

    this.compTixApi.getHouseholdTypes().then(data => {
      if (data) {
        this.setHouseholdTypes(data);
      }
    });

    this.compTixApi.getCategoryTypes().then(data => {
      if (data) {
        for (const categoryType of data) {
          if (categoryType.sectionCategoryName === "Both") {
            categoryType.sectionCategoryName = "All";
          }
        }
        this.setCategoryTypes(data);
      }
    });

    this.setDigitalDeliveryTypes([
      { label: "Require Email", value: { allowEitherContactMethod: false, requireEmail: true, requirePhone: false } },
      { label: "Require Phone", value: { allowEitherContactMethod: false, requireEmail: false, requirePhone: true } },
      {
        label: "Require Email & Phone",
        value: { allowEitherContactMethod: false, requireEmail: true, requirePhone: true }
      },
      {
        label: "Require Email OR Phone",
        value: { allowEitherContactMethod: true, requireEmail: false, requirePhone: false }
      }
    ]);

    if (this.authStore.isGlobal && localStorage.getItem(CommonConstants.KEYS.SELECTED_ORG)) {
      this.selectedOrgId = JSON.parse(localStorage.getItem(CommonConstants.KEYS.SELECTED_ORG)).orgId;
      this.compTixApi.getOrgSettings(this.selectedOrgId).then(data => {
        if (data) {
          this.setUseHHDropdown(data.useHHDropdown);
        }
      });
    } else {
      this.selectedOrgId = -1;
    }
  }

  getCurrentSeasons() {
    this.compTixApi.getCurrentSeasons().then(data => {
      if (data) {
        this.setSeasons(data);
      }
    });
  }

  getHomeAwayTypeValue = value => {
    return value !== "" ? this.homeAwayOptions.find(hao => hao.value.homeAwayTypeId === value.homeAwayTypeId) : "";
  };

  getDigitalDeliveryOption = pickupLocation => {
    return this.digitalDeliveryOptions.find(
      opt =>
        opt.value.requireEmail === pickupLocation.requireEmail &&
        opt.value.requirePhone === pickupLocation.requireMobilePhone
    );
  };

  onStartChange(values) {
    return updateStart(values);
  }

  onEndChange(values) {
    return updateEnd(values);
  }
}

decorate(CommonStore, {
  categoryTypesDropdown: computed,
  orgDropdownList: computed,
  orgDropdownListWithAll: computed,
  rolesDropdownList: computed,
  currentOrg: computed,
  currentOrgDropdown: computed,
  currentOrgId: computed,
  currentOrgName: computed,
  currentOrgLogo: computed,
  seasonDropdownList: computed,
  yearsDropdownList: computed,
  friendsAndFamilyDropdown: computed,
  homeType: computed,
  awayType: computed,
  homeAwayOptions: computed,
  householdOptions: computed,
  currentTime: computed,
  currentSeasonIndex: computed,
  currentSeason: computed,
  userSeason: computed
});

export default CommonStore;
