import React from "react";
import { inject, observer } from "mobx-react";
import { BestDropdown, FormColumn4Wide } from "best-common-react";

class DepartmentFilters extends React.Component {
  render() {
    const { commonStore, departmentAdminStore } = this.props.rootStore;
    return (
      <div className="row">
        <FormColumn4Wide>
          <BestDropdown
            title="Season"
            id="department-season-select"
            options={commonStore.activeSeasonDropdownList}
            value={departmentAdminStore.getSelectedSeason}
            onChange={departmentAdminStore.setSelectedSeason}
          />
        </FormColumn4Wide>
      </div>
    );
  }
}

export default inject("rootStore")(observer(DepartmentFilters));
