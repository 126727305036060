import { action, autorun, computed, decorate, extendObservable } from "mobx";
import _ from "lodash";
import Moment from "moment";

class SystemSeasonStore {
  constructor(routerStore, commonStore, comptixApi, loadingStore) {
    this.routerStore = routerStore;
    this.commonStore = commonStore;
    this.comptixApi = comptixApi;
    this.loadingStore = loadingStore;

    this.defaults = {
      seasons: [],
      sortFilters: {
        direction: "DESC",
        key: "year"
      }
    };

    extendObservable(this, {
      seasons: this.defaults["seasons"],
      sortFilters: this.defaults["sortFilters"],
      setSeasons: action(value => {
        this.seasons = value;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      updateSelected: action(rows => {
        rows.forEach(row => {
          let index = this.seasons.findIndex(s => s.seasonId === row.row.seasonId);
          if (index > -1) {
            let copy = Object.assign({}, this.seasons[index]);
            copy.isSelected = !copy.isSelected;
            this.seasons[index] = copy;
          }
        });
      }),
      resetStore: action(() => {
        this.seasons = this.defaults["seasons"];
      })
    });

    autorun(() => {
      if (this.routerStore.isSystemSeasonsTab) {
        this.getSeasons();
      } else {
        this.resetStore();
      }
    });
  }

  getSeasons = () => {
    this.loadingStore.setLoading(true);
    this.comptixApi.getSeasons().then(data => {
      this.setSeasons(data.map(s => ({ ...s, isSelected: false })));
      this.loadingStore.setLoading(false);
    });
  };

  newSeason = () => {
    this.routerStore.history.push(`/system/season/edit/-1`);
  };

  archiveSeason = seasonId => {
    this.loadingStore.setLoading(true);
    this.comptixApi.archiveSeason(seasonId).then(() => {
      this.getSeasons();
      this.commonStore.getCurrentSeasons();
    });
  };

  archiveSeasons = () => {
    this.loadingStore.setLoading(true);
    let ids = this.selectedSeasons.map(s => s.seasonId);
    this.comptixApi.archiveSeasons(ids).then(() => {
      this.getSeasons();
      this.commonStore.getCurrentSeasons();
    });
  };

  get currentSeasons() {
    let seasons = this.seasons.map(s => ({
      seasonId: s.seasonId,
      type: s.seasonType.seasonTypeName,
      typeId: s.seasonType.seasonTypeId,
      year: s.year,
      active: s.active,
      defaultEventAccess: s.defaultEventAccess ? Moment(s.defaultEventAccess) : null,
      nriDropoff: s.nriDropoff ? Moment(s.nriDropoff) : null,
      editLink: `/system/season/edit/${s.seasonId}`,
      isSelected: s.isSelected ? s.isSelected : false
    }));
    return this.sort(seasons);
  }

  get selectedSeasons() {
    return this.seasons.filter(s => s.isSelected);
  }

  sort = sortData => {
    if (this.sortFilters["key"] !== "year") {
      if (this.sortFilters["direction"] === "ASC") {
        return _.sortBy(sortData, this.sortFilters["key"]);
      } else if (this.sortFilters["direction"] === "DESC") {
        return _.sortBy(sortData, this.sortFilters["key"]).reverse();
      } else {
        return sortData;
      }
    } else {
      if (this.sortFilters["direction"] === "ASC") {
        return _.sortBy(sortData, item => [item.year, item.typeId]);
      } else if (this.sortFilters["direction"] === "DESC") {
        return _.sortBy(sortData, item => [item.year, item.typeId]).reverse();
      } else {
        return sortData;
      }
    }
  };
}

decorate(SystemSeasonStore, {
  currentSeasons: computed,
  selectedSeasons: computed
});

export default SystemSeasonStore;
