import { extendObservable, action, reaction } from "mobx";
import { getTeamAbbreviation } from "../../utilities/GameUtility";
import moment from "moment";

class ReservationCopyStore {
  constructor(authStore, commonStore, compTixApi, routerStore, eventReservationsStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.eventReservationsStore = eventReservationsStore;

    this.defaults = {
      showCopyModal: false,
      reservations: [],
      futureGames: [],
      gameToCopyTo: {}
    };

    extendObservable(this, {
      showCopyModal: this.defaults["showCopyModal"],
      reservations: this.defaults["reservation"],
      futureGames: this.defaults["futureGames"],
      gameToCopyTo: this.defaults["gameToCopyTo"],
      setModalVisibility: action(show => {
        this.showCopyModal = show;
      }),
      setReservations: action(value => {
        this.reservations = value;
      }),
      setFutureGames: action(value => {
        this.futureGames = value;
      }),
      setGameToCopyTo: action(value => {
        this.gameToCopyTo = value;
      }),
      cancelReservationDelete: action(() => {
        this.setModalVisibility(false);
        this.reservations = [];
      }),
      copyReservations: action(callback => {
        if (this.reservations.toJSON().length > 0) {
          this.compTixApi
            .copyReservations(this.reservations.toJSON().map(f => f.id), this.gameToCopyTo.value.gamePk)
            .then(retValue => {
              this.setModalVisibility(false);
              this.reservations = [];
            })
            .error(err => {});
        }
      })
    });

    reaction(
      () => this.showCopyModal,
      () => {
        if (this.showCopyModal && this.reservations.length) {
          const gameDate = moment(this.reservations[0].game.gameDate).format("MM/DD/YYYY");
          this.compTixApi
            .getGamesByOrgAndSeasonAndVenue(
              this.commonStore.currentOrgId,
              this.reservations[0].game.seasonId,
              this.reservations[0].game.venue.id,
              gameDate
            )
            .then(value => {
              const vals = value.map(game => {
                let gameDate =
                  game.status.startTimeTBD || !game.venue.timeZone
                    ? moment(game.gameDate).utc()
                    : moment(game.gameDate).tz(game.venue.timeZone.id);

                const label =
                  gameDate.format("ddd, MMM D") +
                  " " +
                  getTeamAbbreviation(game, false) +
                  " @ " +
                  getTeamAbbreviation(game, true) +
                  " " +
                  gameDate.format("HH:mm z");
                return { label: label, value: game };
              });
              this.setFutureGames(vals);
            });
        } else {
          this.setFutureGames([]);
          this.setGameToCopyTo({});
        }
      }
    );
  }
}

export default ReservationCopyStore;
