import React from "react";
import { LightButton, StickyFooterButtons } from "best-common-react";
import { inject, observer } from "mobx-react";

const UserReservationsReportFooter = ({ rootStore: { userReservationsReportStore } }) => (
  <StickyFooterButtons>
    <LightButton onClick={() => userReservationsReportStore.getReservations()}>Load Reservations</LightButton>
    <LightButton onClick={() => userReservationsReportStore.buildUserReport()}>Export to Excel</LightButton>
  </StickyFooterButtons>
);

export default inject("rootStore")(observer(UserReservationsReportFooter));
