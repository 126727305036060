import React from "react";
import { inject, observer } from "mobx-react";
import EventDataCard from "../../events/EventDataCard";
import { BestSelect, RequiredInputWithLabel } from "best-common-react";

class TransferTicketsBody extends React.Component {
  render() {
    const { transferRequestStore, commonStore, authStore } = this.props.rootStore;

    const displayCountdown = commonStore.currentOrgId === authStore.userData.org.orgId;
    const remaining = `${transferRequestStore.quantity} ${
      transferRequestStore.quantity === 1 ? "ticket" : "tickets"
    } remaining`;
    return (
      <div className="row">
        <div className="col-xs-12 col-md-5 col-lg-4 col-xl-3">
          <div>
            {transferRequestStore.currentGame && transferRequestStore.currentGame.venue ? (
              <EventDataCard
                game={transferRequestStore.currentGame}
                displayCountdown={displayCountdown}
                id="transfer-ticket-event-data-card"
              />
            ) : null}
          </div>
        </div>
        <div className="col-xs-12 col-md-7 col-lg-8 col-xl-9">
          <div className="row">
            <div className="col-xs-12 col-lg-6 col-xl-4">
              <div className="form-group">
                <RequiredInputWithLabel name="transferTo" label="Transfer To">
                  <BestSelect
                    id="user"
                    options={transferRequestStore.userOptions}
                    value={transferRequestStore.selectedUser}
                    onChange={transferRequestStore.setSelectedUser}
                    isSearchable={true}
                  />
                </RequiredInputWithLabel>
              </div>
            </div>
            <div className="col-xs-12 col-lg-6 col-xl-4">
              <div className="form-group">
                <RequiredInputWithLabel name="ticketAllotment" label="From Ticket Allotment">
                  <BestSelect
                    id="allotment-type"
                    options={transferRequestStore.allotmentTypeOptions}
                    value={transferRequestStore.allotmentType}
                    onChange={transferRequestStore.setAllotmentType}
                  />
                </RequiredInputWithLabel>
              </div>
            </div>
            <div className="col-xs-12 col-lg-6 col-xl-4">
              <div className="form-group">
                <RequiredInputWithLabel name="quantity" label="Quantity" rightLabel={remaining}>
                  <BestSelect
                    id="quantity"
                    options={transferRequestStore.quantityOptions}
                    value={transferRequestStore.transferQuantity}
                    onChange={transferRequestStore.setTransferQuantity}
                  />
                </RequiredInputWithLabel>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(TransferTicketsBody));
