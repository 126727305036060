import React from "react";
import Protected from "../../components/protected/Protected";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import TransferTicketsBody from "../../components/request/transfer/TransferTicketsBody";
import TransferTicketsFooter from "../../components/request/transfer/TransferTicketsFooter";

class TicketTransferRequestContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>Transfer Tickets</CardHeader>
          <CardBody>
            <TransferTicketsBody />
          </CardBody>
          <CardFooter>
            <TransferTicketsFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(TicketTransferRequestContainer);
