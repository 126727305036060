import React from "react";
import { inject, observer } from "mobx-react";
import { AccordionTableWrapper, IconFormatter, TableTitle } from "best-common-react";
import VenueAccordionMobile from "./VenueAccordionMobile";
import styled from "styled-components";
import HigherOrderComponent from "../../../utilities/HigherOrderComponent";

let PencilFormatter = HigherOrderComponent(IconFormatter);
PencilFormatter.defaultProps = {
  icon: "fa-pencil-alt",
  id: "venue-edit-formatter"
};

const GridHolder = styled.div`
  .react-grid-Canvas,
  .react-grid-Grid {
    background-color: #f7f7f7 !important;
  }
`;

const cols = [
  {
    key: "editLink",
    name: "",
    width: 35,
    formatter: PencilFormatter,
    locked: true
  },
  { key: "venueName", name: "Venue", sortable: true, locked: true, resizable: true },
  { key: "location", name: "Location", width: 200, sortable: false, resizable: true },
  { key: "homeInventory", name: "Home Inventory", sortable: false, width: 150, resizable: true },
  { key: "awayInventory", name: "Away Inventory", sortable: false, width: 150, resizable: true },
  {
    key: "homePickupLocations",
    name: "Home Pickup Locations",
    sortable: false,
    cellClass: "no-overflow",
    width: 250
  },
  {
    key: "awayPickupLocations",
    name: "Away Pickup Locations",
    sortable: false,
    cellClass: "no-overflow",
    width: 250
  }
];

class VenueTable extends React.Component {
  render() {
    const { authStore, departmentAdminStore } = this.props.rootStore;
    const rowCount = this.props.venues.length;

    return (
      <div className="row">
        <div className="col-12 mb-sm-2 mb-md-3">
          <TableTitle>{this.props.season + " Venues"}</TableTitle>
          <GridHolder>
            <AccordionTableWrapper
              id="venue-table"
              data={this.props.venues}
              columns={authStore.isGlobal ? cols : cols.slice(1)}
              accordion={VenueAccordionMobile}
              sortFunc={departmentAdminStore.setSortDirection}
              height={rowCount * 35 + 50} //height of rows in table body + height of table header
              sortColumn={departmentAdminStore.sortFilters["key"]}
              sortDirection={departmentAdminStore.sortFilters["direction"]}
              minColumnWidth={200}
            />
          </GridHolder>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(VenueTable));
