import React from "react";
import { inject, observer } from "mobx-react";
import LoadingSpinner from "../../loading/LoadingSpinner";

class TicketEventsHeader extends React.Component {
  render() {
    return (
      <div className="d-flex">
        <div className="mr-2">Request/Transfer Tickets</div>
        <LoadingSpinner size={10} />
      </div>
    );
  }
}

export default inject("rootStore")(observer(TicketEventsHeader));
