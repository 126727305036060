import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { Icon, Input, BestSelect, RequiredInputForTable } from "best-common-react";

const VerticalAligned = styled.td`
  &&& {
    vertical-align: middle;
  }
`;

const PickupLocation = styled(Input)`
  &&& {
    width: 12.5rem;
  }
`;

const DigitalDelivery = styled(BestSelect)`
  &&& {
    width: 12.5rem;
  }
`;

const DepartmentSelect = styled(BestSelect)`
  &&& {
    width: 100%;
  }
`;

const AddAll = styled.button`
  font-size: 0.875rem;
  text-decoration: none !important;
`;

class VenueEditPickupLocation extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(key, value) {
    let { pickupLocation } = this.props;
    let newPickupLocation = Object.assign({}, pickupLocation);
    switch (key) {
      case "pickupLocationName":
        newPickupLocation.pickupLocationName = value;
        break;
      case "homeAway":
        newPickupLocation.type = value;
        break;
      case "departments":
        let departments = value
          ? value.map((dp, index) => {
              if (dp.department.hasOwnProperty("departmentLocationId")) {
                return dp.department;
              } else {
                return {
                  isDeleted: false,
                  sequence: index,
                  department: dp.department,
                  departmentLocationId: null
                };
              }
            })
          : [];
        newPickupLocation.departments = departments;
        break;
      case "isDeleted":
        newPickupLocation.isDeleted = value;
        break;
      case "digitalDelivery":
        newPickupLocation.digitalDelivery = !!value;
        if (newPickupLocation.digitalDelivery) {
          newPickupLocation.pickupLocationName = "Digital Delivery";
        } else {
          newPickupLocation.requireEmail = false;
          newPickupLocation.requireMobilePhone = false;
          newPickupLocation.allowEitherContactMethod = false;
          newPickupLocation.pickupLocationName = "";
        }
        break;
      case "digitalDeliveryOption":
        newPickupLocation.requireEmail = value.requireEmail;
        newPickupLocation.requireMobilePhone = value.requirePhone;
        newPickupLocation.allowEitherContactMethod = value.allowEitherContactMethod;
        break;
      default:
        break;
    }
    this.props.onChange(pickupLocation.sequence, newPickupLocation);
  }

  createDepartmentValue(departments) {
    return departments
      .filter(d => !d.isDeleted)
      .map(d => {
        return {
          label: d.department.departmentName,
          value: d.department.departmentTypeId,
          department: d
        };
      });
  }

  render() {
    const { pickupLocation, index, rootStore } = this.props;
    const { commonStore } = rootStore;
    let currentDepartments = this.createDepartmentValue(pickupLocation.departments);
    return (
      <Draggable key={index} draggableId={index} index={index}>
        {provided => (
          <tr {...provided.draggableProps} ref={provided.innerRef}>
            <VerticalAligned>
              <div className="d-flex">
                <div className="flex-fill text-center mx-1">
                  <Icon iconName="fa-grip-horizontal" {...provided.dragHandleProps} id="grip" />
                </div>
                <div className="flex-fill text-center mx-1">
                  <Icon
                    id="delete"
                    iconName="fa-trash-alt"
                    onClick={() => {
                      this.onChange("isDeleted", true);
                    }}
                  />
                </div>
              </div>
            </VerticalAligned>
            <VerticalAligned>
              <div className="d-flex">
                <div className="flex-fill text-center mx-1">
                  <input
                    type="checkbox"
                    id="digital-delivery"
                    value={pickupLocation.digitalDelivery}
                    checked={pickupLocation.digitalDelivery}
                    onChange={event => {
                      this.onChange("digitalDelivery", !pickupLocation.digitalDelivery);
                    }}
                  />
                </div>
              </div>
            </VerticalAligned>
            <VerticalAligned>
              <RequiredInputForTable>
                {pickupLocation.digitalDelivery ? (
                  <DigitalDelivery
                    id="digital-delivery-requirements"
                    options={commonStore.digitalDeliveryOptions}
                    value={commonStore.getDigitalDeliveryOption(pickupLocation)}
                    onChange={opt => {
                      this.onChange("digitalDeliveryOption", opt.value);
                    }}
                  />
                ) : (
                  <PickupLocation
                    id="pickup-location"
                    value={pickupLocation.pickupLocationName}
                    onChange={event => {
                      this.onChange("pickupLocationName", event.target.value);
                    }}
                  />
                )}
              </RequiredInputForTable>
            </VerticalAligned>
            <VerticalAligned>
              <BestSelect
                id="home-away"
                options={commonStore.homeAwayOptions}
                value={commonStore.getHomeAwayTypeValue(pickupLocation.type)}
                onChange={value => {
                  this.onChange("homeAway", value.value);
                }}
              />
            </VerticalAligned>
            <td>
              <div className="d-flex">
                <AddAll
                  id="add-all"
                  className="btn btn-link mr-2"
                  onClick={() => this.onChange("departments", this.props.departments)}
                >
                  Add All
                </AddAll>
                <DepartmentSelect
                  isMulti
                  id="departments-multi"
                  options={this.props.departments}
                  defaultValue={currentDepartments}
                  value={currentDepartments}
                  onChange={value => {
                    this.onChange("departments", value);
                  }}
                />
              </div>
            </td>
          </tr>
        )}
      </Draggable>
    );
  }
}

export default inject("rootStore")(observer(VenueEditPickupLocation));
