import React from "react";
import {
  BestLabel,
  BestSelect,
  DateRangePickerWithLabel,
  MobileView,
  SearchBox,
  DesktopTabletView
} from "best-common-react";
import { inject, observer } from "mobx-react";
import TotalsBox from "./TotalsBox";
import TotalsBoxMobile from "./TotalsBoxMobile";

const UserSummaryFilter = ({ rootStore }) => {
  const { userSummaryStore, commonStore } = rootStore;
  const homeAwayBreakdown = userSummaryStore.homeAwayBreakdown;
  let selectedHomeAwayOption = userSummaryStore.selectedHomeAwayOption;
  const home = selectedHomeAwayOption.label === "Away" ? 0 : homeAwayBreakdown.homeGames || 0;
  const away = selectedHomeAwayOption.label === "Home" ? 0 : homeAwayBreakdown.awayGames || 0;
  const isUmpire = commonStore.currentOrgId === 31;
  return (
    <div className="row m-0 mb-md-3">
      <div className="col-lg-5 col-12 p-0 mt-1">
        <DateRangePickerWithLabel
          value={commonStore.dateRangeFilter}
          onStartChange={commonStore.setDateRangeFilterStart}
          onEndChange={commonStore.setDateRangeFilterEnd}
          label="Event Date"
        />
      </div>
      {isUmpire ? (
        <div className="col-lg-3 col-12 p-0 pl-lg-3 mt-1">
          <BestLabel>Category</BestLabel>
          <BestSelect
            id="category"
            options={commonStore.categoryTypesDropdown}
            value={userSummaryStore.selectedCategory}
            onChange={userSummaryStore.setSelectedCategory}
          />
        </div>
      ) : (
        <div className="col-lg-3 col-12 p-0 pl-lg-3 mt-1">
          <BestLabel>Type</BestLabel>
          <BestSelect
            id="home-away"
            options={userSummaryStore.homeAwayOptions}
            value={userSummaryStore.selectedHomeAwayOption}
            onChange={userSummaryStore.setSelectedHomeAwayOption}
          />
        </div>
      )}

      <div className="col-lg-4 col-xl-4 col-12 p-0 pl-lg-3 mt-md-1">
        <BestLabel>Search</BestLabel>
        <SearchBox
          id="reservation-search"
          placeholder="begin typing to filter"
          value={userSummaryStore.searchTerm}
          onChange={search => userSummaryStore.setSearchTerm(search.target.value)}
        />
      </div>
      <DesktopTabletView>
        <TotalsBox
          home={home}
          away={away}
          reservations={userSummaryStore.totals.reservations}
          tickets={userSummaryStore.totals.tickets}
          value={userSummaryStore.totals.value}
        />
      </DesktopTabletView>
      <MobileView>
        <TotalsBoxMobile
          home={home}
          away={away}
          reservations={userSummaryStore.totals.reservations}
          tickets={userSummaryStore.totals.tickets}
          value={userSummaryStore.totals.value}
        />
      </MobileView>
    </div>
  );
};

export default inject("rootStore")(observer(UserSummaryFilter));
