import React from "react";
import { inject, observer } from "mobx-react";

class ReservationsMultiDeleteBody extends React.Component {
  render() {
    const { reservationDeleteStore } = this.props.rootStore;
    let reservations = reservationDeleteStore.reservationsToDelete;

    return (
      <div className="row">
        <div className="col-12">Are you sure you want to delete {reservations.length} reservations?</div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ReservationsMultiDeleteBody));
