import React from "react";
import { inject, observer } from "mobx-react";
import { AccordionTableWrapper, LightButton, TableTitle, DesktopTabletView, MobileView } from "best-common-react";
import EventTicketTimeFormatterWithOverride from "./formatters/EventTicketTimeFormatterWithOverride";
import OverrideIconFormatter from "../../../overrides/OverrideIconFormatter";
import OverrideAccordion from "../../../overrides/OverrideAccordion";
import styled from "styled-components";
import OverrideFormatter from "../../../overrides/OverrideFormatter";

const StyledDiv = styled.div.attrs({ className: "col" })`
  padding-top: 0.75rem;
`;

const SmLight = styled(LightButton).attrs({
  className: "btn-sm",
  id: "small-light-button"
})`
  &&& {
    width: ${props => (props.full ? "100%" : "")}
    padding: 0.4rem 0.7rem;
  }
`;

const overrideCol = {
  key: "editAndDelete",
  name: "Override",
  width: 80,
  sortable: false,
  formatter: OverrideIconFormatter
};

const cols = [
  { key: "departmentName", name: "Department", width: 150, sortable: true, locked: true },
  { key: "access", name: "Event Access (local time)", sortable: true, formatter: EventTicketTimeFormatterWithOverride },
  { key: "cutoff", name: "Event Cutoff (local time)", sortable: true, formatter: EventTicketTimeFormatterWithOverride },
  { key: "homeFamily", name: "Home - Family", sortable: true, formatter: OverrideFormatter },
  { key: "homeFriends", name: "Home - Friends", sortable: true, formatter: OverrideFormatter },
  { key: "awayFamily", name: "Away - Family", sortable: true, formatter: OverrideFormatter },
  { key: "awayFriends", name: "Away - Friends", sortable: true, formatter: OverrideFormatter }
];

class EventTicketAccessTable extends React.Component {
  render() {
    const { eventDetailsStore, authStore } = this.props.rootStore;
    const finalCols = authStore.isGlobal ? [overrideCol].concat(cols) : cols;
    return (
      <div>
        <MobileView>
          <div className="row">
            <div className="col">
              <TableTitle>Ticket Access</TableTitle>
              <div>
                <SmLight
                  disabled={!authStore.isGlobal}
                  full={true}
                  onClick={() => {
                    eventDetailsStore.setShowEventBulkAccessOverrideModal(true);
                  }}
                >
                  Bulk Override
                </SmLight>
              </div>
              <AccordionTableWrapper
                id="event-ticket-access-table"
                data={eventDetailsStore.currentDepartments}
                columns={finalCols}
                accordion={OverrideAccordion}
                height={400}
                sortFunc={eventDetailsStore.accessGridStore.setSortDirection}
                sortColumn={eventDetailsStore.accessGridStore.sortFilters["key"]}
                sortDirection={eventDetailsStore.accessGridStore.sortFilters["direction"]}
              />
            </div>
          </div>
        </MobileView>
        <DesktopTabletView>
          <div className="row">
            <div className="col">
              <TableTitle>Ticket Access</TableTitle>
              <AccordionTableWrapper
                id="event-ticket-access-table"
                data={eventDetailsStore.currentDepartments}
                columns={finalCols}
                accordion={OverrideAccordion}
                height={400}
                sortFunc={eventDetailsStore.accessGridStore.setSortDirection}
                sortColumn={eventDetailsStore.accessGridStore.sortFilters["key"]}
                sortDirection={eventDetailsStore.accessGridStore.sortFilters["direction"]}
              />
            </div>
          </div>
          <div className="row">
            <StyledDiv>
              <SmLight
                disabled={!authStore.isGlobal}
                onClick={() => {
                  eventDetailsStore.setShowEventBulkAccessOverrideModal(true);
                }}
              >
                Bulk Override
              </SmLight>
            </StyledDiv>
          </div>
        </DesktopTabletView>
      </div>
    );
  }
}

export default inject("rootStore")(observer(EventTicketAccessTable));
