import React from "react";
import { inject, observer } from "mobx-react";
import {
  AttributeHeader,
  AttributeValue,
  BestLabel,
  Input,
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";

class SectionInventoryOverrideModal extends React.Component {
  render() {
    const { eventDetailsStore, authStore } = this.props.rootStore;
    return (
      <Modal show={this.props.show} onClose={() => eventDetailsStore.setSectionToEdit(null)} size="lg">
        <ModalHeaderWithTitleAndClose
          title="Ticket Inventory Override"
          close={() => eventDetailsStore.setShowEditSectionModal(false)}
        />
        <ModalBody>
          <div>
            <div className="row">
              <div className="col-12">
                <AttributeHeader>Section</AttributeHeader>
                <AttributeValue>
                  {eventDetailsStore.sectionToEdit ? eventDetailsStore.sectionToEdit.sectionNumber : null}
                </AttributeValue>
              </div>
            </div>
            <div className="row mt-md-4 mt-sm-2">
              <div className="col-sm-12 col-md-6">
                {eventDetailsStore.sectionToEdit && eventDetailsStore.sectionToEdit.override ? (
                  <React.Fragment>
                    <BestLabel for="inventory"># of Seats</BestLabel>
                    <Input
                      type="number"
                      step="1"
                      min="0"
                      required={false}
                      id="inventory"
                      name="inventory"
                      value={eventDetailsStore.sectionToEdit.override.quantity}
                      onChange={e => eventDetailsStore.updateSectionToEditQuantity(e.target.value)}
                    />
                  </React.Fragment>
                ) : null}
              </div>

              <div className="col-sm-12 col-md-6 mt-2 mt-md-0">
                {eventDetailsStore.sectionToEdit && eventDetailsStore.sectionToEdit.override ? (
                  <React.Fragment>
                    <BestLabel for="inventory">Price</BestLabel>
                    <Input
                      disabled={!authStore.isGlobal}
                      type="number"
                      required={false}
                      id="price"
                      name="price"
                      min="0"
                      step="any"
                      value={eventDetailsStore.sectionToEdit.override.price}
                      onChange={e => eventDetailsStore.updateSectionToEditPrice(e.target.value)}
                    />
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton
              className="mr-2"
              onClick={() => {
                if (eventDetailsStore.sectionToEdit.originalOverride.inventoryOverrideId) {
                  eventDetailsStore.editSectionOverride(eventDetailsStore.sectionToEdit);
                } else {
                  eventDetailsStore.createSectionOverride(eventDetailsStore.sectionToEdit);
                }
              }}
              disabled={!eventDetailsStore.isOverridesValid || eventDetailsStore.isSaving}
            >
              Save Override
            </PrimaryButton>
            <LightButton onClick={() => eventDetailsStore.setShowEditSectionModal(false)}>Cancel</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(SectionInventoryOverrideModal));
