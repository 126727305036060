import React from "react";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import Protected from "../../components/protected/Protected";
import SystemSeasonsFooter from "../../components/system/seasons/admin/SystemSeasonsFooter";
import SystemSeasonsBody from "../../components/system/seasons/admin/SystemSeasonsBody";
import SystemSeasonHeader from "../../components/system/seasons/admin/SystemSeasonHeader";

const SystemSeasonsContainer = () => (
  <div>
    <CardContainer>
      <CardHeader>
        <SystemSeasonHeader />
      </CardHeader>
      <CardBody>
        <SystemSeasonsBody />
      </CardBody>
      <CardFooter>
        <SystemSeasonsFooter />
      </CardFooter>
    </CardContainer>
  </div>
);

export default Protected(SystemSeasonsContainer);
