import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, PrimaryButton, StickyFooterButtons } from "best-common-react";

class MyAccountFooter extends React.Component {
  render() {
    const { myAccountStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <PrimaryButton disabled={!myAccountStore.isValidSave} onClick={myAccountStore.saveUser} id="save-user">
          Save
        </PrimaryButton>
        <LightButton onClick={myAccountStore.cancel} id="cancel">
          Cancel
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(MyAccountFooter));
