import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import Protected from "../../components/protected/Protected";
import ReservationsFilters from "../../components/reservations/admin/ReservationsFilters";
import ReservationsTable from "../../components/reservations/admin/ReservationsTable";
import ReservationsDeleteModal from "../../components/reservations/delete/ReservationsDeleteModal";

class ReservationsContainer extends React.PureComponent {
  render() {
    const { reservationsStore } = this.props.rootStore;

    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>Manage Reservations</CardHeader>
          <CardBody>
            <ReservationsDeleteModal callback={() => reservationsStore.getReservations()} />
            <ReservationsFilters />
            <ReservationsTable />
          </CardBody>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(ReservationsContainer);
