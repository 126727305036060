import React from "react";
import moment from "moment";
import styled from "styled-components";
import { findTimes } from "../../utilities/comptixFormatter";
import { inject, observer } from "mobx-react";

const CountdownBox = styled.div.attrs({
  className: "d-flex flex-column align-items-center mx-1",
  id: "count-down-box"
})``;

const CountdownHolder = styled.div.attrs({
  className: "d-flex justify-content-around px-2 mb-1",
  id: "count-down-header"
})`
  && {
    background-color: ${props => props.theme["light-grey"]};
  }
`;

const CountdownNumber = styled.div.attrs({
  id: "count-down-number"
})`
  && {
    font-size: 1rem;
    height: 1rem;
    font-weight: 300;
    color: ${props => props.theme["dark-grey"]};
  }
`;

const CountdownLabel = styled.div.attrs({
  id: "count-down-label"
})`
  && {
    font-size: 0.625rem;
    font-weight: 300;
    color: ${props => props.theme["dark-grey"]};
  }
`;

const SmallDescription = styled.div.attrs({
  id: "small-description"
})`
  &&& {
    font-size: 0.625rem;
    font-weight: 300;
    color: ${props => props.theme["dark-grey"]};
  }
`;

class EventTimeRemaining extends React.Component {
  constructor(props) {
    super(props);
    this.commonStore = this.props.rootStore.commonStore;
    this.countDown = this.countDown.bind(this);
    this.timeUntilCutoff = this.timeUntilCutoff.bind(this);
    this.timeUntilAccess = this.timeUntilAccess.bind(this);
  }

  countDown() {
    if (this.commonStore.currentTime.isAfter(this.props.game.eventAccess)) {
      if (this.commonStore.currentTime.isAfter(this.props.game.eventCutoff)) {
        return { minutes: 0, hours: 0, days: 0 };
      } else {
        return this.timeUntilCutoff();
      }
    } else {
      return this.timeUntilAccess();
    }
  }

  timeUntilCutoff() {
    return findTimes(
      moment.duration(moment(this.props.game.eventCutoff).diff(this.commonStore.currentTime)).asMinutes()
    );
  }

  timeUntilAccess() {
    return findTimes(
      moment.duration(moment(this.props.game.eventAccess).diff(this.commonStore.currentTime)).asMinutes()
    );
  }

  render() {
    const { game, column } = this.props;
    let flexStyle = column ? "flex-column" : "flex-md-column";
    return (
      <div className={"d-flex align-items-center " + flexStyle}>
        <div>
          <CountdownHolder>
            <CountdownBox className="days">
              <CountdownNumber>{this.countDown().days}</CountdownNumber>
              <CountdownLabel>days</CountdownLabel>
            </CountdownBox>
            <CountdownBox className="hours">
              <CountdownNumber>{this.countDown().hours}</CountdownNumber>
              <CountdownLabel>hrs</CountdownLabel>
            </CountdownBox>
            <CountdownBox className="minutes">
              <CountdownNumber>{parseInt(this.countDown().minutes, 10)}</CountdownNumber>
              <CountdownLabel>mins</CountdownLabel>
            </CountdownBox>
          </CountdownHolder>
        </div>
        <div className="ml-2 ml-md-0">
          <SmallDescription>
            {this.commonStore.currentTime.isAfter(moment(game.eventAccess)) ? "time remaining" : "request tickets in"}
          </SmallDescription>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(EventTimeRemaining));
