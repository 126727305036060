import React from "react";
import { inject, observer } from "mobx-react";
import { DateRangePickerWithLabel } from "best-common-react";

class ReportEventReservationsFilters extends React.Component {
  render() {
    const { commonStore } = this.props.rootStore;
    return (
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-7 col-sm-8 col-12">
          <div className="form-group">
            <DateRangePickerWithLabel
              value={commonStore.dateRangeFilter}
              onStartChange={commonStore.setDateRangeFilterStart}
              onEndChange={commonStore.setDateRangeFilterEnd}
              onChange={() => {}}
              label="Event Date"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ReportEventReservationsFilters));
