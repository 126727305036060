import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { MobileView, NavbarDropdown, NavigationDropdownItem, NavigationDropdownLink } from "best-common-react";
import { RouteConstants } from "../../constants/RouteConstants";

const NavbarHolder = styled.div.attrs({
  id: "navbarSupportedContent"
})`
  @media (max-width: 767px) {
    height: ${props => (props.isProd ? "calc(100vh - 60px)" : "calc(100vh - 78px)")};
    margin-left: -1rem;
    margin-right: -1rem;
    overflow: scroll;
  }
  @media (min-width: 768px) {
    margin-top: -1rem;
    margin-bottom: -1rem;
    height: 60px;
  }
`;

const Navbar = styled.ul.attrs({
  className: "navbar-nav mr-auto"
})`
  height: 60px;
`;

const FEATURE_SUPPORT = process.env.FEATURE_SUPPORT === "true";
const FEATURE_CONTACT = process.env.FEATURE_CONTACT === "true";

class NavigationLinks extends React.Component {
  render() {
    const { authStore, routerStore } = this.props.rootStore;
    const collapseClass = authStore.mobileNavOpen ? "show" : "hide";
    return (
      <NavbarHolder isProd={authStore.isProd} className={`collapse navbar-collapse ${collapseClass}`}>
        <Navbar>
          <NavbarDropdown title="Tickets">
            <NavigationDropdownLink
              to={RouteConstants.TICKET_EVENTS}
              name="Request/Transfer Tickets"
              isActive={routerStore.isTicketsEventsTab}
              toggleMobileNav={() => {
                authStore.setMobileNavOpen(false);
              }}
            />
            <NavigationDropdownLink
              to={RouteConstants.RESERVATIONS}
              name="Manage Reservations"
              isActive={routerStore.isReservationsTab}
              toggleMobileNav={() => {
                authStore.setMobileNavOpen(false);
              }}
            />
          </NavbarDropdown>
          <NavbarDropdown title="Reports">
            {authStore.isAdmin ? (
              <NavigationDropdownLink
                to={RouteConstants.REPORT_EVENTS}
                name="Event Reservations"
                isActive={routerStore.isEventsReservationsTab}
                toggleMobileNav={() => {
                  authStore.setMobileNavOpen(false);
                }}
              />
            ) : null}
            {authStore.isAdmin ? (
              <NavigationDropdownLink
                to={RouteConstants.USER_SUMMARY}
                name="User Reservation Summary"
                isActive={routerStore.isUserSummaryTab}
                toggleMobileNav={() => {
                  authStore.setMobileNavOpen(false);
                }}
              />
            ) : null}
            <NavigationDropdownLink
              to={RouteConstants.REPORT_USERS}
              name="User Reservations"
              isActive={routerStore.isUserReservationsReportTab}
              toggleMobileNav={() => {
                authStore.setMobileNavOpen(false);
              }}
            />
          </NavbarDropdown>
          {FEATURE_CONTACT ? (
            <NavbarDropdown title="Contacts">
              <NavigationDropdownLink
                to={RouteConstants.CONTACTS}
                name="Manage Contacts"
                isActive={routerStore.isContactsTab}
                toggleMobileNav={() => {
                  authStore.setMobileNavOpen(false);
                }}
              />{" "}
            </NavbarDropdown>
          ) : null}

          {authStore.isAdmin || authStore.isBoxOfficeAdmin ? (
            <NavbarDropdown title="Admin">
              {authStore.isAdmin ? (
                <NavigationDropdownLink
                  to={RouteConstants.ADMIN_USERS}
                  name={authStore.isAdmin ? "Users" : null}
                  isActive={routerStore.isUserAdminTab}
                  toggleMobileNav={() => {
                    authStore.setMobileNavOpen(false);
                  }}
                />
              ) : null}
              {authStore.isGlobal ? (
                <NavigationDropdownLink
                  to={RouteConstants.ADMIN_VENUES}
                  name="Venues"
                  isActive={routerStore.isVenueAdminTab}
                  toggleMobileNav={() => {
                    authStore.setMobileNavOpen(false);
                  }}
                />
              ) : null}
              {authStore.isAdmin ? (
                <NavigationDropdownLink
                  to={RouteConstants.ADMIN_DEPARTMENTS}
                  name="Departments"
                  isActive={routerStore.isDepartmentAdminTab}
                  toggleMobileNav={() => {
                    authStore.setMobileNavOpen(false);
                  }}
                />
              ) : null}
              {authStore.isGlobal || authStore.isBoxOfficeAdmin ? (
                <NavigationDropdownLink
                  to={RouteConstants.ADMIN_EVENTS}
                  name="Events"
                  isActive={false}
                  toggleMobileNav={() => {
                    authStore.setMobileNavOpen(false);
                  }}
                />
              ) : null}
              {authStore.isAdmin && FEATURE_SUPPORT ? (
                <NavigationDropdownLink
                  to={RouteConstants.ADMIN_SUPPORT}
                  name="Ticket Requests"
                  isActive={false}
                  toggleMobileNav={() => {
                    authStore.setMobileNavOpen(false);
                  }}
                />
              ) : null}
              {authStore.isGlobal ? (
                <NavigationDropdownLink
                  to={RouteConstants.ORG_SETTING}
                  name="HH Guest"
                  isActive={false}
                  toggleMobileNav={() => {
                    authStore.setMobileNavOpen(false);
                  }}
                />
              ) : null}
            </NavbarDropdown>
          ) : null}
          {authStore.isGlobal && (
            <NavbarDropdown title="System">
              <NavigationDropdownLink
                to={RouteConstants.SYSTEM_SEASONS}
                name="Seasons"
                isActive={routerStore.isSystemSeasonsTab}
                toggleMobileNav={() => {
                  authStore.setMobileNavOpen(false);
                }}
              />
              <NavigationDropdownLink
                to={RouteConstants.REPORT_HISTORY}
                name="Report History"
                isActive={routerStore.isReportHistoryTab}
                toggleMobileNav={() => {
                  authStore.setMobileNavOpen(false);
                }}
              />
            </NavbarDropdown>
          )}
          <MobileView>
            <NavbarDropdown title={authStore.userData.username}>
              <NavigationDropdownLink
                to={RouteConstants.MY_ACCOUNT}
                name="My Account"
                isActive={false}
                toggleMobileNav={() => {
                  authStore.setMobileNavOpen(false);
                }}
              />
              <NavigationDropdownItem
                onClick={authStore.logout}
                name="Sign Out"
                isActive={false}
                toggleMobileNav={() => {
                  authStore.setMobileNavOpen(false);
                }}
              />
            </NavbarDropdown>
          </MobileView>
        </Navbar>
      </NavbarHolder>
    );
  }
}

export default inject("rootStore")(observer(NavigationLinks));
