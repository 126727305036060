export const RouteConstants = {
  BASE: "/",
  LOGIN: "/login",
  EXAMPLES: "/examples",
  MY_ACCOUNT: "/myaccount",
  ADMIN_DEPARTMENTS: "/admin/departments",
  ADMIN_USERS: "/admin/users",
  ADMIN_VENUES: "/admin/venues",
  ADMIN_SUPPORT: "/admin/support",
  ADMIN_EVENTS: "/admin/events",
  TICKET_EVENTS: "/tickets/events",
  DEPARTMENT_EDIT: "/admin/department/edit",
  VENUE_EDIT: "/admin/venue/edit",
  USER_EDIT: "/admin/user/edit/:userId",
  TRANSFER_TICKET_REQUEST: "/transfer/:gamePk/request",
  RESERVATIONS: "/reservations",
  RESERVATIONS_EDIT: "/reservations/edit/:reservationId",
  REPORT_EVENTS: "/reports/events",
  REPORT_USERS: "/reports/users",
  EXPORT_SETTINGS: "/reports/settings",
  EVENT_REQUESTS: "/events/:gamePK(\\d+)/request",
  EVENT_RESERVATIONS: "/events/:gamePk(\\d+)/reservations",
  EVENT_DETAILS: "/events/:gamePk(\\d+)/details",
  VENUE_PRICES_BULK_OVERRIDE: "/admin/venue/bulkOverride",
  USER_SUMMARY: "/reports/users/summary",
  SYSTEM_SEASONS: "/system/seasons",
  SYSTEM_SEASONS_EDIT: "/system/season/edit/:seasonId",
  REPORT_HISTORY: "/reports/history",
  ORG_SETTING: "/org/settings",
  CONTACTS: "/contacts",
  WILDCARD: "/*"
};
