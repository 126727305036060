import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  render() {
    return (
      <div>
        <h4>Modal Example</h4>
        <PrimaryButton
          onClick={() => {
            this.setState({ show: true });
          }}
        >
          Modal Test
        </PrimaryButton>
        <Modal
          show={this.state.show}
          onClose={() => {
            this.setState({ show: false });
          }}
          size="lg"
        >
          <ModalHeaderWithTitleAndClose
            title="Modal title"
            close={() => {
              this.setState({ show: false });
            }}
          />
          <ModalBody>
            <div>Modal Body</div>
          </ModalBody>
          <ModalFooter>
            <div>
              <PrimaryButton onClick={() => {}}>Confirm</PrimaryButton>
              <LightButton onClick={() => {}}>Cancel</LightButton>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalExample;
