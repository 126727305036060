import React from "react";
import { Table, TableHeader } from "best-common-react";
import styled from "styled-components";

const Text = styled.div.attrs({ className: "mb-4" })`
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
`;

const BlueHeader = styled.th`
  &&& {
    color: #002e6d;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;

const Borderless = styled.td`
  &&& {
    border: none;
  }
`;

class UserDeleteBodyLarge extends React.Component {
  render() {
    const currentUser = this.props.currentUser;
    return (
      <div>
        <Text>Are you sure you want to delete the following user?</Text>
        <Table>
          <TableHeader>
            <BlueHeader>Name</BlueHeader>
            <BlueHeader>Username</BlueHeader>
            <BlueHeader>Organization</BlueHeader>
            <BlueHeader>Department</BlueHeader>
          </TableHeader>
          <tbody>
            <tr>
              <Borderless>{currentUser.lastName + ", " + currentUser.firstName}</Borderless>
              <Borderless>{currentUser.username}</Borderless>
              <Borderless>{currentUser.org.orgName}</Borderless>
              <Borderless>{currentUser.department.departmentType.departmentName}</Borderless>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default UserDeleteBodyLarge;
