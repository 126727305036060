import { action, autorun, computed, decorate, extendObservable } from "mobx";
import _ from "lodash";
import moment from "moment";
import { Icon } from "best-common-react";
import React from "react";

class ReportHistoryStore {
  constructor(commonStore, compTixApi, loadStore, routerStore) {
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.loadingStore = loadStore;
    this.routerStore = routerStore;

    const today = moment()
      .startOf("day")
      .toDate();
    const month = moment()
      .startOf("day")
      .add(-1, "month")
      .toDate();

    this.defaults = {
      allHistory: ["Default"],
      sortFilters: {
        direction: "DESC",
        key: "date"
      },
      dateRangeFilter: { start: month, end: today },
      filterError: false,
      selectedOrg: { value: "All", label: "All" },
      reportTypes: [],
      selectedTypes: { value: "All", label: "All" }
    };

    extendObservable(this, {
      allHistory: this.defaults["allHistory"],
      sortFilters: this.defaults["sortFilters"],
      dateRangeFilter: this.defaults["dateRangeFilter"],
      filterError: this.defaults["filterError"],
      selectedOrg: this.defaults["selectedOrg"],
      reportTypes: this.defaults["reportTypes"],
      selectedTypes: this.defaults["selectedTypes"],
      setAllHistory: action(value => {
        this.allHistory = value;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      setDateRangeFilter: action(filters => {
        this.dateRangeFilter = filters;
      }),
      setSelectedOrg: action(value => {
        this.selectedOrg = value;
      }),
      setReportTypes: action(value => {
        this.reportTypes = value;
      }),
      setSelectedTypes: action(value => {
        this.selectedTypes = value;
      }),
      resetStore: action(() => {
        this.allHistory = this.defaults["allHistory"];
        this.dateRangeFilter = this.defaults["dateRangeFilter"];
      })
    });

    autorun(() => {
      if (this.routerStore.isReportHistoryTab) {
        if (this.reportTypes.length === 0) {
          this.getReportTypes();
        }
        this.getHistory();
      } else {
        this.resetStore();
      }
    });
  }

  getHistory = () => {
    this.loadingStore.setLoading(true);
    let start = moment(this.dateRangeFilter.start).format("YYYY-MM-DD");
    let end = moment(this.dateRangeFilter.end).format("YYYY-MM-DD");
    this.compTixApi.getHistory(this.selectedOrg.label, this.selectedTypes.label, start, end).then(data => {
      this.setAllHistory(data);
      this.loadingStore.setLoading(false);
    });
  };

  getReportTypes = () => {
    this.loadingStore.setLoading(true);
    this.compTixApi.getHistoryTypes().then(data => {
      this.setReportTypes(data);
      this.loadingStore.setLoading(false);
    });
  };

  toggleError = () => {
    this.filterError = !this.filterError;
  };

  sort = sortData => {
    if (this.sortFilters["direction"] === "ASC") {
      return _.sortBy(sortData, this.sortFilters["key"]);
    } else if (this.sortFilters["direction"] === "DESC") {
      return _.sortBy(sortData, this.sortFilters["key"]).reverse();
    } else {
      return sortData;
    }
  };

  get reportTypesWithAll() {
    let types = this.reportTypes.map(type => {
      return {
        value: type.reportTypeId,
        label: type.reportTypeName
      };
    });
    types.unshift({ label: "All", value: "All" });
    return types;
  }

  get currentHistory() {
    if (this.allHistory[0] === "Default") {
      return [];
    }

    let history = this.allHistory.map(h => ({
      date: h.completedDate,
      type: h.reportType.reportTypeName,
      orgCode: h.org.orgCode,
      status: h.hadErrors ? <Icon iconName="fa-check-circle" /> : "",
      gamePk: h.gamePk,
      requester: h.ticketRequester ? h.ticketRequester.firstName + ", " + h.ticketRequester.lastName : "",
      user: h.user ? h.user.firstName + ", " + h.user.lastName : ""
    }));
    if (this.filterError === true) {
      history = history.filter(report => {
        return report.status;
      });
    }
    return this.sort(history);
  }
}

decorate(ReportHistoryStore, {
  currentHistory: computed,
  reportTypesWithAll: computed
});

export default ReportHistoryStore;
