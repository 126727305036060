import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import Protected from "../../components/protected/Protected";
import RequestBody from "../../components/request/RequestBody";
import RequestFooter from "../../components/request/RequestFooter";

class RequestContainer extends React.PureComponent {
  render() {
    let params = new URLSearchParams(this.props.location.search);
    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>Request Tickets</CardHeader>
          <CardBody>
            <RequestBody gamePK={this.props.match.params.gamePK} from={params.get("from")} />
          </CardBody>
          <CardFooter>
            <RequestFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(RequestContainer);
