import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { Accordion, AttributeHeader, AttributeValue, Icon, LightButton, BestLink } from "best-common-react";
import Moment from "moment";

const Archive = styled(LightButton).attrs(() => ({
  className: "mt-3"
}))`
  width: 100%;
  padding: 10px;
`;

const SystemSeasonAccordionMobile = ({ data, index, rootStore: { systemSeasonStore }, ...rest }) => (
  <div>
    <Accordion id={index.toString()} {...rest}>
      <Accordion.Header isToggle={true}>
        <div className="d-flex">
          <BestLink to={data.editLink} className="mr-2">
            <Icon iconName="fa-pencil-alt" className="fa-lg d-inline-block mx-1" />
          </BestLink>
          <div>
            {data.year} {data.type}
          </div>
        </div>
      </Accordion.Header>

      <Accordion.Body>
        <AttributeHeader>Active</AttributeHeader>
        <AttributeValue>{data.active && <Icon iconName="fa-check" />}</AttributeValue>
        <AttributeHeader>Default Event Access (ET)</AttributeHeader>
        <AttributeValue>
          {data.defaultEventAccess
            ? Moment(data.defaultEventAccess)
                .utc()
                .format("MM/DD/YYYY - h:mma")
            : ""}
        </AttributeValue>
        {data && data.typeId === 1 && (
          <div>
            <AttributeHeader>NRI Dropoff</AttributeHeader>
            <AttributeValue>{data.nriDropoff ? Moment(data.nriDropoff).format("MM/DD/YYYY") : ""}</AttributeValue>
          </div>
        )}
        {data.active && (
          <Archive
            onClick={() => {
              systemSeasonStore.archiveSeason(data.seasonId);
            }}
          >
            Archive
          </Archive>
        )}
      </Accordion.Body>
    </Accordion>
  </div>
);

export default inject("rootStore")(observer(SystemSeasonAccordionMobile));
