import { action, autorun, extendObservable, reaction } from "mobx";
import _ from "lodash";
import { validateEmail } from "../../utilities/InputUtilities";
import requestStore from "../request/RequestStore";

class ContactStore {
  constructor(commonStore, comptixApi, requestStore) {
    this.commonStore = commonStore;
    this.comptixApi = comptixApi;
    this.requestStore = requestStore;

    this.defaults = {
      contacts: [],
      showNewContactModal: false,
      showSelectContactModal: false,
      selectedContact: null,
      searchTerm: "",
      canSaveContact: false
    };

    extendObservable(this, {
      contacts: this.defaults["contacts"],
      showNewContactModal: this.defaults["showNewContactModal"],
      showSelectContactModal: this.defaults["showSelectContactModal"],
      selectedContact: this.defaults["selectedContact"],
      searchTerm: this.defaults["searchTerm"],
      canSaveContact: this.defaults["canSaveContact"],
      setShowNewContactModal: action(showModal => {
        this.showNewContactModal = showModal;
      }),
      setShowSelectContactModal: action(showModal => {
        this.showSelectContactModal = showModal;
      }),
      setSelectedContact: action(contact => {
        this.selectedContact = contact;
      }),
      setSearchTerm: action(searchTerm => {
        this.searchTerm = searchTerm;
      }),
      setCanSaveContact: action(canSaveContact => {
        this.canSaveContact = canSaveContact;
      }),
      clearSearchTerm: action(() => {
        this.searchTerm = "";
      }),
      resetStore: action(() => {
        this.contacts = this.defaults["contacts"];
        this.showNewContactModal = this.defaults["showNewContactModal"];
        this.showSelectContactModal = this.defaults["showSelectContactModal"];
        this.selectedContact = this.defaults["selectedContact"];
        this.searchTerm = this.defaults["searchTerm"];
      }),
      saveContact: action(() => {
        const contact = {
          firstName: this.requestStore.firstName,
          lastName: this.requestStore.lastName,
          relation: this.requestStore.selectedRelation.value,
          phone: this.requestStore.deliveryMobilePhone,
          email: this.requestStore.deliveryEmail,
          userId: this.requestStore.user.userId
        };
        this.comptixApi.saveContact(contact);
        this.setShowNewContactModal(false);
        this.contacts.push(contact);
      }),
      selectContact: action(selectedContact => {
        if (this.selectedContact !== null) {
          this.requestStore.firstName = this.selectedContact.firstName;
          this.requestStore.lastName = this.selectedContact.lastName;
          this.requestStore.deliveryEmail = this.selectedContact.email;
          this.requestStore.deliveryMobilePhone = this.selectedContact.phone;
          const relationOpts = commonStore.friendsAndFamilyDropdown;
          relationOpts.forEach(relation => {
            if (relation.value === this.selectedContact.relation) {
              this.requestStore.selectedRelation = relation;
            }
          });
        }
        this.setShowSelectContactModal(false);
      })
    });

    autorun(() => {
      if (this.requestStore.user) {
        this.refreshContacts();
      } else {
        this.resetStore();
      }
    });
  }

  updateCanSave() {
    if (
      !!this.requestStore.firstName &&
      !!this.requestStore.lastName &&
      (validateEmail(this.requestStore.deliveryEmail) || !!this.requestStore.deliveryMobilePhone)
    ) {
      this.setCanSaveContact(true);
    } else {
      this.setCanSaveContact(false);
    }
  }

  refreshContacts() {
    this.comptixApi.getContactsByUserId(this.requestStore.user.userId).then(data => {
      this.contacts = data;
    });
  }

  // Note: only one contact can be selected at a time
  handleContactSelect(row) {
    this.contacts.forEach(contact => {
      if (contact.contactId === row.row.contactId) {
        contact.isSelected = true;
        this.setSelectedContact(contact);
      } else {
        contact.isSelected = false;
      }
    });
  }

  handleContactDeselect() {
    this.contacts.forEach(contact => {
      contact.isSelected = false;
      this.setSelectedContact(null);
    });
  }

  filterBySearchTerm(searchTerm) {
    if (searchTerm.length <= 0) {
      return this.contacts;
    } else {
      return _.filter(this.contacts, contact => {
        let search = _.lowerCase(searchTerm);
        return (
          _.includes(_.lowerCase(contact.firstName), search) ||
          _.includes(_.lowerCase(contact.email), search) ||
          _.includes(_.lowerCase(contact.lastName), search) ||
          _.includes(_.lowerCase(contact.phone), search) ||
          _.includes(_.lowerCase(contact.relation), search)
        );
      });
    }
  }

  get displayContacts() {
    return this.filterBySearchTerm(this.searchTerm);
  }
}

export default ContactStore;
