import React from "react";
import { inject, observer } from "mobx-react";
import { ErrorMessage, Input, RequiredInputWithLabel, SuccessMessage } from "best-common-react";

class UserResetPasswordForm extends React.Component {
  componentDidMount() {
    if (this.password) {
      this.password.focus();
    }
  }

  render() {
    const { resetPasswordStore } = this.props.rootStore;
    return (
      <form id="reset-password-form">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <RequiredInputWithLabel name="password" label="Password">
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={resetPasswordStore.resetPasswordObj.password}
                  ref={input => (this.password = input)}
                  onChange={resetPasswordStore.setPassword}
                />
              </RequiredInputWithLabel>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <RequiredInputWithLabel name="confirmPassword" label="Confirm Password">
                <Input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={resetPasswordStore.resetPasswordObj.confirmPassword}
                  onChange={resetPasswordStore.setConfirmPassword}
                />
              </RequiredInputWithLabel>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {resetPasswordStore.passwordErrors.length > 0 ? (
              <ErrorMessage>{resetPasswordStore.passwordErrors[0]}</ErrorMessage>
            ) : (
              <SuccessMessage>Looks good!</SuccessMessage>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default inject("rootStore")(observer(UserResetPasswordForm));
