import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import { CardContainer, CardBody, CardHeader, CardFooter } from "best-common-react";
import AdminProtected from "../../components/protected/AdminProtected";
import VenueBulkOverrideFooter from "../../components/venue/admin/override/VenueBulkOverrideFooter";
import VenueBulkOverrideBody from "../../components/venue/admin/override/VenueBulkOverrideBody";
import LoadingHeader from "../../components/loading/LoadingHeader";

class VenuePriceBulkOverrideContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>
            <LoadingHeader title="Bulk Override Prices" />
          </CardHeader>
          <CardBody>
            <VenueBulkOverrideBody />
          </CardBody>
          <CardFooter>
            <VenueBulkOverrideFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default AdminProtected(VenuePriceBulkOverrideContainer);
