import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import LoadingHeader from "../../components/loading/LoadingHeader";
import GlobalProtected from "../../components/protected/GlobalProtected";
import OrgSettingsFooter from "../../components/orgSettings/OrgSettingsFooter";
import OrgSettingsBody from "../../components/orgSettings/OrgSettingsBody";
import { observer } from "mobx-react";

class OrgSettingsContainer extends React.Component {
  render() {
    return (
      <div>
        <CardContainer>
          <CardHeader>
            <LoadingHeader title="HH Guest" />
          </CardHeader>
          <CardBody>
            <OrgSettingsBody />
          </CardBody>
          <CardFooter>
            <OrgSettingsFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default GlobalProtected(observer(OrgSettingsContainer));
