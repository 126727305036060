import React from "react";
import { LightButton } from "best-common-react";
import { inject, observer } from "mobx-react";

const ExportSettingsTableFooter = ({ type, rootStore }) => {
  const { exportSettingsStore } = rootStore;
  let disabled = false;
  if (exportSettingsStore[type + "DropdownFields"].length === 0) disabled = true;
  if (type === "label" && exportSettingsStore[type + "Selected"].length >= 5) disabled = true;
  return (
    <div className="d-flex justify-content-end mt-2">
      <LightButton onClick={() => exportSettingsStore.addSelected(type)} disabled={disabled}>
        Add Info
      </LightButton>
    </div>
  );
};

export default inject("rootStore")(observer(ExportSettingsTableFooter));
