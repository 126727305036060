import React, { Component } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Provider, observer } from "mobx-react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { Theme, ErrorCatcher } from "best-common-react";
import RootStore from "./stores/RootStore";
import { RouteConstants } from "./constants/RouteConstants";
import LoginContainer from "./containers/LoginContainer";
import ExamplesContainer from "./containers/ExamplesContainer";
import DepartmentAdminContainer from "./containers/department/DepartmentAdminContainer";
import UserAdminContainer from "./containers/user/UserAdminContainer";
import MyAccountContainer from "./containers/MyAccountContainer";
import VenueEditContainer from "./containers/venue/VenueEditContainer";
import DepartmentEditContainer from "./containers/department/DepartmentEditContainer";
import UserEditContainer from "./containers/user/UserEditContainer";
import VenueAdminContainer from "./containers/venue/VenueAdminContainer";
import RequestContainer from "./containers/requests/RequestContainer";
import TicketTransferRequestContainer from "./containers/requests/TicketTransferRequestContainer";
import ReservationsContainer from "./containers/reservations/ReservationsContainer";
import EventReservationsAdminContainer from "./containers/reservations/reports/EventReservationsReportContainer";
import ReservationsEditContainer from "./containers/reservations/ReservationsEditContainer";
import TicketEventsContainer from "./containers/tickets/TicketEventsContainer";
import ComptixNavigation from "./components/nav/ComptixNavigation";
import ReportEventsContainer from "./containers/reports/ReportEventsContainer";
import AdminEventsContainer from "./containers/events/AdminEventsContainer";
import AdminEventDetailsContainer from "./containers/events/AdminEventDetailsContainer";
import ComptixAlerts from "./components/alerts/ComptixAlerts";
import VenuePriceBulkOverrideContainer from "./containers/venue/VenuePriceBulkOverrideContainer";
import ExportSettingsContainer from "./containers/reports/ExportSettingsContainer";
import ResetPasswordModal from "./components/login/ResetPasswordModal";
import UserReservationsContainer from "./containers/user/reports/UserReservationsContainer";
import UserSummaryContainer from "./containers/user/reports/UserSummaryContainer";
import SystemSeasonsEditContainer from "./containers/system/SystemSeasonsEditContainer";
import SystemSeasonsContainer from "./containers/system/SystemSeasonsContainer";
import ReportHistoryContainer from "./containers/reports/ReportHistoryContainer";
import OrgSettingsContainer from "./containers/orgSettings/OrgSettingsContainer";
import AdminSupportContainer from "./containers/support/AdminSupportContainer";
import { datadogRum } from "@datadog/browser-rum";
import ContactManagementContainer from "./containers/contacts/ContactManagementContainer";

const ENV = process.env.REACT_APP_ENV;
const APP_ID = process.env.DATADOG_APP_ID;
const CLIENT_TOKEN = process.env.DATADOG_CLIENT_TOKEN;
const FEATURE_SUPPORT = process.env.FEATURE_SUPPORT === "true";

const rootStore = new RootStore();

const Container = styled.div.attrs({
  className: "container-fluid"
})`
  @media (max-width: 767px) {
    margin-top: ${props => (props.isProd ? "65px" : props.loggedIn ? "85px" : "30px")};
  }
  @media (min-width: 768px) {
    margin-top: ${props => (props.loggedIn ? "90px" : "30px")};
  }
  margin-bottom: 1.25rem;
`;

datadogRum.init({
  applicationId: APP_ID,
  clientToken: CLIENT_TOKEN,
  site: "us5.datadoghq.com",
  service: "comptix",
  env: ENV,
  version: "0.0.1",
  trackInteractions: true
});

datadogRum.startSessionReplayRecording();

class App extends Component {
  render() {
    return (
      <Provider rootStore={rootStore}>
        <Router history={rootStore.history}>
          <ThemeProvider theme={Theme}>
            <div className="ctx-body">
              <ErrorCatcher active={ENV && ENV === "PROD"} appName="Comptix">
                <ResetPasswordModal />
                <ComptixAlerts />
                <ComptixNavigation />
                <Container isProd={rootStore.authStore.isProd} loggedIn={rootStore.authStore.loggedIn}>
                  <div className="row">
                    <div className="col-12">
                      <Switch>
                        <Route exact path={RouteConstants.BASE} component={TicketEventsContainer} />
                        <Route exact path={RouteConstants.LOGIN} component={LoginContainer} />
                        <Route exact path={RouteConstants.EXAMPLES} component={ExamplesContainer} />
                        <Route exact path={RouteConstants.MY_ACCOUNT} component={MyAccountContainer} />
                        <Route exact path={RouteConstants.ADMIN_USERS} component={UserAdminContainer} />
                        <Route exact path={RouteConstants.USER_EDIT} component={UserEditContainer} />
                        <Route exact path={RouteConstants.ADMIN_DEPARTMENTS} component={DepartmentAdminContainer} />
                        <Route exact path={RouteConstants.DEPARTMENT_EDIT} component={DepartmentEditContainer} />
                        <Route exact path={RouteConstants.ADMIN_VENUES} component={VenueAdminContainer} />
                        <Route exact path={RouteConstants.VENUE_EDIT} component={VenueEditContainer} />
                        <Route exact path={RouteConstants.TICKET_EVENTS} component={TicketEventsContainer} />
                        <Route exact path={RouteConstants.RESERVATIONS} component={ReservationsContainer} />
                        <Route exact path={RouteConstants.ADMIN_EVENTS} component={AdminEventsContainer} />
                        <Route exact path={RouteConstants.RESERVATIONS_EDIT} component={ReservationsEditContainer} />
                        <Route exact path={RouteConstants.CONTACTS} component={ContactManagementContainer} />
                        {FEATURE_SUPPORT ? (
                          <Route exact path={RouteConstants.ADMIN_SUPPORT} component={AdminSupportContainer} />
                        ) : null}
                        <Route
                          exact
                          path={RouteConstants.VENUE_PRICES_BULK_OVERRIDE}
                          component={VenuePriceBulkOverrideContainer}
                        />
                        <Route
                          exact
                          path={RouteConstants.EVENT_REQUESTS}
                          render={props => <RequestContainer {...props} />}
                        />
                        <Route
                          exact
                          path={RouteConstants.EVENT_RESERVATIONS}
                          render={props => <EventReservationsAdminContainer {...props} />}
                        />
                        <Route
                          exact
                          path={RouteConstants.EVENT_DETAILS}
                          render={props => <AdminEventDetailsContainer {...props} />}
                        />
                        <Route exact path={RouteConstants.REPORT_EVENTS} component={ReportEventsContainer} />
                        <Route exact path={RouteConstants.REPORT_USERS} component={UserReservationsContainer} />
                        <Route exact path={RouteConstants.REPORT_HISTORY} component={ReportHistoryContainer} />
                        <Route exact path={RouteConstants.USER_SUMMARY} component={UserSummaryContainer} />
                        <Route
                          exact
                          path={RouteConstants.TRANSFER_TICKET_REQUEST}
                          component={TicketTransferRequestContainer}
                        />
                        <Route exact path={RouteConstants.EXPORT_SETTINGS} component={ExportSettingsContainer} />
                        <Route exact path={RouteConstants.SYSTEM_SEASONS} component={SystemSeasonsContainer} />
                        <Route exact path={RouteConstants.SYSTEM_SEASONS_EDIT} component={SystemSeasonsEditContainer} />
                        <Route exact path={RouteConstants.ORG_SETTING} component={OrgSettingsContainer} />
                        <Redirect from={RouteConstants.WILDCARD} to={RouteConstants.BASE} />
                      </Switch>
                    </div>
                  </div>
                </Container>
              </ErrorCatcher>
            </div>
          </ThemeProvider>
        </Router>
      </Provider>
    );
  }
}

export default observer(App);
