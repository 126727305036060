import React from "react";
import { inject, observer } from "mobx-react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  RequiredInputWithLabel,
  Input,
  Icon
} from "best-common-react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const MatchingPassword = styled.div.attrs({
  className: "col-sm-4"
})`
  color: green;
  margin: auto;
  display: inline-block;
  visibility: ${props => (props.in ? "visible" : "hidden")};
  animation: ${props => (props.in ? fadeIn : fadeOut)} 1s linear;
  transition: visibility 1s linear;
`;

const SignOut = styled.div`
  display: inline-block;
  color: ${props => props.theme["sky-blue"]};
  &:hover {
    cursor: pointer;
  }
`;

const ResetPasswordModal = ({ rootStore }) => {
  const { loginStore } = rootStore;
  return (
    <Modal show={loginStore.showResetPassword} onClose={loginStore.onCancelPassword} size="lg">
      <ModalHeader>
        <h3 className="m-0">Welcome!</h3>
      </ModalHeader>
      <ModalBody>
        <div>
          <p>For security purposes please reset your password</p>
          <div className="row mb-3">
            <div className="col-sm-4 form-group">
              <RequiredInputWithLabel name="password" label="Password">
                <Input
                  type="password"
                  name="reset-password"
                  id="reset-password"
                  value={loginStore.password}
                  onChange={loginStore.setPassword}
                />
              </RequiredInputWithLabel>
            </div>
            <div className="col-sm-4 form-group">
              <RequiredInputWithLabel name="confirmPassword" label="Confirm Password">
                <Input
                  type="password"
                  id="reset-confirmPassword"
                  name="reset-confirmPassword"
                  value={loginStore.confirmPassword}
                  onChange={loginStore.setConfirmPassword}
                />
              </RequiredInputWithLabel>
            </div>
            <MatchingPassword in={loginStore.matchingPasswords}>
              <span>
                <Icon iconName="fa-check-circle" style={{ color: "green" }} /> Looks good!
              </span>
            </MatchingPassword>
          </div>
          <SignOut onClick={loginStore.onCancelPassword}>Sign out and change password later</SignOut>
        </div>
      </ModalBody>
      <ModalFooter>
        <LightButton disabled={!loginStore.matchingPasswords} onClick={loginStore.onSavePassword}>
          Save Password
        </LightButton>
      </ModalFooter>
    </Modal>
  );
};

export default inject("rootStore")(observer(ResetPasswordModal));
