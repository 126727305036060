import React from "react";
import ExportSettingsTableRow from "./ExportSettingsTableRow";
import { inject, observer } from "mobx-react";

const ExportSettingsTableBody = ({ type, displayLabelColumn, rootStore }) => {
  const { exportSettingsStore } = rootStore;
  let selectedFields = exportSettingsStore[type + "Selected"];
  return (
    <React.Fragment>
      {selectedFields.map((field, index) => (
        <ExportSettingsTableRow
          type={type}
          key={index}
          field={field}
          index={index}
          displayLabelColumn={displayLabelColumn}
        />
      ))}
    </React.Fragment>
  );
};

export default inject("rootStore")(observer(ExportSettingsTableBody));
