import React from "react";
import { inject, observer } from "mobx-react";

class DepartmentEditContainerHeader extends React.Component {
  render() {
    const { departmentEditStore } = this.props.rootStore;
    return (
      <span id="department-edit-container-header">
        {departmentEditStore.department.departmentType && departmentEditStore.department.departmentType.departmentName}
      </span>
    );
  }
}

export default inject("rootStore")(observer(DepartmentEditContainerHeader));
