import { action, autorun, computed, decorate, extendObservable } from "mobx";
import Moment from "moment";
import _ from "lodash";

const DATE_FORMAT = "M/DD/YYYY";

class EventAdminStore {
  constructor(authStore, commonStore, compTixApi, routerStore, loadingStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.loadingStore = loadingStore;

    this.defaults = {
      events: [],
      sortFilters: {
        direction: "ASC",
        key: "date"
      },
      homeAwayOptions: [
        { label: "All", value: "all" },
        { label: "Home", value: "home" },
        { label: "Away", value: "away" }
      ],
      homeAwaySelected: { label: "All", value: "all" }
    };

    extendObservable(this, {
      events: this.defaults["events"],
      sortFilters: this.defaults["sortFilters"],
      homeAwayOptions: this.defaults["homeAwayOptions"],
      homeAwaySelected: this.defaults["homeAwaySelected"],
      setEvents: action(values => {
        this.events = values;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      setHomeAwaySelected: action(value => {
        this.homeAwaySelected = value;
      }),
      resetStore: action(() => {
        this.homeAwaySelected = this.defaults["homeAwaySelected"];
      })
    });

    autorun(() => {
      if (this.routerStore.isEventAdminTab) {
        this.getEvents();
      } else if (!(this.routerStore.isEventAdminTab || this.routerStore.isEventDetailsTab)) {
        this.resetStore();
      }
    });
  }

  get currentEvents() {
    let flatEvents = this.events
      .filter(event => {
        switch (this.homeAwaySelected.value) {
          case "all":
            return true;
          case "home":
            return event.game.teams.home.team.name === this.commonStore.currentOrgName;
          case "away":
            return event.game.teams.away.team.name === this.commonStore.currentOrgName;
          default:
            return false;
        }
      })
      .map(event => {
        let game = event.game;
        let home = game.teams.home.team;
        let away = game.teams.away.team;
        let gameDescription =
          game.gameType === "Z" || game.gameType === "O" || game.gameType === "A"
            ? game.description
            : away.abbreviation + " @ " + home.abbreviation;
        let tzId = game.venue && game.venue.timeZone ? game.venue.timeZone.id : -1;
        let gameDate = game
          ? {
              gameDate: game.gameDate,
              gamePk: game.gamePk,
              tzId: tzId,
              tbd: game.status.startTimeTBD
            }
          : {};
        return {
          date: gameDate,
          gameStr: { gameStr: gameDescription, gamePk: game.gamePk },
          game: game,
          venue: game.venue.name,
          toPath: "details"
        };
      });
    return this.sort(flatEvents, this.sortFilters);
  }

  getEvents = () => {
    let start = Moment(this.commonStore.dateRangeFilter.start);
    let end = Moment(this.commonStore.dateRangeFilter.end);
    if (start.isBefore(end) && this.commonStore.currentOrgId) {
      this.loadingStore.setLoading(true);
      this.compTixApi
        .getReservationsByOrgAndDate(this.commonStore.currentOrgId, start.format(DATE_FORMAT), end.format(DATE_FORMAT))
        .then(data => {
          this.setEvents(data);
          this.loadingStore.setLoading(false);
        });
    }
  };

  sort = (users, searchFilters) => {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(users, "name");
    } else if (direction === "ASC") {
      return _.sortBy(users, searchFilters.key);
    } else if (direction === "DESC") {
      return _.sortBy(users, searchFilters.key).reverse();
    } else {
      return users;
    }
  };
}

decorate(EventAdminStore, {
  currentEvents: computed
});

export default EventAdminStore;
