import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { BestLink, DropdownItem, NavBar, NavBarSignout, NavbarUsernameDropdown, Navigation } from "best-common-react";
import navlogo from "../../assets/logo/comptix-logo@2x.png";
import NavigationLinks from "./NavigationLinks";
import ViewAs from "./ViewAs";

const MyAccountLink = styled(BestLink)`
  z-index: 1051 !important;
  padding: 0.25rem 1.5rem;
  &&& {
    text-decoration: none;
  }
`;

const NavBarSignoutLink = styled(DropdownItem)`
  z-index: 1051 !important;
`;

const env = process.env.REACT_APP_ENV;

class ComptixNavigation extends React.Component {
  render() {
    const { authStore, routerStore } = this.props.rootStore;
    let username = authStore.userData ? authStore.userData.username : "";
    return (
      <div id="comptix-navigation">
        <NavBar displayEnvIndicator={!routerStore.isLoginTab} env={env}>
          <NavBarSignout loggedIn={authStore.loggedIn} env={env}>
            <NavbarUsernameDropdown username={username}>
              <MyAccountLink to="/myaccount" id="my-account">
                My Account
              </MyAccountLink>
              <NavBarSignoutLink onClick={authStore.logout} id="sign-out">
                Sign Out
              </NavBarSignoutLink>
            </NavbarUsernameDropdown>
          </NavBarSignout>
          <Navigation
            loggedIn={authStore.loggedIn}
            isLoginTab={routerStore.isLoginTab}
            logo={navlogo}
            mobileNavOpen={authStore.mobileNavOpen}
            toggleMobileNav={authStore.setMobileNavOpen}
            displayEnvIndicator={!routerStore.isLoginTab}
          >
            <NavigationLinks />
          </Navigation>
        </NavBar>
        <ViewAs name={authStore.firstCommaLast} />
      </div>
    );
  }
}

export default inject("rootStore")(observer(ComptixNavigation));
