import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Check = styled.div`
  &&& {
    color: ${props => props.theme["sky-blue"]};
  }
`;

// Used for formatting booleans to either a check or nothing.
class CheckFormatter extends React.Component {
  render() {
    const { value } = this.props;
    return <Check className={value ? "fa fa-check" : ""} />;
  }
}

CheckFormatter.defaultProps = {
  color: ""
};

CheckFormatter.propTypes = {
  color: PropTypes.string,
  value: PropTypes.any
};

export default CheckFormatter;
