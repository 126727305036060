import React from "react";
import { inject, observer } from "mobx-react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import PropTypes from "prop-types";
import ReservationsCopyBody from "./ReservationsCopyBody";

class ReservationsCopyModal extends React.Component {
  render() {
    const { reservationCopyStore, eventReservationsStore } = this.props.rootStore;
    const { callback } = this.props;

    return (
      <Modal
        show={reservationCopyStore.showCopyModal}
        onClose={() => reservationCopyStore.setModalVisibility(false)}
        size="lg"
      >
        <ModalHeaderWithTitleAndClose
          title="Copy Reservations"
          close={() => reservationCopyStore.setModalVisibility(false)}
        />
        <ModalBody>
          <ReservationsCopyBody reservations={reservationCopyStore.reservations} game={eventReservationsStore.game} />
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton
              className="mr-2"
              onClick={() => {
                reservationCopyStore.copyReservations(callback);
              }}
              disabled={!reservationCopyStore.gameToCopyTo.value}
            >
              Copy Reservations
            </PrimaryButton>
            <LightButton onClick={() => reservationCopyStore.setModalVisibility(false)}>Cancel</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(ReservationsCopyModal));
