import { action, extendObservable } from "mobx";
import _ from "lodash";

class GridStore {
  constructor() {
    this.defaults = {
      sortFilters: {
        direction: "ASC",
        key: ""
      }
    };

    extendObservable(this, {
      sortFilters: this.defaults["sortFilters"],
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      })
    });
  }

  sort = sortData => {
    if (this.sortFilters["direction"] === "ASC") {
      return _.sortBy(sortData, this.sortFilters["key"]);
    } else if (this.sortFilters["direction"] === "DESC") {
      return _.sortBy(sortData, this.sortFilters["key"]).reverse();
    } else {
      return _.sortBy(sortData, "section");
    }
  };

  sortWithFilters = (sortFilters, sortData) => {
    if (sortFilters["direction"] === "ASC") {
      return _.sortBy(sortData, sortFilters["key"]);
    } else if (sortFilters["direction"] === "DESC") {
      return _.sortBy(sortData, sortFilters["key"]).reverse();
    } else {
      return _.sortBy(sortData, "section");
    }
  };
}

export default GridStore;
