import React from "react";
import { inject } from "mobx-react";
import { Redirect } from "react-router-dom";
import Protected from "./Protected";

export default WrappedComponent => {
  class GlobalProtected extends React.Component {
    render() {
      const {
        rootStore: { authStore }
      } = this.props;
      if (authStore.isGlobal) {
        return <WrappedComponent {...this.props} />;
      } else {
        return <Redirect to="/" />;
      }
    }
  }

  return inject("rootStore")(Protected(GlobalProtected));
};
