import React from "react";
import Protected from "../../../components/protected/Protected";
import TeamTitle from "../../../components/title/TeamTitle";
import { CardBody, CardContainer, CardHeader, CardFooter } from "best-common-react";
import LoadingHeader from "../../../components/loading/LoadingHeader";
import UserSummaryFilter from "../../../components/user/reports/UserSummaryFilter";
import UserSummaryTable from "../../../components/user/reports/UserSummaryTable";
import UserReservationSummaryReportFooter from "../../../components/user/reports/UserReservationSummaryReportFooter";

class UserSummaryContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle />
        <CardContainer>
          <CardHeader>
            <LoadingHeader title="User Reservation Summary" />
          </CardHeader>
          <CardBody>
            <UserSummaryFilter />
            <UserSummaryTable />
          </CardBody>
          <CardFooter>
            <UserReservationSummaryReportFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(UserSummaryContainer);
