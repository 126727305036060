import React from "react";
import Protected from "../../../components/protected/Protected";
import TeamTitle from "../../../components/title/TeamTitle";
import { CardBody, CardContainer, CardHeader, CardFooter } from "best-common-react";
import LoadingHeader from "../../../components/loading/LoadingHeader";
import UserReservationsReportFilters from "../../../components/user/reports/UserReservationsReportFilters";
import UserReservationsReportTable from "../../../components/user/reports/UserReservationsReportTable";
import UserReservationsReportFooter from "../../../components/user/reports/UserReservationsReportFooter";

class UserReservationsContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle />
        <CardContainer>
          <CardHeader>
            <LoadingHeader title="User Reservations Report" />
          </CardHeader>
          <CardBody>
            <UserReservationsReportFilters />
            <UserReservationsReportTable />
          </CardBody>
          <CardFooter>
            <UserReservationsReportFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(UserReservationsContainer);
