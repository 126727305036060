import Moment from "moment";
// eslint-disable-next-line
import tz from "moment-timezone";

// visible for testing
export function findTimes(totalMinutes) {
  const oneHour = 60;
  const oneDay = oneHour * 24;
  let minutes = 0;
  let hours = 0;
  let days = 0;

  if (totalMinutes < oneHour) {
    return {
      minutes: totalMinutes,
      hours,
      days
    };
  }

  if (totalMinutes < oneDay) {
    // integer division
    hours = (totalMinutes / oneHour) >> 0;
    minutes = totalMinutes % oneHour;
    return {
      minutes,
      hours,
      days
    };
  }

  if (totalMinutes >= oneDay) {
    // integer division
    days = (totalMinutes / oneDay) >> 0;
    if (totalMinutes - days * oneDay >= 60) {
      hours = ((totalMinutes - days * oneDay) / oneHour) >> 0;
    }
    if ((totalMinutes % oneDay) % oneHour !== 0) {
      minutes = (totalMinutes % oneDay) % oneHour;
    }
    return { minutes, hours, days };
  }

  return { minutes: 0, hours: 0, days: 0 };
}

export function formatMinutes(totalMinutes) {
  if (totalMinutes === undefined || totalMinutes === null) {
    return "N/A";
  }

  let msg = "";
  let values = findTimes(totalMinutes);
  if (values.days !== 0) {
    msg += values.days;
    msg += values.days === 1 ? " day " : " days ";
  }
  if (values.hours !== 0) {
    msg += values.hours;
    msg += values.hours === 1 ? " hour " : " hours ";
  }
  if (values.minutes !== 0) {
    msg += values.minutes;
    msg += values.minutes === 1 ? " minute " : " minutes ";
  }
  return (msg += "prior to game");
}

export function formatAllotment(allotment, season, familyAllotment, friendsAllotment) {
  function formatAllotment(first, second) {
    return first + "/" + second;
  }

  function getAllotmentForSeason(departmentsAllotment, season) {
    return departmentsAllotment.toJSON().filter(allotment => allotment.season.seasonId === season.value);
  }

  if (allotment.length === 0) {
    return "0/0";
  }
  let departmentsAllotments = getAllotmentForSeason(allotment, season);
  if (departmentsAllotments.length !== 1) {
    return "0/0";
  }
  let allotmentForSeason = departmentsAllotments[0];
  return formatAllotment(allotmentForSeason[familyAllotment], allotmentForSeason[friendsAllotment]);
}

export function formatAvailable(department, season, timeZone) {
  function getAccessForSeason(departmentAccess, season) {
    return departmentAccess.toJSON().filter(access => access.season.seasonId === season.value);
  }

  let access = getAccessForSeason(department["departmentAccess"], season);
  if (access && access.length === 1) {
    let departmentAccess = access[0];
    if (departmentAccess.availableUseDate) {
      return (
        Moment(departmentAccess.availableDate)
          .tz(timeZone)
          .format("M/D/YYYY - h:mma") +
        " " +
        timezone(
          Moment(departmentAccess.availableDate)
            .tz(timeZone)
            .format("zz")
        )
      );
    } else {
      return formatMinutes(departmentAccess.availableMinutesBefore);
    }
  }
  return "N/A";
}

export function formatCutoff(department, season, timeZone) {
  function getAccessForSeason(departmentAccess, season) {
    return departmentAccess.toJSON().filter(access => access.season.seasonId === season.value);
  }

  let cutoff = getAccessForSeason(department["departmentAccess"], season);
  if (cutoff && cutoff.length === 1) {
    let departmentCutoff = cutoff[0];
    if (departmentCutoff.cutoffUseDate) {
      return (
        Moment(departmentCutoff.cutoffDate)
          .tz(timeZone)
          .format("M/D/YYYY - h:mma") +
        " " +
        timezone(
          Moment(departmentCutoff.cutoffDate)
            .tz(timeZone)
            .format("zz")
        )
      );
    } else {
      return formatMinutes(departmentCutoff.cutoffMinutesBefore);
    }
  }
  return "N/A";
}

export function timezone(tz) {
  if (tz && tz.length !== 2) {
    return tz[0] + tz[tz.length - 1];
  } else {
    return tz;
  }
}
