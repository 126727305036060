import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardHeader } from "best-common-react";
import Protected from "../../components/protected/Protected";
import TicketEventFilters from "../../components/tickets/events/TicketEventFilters";
import TicketEventsList from "../../components/tickets/events/TicketEventsList";
import TicketEventsHeader from "../../components/tickets/events/TicketEventsHeader";
import SetEmailModal from "../../components/login/SetEmailModal";
import EventMessageModal from "../../components/messages/EventMessageModal";

class EventsContainer extends React.Component {
  render() {
    return (
      <div>
        <EventMessageModal show={true} />
        <SetEmailModal />
        <TeamTitle />
        <CardContainer>
          <CardHeader>
            <TicketEventsHeader />
          </CardHeader>
          <CardBody>
            <TicketEventFilters />
            <TicketEventsList />
          </CardBody>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(EventsContainer);
