import React from "react";
import GameDateFormatter from "../../elements/tables/GameDateFormatter";
import { BestLink } from "best-common-react";

class EventDateLinkFormatter extends React.Component {
  render() {
    const { value, path } = this.props;
    const to = `/events/${value.gamePk}/${path}`;

    return (
      <BestLink to={to}>
        <GameDateFormatter value={value} />
      </BestLink>
    );
  }
}

export default EventDateLinkFormatter;
