import React from "react";
import { Icon, LightButton, AnchorLink } from "best-common-react";
import { Card, CardBody } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";
import { DesktopTabletView, MobileView } from "best-common-react";

const TableButton = styled(LightButton)`
  width: 100%;
`;

const MobileOverrideIconsCard = styled(Card).attrs({})`
  &&& {
    border: 1px solid ${props => props.theme["grey"]};
    background-color: ${props => props.theme["white"]};
    border-radius: 0;
    text-align: center;
  }
`;

const OverrideMutedText = styled.small`
  color: ${props => props.theme["grey-four"]};
`;

class OverrideIconFormatter extends React.Component {
  render() {
    const { index, hasOverrides, onAdd, onEdit, onDelete } = this.props.value;
    const desktopAddOverrideId = `desktop-add-override_${index}`;
    const mobileAddOverrideId = `mobile-add-override_${index}`;
    const desktopEditOverrideId = `desktop-edit-override_${index}`;
    const desktopDeleteOverrideId = `desktop-delete-override_${index}`;

    if (!hasOverrides) {
      return (
        <div>
          <DesktopTabletView>
            <div>
              <TableButton id={desktopAddOverrideId} className="btn-sm" onClick={onAdd}>
                Add
              </TableButton>
            </div>
          </DesktopTabletView>
          <MobileView>
            <div>
              <MobileOverrideIconsCard className="border-bottom-0">
                <CardBody className="p-1">
                  <AnchorLink id={mobileAddOverrideId} onClick={onAdd}>
                    <small>Add Override</small>
                  </AnchorLink>
                </CardBody>
              </MobileOverrideIconsCard>
            </div>
          </MobileView>
        </div>
      );
    } else {
      return (
        <div>
          <DesktopTabletView>
            <div className="d-flex">
              <div className="flex-fill text-center">
                <Icon id={desktopEditOverrideId} iconName="fa-pencil-alt" onClick={onEdit} />
              </div>
              <div className="flex-fill text-center">
                <Icon id={desktopDeleteOverrideId} iconName="fa-trash-alt" onClick={onDelete} />
              </div>
            </div>
          </DesktopTabletView>
          <MobileView>
            <MobileOverrideIconsCard className="border-bottom-0">
              <CardBody className="p-1">
                <div className="d-flex justify-content-between">
                  <Icon
                    id={desktopEditOverrideId}
                    className="flex-fill text-center mx-1"
                    iconName="fa-pencil-alt"
                    onClick={onEdit}
                  />
                  <div>
                    <OverrideMutedText>Override</OverrideMutedText>
                  </div>
                  <Icon
                    id={desktopDeleteOverrideId}
                    className="flex-fill text-center mx-1"
                    iconName="fa-trash-alt"
                    onClick={onDelete}
                  />
                </div>
              </CardBody>
            </MobileOverrideIconsCard>
          </MobileView>
        </div>
      );
    }
  }
}

OverrideIconFormatter.propTypes = {
  hasOverrides: PropTypes.bool,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default OverrideIconFormatter;
