import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import styled from "styled-components";
import { DesktopTabletView, MobileView, BestLink, InfoCard, DisabledButton } from "best-common-react";
import EventTimeRemaining from "../../events/EventTimeRemaining";
import { timezone } from "../../../utilities/comptixFormatter";
import MessageIcon from "../../messages/MessageIcon";
import { getTeamAbbreviation } from "../../../utilities/GameUtility";

const Date = styled.div.attrs({
  id: "date"
})`
  &&& {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: ${props => props.theme.lineHeight["eventGrid"]};
  }
`;

const SmallerDate = styled.div.attrs({
  id: "smaller-date"
})`
  &&& {
    font-size: 1rem;
    font-weight: bold;
    line-height: ${props => props.theme.lineHeight["eventGrid"]};
  }
`;

const HiddenDescription = styled.div.attrs({ className: "d-md-block d-lg-none", id: "hidden-description" })`
  &&& {
    font-size: 0.875rem;
    color: ${props => props.theme["dark-grey"]};
    line-height: ${props => props.theme.lineHeight["eventGrid"]};
  }
`;

const Description = styled.div.attrs({
  id: "description"
})`
  &&& {
    font-size: 0.875rem;
    color: ${props => props.theme["dark-grey"]};
    line-height: ${props => props.theme.lineHeight["eventGrid"]};
  }
`;

const MinorHeader = styled.div.attrs({
  id: "minor-header"
})`
  &&& {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: ${props => props.theme.lineHeight["eventGrid"]};
  }
`;

const Game = styled.div.attrs({
  id: "game"
})`
  &&& {
    font-size: 0.875rem;
    font-weight: bold;
    line-height: ${props => props.theme.lineHeight["eventGrid"]};
  }
`;

const GameAndTime = styled.div.attrs({
  id: "game-time"
})`
  &&& {
    min-width: 50px;
    line-height: ${props => props.theme.lineHeight["eventGrid"]};

    @media (max-width: 991) {
      width: 80%;
    }

    @media (min-width: 992px) {
      width: 20%;
    }
  }
`;

const TicketsLeft = styled.div.attrs({ className: "ml-auto", id: "tickets-left" })`
  &&& {
    font-size: 0.75rem;
    color: ${props => (props.isActive ? props.theme["dark-grey"] : props.theme["grey"])};
    text-align: right;
    margin-top: 0.4rem;
  }
`;

const SmallDescription = styled.div.attrs({
  id: "small-description"
})`
  &&& {
    font-size: 0.625rem;
    font-weight: 300;
    color: ${props => props.theme["dark-grey"]};
    margin-top: 0.25rem;
  }
`;

const SmPrimaryButton = styled(DisabledButton).attrs({
  className: "btn btn-primary btn-sm",
  id: "small-primary-button"
})`
  &&& {
    font-size: 0.7rem;
    background-color: ${props => props.theme.primary};
    color: #ffffff;
    border-color: ${props => props.theme.primary};
    padding: 0.4rem 0.7rem;
  }
`;

const SmLightButton = styled(DisabledButton).attrs({
  className: "btn btn-sm",
  id: "small-light-button"
})`
  &&& {
    font-size: 0.7rem;
    color: #666666;
    border-radius: 4px;
    background-color: ${props => props.theme["white"]};
    border: solid 1px ${props => props.theme["grey"]};
    &:hover {
      ${props => (props.disabled ? "" : "background-color: " + props.theme["grey-three"] + ";")};
      ${props => (props.disabled ? "" : "border: solid 1px " + props.theme["grey-two"] + ";")};
    }
    padding: 0.4rem 0.7rem;
  }
`;

const LinkWithoutUnderline = styled(BestLink)`
  &&& {
    text-decoration: none;
  }
`;

const Countdown = styled.div.attrs({
  id: "count-down"
})`
  width: 120px;
`;

class Event extends React.Component {
  render() {
    const { game, displayCountdown, onIconClick } = this.props;
    let gameDate =
      game.status.startTimeTBD || !(game.venue.timeZone && game.venue.timeZone.id)
        ? moment(game.gameDate).utc()
        : moment(game.gameDate).tz(game.venue.timeZone.id);
    let tz = game.venue.timeZone && game.venue.timeZone.id ? timezone(gameDate.format("zz")) : "UTC";
    return (
      <InfoCard backgroundColor="white" className="my-2" title="">
        <div>
          <DesktopTabletView>
            <div className="d-flex">
              <div style={{ width: "100%" }} className="d-flex mr-2">
                <div style={{ width: "100px" }}>
                  <Date>{gameDate.format("MMM D")}</Date>
                  <Description>{gameDate.format("ddd")}</Description>
                </div>

                <GameAndTime className="d-none d-md-block">
                  <Game>
                    {game.gameType === "Z" || game.gameType === "O" || game.gameType === "A"
                      ? game.description
                      : getTeamAbbreviation(game, false) + " @ " + getTeamAbbreviation(game, true)}
                  </Game>
                  <Description>{game.resumedFromDate ? "Resumed from " + game.resumedFromDate : ""}</Description>
                  <HiddenDescription>
                    {game.status.startTimeTBD ? "TBD" : gameDate.format("h:mm a") + " " + tz}
                  </HiddenDescription>
                </GameAndTime>

                <div style={{ minWidth: "50px", width: "20%" }} className="d-none d-md-none d-lg-block">
                  <Description>{game.status.startTimeTBD ? "TBD" : gameDate.format("h:mm a") + " " + tz}</Description>
                </div>

                <div className="d-none d-md-none d-lg-block">
                  <MinorHeader>{game.venue.name}</MinorHeader>
                  {game.venue.location ? (
                    <Description>
                      {game.venue.location.city}{" "}
                      {game.venue.location.city && game.venue.location.stateAbbrev ? ", " : ""}{" "}
                      {game.venue.location.stateAbbrev}
                    </Description>
                  ) : null}
                </div>
              </div>

              <div className="ml-auto">
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <TicketsLeft style={{ width: "85px" }} className="mr-3" isActive={game.activeRequest}>
                      {!game.passedCutoff ? Math.max(0, game.ticketsRemaining) + " tickets left" : ""}
                    </TicketsLeft>
                    {game.message ? (
                      <div className="ml-auto" onClick={onIconClick}>
                        <MessageIcon className="mr-3" />
                      </div>
                    ) : null}
                  </div>

                  <div style={{ width: "155px" }}>
                    <div className="d-flex flex-column align-items-center">
                      <div>
                        <div className="d-flex">
                          <LinkWithoutUnderline to={"/events/" + game.gamePk + "/request"} className="mr-1">
                            <SmPrimaryButton disabled={!game.activeRequest || game.isLocked}> Request </SmPrimaryButton>
                          </LinkWithoutUnderline>
                          <LinkWithoutUnderline to={"/transfer/" + game.gamePk + "/request"}>
                            <SmLightButton disabled={!game.activeRequest || game.isLocked}> Transfer</SmLightButton>
                          </LinkWithoutUnderline>
                        </div>
                      </div>
                      <div>
                        <SmallDescription>
                          {game.ticketRequests.used}/{game.ticketRequests.allowed} requested
                        </SmallDescription>
                      </div>
                    </div>
                  </div>

                  <Countdown className={displayCountdown ? "d-flex flex-column align-items-center" : "d-none"}>
                    <EventTimeRemaining column={true} game={game} />
                  </Countdown>
                </div>
              </div>
            </div>
          </DesktopTabletView>

          <MobileView>
            <div className="row">
              <div className="col-4">
                <SmallerDate>{gameDate.format("MMM D")}</SmallerDate>
                <Description>{gameDate.format("ddd")}</Description>
              </div>
              <div className="col-5">
                <Game>
                  {game.gameType === "Z" || game.gameType === "O" || game.gameType === "A"
                    ? game.description
                    : getTeamAbbreviation(game, false) + " @ " + getTeamAbbreviation(game, true)}
                </Game>
                <Description>{game.status.startTimeTBD ? "TBD" : gameDate.format("h:mm a") + " " + tz}</Description>
              </div>

              <div className="col-3">
                <div className="d-flex flex-column">
                  <TicketsLeft isActive={game.activeRequest}>
                    {!game.passedCutoff ? game.ticketsRemaining + " left" : ""}
                  </TicketsLeft>
                  {game.message ? (
                    <div className="ml-auto" onClick={onIconClick}>
                      <MessageIcon />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex justify-content-start">
                      <div>
                        <div className="d-flex justify-content-center">
                          <LinkWithoutUnderline to={"/events/" + game.gamePk + "/request"} className="mr-1">
                            <SmPrimaryButton disabled={!game.activeRequest || game.isLocked}> Request </SmPrimaryButton>
                          </LinkWithoutUnderline>
                          <LinkWithoutUnderline to={"/transfer/" + game.gamePk + "/request"}>
                            <SmLightButton disabled={!game.activeRequest || game.isLocked}> Transfer</SmLightButton>
                          </LinkWithoutUnderline>
                        </div>
                        <div className="d-flex justify-content-center">
                          <SmallDescription>
                            {game.ticketRequests.used}/{game.ticketRequests.allowed} requested
                          </SmallDescription>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>{displayCountdown ? <EventTimeRemaining column={true} game={game} /> : null}</div>
                </div>
              </div>
            </div>
          </MobileView>
        </div>
      </InfoCard>
    );
  }
}

export default observer(Event);
