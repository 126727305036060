import React from "react";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import Protected from "../../components/protected/Protected";
import SystemSeasonEditBody from "../../components/system/seasons/edit/SystemSeasonEditBody";
import SystemSeasonEditFooter from "../../components/system/seasons/edit/SystemSeasonEditFooter";

const SystemSeasonsEditContainer = () => (
  <div>
    <CardContainer>
      <CardHeader>Seasons</CardHeader>
      <CardBody>
        <SystemSeasonEditBody />
      </CardBody>
      <CardFooter>
        <SystemSeasonEditFooter />
      </CardFooter>
    </CardContainer>
  </div>
);

export default Protected(SystemSeasonsEditContainer);
