import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { BestLabel, YearPickerSelect } from "best-common-react";

class TicketAllotmentFilters extends React.Component {
  render() {
    const { commonStore } = this.props.rootStore;
    return (
      <div className="form-group" id="ticket-allotment-filters">
        <BestLabel htmlFor="ticket-allotment-year-select">Year</BestLabel>
        <YearPickerSelect
          startYear={
            commonStore.activeYearsDropdownList.length
              ? commonStore.activeYearsDropdownList[0].value
              : new Date().getFullYear()
          }
          endYear={
            commonStore.activeYearsDropdownList.length
              ? commonStore.activeYearsDropdownList.slice(-1)[0].value
              : new Date().getFullYear()
          }
          value={this.props.allotmentYear}
          onChange={this.props.onYearChange}
          id="ticket-allotment-year-select"
        />
      </div>
    );
  }
}

TicketAllotmentFilters.propTypes = {
  allotmentYear: PropTypes.number.isRequired,
  onYearChange: PropTypes.func.isRequired
};

export default inject("rootStore")(observer(TicketAllotmentFilters));
