import React from "react";
import { inject, observer } from "mobx-react";
import { DataTable } from "best-common-react";
import GameDateFormatter from "../../../elements/tables/GameDateFormatter";

const cols = [
  {
    key: "date",
    name: "Date",
    resizable: true,
    formatter: GameDateFormatter,
    width: 185,
    sortable: true
  },
  {
    key: "game",
    name: "Game",
    width: 125,
    resizable: true,
    sortable: true
  },
  {
    key: "venue",
    name: "Venue",
    resizable: true,
    sortable: true
  }
];

class VenueOverideGameTable extends React.Component {
  render() {
    const { venueBulkOverrideStore } = this.props.rootStore;
    return (
      <div>
        <DataTable
          data={venueBulkOverrideStore.gameData}
          columns={cols}
          sortFunc={venueBulkOverrideStore.setGameSortDirection}
          sortColumn={venueBulkOverrideStore.gameSortFilters["key"]}
          sortDirection={venueBulkOverrideStore.gameSortFilters["direction"]}
          rowSelection={{
            showCheckbox: true,
            onRowsSelected: venueBulkOverrideStore.onChecked,
            onRowsDeselected: venueBulkOverrideStore.onChecked,
            selectBy: {
              isSelectedKey: "checked"
            },
            enableShiftSelect: true
          }}
          selectAll={false}
        />
      </div>
    );
  }
}

export default inject("rootStore")(observer(VenueOverideGameTable));
