import React from "react";
import { inject, observer } from "mobx-react";
import EventDataCard from "../events/EventDataCard";
import TicketRequestFilters from "./TicketRequestFilters";
import { Icon } from "best-common-react";
import styled from "styled-components";

const WarningIcon = styled(Icon).attrs({ className: "mr-2" })`
  &&& {
    color: #ff9100;
  }
`;

const Information = styled.div.attrs({ className: "mt-2" })`
  &&& {
    color: #ff9100;
  }
`;

class RequestBody extends React.Component {
  render() {
    const { requestStore, authStore, commonStore } = this.props.rootStore;
    requestStore.setGamePK(this.props.gamePK);
    requestStore.setReturnToReservations(this.props.from);
    if (!requestStore.game.venue) {
      return <div>No Event</div>;
    }

    const displayCountdown = commonStore.currentOrgId === authStore.userData.org.orgId;

    return (
      <div className="row">
        <div className="col-lg-3 col-md-5">
          <EventDataCard game={requestStore.game} displayCountdown={displayCountdown} title="" />
          {requestStore.message ? (
            <Information>
              <span>
                <WarningIcon iconName="fa-info-circle" />
              </span>
              {requestStore.message}
            </Information>
          ) : null}
          {requestStore.game.resumedFromDate ? (
            <Information>
              <span>
                <WarningIcon iconName="fa-info-circle" />
              </span>
              Game resumed from {requestStore.game.resumedFromDate}
            </Information>
          ) : null}
        </div>

        <div className="col-lg-9 col-md-7 mt-1">
          <TicketRequestFilters game={requestStore.game} id="ticket-request-filters" />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(RequestBody));
