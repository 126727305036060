import React from "react";
import { inject, observer } from "mobx-react";
import { DataTable, PriceFormatter } from "best-common-react";
import OverridePriceFormatter from "../../../elements/tables/OverridePriceFormatter";

const cols = [
  {
    key: "section",
    name: "Section",
    resizable: true,
    locked: true,
    sortable: true
  },
  {
    key: "homeAway",
    name: "Type",
    resizable: true,
    sortable: true
  },
  {
    key: "price",
    name: "Price",
    resizable: true,
    sortable: true,
    formatter: PriceFormatter
  },
  {
    key: "overridePrice",
    name: "Override",
    resizable: true,
    cellClass: "text-right",
    formatter: OverridePriceFormatter
  }
];

class VenueOverrideSectionTable extends React.Component {
  render() {
    const { venueBulkOverrideStore } = this.props.rootStore;
    return (
      <div>
        <DataTable
          data={venueBulkOverrideStore.sectionData}
          columns={cols}
          sortFunc={venueBulkOverrideStore.setSectionSortDirection}
          sortColumn={venueBulkOverrideStore.sectionSortFilters["key"]}
          sortDirection={venueBulkOverrideStore.sectionSortFilters["direction"]}
        />
      </div>
    );
  }
}

export default inject("rootStore")(observer(VenueOverrideSectionTable));
