import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestSelect, DateRangePickerWithLabel } from "best-common-react";

class EventAdminFilters extends React.Component {
  render() {
    const { eventAdminStore, commonStore } = this.props.rootStore;
    return (
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-7 col-sm-8 col-12">
          <div className="form-group" id="event-date-form-group">
            <DateRangePickerWithLabel
              value={commonStore.dateRangeFilter}
              onStartChange={commonStore.setDateRangeFilterStart}
              onEndChange={commonStore.setDateRangeFilterEnd}
              onChange={() => {}}
              label="Event Date"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12">
          <BestLabel htmlFor="home-away">Home/Away</BestLabel>
          <BestSelect
            id="home-away"
            options={eventAdminStore.homeAwayOptions.toJSON()}
            value={eventAdminStore.homeAwaySelected}
            onChange={eventAdminStore.setHomeAwaySelected}
          />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(EventAdminFilters));
