import React from "react";
import { inject, observer } from "mobx-react";
import VenueEditPickupLocation from "./VenueEditPickupLocation";

class VenueEditPickupLocationTableBody extends React.Component {
  render() {
    const { venueEditStore } = this.props.rootStore;
    let locations = venueEditStore.sortedPickupLocations;
    let departmentOptions = venueEditStore.departmentOptions;
    return (
      <React.Fragment>
        {locations.map((pl, index) => (
          <VenueEditPickupLocation
            id={"pickup-location-" + index}
            key={index}
            index={index}
            pickupLocation={pl}
            departments={departmentOptions}
            onChange={venueEditStore.onPickupLocationChange}
          />
        ))}
      </React.Fragment>
    );
  }
}

export default inject("rootStore")(observer(VenueEditPickupLocationTableBody));
