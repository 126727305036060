import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, StickyFooterButtons } from "best-common-react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import styled from "styled-components";

const LightDropdownButton = styled(DropdownToggle)`
  &&& {
    color: #666666;
    border-radius: 4px;
    background-color: ${props => props.theme["white"]};
    border: solid 1px ${props => props.theme["grey"]};
    &:hover {
      ${props => (props.disabled ? "" : "background-color: " + props.theme["grey-three"] + ";")};
      ${props => (props.disabled ? "" : "border: solid 1px " + props.theme["grey-two"] + ";")};
    }
    &:after {
      margin-left: 0.5rem;
    }
  }
`;

class EventReservationsFooter extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    const { eventReservationsStore, reservationDeleteStore, reservationCopyStore, commonStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <ButtonDropdown direction="up" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <LightDropdownButton outline caret block>
            Export
          </LightDropdownButton>
          <DropdownMenu right>
            <DropdownItem onClick={() => eventReservationsStore.buildFullReport()}>Export to Excel</DropdownItem>
            <DropdownItem onClick={() => eventReservationsStore.buildLabelsReport(commonStore.currentOrgId)}>
              Labels
            </DropdownItem>
            <DropdownItem onClick={() => eventReservationsStore.buildBoxOfficeReport(commonStore.currentOrgId)}>
              Box Office Report
            </DropdownItem>
            <DropdownItem onClick={() => eventReservationsStore.buildDigitalTicketInventory(commonStore.currentOrgId)}>
              Digital Ticket Inventory
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
        <LightButton onClick={() => eventReservationsStore.addNewReservation()}>Add Reservation</LightButton>
        <LightButton
          disabled={eventReservationsStore.selectedReservations.length === 0}
          onClick={() => {
            reservationCopyStore.setReservations(eventReservationsStore.selectedReservations);
            reservationCopyStore.setModalVisibility(true);
          }}
        >
          Copy Reservations
        </LightButton>
        <LightButton
          disabled={!(eventReservationsStore.selectedReservations.length > 0)}
          onClick={() => {
            reservationDeleteStore.setReservationsToDelete(eventReservationsStore.selectedReservations);
            reservationDeleteStore.setModalVisibility(true);
          }}
        >
          Delete
        </LightButton>
        <LightButton onClick={() => eventReservationsStore.cancel()} id="close">
          Close
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(EventReservationsFooter));
