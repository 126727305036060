import React from "react";
import { inject, observer } from "mobx-react";
import { AlertHolder } from "best-common-react";

class ComptixAlerts extends React.Component {
  render() {
    const { alertStore } = this.props.rootStore;
    return (
      <div id="comptix-alerts">
        <AlertHolder alerts={alertStore.alerts.toJSON()} onRemove={alertStore.removeAlert} />
      </div>
    );
  }
}

export default inject("rootStore")(observer(ComptixAlerts));
