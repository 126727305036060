import React from "react";
import { inject, observer } from "mobx-react";
import { AccordionTableWrapper } from "best-common-react";
import EventDateLinkFormatter from "../../events/admin/EventDateLinkFormatter";
import EventGameLinkFormatter from "../../events/admin/EventGameLinkFormatter";
import UserReservationsAccordionMobile from "./UserReservationsAccordionMobile";
import HigherOrderComponent from "../../../utilities/HigherOrderComponent";

const URL_PATH = "reservations";

let DateLinkFormatter = HigherOrderComponent(EventDateLinkFormatter);
DateLinkFormatter.defaultProps = {
  path: URL_PATH
};

let GameLinkFormatter = HigherOrderComponent(EventGameLinkFormatter);
GameLinkFormatter.defaultProps = {
  path: URL_PATH
};

const UserReservationsReportTable = ({ rootStore }) => {
  const { userReservationsReportStore } = rootStore;
  return (
    <div className="row">
      <div className="col-12">
        <AccordionTableWrapper
          id="event-reservations-report-table"
          data={userReservationsReportStore.pastReservations}
          columns={userReservationsReportStore.cols}
          accordion={UserReservationsAccordionMobile}
          height={400}
          sortFunc={userReservationsReportStore.setSortDirection}
          sortColumn={userReservationsReportStore.sortFilters["key"]}
          sortDirection={userReservationsReportStore.sortFilters["direction"]}
        />
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(UserReservationsReportTable));
