import React from "react";
import moment from "moment";
import styled from "styled-components";
import EventTimeRemaining from "./EventTimeRemaining";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { DesktopTabletView, InfoCard } from "best-common-react";
import Date from "../common/displays/Date";
import GameDisplay from "../common/displays/GameDisplay";
import { timezone } from "../../utilities/comptixFormatter";
import { getTeamAbbreviation } from "../../utilities/GameUtility";

const Container = styled.div.attrs({ id: "event-data-card" })``;

const Description = styled.div.attrs({
  id: "description"
})`
  &&& {
    font-size: 0.875rem;
    color: ${props => props.theme["dark-grey"]};
  }
`;

const MinorHeader = styled.div.attrs({
  id: "minor-header"
})`
  &&& {
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: -0.25rem;
  }
`;

const Line = styled.div.attrs({
  id: "line"
})`
  height: 3px;
  border-top: solid 1px #d2d2d2;
`;

class EventDataCard extends React.Component {
  timezone(tz) {
    if (tz && tz.length !== 2) {
      return tz[0] + tz[tz.length - 1];
    } else {
      return tz;
    }
  }

  render() {
    const { game, displayCountdown, showVenue, eventId = {}, isGlobal = false } = this.props;

    let gameDate =
      game.status.startTimeTBD || !game.venue.timeZone
        ? moment(game.gameDate).utc()
        : moment(game.gameDate).tz(game.venue.timeZone.id);

    let timeZone = timezone(gameDate.format("zz"));
    return (
      <Container>
        <InfoCard backgroundColor="white" className="my-2" title="">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-md-between flex-wrap mb-3 mr-2 mt-2">
              <div className="mr-5 mr-md-0">
                <Date>{gameDate.format("MMM D")}</Date>
                <Description>{gameDate.format("ddd")}</Description>
              </div>

              <div>
                <GameDisplay>
                  {game.gameType === "Z" || game.gameType === "O" || game.gameType === "A"
                    ? game.description.split(":")[1] || game.description
                    : getTeamAbbreviation(game, false) + " @ " + getTeamAbbreviation(game, true)}
                </GameDisplay>
                <Description>
                  {game.status.startTimeTBD ? "TBD" : gameDate.format("h:mm a") + " " + timeZone}
                </Description>
                {isGlobal ? (
                  <div>
                    <Description>{eventId["STANDARD"] !== undefined ? "S: " + eventId["STANDARD"] : null}</Description>
                    <Description>{eventId["PREMIUM"] !== undefined ? "P: " + eventId["PREMIUM"] : null}</Description>
                  </div>
                ) : null}
              </div>
            </div>

            <DesktopTabletView>
              {showVenue === false ? null : (
                <div className="mb-4">
                  <div>
                    <MinorHeader>{game.venue.name}</MinorHeader>
                    {game.venue.location ? (
                      <Description>
                        {game.venue.location.city}
                        {game.venue.location.city && game.venue.location.stateAbbrev ? ", " : ""}
                        {game.venue.location.stateAbbrev}
                      </Description>
                    ) : (
                      <Description>N/A</Description>
                    )}
                  </div>
                </div>
              )}
            </DesktopTabletView>
            {displayCountdown ? <Line /> : null}

            {displayCountdown ? (
              <div className="mt-2">
                <EventTimeRemaining column={false} game={game} />
              </div>
            ) : null}
          </div>
        </InfoCard>
      </Container>
    );
  }
}

EventDataCard.propTypes = {
  game: PropTypes.any.isRequired
};

export default inject("rootStore")(observer(EventDataCard));
