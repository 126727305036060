import React from "react";
import moment from "moment";
import { timezone } from "../../../utilities/comptixFormatter";

// eslint-disable-next-line

class RequestDateFormatter extends React.Component {
  render() {
    let requestDate = this.props.value.requestDate;
    let timeZone = this.props.value.tzId;
    let momentInTZ = moment(requestDate).tz(timeZone);
    return (
      <div id="request-date-formatter">
        {requestDate ? momentInTZ.format("M/DD/YYYY - h:mm a") + " " + timezone(momentInTZ.format("zz")) : ""}
      </div>
    );
  }
}

export default RequestDateFormatter;
