import React from "react";
import { inject } from "mobx-react";
import { Icon } from "best-common-react";

class EditContactPencilFormatter extends React.Component {
  render() {
    const { contactManagementStore } = this.props.rootStore;
    return (
      <div id="edit-contact-formatter">
        <Icon
          iconName="fa-pencil-alt"
          onClick={() => {
            contactManagementStore.setSelectedContact(this.props.value);
            contactManagementStore.setShowEditContactModal(true);
          }}
        />
      </div>
    );
  }
}

export default inject("rootStore")(EditContactPencilFormatter);
