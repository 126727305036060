import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, StickyFooterButtons } from "best-common-react";

class VenueAdminFooter extends React.Component {
  render() {
    const { venueAdminStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <LightButton onClick={venueAdminStore.bulkOverridePrices}>Bulk Override Prices</LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(VenueAdminFooter));
