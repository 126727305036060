import React from "react";
import { inject, observer } from "mobx-react";

class VenueEditHeader extends React.Component {
  render() {
    let { venueEditStore } = this.props.rootStore;
    return <div id="venue-edit-header">{venueEditStore.currentVenueName}</div>;
  }
}

export default inject("rootStore")(observer(VenueEditHeader));
