import { action, computed, extendObservable, reaction, decorate, autorun } from "mobx";
import { formatAllotment, formatAvailable, formatCutoff } from "../../utilities/comptixFormatter";

import _ from "lodash";
import queryString from "query-string";

class DepartmentAdminStore {
  constructor(commonStore, compTixApi, routerStore) {
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      selectedSeason: { value: -1, label: "" },
      departments: [],
      sortFilters: {
        direction: "ASC",
        key: "name"
      }
    };

    extendObservable(this, {
      selectedSeason: this.defaults["selectedSeason"],
      departments: this.defaults["departments"],
      sortFilters: this.defaults["sortFilters"],
      setSelectedSeason: action(season => {
        this.routerStore.history.push({
          pathname: this.routerStore.location.pathname,
          search: "?seasonId=" + season.value
        });
      }),
      setDepartments: action(departments => {
        this.departments = departments;
      }),
      setSortDirection: action((key, direction) => {
        this.sortFilters["key"] = key;
        this.sortFilters["direction"] = direction;
      }),
      updateFromUrlParams: action(search => {
        const params = queryString.parse(search);
        if (params["seasonId"]) {
          let match = this.commonStore.seasonDropdownList.filter(
            season => season.value === parseInt(params["seasonId"], 10)
          )[0];
          this.selectedSeason = match;
        } else {
          // set default
          let season = this.commonStore.activeSeasonDropdownList.filter(
            season => season.value === this.commonStore.curSeason.seasonId
          )[0];
          this.selectedSeason = season ? season : this.commonStore.activeSeasonDropdownList[0];
        }
      })
    });

    reaction(
      () => this.commonStore.currentOrgId,
      () => {
        if (this.routerStore.isDepartmentAdminTab) {
          this.updateDepartments(this.commonStore.currentOrgId);
        }
      }
    );

    autorun(() => {
      if (this.routerStore.isDepartmentAdminTab && this.commonStore.currentOrgId) {
        this.updateDepartments(this.commonStore.currentOrgId);
      }
      if (this.commonStore.seasonDropdownList.length) {
        this.updateFromUrlParams(this.routerStore.location.search);
      }
    });
  }

  get getSelectedSeason() {
    if (
      this.selectedSeason &&
      this.selectedSeason.value === -1 &&
      this.commonStore.activeSeasonDropdownList &&
      this.commonStore.activeSeasonDropdownList.length > 0
    ) {
      let season = this.commonStore.activeSeasonDropdownList.filter(
        season => season.value === this.commonStore.curSeason.seasonId
      )[0];
      return season ? season : this.commonStore.activeSeasonDropdownList[0];
    } else {
      return this.selectedSeason;
    }
  }

  get getDepartments() {
    let unsorted = this.departments.map(department => {
      return {
        editLink:
          "department/edit?id=" +
          department["departmentId"] +
          "&year=" +
          this.getSelectedSeason.label.substring(0, 4) +
          "&seasonId=" +
          this.getSelectedSeason.value,
        departmentId: department["departmentId"],
        name: department["departmentType"]["departmentName"],
        homeAllotment: formatAllotment(
          department["departmentAllotment"],
          this.getSelectedSeason,
          "homeFamilyAllotment",
          "homeFriendsAllotment"
        ),
        awayAllotment: formatAllotment(
          department["departmentAllotment"],
          this.getSelectedSeason,
          "awayFamilyAllotment",
          "awayFriendsAllotment"
        ),
        eventAvailable: formatAvailable(department, this.getSelectedSeason, this.commonStore.currentOrg.timeZone),
        eventCutoff: formatCutoff(department, this.getSelectedSeason, this.commonStore.currentOrg.timeZone)
      };
    });
    return this.sort(unsorted, this.sortFilters);
  }

  sort(departments, sortFilters) {
    if (sortFilters["direction"] === "ASC") {
      return _.sortBy(departments, sortFilters["key"]);
    } else if (sortFilters["direction"] === "DESC") {
      return _.sortBy(departments, sortFilters["key"]).reverse();
    } else {
      return _.sortBy(departments, "name");
    }
  }

  updateDepartments(orgId) {
    if (orgId !== -1) {
      this.compTixApi.getDepartmentsByOrg(orgId).then(data => {
        if (data) {
          this.setDepartments(data);
        }
      });
    }
  }
}

decorate(DepartmentAdminStore, {
  getSelectedSeason: computed,
  getDepartments: computed
});

export default DepartmentAdminStore;
