import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, StickyFooterButtons } from "best-common-react";

class EventDetailsFooter extends React.Component {
  render() {
    const { eventDetailsStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <LightButton onClick={() => eventDetailsStore.cancel()} id="cancel">
          Close
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(EventDetailsFooter));
