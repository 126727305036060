import React from "react";
import { inject, observer } from "mobx-react";
import VenueEditTicketInventory from "./VenueEditTicketInventory";
import styled from "styled-components";
import { BestSelect, BestLabel, TableTitle } from "best-common-react";

const AddButton = styled.button`
  &&& {
    background-color: white;
    border-color: #d2d2d2;
  }
`;
class VenueEditTicketInventories extends React.Component {
  render() {
    const { venueEditStore, commonStore } = this.props.rootStore;
    return (
      <div>
        <TableTitle>Ticket Inventory</TableTitle>
        <div className="row mb-2">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
            <BestLabel>Type</BestLabel>
            <BestSelect
              id="filter-sections"
              options={[venueEditStore.allSection, ...commonStore.homeAwayOptions]}
              value={venueEditStore.sectionsFilter}
              onChange={venueEditStore.onFilterChange}
            />
          </div>
        </div>
        <div>
          {venueEditStore.currentSections
            .filter(s => !s.isDeleted)
            .map((s, index) => (
              <VenueEditTicketInventory
                key={index}
                section={s}
                departments={venueEditStore.departmentOptions}
                onChange={venueEditStore.onSectionChange}
              />
            ))}
        </div>
        <div className="d-flex justify-content-end mt-2">
          <AddButton className="btn" onClick={() => venueEditStore.addNewSection()} id="section-early">
            Add Section
          </AddButton>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(VenueEditTicketInventories));
