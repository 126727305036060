import React from "react";
import { inject, observer } from "mobx-react";
import { BestDropdown, FilterHeader, FormColumn4Wide } from "best-common-react";

class VenueEditFilters extends React.Component {
  render() {
    const { venueEditStore } = this.props.rootStore;
    return (
      <div className="row">
        <FormColumn4Wide>
          <div className="form-group">
            <BestDropdown
              id="season"
              title="Season"
              value={venueEditStore.getSelectedSeason}
              options={venueEditStore.availableSeasons}
              onChange={venueEditStore.setSelectedSeason}
            />
          </div>
        </FormColumn4Wide>
        <FormColumn4Wide>
          <div className="form-group">
            <FilterHeader>Location</FilterHeader>
            <div id="venue-location">{venueEditStore.currentVenueLocation}</div>
          </div>
        </FormColumn4Wide>
      </div>
    );
  }
}

export default inject("rootStore")(observer(VenueEditFilters));
