import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestSelect, SearchBox } from "best-common-react";

class ContactsFilters extends React.Component {
  render() {
    const {
      rootStore: { contactManagementStore, authStore }
    } = this.props;
    return (
      <div className="row m-0 mb-3">
        {authStore.isAdmin ? (
          <>
            <div className="col-lg-4 col-xl-5 p-0 mt-1">
              <BestLabel>Contacts for</BestLabel>
              <BestSelect
                id="on-behalf-of"
                options={contactManagementStore.userDropdown}
                value={contactManagementStore.selectedUserValue}
                onChange={item => contactManagementStore.setSelectedUser(item.value)}
                isSearchable={true}
              />
            </div>
            <div className="col-lg-4 col-xl-5 p-0 pl-lg-3 mt-1">
              <BestLabel>Search Contacts</BestLabel>
              <SearchBox
                id="contact-search"
                placeholder="begin typing to filter reservations"
                value={contactManagementStore.searchTerm}
                onChange={search => contactManagementStore.setSearchTerm(search.target.value)}
              />
            </div>
          </>
        ) : (
          <div className="col-lg-4 col-xl-5 p-0 mt-1">
            <BestLabel>Search Contacts</BestLabel>
            <SearchBox
              id="contact-search"
              placeholder="begin typing to filter reservations"
              value={contactManagementStore.searchTerm}
              onChange={search => contactManagementStore.setSearchTerm(search.target.value)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default inject("rootStore")(observer(ContactsFilters));
