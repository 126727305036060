import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import {
  DateRangePickerWithLabel,
  Icon,
  Checkbox,
  FormColumn3Wide,
  FormColumn4Wide,
  BestSelect,
  BestLabel,
  SearchBox,
  PrimaryButton,
  RequiredInputWithLabel
} from "best-common-react";

const ClearableInput = styled.div.attrs({ className: "btn-group" })`
  width: 100%;
`;

const ClearUserSearch = styled.span`
  position: absolute;
  right: 8px;
  top: 6px;
`;

const AdminSupportFilters = ({ rootStore: { adminSupportStore, commonStore } }) => {
  return (
    <div className="row">
      <FormColumn4Wide>
        <RequiredInputWithLabel name="gamePk" label="GamePK/Event ID">
          <input
            type="text"
            id="gamePk-search-box"
            className="form-control"
            value={adminSupportStore.gamePkSearchTerm}
            onChange={event => adminSupportStore.setGamePkSearchTerm(event.target.value)}
          />
        </RequiredInputWithLabel>
      </FormColumn4Wide>
      <FormColumn4Wide>
        <br />
        <PrimaryButton
          id="load"
          onClick={adminSupportStore.getTickets}
          disabled={adminSupportStore.isGetting || !adminSupportStore.isValidRequest}
        >
          Load Data
        </PrimaryButton>
      </FormColumn4Wide>
      <FormColumn4Wide>
        <BestLabel>Search Section</BestLabel>
        <ClearableInput>
          <SearchBox
            id="section-search-box"
            placeholder="type to filter sections"
            value={adminSupportStore.sectionSearchTerm}
            onChange={event => adminSupportStore.setSectionSearchTerm(event.target.value)}
          />
          {adminSupportStore.sectionSearchTerm.length > 0 ? (
            <ClearUserSearch>
              <Icon iconName="fa-times-circle" onClick={() => adminSupportStore.clearSectionSearchTerm()} />
            </ClearUserSearch>
          ) : null}
        </ClearableInput>
      </FormColumn4Wide>
      <FormColumn4Wide>
        <BestLabel>Search Other</BestLabel>
        <ClearableInput>
          <SearchBox
            id="other-search-box"
            placeholder="type to filter tickets"
            value={adminSupportStore.searchTerm}
            onChange={event => adminSupportStore.setSearchTerm(event.target.value)}
          />
          {adminSupportStore.searchTerm.length > 0 ? (
            <ClearUserSearch>
              <Icon iconName="fa-times-circle" onClick={() => adminSupportStore.clearSearchTerm()} />
            </ClearUserSearch>
          ) : null}
        </ClearableInput>
      </FormColumn4Wide>
      <FormColumn4Wide>
        <BestLabel>&nbsp;</BestLabel>
        <Checkbox
          label="Only show non-forwarded"
          onChange={adminSupportStore.toggleForwarded}
          checked={adminSupportStore.filterForwarded}
        />
      </FormColumn4Wide>
    </div>
  );
};

export default inject("rootStore")(observer(AdminSupportFilters));
