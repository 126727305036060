import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { BestLabel, BestRadio, DateTimePickerInput } from "best-common-react";
import moment from "moment";
import DepartmentOffset from "../../department/edit/DepartmentOffset";

class EventAccess extends React.Component {
  constructor(props) {
    super(props);
    this.updateField = this.updateField.bind(this);
  }

  updateField(key, value) {
    let access = this.props.access;
    access[key] = value;
    this.props.onUpdate(access);
  }

  render() {
    const { access, id, disabled, wrap } = this.props;
    const accessName = "accessType" + id;
    const accessStartId = "accessStart" + id;
    const accessOffsetId = "accessOffset" + id;
    return (
      <div>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="mb-1 mb-md-0">
            <BestRadio
              id={accessStartId}
              name={accessName}
              checked={access.availableUseDate}
              value={true}
              disabled={disabled}
              onChange={event => {
                let bool = event.target.value === "true";
                this.updateField("availableUseDate", bool);
              }}
            />
            <BestLabel htmlFor={accessStartId}>Start Date</BestLabel>
          </div>
          <div>
            <DateTimePickerInput
              id="available-date"
              timeZone={access.timeZone}
              value={access.availableDate}
              disabled={!access.availableUseDate || disabled}
              onChange={event => {
                let val = moment.isMoment(event) ? event : moment(event);
                this.updateField("availableDate", val.utc());
              }}
            />
          </div>
        </div>

        <div className="d-flex flex-wrap align-items-center justify-content-between mt-1">
          <div className="mb-1 mb-md-0">
            <BestRadio
              id={accessOffsetId}
              name={accessName}
              checked={!access.availableUseDate}
              value={false}
              disabled={disabled}
              onChange={event => {
                let bool = event.target.value === "true";
                this.updateField("availableUseDate", bool);
              }}
            />
            <BestLabel htmlFor={accessOffsetId}>Offset from Game Time</BestLabel>
          </div>
          <div className="ml-2">
            <DepartmentOffset
              id="available-minutes-before"
              data={access.availableMinutesBefore}
              disabled={access.availableUseDate || disabled}
              onChange={data => {
                this.updateField("availableMinutesBefore", data);
              }}
              wrap={wrap}
            />
          </div>
        </div>
      </div>
    );
  }
}

EventAccess.propTypes = {
  access: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  wrap: PropTypes.bool
};

EventAccess.defaultProps = {
  disabled: false,
  wrap: false
};

export default observer(EventAccess);
