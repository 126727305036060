import { action, autorun, computed, decorate, extendObservable } from "mobx";
import moment from "moment";
import _ from "lodash";

const DATE_FORMAT = "M/DD/YYYY";
const DATE_FORMAT2 = "YYYY-MM-DD";

class UserSummaryStore {
  constructor(commonStore, compTixApi, routerStore, loadingStore) {
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.loadingStore = loadingStore;

    const yesterday = moment()
      .add(-1, "days")
      .toDate();
    const lastMonth = moment()
      .add(-1, "days")
      .add(-1, "months")
      .toDate();

    this.defaults = {
      users: [],
      summaries: [],
      homeAwayOptions: [
        { label: "All", value: "all" },
        { label: "Home", value: "home" },
        { label: "Away", value: "away" }
      ],
      selectedCategory: { label: "All", value: 3 },
      sortFilters: {
        direction: "ASC",
        key: "user"
      },
      searchTerm: "",
      homeAwayBreakdown: { home: 0, away: 0 }
    };

    extendObservable(this, {
      selectedHomeAwayOption: this.defaults["homeAwayOptions"][0],
      summaries: this.defaults["summaries"],
      sortFilters: this.defaults["sortFilters"],
      searchTerm: this.defaults["searchTerm"],
      homeAwayOptions: this.defaults["homeAwayOptions"],
      homeAwayBreakdown: this.defaults["homeAwayBreakdown"],
      selectedCategory: this.defaults["selectedCategory"],
      setReservationSummaries: action(values => {
        this.summaries = values;
      }),
      setUsers: action(values => {
        this.users = values;
      }),
      setSelectedUser: action(user => {
        this.selectedUser = user;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      setSearchTerm: action(searchTerm => {
        this.searchTerm = searchTerm;
      }),
      setSelectedCategory: action(category => {
        this.selectedCategory = category;
      }),
      resetStore: action(() => {
        this.sortFilters = this.defaults["sortFilters"];
        this.searchTerm = this.defaults["searchTerm"];
        this.selectedHomeAwayOption = this.defaults["homeAwayOptions"][0];
        this.selectedCategory = this.defaults["selectedCategory"];
      }),
      setSelectedHomeAwayOption: action(option => {
        this.selectedHomeAwayOption = option;
      }),
      setHomeAwayBreakdown: action(homeAwayBreakdown => {
        this.homeAwayBreakdown = homeAwayBreakdown;
      })
    });

    autorun(() => {
      if (this.routerStore.isUserSummaryTab && this.commonStore.selectedOrgId) {
        this.setReservationSummaries([]);
        this.getReservationSummaries();
      } else {
        this.resetStore();
      }
    });
  }

  getHomeAwayBreakdown() {
    if (this.commonStore.selectedOrgId && this.commonStore.selectedOrgId !== -1) {
      let start = moment(this.commonStore.dateRangeFilter.start);
      let end = moment(this.commonStore.dateRangeFilter.end);
      if (start.isAfter(end)) {
        return;
      }
      this.compTixApi
        .getHomeAwayBreakdown(this.commonStore.selectedOrgId, start.format(DATE_FORMAT), end.format(DATE_FORMAT))
        .then(data => {
          this.setHomeAwayBreakdown(data);
        });
    }
  }

  getReservationSummaries() {
    if (this.commonStore.selectedOrgId && this.commonStore.selectedOrgId !== -1) {
      let start = moment(this.commonStore.dateRangeFilter.start);
      let end = moment(this.commonStore.dateRangeFilter.end);
      if (start.isAfter(end)) {
        return;
      }
      this.loadingStore.setLoading(true);
      this.compTixApi
        .getReservationSummaries(
          this.commonStore.selectedOrgId,
          this.selectedHomeAwayOption.label,
          start.format(DATE_FORMAT),
          end.format(DATE_FORMAT),
          this.selectedCategory.value
        )
        .then(data => {
          this.setReservationSummaries(data);
          this.loadingStore.setLoading(false);
          this.getHomeAwayBreakdown();
        });
    }
  }

  sort(reservations, searchFilters) {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(reservations, [
        reservation => _.toLower(reservation.lastName),
        reservation => _.toLower(reservation.firstName)
      ]);
    }

    let sorted;
    if (searchFilters.key === "user") {
      sorted = _.sortBy(reservations, [
        reservation => _.toLower(reservation.lastName),
        reservation => _.toLower(reservation.firstName)
      ]);
    } else if (searchFilters.key === "department") {
      sorted = _.sortBy(reservations, [reservation => reservation.department]);
    } else if (searchFilters.key === "reservations") {
      sorted = _.sortBy(reservations, [reservation => reservation.reservations]);
    } else if (searchFilters.key === "value") {
      sorted = _.sortBy(reservations, [reservation => Number(reservation.value)]);
    } else if (searchFilters.key === "tickets") {
      sorted = _.sortBy(reservations, [reservation => Number(reservation.tickets)]);
    } else {
      sorted = _.sortBy(reservations, [reservation => _.toLower(reservation[searchFilters.key])]);
    }

    if (direction === "DESC") {
      sorted = sorted.reverse();
    }

    return sorted;
  }

  searchString(reservationSummary) {
    return (
      reservationSummary.firstName.toLowerCase() +
      " " +
      reservationSummary.lastName.toLowerCase() +
      " " +
      reservationSummary.department
    );
  }

  buildUserSummary() {
    let start = moment(this.commonStore.dateRangeFilter.start);
    let end = moment(this.commonStore.dateRangeFilter.end);

    this.compTixApi
      .buildUserSummary(
        this.commonStore.currentOrgId,
        this.searchTerm,
        this.selectedHomeAwayOption.label,
        start.format(DATE_FORMAT),
        end.format(DATE_FORMAT),
        this.sortFilters.key,
        this.sortFilters.direction,
        this.selectedCategory.value
      )
      .then(response => {
        this.downloadLink(response, "application/vnd.ms-excel");
      });
  }

  downloadLink(response, type) {
    let blob = new Blob([response.data], { type: type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, response.headers["report-title"]);
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", response.headers["report-title"]);
    document.body.appendChild(link);
    link.click();
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  get reservationSummaries() {
    let start = moment(this.commonStore.dateRangeFilter.start);
    let end = moment(this.commonStore.dateRangeFilter.end);

    return this.sort(
      this.summaries
        .map(reservationSummary => {
          return {
            firstName: reservationSummary.firstName,
            lastName: reservationSummary.lastName,
            user: reservationSummary.lastName + ", " + reservationSummary.firstName,
            userObject: {
              username: reservationSummary.lastName + ", " + reservationSummary.firstName,
              userId: reservationSummary.userId,
              start: start.format(DATE_FORMAT2),
              end: end.format(DATE_FORMAT2)
            },
            department: reservationSummary.department,
            reservations: reservationSummary.reservations,
            tickets: reservationSummary.tickets,
            value: reservationSummary.value,
            orgId: reservationSummary.org.orgId,
            searchString: this.searchString(reservationSummary)
          };
        })
        .filter(reservation => {
          return _.includes(reservation.searchString, this.searchTerm.toLowerCase());
        }),
      this.sortFilters
    );
  }

  getMaxDate() {
    return moment(new Date().valueOf()).subtract(1, "days");
  }

  get totals() {
    let home = 0;
    let away = 0;
    let reservations = 0;
    let tickets = 0;
    let value = 0.0;

    for (let summary in this.reservationSummaries) {
      const sum = this.reservationSummaries[summary];
      if (sum.orgId === this.commonStore.currentOrgId) {
        home = home + 1;
      } else {
        away = away + 1;
      }
      reservations += sum.reservations;
      tickets += sum.tickets;
      value += sum.value;
    }
    return {
      reservations,
      tickets,
      value,
      department: "Total",
      user: "",
      home,
      away
    };
  }
}

decorate(UserSummaryStore, {
  reservationSummaries: computed,
  totals: computed
});

export default UserSummaryStore;
