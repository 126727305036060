import React from "react";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import EditUserBody from "../../components/user/edit/EditUserBody";
import EditUserFooter from "../../components/user/edit/EditUserFooter";
import TeamTitle from "../../components/title/TeamTitle";
import AdminProtected from "../../components/protected/AdminProtected";
import UserDeleteModal from "./UserDeleteModal";

class UserEditContainer extends React.Component {
  render() {
    return (
      <div>
        <UserDeleteModal />
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>User</CardHeader>
          <CardBody>
            <EditUserBody />
          </CardBody>
          <CardFooter>
            <EditUserFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default AdminProtected(UserEditContainer);
