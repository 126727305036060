import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

const ConfirmMessage = styled.div.attrs({ className: "mb-3" })`
  font-size: 1.125rem;
  font-weight: 300;
`;

class LockGameModal extends React.Component {
  render() {
    return (
      <Modal show={this.props.show} size="lg" onClose={this.props.onClose}>
        <ModalHeaderWithTitleAndClose title="Lock Game" onClose={this.props.onClose} close={this.props.onClose} />
        <ModalBody>
          <ConfirmMessage>
            Are you sure you want to lock this game? Players will not be able to create and edit requests for this game
          </ConfirmMessage>
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton className="mr-2" onClick={this.props.onLock} disabled={false}>
              Lock
            </PrimaryButton>
            <LightButton onClick={this.props.onClose}>Cancel</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(LockGameModal));
