import React from "react";
import { inject, observer } from "mobx-react";
import VenueEditPickupLocationMobile from "./VenueEditPickupLocationMobile";

class VenueEditPickupLocationsMobile extends React.Component {
  render() {
    const { venueEditStore } = this.props.rootStore;
    const { pickupLocations, departmentOptions } = this.props;
    return (
      <React.Fragment>
        {pickupLocations.map((pl, index) => (
          <VenueEditPickupLocationMobile
            className="mx-2"
            key={index}
            index={index}
            pickupLocation={pl}
            departments={departmentOptions}
            onChange={venueEditStore.onPickupLocationChange}
          />
        ))}
      </React.Fragment>
    );
  }
}

export default inject("rootStore")(observer(VenueEditPickupLocationsMobile));
