import React from "react";
import moment from "moment";
import { DateTimePickerInput } from "best-common-react";

class DateTimePickerExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = { time: new Date() };
  }

  render() {
    return (
      <div>
        <DateTimePickerInput
          value={this.state.time}
          onChange={event => {
            this.setState({ time: moment(event).utc() });
          }}
        />
      </div>
    );
  }
}

export default DateTimePickerExample;
