import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  DesktopTabletView,
  MobileView,
  Table,
  TableHeader,
  TableTdInfo,
  Input,
  RequiredIndicator
} from "best-common-react";

const MobileTableHeader = styled.th.attrs({
  colSpan: 2
})`
  text-align: center;
  font-weight: bold;
  font-size: 0.875rem;
`;

const MobileTdInfo = styled.td.attrs({
  id: "mobile-td-info"
})`
  &&& {
    font-weight: bold;
    font-size: 0.875rem;
    vertical-align: middle;
    width: 50%;
  }
`;

const SeasonHeader = styled.th`
  &&& {
    padding: 0.5rem;
  }
`;

const TicketAllotmentTable = ({ allotments, onAllotmentChange, isRequired = true }) => (
  <div id="ticket-allotment-table">
    <DesktopTabletView>
      <Table>
        <TableHeader>
          <tr>
            <SeasonHeader>Season</SeasonHeader>
            <th className="text-center text-nowrap">Home - Family {isRequired && <RequiredIndicator />}</th>
            <th className="text-center text-nowrap">Home - Friend {isRequired && <RequiredIndicator />}</th>
            <th className="text-center text-nowrap">Away - Family {isRequired && <RequiredIndicator />}</th>
            <th className="text-center text-nowrap">Away - Friend {isRequired && <RequiredIndicator />}</th>
          </tr>
        </TableHeader>
        <tbody>
          {allotments.map((allotment, index) => (
            <tr key={index}>
              <TableTdInfo>{allotment.season.seasonType.seasonTypeName}</TableTdInfo>
              <td>
                <Input
                  className="text-center"
                  type="number"
                  min="0"
                  value={allotment.homeFamilyAllotment}
                  onChange={event => {
                    onAllotmentChange(allotment, "homeFamilyAllotment", event.target.value);
                  }}
                />
              </td>
              <td>
                <Input
                  className="text-center"
                  type="number"
                  min="0"
                  value={allotment.homeFriendsAllotment}
                  onChange={event => {
                    onAllotmentChange(allotment, "homeFriendsAllotment", event.target.value);
                  }}
                />
              </td>
              <td>
                <Input
                  className="text-center"
                  type="number"
                  min="0"
                  value={allotment.awayFamilyAllotment}
                  onChange={event => {
                    onAllotmentChange(allotment, "awayFamilyAllotment", event.target.value);
                  }}
                />
              </td>
              <td>
                <Input
                  className="text-center"
                  type="number"
                  min="0"
                  value={allotment.awayFriendsAllotment}
                  onChange={event => {
                    onAllotmentChange(allotment, "awayFriendsAllotment", event.target.value);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </DesktopTabletView>
    <MobileView>
      {allotments.map((allotment, index) => (
        <Table key={index}>
          <TableHeader>
            <MobileTableHeader>{allotment.season.seasonType.seasonTypeName}</MobileTableHeader>
          </TableHeader>
          <tbody>
            <tr>
              <MobileTdInfo>Home - Family {isRequired && <RequiredIndicator />}</MobileTdInfo>
              <td>
                <Input
                  className="text-center"
                  type="number"
                  min="0"
                  value={allotment.homeFamilyAllotment}
                  onChange={event => {
                    onAllotmentChange(allotment, "homeFamilyAllotment", event.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <MobileTdInfo>Home - Friends {isRequired && <RequiredIndicator />}</MobileTdInfo>
              <td>
                <Input
                  className="text-center"
                  type="number"
                  min="0"
                  value={allotment.homeFriendsAllotment}
                  onChange={event => {
                    onAllotmentChange(allotment, "homeFriendsAllotment", event.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <MobileTdInfo>Away - Family {isRequired && <RequiredIndicator />}</MobileTdInfo>
              <td>
                <Input
                  className="text-center"
                  type="number"
                  min="0"
                  value={allotment.awayFamilyAllotment}
                  onChange={event => {
                    onAllotmentChange(allotment, "awayFamilyAllotment", event.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <MobileTdInfo>Away - Friends {isRequired && <RequiredIndicator />}</MobileTdInfo>
              <td>
                <Input
                  className="text-center"
                  type="number"
                  min="0"
                  value={allotment.awayFriendsAllotment}
                  onChange={event => {
                    onAllotmentChange(allotment, "awayFriendsAllotment", event.target.value);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      ))}
    </MobileView>
  </div>
);

TicketAllotmentTable.propTypes = {
  allotments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isRequired: PropTypes.bool,
  onAllotmentChange: PropTypes.func.isRequired
};

export default observer(TicketAllotmentTable);
