import React from "react";
import EventAdminFilters from "./EventAdminFilters";
import EventAdminTable from "./EventAdminTable";

class EventAdminBody extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col">
          <EventAdminFilters />
          <EventAdminTable />
        </div>
      </div>
    );
  }
}

export default EventAdminBody;
