import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { BestLabel, BestRadio, DateTimePickerInput } from "best-common-react";
import moment from "moment";
import DepartmentOffset from "../../department/edit/DepartmentOffset";

class EventCutoff extends React.Component {
  constructor(props) {
    super(props);
    this.updateField = this.updateField.bind(this);
  }

  updateField(key, value) {
    let access = this.props.access;
    access[key] = value;
    this.props.onUpdate(access);
  }

  //format event date time based on tz passed in access
  render() {
    const { access, id, disabled, wrap } = this.props;
    const cutoffName = "cutoffType" + id;
    const cutoffStartId = "cutoffStart" + id;
    const cutoffOffsetId = "cutoffOffset" + id;
    return (
      <div>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="mb-1 mb-md-0">
            <BestRadio
              id={cutoffStartId}
              name={cutoffName}
              checked={access.cutoffUseDate}
              value={true}
              disabled={disabled}
              onChange={event => {
                let bool = event.target.value === "true";
                this.updateField("cutoffUseDate", bool);
              }}
            />
            <BestLabel htmlFor={cutoffStartId}>End Date</BestLabel>
          </div>
          <div>
            <DateTimePickerInput
              id="cutoff-date"
              timeZone={access.timeZone}
              value={access.cutoffDate}
              onChange={event => {
                let val = moment.isMoment(event) ? event : moment(event);
                this.updateField("cutoffDate", val.utc());
              }}
              disabled={!access.cutoffUseDate || disabled}
            />
          </div>
        </div>

        <div className="d-flex flex-wrap align-items-center justify-content-between mt-1">
          <div className="mb-1 mb-md-0">
            <BestRadio
              id={cutoffOffsetId}
              name={cutoffName}
              checked={!access.cutoffUseDate}
              value={false}
              onChange={event => {
                let bool = event.target.value === "true";
                this.updateField("cutoffUseDate", bool);
              }}
              disabled={disabled}
            />
            <BestLabel htmlFor={cutoffOffsetId}>Offset from Game Time</BestLabel>
          </div>
          <div className="ml-2">
            <DepartmentOffset
              id="cutoff-minutes-before"
              data={access.cutoffMinutesBefore}
              disabled={access.cutoffUseDate || disabled}
              onChange={data => {
                this.updateField("cutoffMinutesBefore", data);
              }}
              wrap={wrap}
            />
          </div>
        </div>
      </div>
    );
  }
}

EventCutoff.propTypes = {
  access: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  wrap: PropTypes.bool
};

EventCutoff.defaultProps = {
  disabled: false,
  wrap: false
};

export default observer(EventCutoff);
