import React from "react";
import { inject, observer } from "mobx-react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  Input,
  BestLabel
} from "best-common-react";

const SetEmailModal = ({ rootStore }) => {
  const { loginStore } = rootStore;
  return (
    <Modal show={loginStore.showSetEmail} onClose={loginStore.onCancelEmail} size="lg">
      <ModalHeaderWithTitleAndClose title="Missing Email" close={loginStore.resetStore} />
      <ModalBody>
        <div className="row">
          <div className="col-12">
            We see that you do not have an email address associated with your account. For the best experience please
            provide your primary email.
          </div>
          <div className="col-sm-6 mt-2">
            <BestLabel htmlFor="newEmail">Email</BestLabel>
            <Input type="email" id="newEmail" name="email" value={loginStore.email} onChange={loginStore.setEmail} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div>
          <LightButton className="mr-2" disabled={loginStore.isValidEmail} onClick={loginStore.onSaveEmail}>
            Save Email
          </LightButton>
          <LightButton onClick={loginStore.onCancelEmail}>No Thanks</LightButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default inject("rootStore")(observer(SetEmailModal));
