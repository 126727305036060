import React from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { BestLink, Icon } from "best-common-react";

class ReservationEditIconFormatter extends React.Component {
  render() {
    const { reservationsStore, authStore } = this.props.rootStore;
    let filterElement = reservationsStore.currentReservations.find(r => r.ticketRequestId === this.props.value);
    let isBefore = moment().isBefore(filterElement && filterElement.reservation.game.eventCutoff);
    if (authStore.isAdmin || (isBefore && !filterElement.reservation.game.isLocked)) {
      let to = "/reservations/edit/" + this.props.value;
      return (
        <div id="reservation-edit-formatter">
          <BestLink to={to}>
            <Icon iconName="fa-pencil-alt" />
          </BestLink>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default inject("rootStore")(observer(ReservationEditIconFormatter));
