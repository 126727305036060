import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { BestLabel, SearchBox } from "best-common-react";

const Search = styled(SearchBox)`
  &&& {
    width: 20rem;
  }
`;

class ReservationsFilters extends React.Component {
  render() {
    const { reservationsStore } = this.props.rootStore;

    return (
      <div className="d-flex" id="reservation-filters">
        <div>
          <BestLabel htmlFor="reservation-search">Search Reservations</BestLabel>
          <Search
            id="reservation-search"
            type="text"
            placeholder="begin typing to filter reservations"
            onChange={term => reservationsStore.setSearchTerm(term.target.value)}
          />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ReservationsFilters));
