import { action, autorun, computed, decorate, extendObservable } from "mobx";
import { RouteConstants } from "../../constants/RouteConstants";

class VenueBulkOverrideStore {
  constructor(authStore, commonStore, compTixApi, routerStore, gridStore, loadingStore, alertStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.gridStore = gridStore;
    this.loadingStore = loadingStore;
    this.alertStore = alertStore;

    this.defaults = {
      games: [],
      gamesSelected: 0,
      gameSortFilters: {
        key: "date",
        direction: "ASC"
      },
      selectedSeason: { label: "", value: "" },
      selectedVenue: { label: "", value: "" },
      sections: [],
      sectionSortFilters: {
        key: "section",
        direction: "ASC"
      },
      venues: []
    };

    extendObservable(this, {
      games: this.defaults["games"],
      gamesSelected: this.defaults["gamesSelected"],
      gameSortFilters: this.defaults["gameSortFilters"],
      selectedSeason: this.defaults["selectedSeason"],
      selectedVenue: this.defaults["selectedVenue"],
      sections: this.defaults["sections"],
      sectionSortFilters: this.defaults["sectionSortFilters"],
      venues: this.defaults["venues"],
      saveOverrides: action(() => {
        let overrides = this.createInventoryOverrides();
        this.compTixApi.bulkCreateInventoryOverride(overrides).then(() => {
          this.resetSelectedValues();
        });
      }),
      onClose: action(() => {
        this.routerStore.history.push(RouteConstants.ADMIN_VENUES);
      }),
      onChecked: action(rows => {
        rows.forEach(r => {
          let index = r.rowIdx;
          let game = Object.assign({}, this.games[index]);
          game.checked = !game.checked;
          this.games[index] = game;
        });
        this.calculateGamesSelected();
      }),
      setGames: action(values => {
        this.games = values;
      }),
      setGamesSelected: action(value => {
        this.gamesSelected = value;
      }),
      setGameSortDirection: action((col, direction) => {
        this.gameSortFilters.key = col;
        this.gameSortFilters.direction = direction;
      }),
      setSelectedSeason: action(value => {
        this.selectedSeason = value;
        this.selectedVenue = this.defaults["selectedVenue"];
        this.games = this.defaults["games"];
        this.sections = this.defaults["sections"];
      }),
      setSelectedVenue: action(value => {
        this.selectedVenue = value;
        this.games = this.defaults["games"];
        this.sections = this.defaults["sections"];
      }),
      setSections: action(values => {
        this.sections = values;
      }),
      setSectionSortDirection: action((col, direction) => {
        this.sectionSortFilters.key = col;
        this.sectionSortFilters.direction = direction;
      }),
      setVenues: action(values => {
        this.venues = values;
      }),
      resetSelectedValues: action(() => {
        this.games.forEach(g => {
          g.checked = false;
        });
        this.sections.forEach(s => {
          s.overridePrice = "";
        });
        this.calculateGamesSelected();
      }),
      resetStore: action(() => {
        this.games = this.defaults["games"];
        this.gamesSelected = this.defaults["gamesSelected"];
        this.gameSortFilters = this.defaults["gameSortFilters"];
        this.selectedSeason = this.defaults["selectedSeason"];
        this.selectedVenue = this.defaults["selectedVenue"];
        this.sections = this.defaults["sections"];
        this.sectionSortFilters = this.defaults["sectionSortFilters"];
        this.venues = this.defaults["venues"];
      })
    });

    autorun(() => {
      if (this.routerStore.isVenueBulkOverrideTab) {
        this.getVenues(this.commonStore.currentOrgId);
      } else {
        this.resetStore();
      }
    });

    autorun(() => {
      if (
        this.routerStore.isVenueBulkOverrideTab &&
        this.selectedVenue.label &&
        this.selectedVenue.label.length &&
        this.selectedSeason.label &&
        this.selectedSeason.label.length
      ) {
        this.loadingStore.setLoading(true);
        let venue = this.selectedVenue.value;
        if (venue.venueSections.length) {
          this.getSections(
            this.commonStore.currentOrgId,
            this.selectedSeason.value,
            this.selectedVenue.value.venue.venueId
          );
          this.getGames(
            this.commonStore.currentOrgId,
            this.selectedSeason.value,
            this.selectedVenue.value.venue.venueId
          );
        } else {
          setTimeout(() => {
            this.loadingStore.setLoading(false);
            this.alertStore.createResponseAlerts([
              { message: "No sections exists for " + venue.venue.venueName + " in " + this.selectedSeason.label }
            ]);
          }, 1000);
        }
      }
    });
  }

  getVenues = orgId => {
    if (orgId) {
      this.compTixApi.getVenues(orgId).then(data => {
        this.setVenues(data);
      });
    }
  };

  getGames = (orgId, seasonId, venueId) => {
    if (orgId && seasonId) {
      this.compTixApi.getGamesByOrgAndSeasonAndVenue(orgId, seasonId, venueId).then(data => {
        this.setGames(data);
        this.loadingStore.setLoading(false);
      });
    }
  };

  getSections = (orgId, seasonId, venueId) => {
    if (orgId && seasonId && venueId) {
      this.compTixApi.getSectionsForOrgSeasonAndVenue(orgId, seasonId, venueId).then(data => {
        let data1 = data.map(d => {
          return { overridePrice: "", ...d };
        });
        this.setSections(data1);
      });
    }
  };

  get gameData() {
    let games = this.games.map(g => {
      let home = g.teams && g.teams.home && g.teams.home.team ? g.teams.home.team.abbreviation : "TBD";
      let away = g.teams && g.teams.away && g.teams.away.team ? g.teams.away.team.abbreviation : "TBD";
      let game = g.gameType === "Z" ? g.description : away + " @ " + home;
      let tzId = g.venue && g.venue.timeZone ? g.venue.timeZone.id : -1;
      let gameDate = g ? { gameDate: g.gameDate, tzId: tzId, tbd: g.status.startTimeTBD } : {};
      return {
        checked: g.checked,
        date: gameDate,
        game: game,
        venue: g.venue.name
      };
    });
    return this.gridStore.sortWithFilters(this.gameSortFilters, games);
  }

  get isValidSave() {
    return (
      this.games.length > 0 &&
      this.sections.length > 0 &&
      this.sections.filter(s => s.overridePrice).length > 0 &&
      this.gamesSelected > 0
    );
  }

  get sectionData() {
    let sections = this.sections.map(s => {
      return {
        section: s.sectionNumber,
        homeAway: s.homeAwayType.homeAwayTypeName,
        price: s.sectionPrice,
        overridePrice: {
          overridePrice: s.overridePrice,
          onChange: value => {
            if (!isNaN(value)) {
              s.overridePrice = value;
            }
          }
        }
      };
    });
    return this.gridStore.sortWithFilters(this.sectionSortFilters, sections);
  }

  get venueOptions() {
    return this.venues
      .filter(v => this.selectedSeason.value === v.season.seasonId)
      .map(v => ({ label: v.venue.venueName, value: v }));
  }

  get selectedGames() {
    return this.games.filter(g => g.checked);
  }

  calculateGamesSelected() {
    this.setGamesSelected(this.games.filter(g => g.checked).length);
  }

  createInventoryOverrides() {
    let overrides = [];
    this.selectedGames.forEach(game => {
      this.sections
        .filter(s => s.overridePrice)
        .forEach(section => {
          overrides.push({
            gamePk: game.gamePk,
            sectionId: section.sectionId,
            price: section.overridePrice,
            homeAwayType: section.homeAwayType
          });
        });
    });
    return overrides;
  }
}

decorate(VenueBulkOverrideStore, {
  gameData: computed,
  isValidSave: computed,
  sectionData: computed,
  selectedGames: computed,
  venueOptions: computed
});

export default VenueBulkOverrideStore;
