import React from "react";
import styled from "styled-components";

const ENV = process.env.REACT_APP_ENV;

const ViewAsHolder = styled.div`
  position: fixed;
  top: 80px;
  width: 100vw;
`;

const ViewingAs = styled.div.attrs(() => ({
  className: "p-2"
}))`
  border: 1px solid black;
  color: white;
  background: #ff9300;
  font-size: 0.8rem;
`;

const ViewAs = ({ name }) => (
  <React.Fragment>
    {ENV === "ro" && (
      <ViewAsHolder>
        <div className="d-flex justify-content-center">
          <ViewingAs>viewing as {name}</ViewingAs>
        </div>
      </ViewAsHolder>
    )}
  </React.Fragment>
);

export default ViewAs;
