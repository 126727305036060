import React from "react";
import { inject, observer } from "mobx-react";
import {
  Modal,
  ModalHeaderWithTitleAndClose,
  ModalBody,
  ModalFooter,
  LightButton,
  InfoMessage
} from "best-common-react";

export default inject("rootStore")(
  observer(({ rootStore: { venueEditStore } }) => (
    <Modal
      show={venueEditStore.sectionCategoryModal}
      close={() => venueEditStore.toggleSectionCategoryModal(false)}
      size="lg"
    >
      <ModalHeaderWithTitleAndClose
        title="Additional Info"
        close={() => venueEditStore.toggleSectionCategoryModal(false)}
      />
      <ModalBody>
        <InfoMessage>
          <div>
            <b>Advance</b> Inventory is available 24 hours or <b>more</b> before the event
          </div>
          <div>
            <b>Convenience</b> Inventory is available 24 hours or <b>less</b> before the event
          </div>
        </InfoMessage>
      </ModalBody>
      <ModalFooter>
        <LightButton onClick={() => venueEditStore.toggleSectionCategoryModal(false)}>Close</LightButton>
      </ModalFooter>
    </Modal>
  ))
);
