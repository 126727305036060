import React from "react";
import { inject, observer } from "mobx-react";
import UserResetPasswordForm from "./UserResetPasswordForm";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";

class UserResetPasswordModal extends React.Component {
  render() {
    const { userAdminStore, resetPasswordStore } = this.props.rootStore;
    return (
      <Modal
        show={resetPasswordStore.showResetPassword}
        onClose={() => {
          userAdminStore.cancelResetPassword();
        }}
        size="lg"
        id="reset-password-modal"
      >
        <ModalHeaderWithTitleAndClose
          title={"Reset Password - " + userAdminStore.lastCommaFirst}
          close={() => {
            userAdminStore.cancelResetPassword();
          }}
        />
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <UserResetPasswordForm />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton onClick={resetPasswordStore.resetPassword} disabled={!resetPasswordStore.isValidPassword}>
              Save
            </PrimaryButton>
            <LightButton
              onClick={() => {
                userAdminStore.cancelResetPassword();
              }}
            >
              Cancel
            </LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(UserResetPasswordModal));
