import React from "react";
import moment from "moment";
import { timezone } from "../../../utilities/comptixFormatter";

// eslint-disable-next-line

class GameTimeFormatter extends React.Component {
  render() {
    let { value } = this.props;
    let tz = value.tbd ? "UTC" : value.tzId;
    let momentInTZ = moment(value.gameDate).tz(tz);
    return (
      <div id="game-time-formatter">
        {value.gameDate
          ? value.tbd
            ? "TBD"
            : momentInTZ.format("h:mma") + " " + timezone(momentInTZ.format("zz"))
          : ""}
      </div>
    );
  }
}

export default GameTimeFormatter;
