import React from "react";
import { BestSelect } from "best-common-react";
import { inject, observer } from "mobx-react";

const ExportSettingsTableSelect = ({ type, available, index, onUpdate, rootStore }) => {
  return (
    <div>
      <BestSelect
        options={available}
        value={available[0]}
        onChange={selected => {
          onUpdate(type, index, selected);
        }}
      />
    </div>
  );
};

export default inject("rootStore")(observer(ExportSettingsTableSelect));
