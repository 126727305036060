import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { Icon, BestSelect, RequiredInputWithLabel, InfoCard, Input, BestLabel } from "best-common-react";

const AddAll = styled.button`
  text-decoration: none !important;
`;

class VenueEditPickupLocationMobile extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(key, value) {
    let { pickupLocation } = this.props;
    let newPickupLocation = Object.assign({}, pickupLocation);
    switch (key) {
      case "pickupLocationName":
        newPickupLocation.pickupLocationName = value;
        break;
      case "homeAway":
        newPickupLocation.type = value;
        break;
      case "departments":
        let departments = value.map((dp, index) => {
          if (dp.department.hasOwnProperty("departmentLocationId")) {
            return dp.department;
          } else {
            return {
              isDeleted: false,
              sequence: index,
              department: dp.department,
              departmentLocationId: null
            };
          }
        });
        newPickupLocation.departments = departments;
        break;
      case "isDeleted":
        newPickupLocation.isDeleted = value;
        break;
      case "digitalDelivery":
        newPickupLocation.digitalDelivery = !!value;
        break;
      case "digitalDeliveryOption":
        newPickupLocation.requireEmail = value.requireEmail;
        newPickupLocation.requireMobilePhone = value.requirePhone;
        break;
      default:
        break;
    }
    this.props.onChange(pickupLocation.sequence, newPickupLocation);
  }

  validateDepartments(departments) {
    return departments.length > 0;
  }

  createDepartmentValue(departments) {
    return departments
      .filter(d => !d.isDeleted)
      .map(d => {
        return {
          label: d.department.departmentName,
          value: d.department.departmentTypeId,
          department: d
        };
      });
  }

  render() {
    const { pickupLocation, index, rootStore } = this.props;
    const { commonStore } = rootStore;
    let currentDepartments = this.createDepartmentValue(pickupLocation.departments);
    return (
      <Draggable key={index} draggableId={index} index={index}>
        {provided => (
          <div {...provided.draggableProps} ref={provided.innerRef}>
            <InfoCard className="mb-2">
              <div className="d-flex flex-row justify-content-between mb-2">
                <Icon iconName="fa-grip-horizontal" {...provided.dragHandleProps} id="grip" />
                <Icon
                  id="trash"
                  iconName="fa-trash-alt"
                  onClick={() => {
                    this.onChange("isDeleted", true);
                  }}
                />
              </div>

              <div className="d-flex flex-row">
                <div className="flex-fill mx-1">
                  <label className="mr-1">Digital Delivery</label>
                  <input
                    type="checkbox"
                    id="digital-delivery"
                    value={pickupLocation.digitalDelivery}
                    checked={pickupLocation.digitalDelivery}
                    onChange={event => {
                      this.onChange("digitalDelivery", !pickupLocation.digitalDelivery);
                    }}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-12">
                  <RequiredInputWithLabel name="pickupLocationName" label="Pickup Location">
                    {pickupLocation.digitalDelivery ? (
                      <BestSelect
                        id="digital-delivery-requirements"
                        options={commonStore.digitalDeliveryOptions}
                        value={commonStore.getDigitalDeliveryOption(pickupLocation)}
                        onChange={opt => {
                          this.onChange("digitalDeliveryOption", opt.value);
                        }}
                      />
                    ) : (
                      <Input
                        name="pickupLocationName"
                        id="pickupLocationName"
                        value={pickupLocation.pickupLocationName}
                        onChange={event => {
                          this.onChange("pickupLocationName", event.target.value);
                        }}
                      />
                    )}
                  </RequiredInputWithLabel>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-12">
                  <RequiredInputWithLabel name="homeAway" label="Type">
                    <BestSelect
                      id="home-away"
                      options={commonStore.homeAwayOptions}
                      value={commonStore.getHomeAwayTypeValue(pickupLocation.type)}
                      onChange={value => {
                        this.onChange("homeAway", value.value);
                      }}
                    />
                  </RequiredInputWithLabel>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <BestLabel className="my-2" name="departments" label="Departments">
                      Departments
                    </BestLabel>
                    <AddAll
                      id="add-all"
                      className="btn btn-link btn-sm p-0 float-right"
                      onClick={() => this.onChange("departments", this.props.departments)}
                    >
                      Add All
                    </AddAll>
                  </div>
                  <BestSelect
                    name="departments"
                    id="departments"
                    isMulti
                    options={this.props.departments}
                    defaultValue={currentDepartments}
                    value={currentDepartments}
                    onChange={value => {
                      this.onChange("departments", value);
                    }}
                  />
                </div>
              </div>
            </InfoCard>
          </div>
        )}
      </Draggable>
    );
  }
}

export default inject("rootStore")(observer(VenueEditPickupLocationMobile));
