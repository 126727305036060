import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestSelect, FormColumn4Wide, RequiredInputWithLabel, Input } from "best-common-react";

class UserInfo extends React.Component {
  render() {
    const { userEditStore, commonStore, authStore } = this.props.rootStore;
    const hasStatsId = !!userEditStore.user.get("statsId");
    return (
      <div>
        {authStore.isGlobal ? (
          <div className="row">
            <FormColumn4Wide>
              <div className="form-group">
                <RequiredInputWithLabel name="org" label="Organization">
                  <BestSelect
                    id="organization"
                    options={commonStore.orgDropdownList}
                    value={userEditStore.currentOrgValue}
                    onChange={userEditStore.setOrg}
                    isDisabled={hasStatsId}
                  />
                </RequiredInputWithLabel>
              </div>
            </FormColumn4Wide>
          </div>
        ) : null}

        <div className="row">
          <FormColumn4Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="firstName" label="First Name">
                <Input
                  id="first-name"
                  onChange={event => {
                    userEditStore.updateUserInfo("firstName", event.target.value);
                  }}
                  value={userEditStore.currentUser.firstName}
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="lastName" label="Last Name">
                <Input
                  id="last-name"
                  onChange={event => {
                    userEditStore.updateUserInfo("lastName", event.target.value);
                  }}
                  value={userEditStore.currentUser.lastName}
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="department" label="Department">
                <BestSelect
                  id="departments"
                  options={userEditStore.departmentsDropdown}
                  value={userEditStore.departmentsDropdownValue}
                  onChange={userEditStore.setUserDepartment}
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="userRoles" label="User Roles">
                <BestSelect
                  id="roles"
                  options={userEditStore.userRoleDropdownOptions}
                  values={userEditStore.userRoleDropdownValues}
                  onChange={userEditStore.setUserRole}
                  isDisabled={!userEditStore.canEditRoles}
                  isMulti={true}
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <BestLabel htmlFor="email">Email</BestLabel>
              <Input
                id="email"
                onChange={event => {
                  userEditStore.updateUserInfo("email", event.target.value);
                  userEditStore.validateEmail(event.target.value);
                }}
                value={userEditStore.currentUser.email}
              />
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              {userEditStore.currentUser.userId ? (
                <RequiredInputWithLabel
                  name="username"
                  label="Username"
                  validateFunc={() =>
                    userEditStore.validateUsername(userEditStore.currentUser.username, userEditStore.currentUser.userId)
                  }
                  requiredText={userEditStore.currentUser.username ? "Can only use lowercase and numbers" : ""}
                >
                  <Input
                    id="username"
                    value={userEditStore.currentUser.username}
                    disabled={!authStore.isGlobal}
                    onChange={event => {
                      userEditStore.updateUserInfo("username", event.target.value);
                      userEditStore.validateUsername(event.target.value);
                    }}
                  />
                </RequiredInputWithLabel>
              ) : (
                <div>
                  <BestLabel htmlFor="username">Username</BestLabel>
                  <Input id="username" value={userEditStore.currentUser.username} disabled={true} />
                </div>
              )}
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <BestLabel htmlFor="statsId">Stats ID</BestLabel>
              <Input
                id="stats-id"
                value={userEditStore.currentUser.statsId ? userEditStore.currentUser.statsId : ""}
                disabled={!authStore.isGlobal}
                onChange={event => {
                  userEditStore.updateUserInfo("statsId", event.target.value);
                  userEditStore.validateStatsId(event.target.value);
                }}
              />
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <BestLabel htmlFor="active">Active</BestLabel>
              <div>
                <input
                  disabled={
                    hasStatsId && userEditStore.user.get("active") && !userEditStore.user.get("manuallyManaged")
                  }
                  type="checkbox"
                  checked={userEditStore.user.get("active")}
                  onChange={event => userEditStore.setUserIsActive(event.target.checked)}
                />
              </div>
            </div>
          </FormColumn4Wide>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(UserInfo));
