import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardHeader, CardFooter } from "best-common-react";
import AdminProtected from "../../components/protected/AdminProtected";
import VenueAdminFilters from "../../components/venue/admin/VenueAdminFilters";
import VenueTables from "../../components/venue/admin/VenueTables";
import VenueAdminFooter from "../../components/venue/admin/VenueAdminFooter";

class VenueAdminContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle />
        <CardContainer>
          <CardHeader>Venues</CardHeader>
          <CardBody>
            <VenueAdminFilters />
            <VenueTables />
          </CardBody>
          <CardFooter>
            <VenueAdminFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default AdminProtected(VenueAdminContainer);
