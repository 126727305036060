import React from "react";
import { inject, observer } from "mobx-react";
import { AccordionTableWrapper, PriceFormatter } from "best-common-react";
import EventDateLinkFormatter from "../../events/admin/EventDateLinkFormatter";
import EventGameLinkFormatter from "../../events/admin/EventGameLinkFormatter";
import UserSummaryAccordionMobile from "./UserSummaryAccordionMobile";
import NumberFormatter from "../../../utilities/NumberFormatter";
import UserFormatter from "../../../utilities/UserFormatter";
import HigherOrderComponent from "../../../utilities/HigherOrderComponent";

const URL_PATH = "reservations";

let DateLinkFormatter = HigherOrderComponent(EventDateLinkFormatter);
DateLinkFormatter.defaultProps = {
  path: URL_PATH
};

let GameLinkFormatter = HigherOrderComponent(EventGameLinkFormatter);
GameLinkFormatter.defaultProps = {
  path: URL_PATH
};

const cols = [
  { key: "userObject", name: "User", width: 200, sortable: true, locked: true, formatter: UserFormatter },
  { key: "department", name: "Department", width: 200, sortable: true },
  { key: "reservations", name: "Reservations", width: 200, sortable: true, formatter: NumberFormatter },
  { key: "tickets", name: "Tickets", width: 200, sortable: true, formatter: NumberFormatter },
  { key: "value", name: "Value", sortable: true, formatter: PriceFormatter }
];

const UserReservationsReportTable = ({ rootStore }) => {
  const { userSummaryStore } = rootStore;
  return (
    <div className="row">
      <div className="col-12">
        <AccordionTableWrapper
          id="event-reservations-report-table"
          data={userSummaryStore.reservationSummaries}
          columns={cols}
          accordion={UserSummaryAccordionMobile}
          height={userSummaryStore.reservationSummaries.length * 35 + 50}
          sortFunc={userSummaryStore.setSortDirection}
          sortColumn={userSummaryStore.sortFilters["key"]}
          sortDirection={userSummaryStore.sortFilters["direction"]}
          hasTotals={false}
        />
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(UserReservationsReportTable));
