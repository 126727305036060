import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, PrimaryButton, StickyFooterButtons } from "best-common-react";
import { RouteConstants } from "../../../constants/RouteConstants";

const FEATURE_SUPPORT = process.env.FEATURE_SUPPORT === "true";

class ReservationsEditFooter extends React.Component {
  render() {
    const { reservationsEditStore, reservationDeleteStore, routerStore } = this.props.rootStore;
    let saveDisabled = !reservationsEditStore.saveEnabled;
    let digitalTicketDelivery = reservationsEditStore.reservation.digitalTicketDelivery;
    return (
      <StickyFooterButtons>
        <PrimaryButton disabled={saveDisabled} id="save" onClick={reservationsEditStore.save}>
          Save
        </PrimaryButton>
        <LightButton
          id="delete"
          disabled={digitalTicketDelivery == null}
          onClick={() => {
            reservationDeleteStore.setIsEdit(true);
            reservationDeleteStore.setReservationToDelete(reservationsEditStore.reservation.ticketRequestId);
          }}
        >
          Delete
        </LightButton>
        {FEATURE_SUPPORT ? (
          <LightButton
            id="support"
            onClick={() => {
              routerStore.history.push(
                RouteConstants.ADMIN_SUPPORT +
                  "?gamePk=" +
                  reservationsEditStore.reservation.gamePk +
                  "&section=" +
                  reservationsEditStore.currentSection.sectionNumber +
                  "&search=" +
                  reservationsEditStore.reservationId
              );
            }}
          >
            Support
          </LightButton>
        ) : null}
        <LightButton id="cancel" onClick={reservationsEditStore.cancel}>
          Cancel
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(ReservationsEditFooter));
