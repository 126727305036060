import React from "react";
import AdminProtected from "../../components/protected/AdminProtected";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardHeader, CardFooter } from "best-common-react";
import ReportEventReservationsFilters from "../../components/reports/eventReservations/ReportEventReservationsFilters";
import ReportEventReservationsTable from "../../components/reports/eventReservations/ReportEventReservationsTable";
import ReportEventReservationsFooter from "../../components/reports/eventReservations/ReportEventReservationsFooter";
import LoadingHeader from "../../components/loading/LoadingHeader";

class ReportEventsContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle />
        <CardContainer>
          <CardHeader>
            <LoadingHeader title="Event Reservations Report" />
          </CardHeader>
          <CardBody>
            <ReportEventReservationsFilters />
            <ReportEventReservationsTable />
          </CardBody>
          <CardFooter>
            <ReportEventReservationsFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default AdminProtected(ReportEventsContainer);
