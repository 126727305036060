import React from "react";
import { inject, observer } from "mobx-react";
import Moment from "moment";
import { BestLabel, Checkbox, DatePickerInput, FormColumn4Wide, FormInput } from "best-common-react";
import "./overrides.css";

const SystemSeasonEditInfo = ({ rootStore: { systemSeasonEditStore } }) => (
  <form onSubmit={event => event.preventDefault()} id="season-edit-form">
    <div className="row">
      <FormColumn4Wide>
        <FormInput
          label="Year"
          name="Year"
          isRequired={true}
          type="select"
          value={
            systemSeasonEditStore.season
              ? { label: systemSeasonEditStore.season.year, value: systemSeasonEditStore.season.year }
              : {}
          }
          options={systemSeasonEditStore.yearOptions}
          onChangeFn={event => {
            systemSeasonEditStore.updateSeason("year", event.value);
          }}
          editable={systemSeasonEditStore.isNewSeason}
        />
      </FormColumn4Wide>
      <FormColumn4Wide>
        <FormInput
          label="Type"
          name="Type"
          isRequired={true}
          type="select"
          value={
            systemSeasonEditStore.season
              ? systemSeasonEditStore.createSeasonTypeOption(systemSeasonEditStore.season.seasonType)
              : {}
          }
          options={systemSeasonEditStore.seasonTypesOptions}
          onChangeFn={event => {
            systemSeasonEditStore.updateSeason("seasonType", event.value);
          }}
          editable={systemSeasonEditStore.isNewSeason}
        />
      </FormColumn4Wide>
      {systemSeasonEditStore.season &&
        systemSeasonEditStore.season.seasonType &&
        systemSeasonEditStore.season.seasonType.seasonTypeId === 1 && (
          <FormColumn4Wide>
            <BestLabel required={true}>NRI Dropoff Date</BestLabel>
            <DatePickerInput
              value={
                systemSeasonEditStore.season.nriDropoff ? Moment.utc(systemSeasonEditStore.season.nriDropoff) : null
              }
              onChange={event => {
                let val = Moment.isMoment(event) ? event : Moment(event);
                systemSeasonEditStore.updateSeason("nriDropoff", val.utc());
              }}
              maxDate={systemSeasonEditStore.nriDropoffDate.maxDate}
              minDate={systemSeasonEditStore.nriDropoffDate.minDate}
              timeZone="GMT"
            />
          </FormColumn4Wide>
        )}
      <FormColumn4Wide>
        <BestLabel>Active</BestLabel>
        <Checkbox
          label=""
          checked={systemSeasonEditStore.season ? !!systemSeasonEditStore.season.active : false}
          onChange={() => {
            systemSeasonEditStore.updateSeason("active", !systemSeasonEditStore.season.active);
          }}
        />
      </FormColumn4Wide>
      <FormColumn4Wide>
        <FormInput
          label="Default Event Access"
          name="defaultEventAccess"
          isRequired={true}
          type="datetime"
          value={
            systemSeasonEditStore.season
              ? systemSeasonEditStore.isNewSeason
                ? systemSeasonEditStore.season.defaultEventAccess
                : Moment.utc(systemSeasonEditStore.season.defaultEventAccess).format("MM/DD/YYYY - h:mma")
              : null
          }
          onChangeFn={event => {
            let val = Moment.isMoment(event) ? event : Moment(event);
            systemSeasonEditStore.updateSeason("defaultEventAccess", val.utc());
          }}
          editable={systemSeasonEditStore.isNewSeason}
          timeZone="GMT"
        />
      </FormColumn4Wide>
    </div>
  </form>
);

export default inject("rootStore")(observer(SystemSeasonEditInfo));
