import _ from "lodash";
import convertUser from "./ConvertUser";

function createUserDropdown(users) {
  return _.sortBy(users, [user => user.lastName.toLowerCase(), user => user.firstName.toLowerCase()]).map(user =>
    convertUser(user)
  );
}

export default createUserDropdown;
