import React from "react";
import { inject, observer } from "mobx-react";
import { AccordionTableWrapper } from "best-common-react";
import EventDateLinkFormatter from "./EventDateLinkFormatter";
import EventGameLinkFormatter from "./EventGameLinkFormatter";
import EventCardMobile from "./EventCardMobile";
import HigherOrderComponent from "../../../utilities/HigherOrderComponent";

const URL_PATH = "details";

let DateLinkFormatter = HigherOrderComponent(EventDateLinkFormatter);
DateLinkFormatter.defaultProps = {
  path: URL_PATH
};

let GameLinkFormatter = HigherOrderComponent(EventGameLinkFormatter);
GameLinkFormatter.defaultProps = {
  path: URL_PATH
};

const cols = [
  { key: "date", name: "Date", width: 200, sortable: true, formatter: DateLinkFormatter },
  { key: "gameStr", name: "Game", width: 175, sortable: true, formatter: GameLinkFormatter },
  { key: "venue", name: "Venue", sortable: true }
];

class EventAdminTable extends React.Component {
  render() {
    const { eventAdminStore } = this.props.rootStore;
    return (
      <div className="row">
        <div className="col">
          <AccordionTableWrapper
            id="event-admin-table"
            data={eventAdminStore.currentEvents}
            columns={cols}
            accordion={EventCardMobile}
            height={400}
            sortFunc={eventAdminStore.setSortDirection}
            sortColumn={eventAdminStore.sortFilters["key"]}
            sortDirection={eventAdminStore.sortFilters["direction"]}
          />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(EventAdminTable));
