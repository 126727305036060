import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import {
  AnchorLink,
  BestLabel,
  BestTitle,
  DisabledInput,
  FormColumn4Wide,
  Input,
  RequiredInputWithLabel
} from "best-common-react";
import UserResetPasswordForm from "../user/admin/UserResetPasswordForm";

const ResetPasswordLink = styled(AnchorLink)``;

class MyAccountBody extends React.Component {
  render() {
    const { myAccountStore } = this.props.rootStore;
    const showResetPassword = myAccountStore.showResetPassword;
    let resetPassword;

    if (showResetPassword) {
      resetPassword = (
        <div>
          <BestTitle>Reset Password</BestTitle>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <UserResetPasswordForm />
            </div>
          </div>
        </div>
      );
    } else {
      resetPassword = (
        <ResetPasswordLink onClick={myAccountStore.setResetPassword} id="reset-password">
          Reset Password
        </ResetPasswordLink>
      );
    }

    return (
      <div>
        <div className="row">
          <FormColumn4Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="firstName" label="First Name">
                <Input
                  id="first-name"
                  onChange={event => {
                    myAccountStore.updateUserInfo("firstName", event.target.value);
                  }}
                  value={myAccountStore.currentUser.firstName}
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="lastName" label="Last Name">
                <Input
                  id="last-name"
                  onChange={event => {
                    myAccountStore.updateUserInfo("lastName", event.target.value);
                  }}
                  value={myAccountStore.currentUser.lastName}
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <BestLabel for="email">Email</BestLabel>
              <Input
                id="email"
                onChange={event => {
                  myAccountStore.updateUserInfo("email", event.target.value);
                  myAccountStore.validateEmail(event.target.value);
                }}
                value={myAccountStore.currentUser.email}
              />
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <BestLabel for="department">Department</BestLabel>
              <DisabledInput value={myAccountStore.departmentName} id="department" disabled={true} />
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <BestLabel for="userName">Username</BestLabel>
              <DisabledInput value={myAccountStore.currentUser.username} id="username" disabled={true} />
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <BestLabel for="statsId">Stats ID</BestLabel>
              <DisabledInput value={myAccountStore.currentUser.statsId} id="stats-id" disabled={true} />
            </div>
          </FormColumn4Wide>
        </div>
        <div className="row">
          <div className="col-12">{resetPassword}</div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(MyAccountBody));
