import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { observer } from "mobx-react";
import { findTimes } from "../../../utilities/comptixFormatter";
import { BasicSelect, Input } from "best-common-react";

const DaysInput = styled(Input)`
  &&& {
    width: 40px;
    text-align: center;
    &:disabled {
      font-style: oblique;
    }
  }
`;

const SmallSelect = styled(BasicSelect)`
  &&& {
    width: 60px;
    &:disabled {
      font-style: oblique;
    }
  }
`;
const BeforeAfterSelect = styled(BasicSelect).attrs({
  className: "mt-1 mt-md-0"
})`
  &&& {
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      ${props => (props.wrap ? "margin-top: .4rem !important" : "width: 100px;")};
    }
    &: disabled {
      font-style: oblique;
    }
  }
`;

const Text = styled.span.attrs({
  className: "mx-1"
})`
  color: ${props => (props.disabled ? props.theme["grey"] : props.theme["black"])}
  font-style: ${props => (props.disabled ? "italic" : "normal")}
`;

const TimeOptions = [{ label: "Before", value: 1 }, { label: "After", value: 2 }];

const HoursOptions = [];

const MinOptions = [];

for (let m = 0; m < 60; m++) {
  MinOptions.push({ label: m, value: m });
}
for (let h = 0; h < 24; h++) {
  HoursOptions.push({ label: h, value: h });
}

class DepartmentOffset extends React.Component {
  constructor(props) {
    super(props);
    this.calculateMinutes = this.calculateMinutes.bind(this);
  }

  calculateMinutes(days, hours, minutes, beforeAfter) {
    let calculated = 0;
    calculated += days * 24 * 60;
    calculated += hours * 60;
    calculated += minutes * 1;
    if (beforeAfter === 1) {
      calculated = Math.abs(calculated);
    } else if (beforeAfter === 2) {
      calculated = Math.abs(calculated) * -1;
    }
    this.props.onChange(calculated);
  }

  render() {
    const { disabled, wrap, data } = this.props;
    const beforeAfter = data >= 0 ? 1 : 2;
    const breakdown = findTimes(Math.abs(data));

    return (
      <div className="d-flex flex-wrap align-items-center">
        <DaysInput
          id="offset-days"
          value={breakdown.days}
          disabled={disabled}
          onChange={event => {
            this.calculateMinutes(event.target.value, breakdown.hours, breakdown.minutes, beforeAfter);
          }}
        />
        <Text disabled={disabled}>days</Text>
        <SmallSelect
          id="offset-hours"
          value={breakdown.hours}
          disabled={disabled}
          onChange={event => {
            this.calculateMinutes(breakdown.days, event.target.value, breakdown.minutes, beforeAfter);
          }}
        >
          {HoursOptions.map((h, index) => (
            <option value={h.value} key={index}>
              {h.label}
            </option>
          ))}
        </SmallSelect>
        <Text disabled={disabled}>hrs</Text>
        <SmallSelect
          id="offset-minutes"
          value={breakdown.minutes}
          disabled={disabled}
          onChange={event => {
            this.calculateMinutes(breakdown.days, breakdown.hours, event.target.value, beforeAfter);
          }}
        >
          {MinOptions.map((m, index) => (
            <option value={m.value} key={index}>
              {m.label}
            </option>
          ))}
        </SmallSelect>
        <Text disabled={disabled}>mins</Text>
        <BeforeAfterSelect
          id="offset-am-pm"
          value={beforeAfter}
          disabled={disabled}
          onChange={event => {
            this.calculateMinutes(breakdown.days, breakdown.hours, breakdown.minutes, event.target.value * 1);
          }}
          wrap={wrap}
        >
          {TimeOptions.map((t, index) => (
            <option value={t.value} key={index}>
              {t.label}
            </option>
          ))}
        </BeforeAfterSelect>
      </div>
    );
  }
}

DepartmentOffset.propTypes = {
  data: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  wrap: PropTypes.bool
};

DepartmentOffset.defaultProps = {
  disabled: false,
  wrap: false
};

export default observer(DepartmentOffset);
