import React from "react";
import { inject, observer } from "mobx-react";
import ReservationsAccordionMobile from "./ReservationsAccordionMobile";
import DeleteReservationFormatter from "./DeleteReservationFormatter";
import RequestDateFormatter from "../../elements/tables/RequestDateFormatter";
import GameDateFormatter from "../../elements/tables/GameDateFormatter";
import ReservationEditIconFormatter from "./ReservationEditIconFormatter";
import { AccordionTableWrapper } from "best-common-react";

const cols = [
  {
    key: "ticketRequestId1",
    name: "",
    width: 35,
    formatter: ReservationEditIconFormatter,
    locked: true
  },
  {
    key: "ticketRequestId",
    name: "",
    width: 35,
    formatter: DeleteReservationFormatter,
    locked: true
  },
  {
    key: "gameDate",
    name: "Date",
    width: 190,
    sortable: true,
    locked: true,
    formatter: GameDateFormatter
  },
  { key: "game", name: "Game", width: 115, sortable: true, locked: true },
  { key: "venue", name: "Venue", width: 190, sortable: true },
  { key: "recipient", name: "Recipient", width: 125, sortable: true },
  { key: "relation", name: "Relation", width: 85, sortable: true },
  { key: "sectionDescription", name: "Section", width: 200, sortable: true },
  { key: "quantity", name: "Quantity", width: 80, sortable: true },
  {
    key: "requestDate",
    name: "Request Date",
    width: 210,
    sortable: true,
    formatter: RequestDateFormatter
  },
  { key: "comments", name: "Comments", width: 1000, sortable: true }
];

class ReservationsTable extends React.Component {
  render() {
    const { reservationsStore } = this.props.rootStore;
    return (
      <div className="mt-4">
        <AccordionTableWrapper
          id="reservations-table"
          data={reservationsStore.currentReservations}
          columns={cols}
          accordion={ReservationsAccordionMobile}
          sortFunc={reservationsStore.setSortDirection}
          sortColumn={reservationsStore.sortFilters["key"]}
          sortDirection={reservationsStore.sortFilters["direction"]}
          height={400}
        />
      </div>
    );
  }
}

export default inject("rootStore")(observer(ReservationsTable));
