import React from "react";
import {
  BestLabel,
  BestSelect,
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";

const CommentInput = styled.textarea.attrs({ maxLength: "150", id: "comment-text-area" })`
  height: 5em;
`;

const AddAll = styled.button`
  text-decoration: none !important;
`;

class AddMessageModal extends React.Component {
  render() {
    return (
      <Modal show={this.props.show} size="lg" onClose={this.props.onClose}>
        <ModalHeaderWithTitleAndClose
          title="Additional Information"
          onClose={this.props.onClose}
          close={this.props.onClose}
        />
        <ModalBody>
          <div>
            <div className="row">
              <div className="col">
                <BestLabel className="my-2" name="departmentsLabel" label="Departments" required={true}>
                  Departments
                </BestLabel>
                <AddAll
                  id="add-all"
                  className="btn btn-link btn-sm p-0 float-right"
                  onClick={() => this.props.updateDepartments(this.props.departmentOptions)}
                >
                  Add All
                </AddAll>
                <BestSelect
                  isMulti
                  name="departments"
                  id="eventMessageDepartments"
                  options={this.props.departmentOptions}
                  value={toJS(this.props.message.departments)}
                  onChange={value => {
                    this.props.updateDepartments(value);
                  }}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <BestLabel required={true}>Message</BestLabel>
                <CommentInput
                  className="form-control"
                  name="message"
                  id="message"
                  value={this.props.message.message}
                  onChange={this.props.onChange}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton
              className="mr-2"
              onClick={this.props.onSave}
              disabled={!this.props.message.message.trim() || this.props.isSaving}
            >
              Save
            </PrimaryButton>
            <LightButton onClick={this.props.onClose}>Cancel</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

AddMessageModal.propTypes = {
  departmentOptions: PropTypes.any,
  message: PropTypes.object,
  updateDepartments: PropTypes.func
};

AddMessageModal.defaultProps = {
  departmentOptions: []
};

export default inject("rootStore")(observer(AddMessageModal));
