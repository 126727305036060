import React from "react";
import { action, autorun, computed, decorate, extendObservable } from "mobx";
import _ from "lodash";
import moment from "moment";
import { Icon } from "best-common-react";
import styled from "styled-components";
import queryString from "query-string";

const UnclickableIcon = styled(Icon)`
  &&& {
    pointer-events: none;
  }
`;

class AdminSupportStore {
  constructor(commonStore, compTixApi, loadStore, routerStore) {
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.loadingStore = loadStore;
    this.routerStore = routerStore;

    const today = moment()
      .startOf("day")
      .toDate();
    const month = moment()
      .startOf("day")
      .add(-1, "month")
      .toDate();

    this.defaults = {
      allTickets: ["Default"],
      sortFilters: {
        direction: "DESC",
        key: "requestDate"
      },
      filterForwarded: false,
      dateRangeFilter: { start: month, end: today },
      selectedOrg: { value: "", label: "", statsId: -1 },
      gamePkSearchTerm: "",
      sectionSearchTerm: "",
      rowSearchTerm: "",
      searchTerm: "",
      isGetting: false
    };

    extendObservable(this, {
      allTickets: this.defaults["allTickets"],
      sortFilters: this.defaults["sortFilters"],
      filterForwarded: this.defaults["filterForwarded"],
      dateRangeFilter: this.defaults["dateRangeFilter"],
      selectedOrg: this.defaults["selectedOrg"],
      gamePkSearchTerm: this.defaults["gamePkSearchTerm"],
      sectionSearchTerm: this.defaults["sectionSearchTerm"],
      rowSearchTerm: this.defaults["rowSearchTerm"],
      searchTerm: this.defaults["searchTerm"],
      isGetting: this.defaults["isGetting"],
      setAllTickets: action(value => {
        this.allTickets = value;
      }),
      setSelectedOrg: action(value => {
        //this.selectedOrg = value;
        this.compTixApi.getOrgById(value.value).then(org => {
          value.statsId = org.statsId;
          this.selectedOrg = value;
        });
      }),
      setDateRangeFilter: action(filters => {
        this.dateRangeFilter = filters;
      }),

      setGamePkSearchTerm: action(value => {
        this.gamePkSearchTerm = value;
      }),
      clearGamePkSearchTerm: action(() => {
        this.gamePkSearchTerm = "";
      }),

      setSectionSearchTerm: action(value => {
        this.sectionSearchTerm = value;
      }),
      clearSectionSearchTerm: action(() => {
        this.sectionSearchTerm = "";
      }),

      setRowSearchTerm: action(value => {
        this.rowSearchTerm = value;
      }),
      clearRowSearchTerm: action(() => {
        this.rowSearchTerm = "";
      }),

      setSearchTerm: action(value => {
        this.searchTerm = value;
      }),
      clearSearchTerm: action(() => {
        this.searchTerm = "";
      }),

      setIsGetting: action(value => {
        this.isGetting = value;
      }),

      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),

      resetStore: action(() => {
        this.allTickets = this.defaults["allTickets"];
        this.clearGamePkSearchTerm();
        this.clearSectionSearchTerm();
        this.clearRowSearchTerm();
        this.clearSearchTerm();
        this.dateRangeFilter = this.defaults["dateRangeFilter"];
      })
    });

    autorun(() => {
      if (this.routerStore.isTicketSupportTab) {
        this.parseUrl();
      } else {
        this.resetStore();
      }
    });
  }

  parseUrl = () => {
    let params = queryString.parse(this.routerStore.location.search);
    if (params.gamePk) {
      this.setGamePkSearchTerm(params.gamePk);
    }
    if (params.section) {
      this.setSectionSearchTerm(params.section);
    }
    if (params.search) {
      this.setSearchTerm(params.search);
    }
  };

  getTickets = () => {
    this.loadingStore.setLoading(true);
    this.setIsGetting(true);

    this.compTixApi
      .getReservationsForGame(this.gamePkSearchTerm)
      .then(data => {
        if (data) {
          this.allTickets = data;
        }
      })
      .finally(() => {
        this.loadingStore.setLoading(false);
        this.setIsGetting(false);
      });
  };

  sort = sortData => {
    if (this.sortFilters["direction"] === "ASC") {
      return _.sortBy(sortData, this.sortFilters["key"]);
    } else if (this.sortFilters["direction"] === "DESC") {
      return _.sortBy(sortData, this.sortFilters["key"]).reverse();
    } else {
      return sortData;
    }
  };

  get currentTickets() {
    if (this.allTickets.length == 0 || this.allTickets[0] === "Default") {
      return [];
    }
    let tickets = this.allTickets.map(t => ({
      requestId: t.ticketRequestId,
      forwardID: t.digitalTicketDelivery ? t.digitalTicketDelivery.forwardId : "",
      section: t.section.sectionNumber,
      quantity: t.quantity,
      orgCode: t.userDTO.org.orgCode,
      requester: t.user ? t.user.lastName + ", " + t.user.firstName : "",
      requestDate: moment(t.requestDate).format("YYYY-MM-DD h:mm a"),
      forwardDate: "",
      user: t.user.username,
      wasForwarded:
        t.digitalTicketDelivery && t.digitalTicketDelivery.forwardId ? (
          <UnclickableIcon iconName="fa-check-circle" />
        ) : (
          <>
            <UnclickableIcon iconName="fa-times-circle" />
          </>
        )
    }));

    tickets = this.filterByForwarded(tickets, this.filterForwarded);
    tickets = this.filterBySearchTerm(tickets, this.sectionSearchTerm, this.searchTerm);
    return this.sort(tickets);
  }

  filterBySearchTerm(tickets, section, searchTerm) {
    let filtered = tickets;
    if (section.length > 0) {
      filtered = _.filter(filtered, ticket => {
        return _.includes(_.lowerCase(ticket.section), _.lowerCase(section));
      });
    }

    if (searchTerm.length > 0) {
      filtered = _.filter(filtered, ticket => {
        let search = _.lowerCase(searchTerm);
        return (
          _.includes(_.lowerCase(ticket.requestId), search) ||
          _.includes(_.lowerCase(ticket.forwardID), search) ||
          _.includes(_.lowerCase(ticket.orgCode), search) ||
          _.includes(_.lowerCase(ticket.requester), search) ||
          _.includes(_.lowerCase(ticket.user), search)
        );
      });
    }

    return filtered;
  }

  filterByForwarded(tickets, filterForwarded) {
    if (filterForwarded) {
      return _.filter(tickets, ticket => ticket.forwardID === "");
    } else {
      return tickets;
    }
  }

  toggleForwarded = () => {
    this.filterForwarded = !this.filterForwarded;
  };

  get isValidRequest() {
    return this.gamePkSearchTerm.trim();
  }
}

export default AdminSupportStore;
