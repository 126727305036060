import { action, computed, observable, extendObservable, autorun, decorate } from "mobx";
import validate from "validate.js";

class MyAccountStore {
  constructor(authStore, compTixApi, routerStore, resetPasswordStore) {
    this.authStore = authStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.resetPasswordStore = resetPasswordStore;

    this.defaults = {
      user: observable.map().replace({
        authorities: [],
        department: null,
        email: "",
        firstName: "",
        lastName: "",
        org: {},
        statsId: null,
        username: "",
        userId: -1
      }),
      showResetPassword: false,
      resetPasswordClicked: false
    };

    extendObservable(this, {
      user: this.defaults["user"],
      showResetPassword: this.defaults["showResetPassword"],
      resetPasswordClicked: this.defaults["resetPasswordClicked"],
      setResetPassword: action(() => {
        this.resetPasswordClicked = true;
        this.resetPasswordStore.setSelectedUserId(this.currentUser.userId);
        this.showResetPassword = !this.showResetPassword;
      }),
      updateUserInfo: action((key, value) => {
        this.user.set(key, value);
      }),
      setUser: action(value => {
        this.user.replace(value);
      }),
      saveUser: action(() => {
        if (this.resetPasswordStore.isValidPassword) {
          this.resetPasswordStore.resetPassword(() =>
            this.compTixApi.updateUser(this.currentUser).then(data => {
              if (data) {
                this.resetMyAccountStore();
                this.routerStore.history.push("/");
              }
            })
          );
        } else {
          this.compTixApi.updateUser(this.currentUser).then(data => {
            if (data) {
              this.resetMyAccountStore();
              this.routerStore.history.push("/");
            }
          });
        }
      }),
      cancel: action(() => {
        this.resetMyAccountStore();
        this.resetPasswordStore.clearPasswordForm();
        this.routerStore.history.push("/");
      }),
      resetMyAccountStore: action(() => {
        this.showResetPassword = false;
        this.resetPasswordClicked = false;
        this.updateUserInfo("firstName", "");
        this.updateUserInfo("lastName", "");
        this.updateUserInfo("email", "");
        this.resetPasswordStore.clearPasswordForm();
      })
    });

    autorun(() => {
      if (this.routerStore.isMyAccountTab) {
        this.getUser(this.authStore.userData.userId);
      } else {
        this.resetMyAccountStore();
      }
    });
  }

  get currentUser() {
    return this.user.toJSON();
  }

  get departmentName() {
    return this.currentUser && this.currentUser.department
      ? this.currentUser.department.departmentType.departmentName
      : "";
  }

  get isValidSave() {
    return (
      this.currentUser.firstName.trim() &&
      this.currentUser.lastName.trim() &&
      (this.currentUser.email.trim() ? this.validateEmail(this.currentUser.email) : true) &&
      (this.resetPasswordClicked ? this.resetPasswordStore.isValidPassword : true)
    );
  }

  validateEmail(email) {
    return !validate({ from: email }, { from: { email: true } });
  }

  getUser = userId => {
    if (userId !== this.defaults["user"]["userId"]) {
      this.compTixApi.getUserById(userId).then(data => {
        this.setUser(data);
      });
    }
  };
}

decorate(MyAccountStore, {
  currentUser: computed,
  isValidSave: computed,
  departmentName: computed
});

export default MyAccountStore;
