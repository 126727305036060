import React from "react";

/* eslint-disable */
const HigherOrderComponent = WrappedComponent => {
  class HOC extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return HOC;
};

export default HigherOrderComponent;
