import React from "react";
import moment from "moment";
import { timezone } from "../../../utilities/comptixFormatter";

// eslint-disable-next-line

class GameDateFormatter extends React.Component {
  render() {
    let gameDate = this.props.value.gameDate;
    let tbd = this.props.value.tbd;

    if (tbd) {
      return (
        <div id="game-date-formatter">
          {gameDate
            ? moment(gameDate)
                .utc()
                .format("ddd M/D") + " - TBD"
            : ""}
        </div>
      );
    }

    let tz = this.props.value.tzId;
    let momentInTZ = tz && tz !== -1 ? moment(gameDate).tz(tz) : moment(gameDate);
    return <div>{gameDate ? momentInTZ.format("ddd M/D - h:mma") + " " + timezone(momentInTZ.format("zz")) : ""}</div>;
  }
}

export default GameDateFormatter;
