import { action, computed, extendObservable, decorate } from "mobx";

class ResetPasswordStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      selectedUserId: -1,
      resetPasswordObj: {
        password: "",
        confirmPassword: ""
      },
      showResetPassword: false
    };

    extendObservable(this, {
      selectedUserId: this.defaults["selectedUserId"],
      resetPasswordObj: this.defaults["resetPasswordObj"],
      showResetPassword: this.defaults["showResetPassword"],
      resetPassword: action(callback => {
        this.compTixApi
          .resetPassword(this.selectedUserId, this.resetPasswordObj.password, this.resetPasswordObj.confirmPassword)
          .then(data => {
            if (typeof callback === "function") {
              callback();
            } else {
              this.showResetPassword = false;
              this.clearPasswordForm();
            }
          });
      }),
      clearPasswordForm: action(() => {
        this.resetPasswordObj = this.defaults["resetPasswordObj"];
      }),
      setPassword: action(event => {
        this.resetPasswordObj.password = event.target.value;
      }),
      setConfirmPassword: action(event => {
        this.resetPasswordObj.confirmPassword = event.target.value;
      }),
      setSelectedUserId: action(userId => {
        this.selectedUserId = userId;
      }),
      setShowResetPassword: action(value => {
        this.showResetPassword = value;
      })
    });
  }

  get passwordErrors() {
    let errors = [];
    if (this.resetPasswordObj.password.length === 0 || this.resetPasswordObj.confirmPassword.length === 0) {
      errors.push("Both passwords are required");
    } else if (this.resetPasswordObj.password !== this.resetPasswordObj.confirmPassword) {
      errors.push("Passwords do not match");
    }
    return errors;
  }

  get isValidPassword() {
    return this.passwordErrors.length === 0;
  }
}

decorate(ResetPasswordStore, {
  passwordErrors: computed,
  isValidPassword: computed
});

export default ResetPasswordStore;
