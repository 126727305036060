import React from "react";
import LoadingSpinner from "./LoadingSpinner";

const LoadingHeader = ({ title = "" }) => (
  <div className="d-flex">
    <div className="mr-2">{title}</div>
    <LoadingSpinner size={10} />
  </div>
);

export default LoadingHeader;
