import React from "react";
import { inject, observer } from "mobx-react";
import DepartmentAdminAccordion from "./DepartmentAdminAccordion";
import { AccordionTableWrapper, IconFormatter } from "best-common-react";
import HigherOrderComponent from "../../../utilities/HigherOrderComponent";

let PencilFormatter = HigherOrderComponent(IconFormatter);
PencilFormatter.defaultProps = {
  icon: "fa-pencil-alt",
  id: "department-edit-formatter"
};

const cols = [
  {
    key: "editLink",
    name: "",
    width: 35,
    formatter: PencilFormatter,
    locked: true
  },

  { key: "name", name: "Department", sortable: true, locked: true },
  {
    key: "homeAllotment",
    name: "Home (Fam/Frnd)",
    cellClass: "text-center",
    width: 200
  },
  {
    key: "awayAllotment",
    name: "Away (Fam/Frnd)",
    cellClass: "text-center",
    width: 200
  },
  { key: "eventAvailable", name: "Event Access (local time)", width: 350 },
  { key: "eventCutoff", name: "Event Cutoff (local time)", width: 350 }
];

class DepartmentTable extends React.Component {
  render() {
    const { authStore, departmentAdminStore } = this.props.rootStore;
    return (
      <div className="mt-4">
        <AccordionTableWrapper
          id="department-table"
          data={departmentAdminStore.getDepartments}
          columns={authStore.isGlobal ? cols : cols.slice(1)}
          sortFunc={departmentAdminStore.setSortDirection}
          accordion={DepartmentAdminAccordion}
          height={400}
          sortColumn={departmentAdminStore.sortFilters["key"]}
          sortDirection={departmentAdminStore.sortFilters["direction"]}
          minColumnWidth={200}
        />
      </div>
    );
  }
}

export default inject("rootStore")(observer(DepartmentTable));
