import React from "react";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import Protected from "../components/protected/Protected";
import TeamTitle from "../components/title/TeamTitle";
import ModalExample from "../components/examples/ModalExample";
import AccordionExample from "../components/examples/AccordionExample";
import StickyFooterExample from "../components/examples/StickyFooterExample";
import DataTableExample from "../components/examples/DataTableExample";
import AccordionTableExample from "../components/examples/AccordionTableExample";
import PermissionDisplayExample from "../components/examples/PermissionDisplayExample";
import FormExample from "../components/examples/FormExample";

class ExamplesContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle />
        <CardContainer>
          <CardHeader>Examples</CardHeader>
          <CardBody>
            <PermissionDisplayExample />
            <ModalExample />
            <AccordionExample />
            <FormExample />
            <DataTableExample />
            <AccordionTableExample />
          </CardBody>
          <CardFooter>
            <StickyFooterExample />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(ExamplesContainer);
