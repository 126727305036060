import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestSelect, FormColumn3Wide, Input, RequiredInputWithLabel, TextArea } from "best-common-react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

class ReservationEditForm extends React.Component {
  render() {
    const { reservationsEditStore, commonStore } = this.props.rootStore;
    const quantityLabel =
      reservationsEditStore.allotmentRemaining === 1
        ? reservationsEditStore.allotmentRemaining + " ticket remaining"
        : reservationsEditStore.allotmentRemaining + " tickets remaining";
    const { reservation } = reservationsEditStore;
    return (
      <form>
        <div className="row">
          {reservationsEditStore.displayRequester ? (
            <FormColumn3Wide>
              <div className="form-group">
                <RequiredInputWithLabel name="requester" label="Requester">
                  <BestSelect
                    id="requester"
                    options={reservationsEditStore.requesterOptions}
                    value={reservationsEditStore.requesterDropdownValue}
                    onChange={value => {
                      reservationsEditStore.updateValue("requesterUser", value.value);
                    }}
                    placeholder=""
                  />
                </RequiredInputWithLabel>
              </div>
            </FormColumn3Wide>
          ) : null}
          <FormColumn3Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="firstName" label="First Name">
                <Input
                  id="first-name"
                  type="text"
                  value={reservation.recipientFirstName}
                  onChange={event => {
                    reservationsEditStore.updateValue("recipientFirstName", event.target.value);
                  }}
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn3Wide>
          <FormColumn3Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="lastName" label="Last Name">
                <Input
                  id="last-name"
                  type="text"
                  value={reservation.recipientLastName}
                  onChange={event => {
                    reservationsEditStore.updateValue("recipientLastName", event.target.value);
                  }}
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn3Wide>
        </div>
        <div className="row">
          <FormColumn3Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="relation" label="Relation">
                <BestSelect
                  id="relation"
                  options={commonStore.friendsAndFamilyDropdown}
                  value={reservationsEditStore.relationDropdownValue}
                  onChange={value => {
                    reservationsEditStore.updateValue("relation", value.value);
                  }}
                  placeholder=""
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn3Wide>
          <FormColumn3Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="sections" label="Available Sections">
                <BestSelect
                  id="sections"
                  options={reservationsEditStore.sectionInventoryOptions}
                  value={reservationsEditStore.sectionInventoryDropdownValue}
                  onChange={value => {
                    reservationsEditStore.updateValue("section", value.value);
                  }}
                  placeholder=""
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn3Wide>
          <FormColumn3Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="quantity" label="Quantity" rightLabel={quantityLabel}>
                <BestSelect
                  id="quantity"
                  options={reservationsEditStore.allotmentOptions}
                  value={reservationsEditStore.allotmentDropdownValue}
                  onChange={value => {
                    reservationsEditStore.updateValue("quantity", value.value);
                  }}
                  placeholder=""
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn3Wide>
        </div>
        <div className="row">
          <FormColumn3Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="pickupLocation" label="Pickup Location">
                <BestSelect
                  id="pickup-location"
                  options={reservationsEditStore.pickupLocationOptions}
                  value={reservationsEditStore.pickupLocationDropdownValue}
                  onChange={value => {
                    reservationsEditStore.updateValue("pickupLocation", value.value);
                  }}
                  placeholder=""
                />
              </RequiredInputWithLabel>
            </div>
          </FormColumn3Wide>
          {reservation.pickupLocation && reservation.pickupLocation.digitalDelivery ? (
            <React.Fragment>
              <FormColumn3Wide>
                {reservation.pickupLocation.requireEmail ? (
                  <RequiredInputWithLabel name="deliveryEmail" label="Email">
                    <Input
                      type="email"
                      id="deliveryEmail"
                      value={reservation.deliveryEmail}
                      onChange={event => reservationsEditStore.updateValue("deliveryEmail", event.target.value)}
                    />
                  </RequiredInputWithLabel>
                ) : null}
              </FormColumn3Wide>
              <FormColumn3Wide className="col-lg-4 col-12 form-group">
                {reservation.pickupLocation.requireMobilePhone ? (
                  <RequiredInputWithLabel name="deliveryMobilePhone" label="Mobile Phone">
                    <IntlTelInput
                      containerClassName="allow-dropdown intl-tel-input d-flex"
                      placeholder=""
                      formatOnInit={false}
                      inputClassName={
                        "form-control " + (reservation.valid && reservation.deliveryMobilePhone ? "" : "is-invalid")
                      }
                      preferredCountries={["us", "pr", "do", "ca", "mx", "jp", "kr"]}
                      onPhoneNumberChange={(valid, value, code) => {
                        reservationsEditStore.updateValue("deliveryMobilePhone", value, code, valid);
                      }}
                      onSelectFlag={(value, code, ignore, isValid) => {
                        reservationsEditStore.updateValue("deliveryMobilePhone", value, code, isValid);
                      }}
                      value={reservation.deliveryMobilePhone}
                    />
                  </RequiredInputWithLabel>
                ) : null}
              </FormColumn3Wide>
            </React.Fragment>
          ) : null}
          {reservation.pickupLocation && reservation.pickupLocation.allowEitherContactMethod ? (
            <React.Fragment>
              <div className="col-12 pb-2">
                Please enter a valid e-mail address or U.S. phone number. International phone numbers are not supported
                at this time.{" "}
              </div>
              <div className="col-lg-4 col-12 form-group">
                <BestLabel>Email</BestLabel>
                <Input
                  type="email"
                  className="form-control"
                  id="deliveryEmail"
                  value={reservation.deliveryEmail}
                  onChange={event => reservationsEditStore.updateValue("deliveryEmail", event.target.value)}
                />
              </div>
              <div className="col-lg-4 col-12 form-group">
                <BestLabel>Mobile Phone</BestLabel>
                <IntlTelInput
                  containerClassName="allow-dropdown intl-tel-input d-flex"
                  placeholder="U.S. Phone Numbers Only"
                  inputClassName={
                    "form-control " +
                    ((reservation.valid && reservation.deliveryMobilePhone) || !reservation.deliveryMobilePhone
                      ? ""
                      : "is-invalid")
                  }
                  formatOnInit={false}
                  defaultCountry="us"
                  preferredCountries={["us"]}
                  onlyCountries={["us"]}
                  onPhoneNumberChange={(valid, value, code) => {
                    reservationsEditStore.updateValue("deliveryMobilePhone", value, code, valid);
                  }}
                  onSelectFlag={(value, code, ignore, isValid) => {
                    reservationsEditStore.updateValue("deliveryMobilePhone", value, code, isValid);
                  }}
                  value={reservation.deliveryMobilePhone}
                />
              </div>
            </React.Fragment>
          ) : null}
          {commonStore.useHHDropdown ? (
            <div className="col-lg-4 col-12 form-group">
              <RequiredInputWithLabel name="householdType" label="Household Type">
                <BestSelect
                  id="household-type"
                  options={commonStore.householdOptions}
                  value={reservationsEditStore.householdTypeValue}
                  onChange={value => reservationsEditStore.updateValue("householdType", value.value)}
                />
              </RequiredInputWithLabel>
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col">
            <BestLabel>Comments</BestLabel>
            <TextArea
              id="comments"
              value={reservation.comments}
              onChange={event => {
                reservationsEditStore.updateValue("comments", event.target.value);
              }}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default inject("rootStore")(observer(ReservationEditForm));
