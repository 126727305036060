import React from "react";
import moment from "moment";
import { DatePickerInput } from "best-common-react";

class DatePickerExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date() };
  }

  render() {
    return (
      <div>
        <DatePickerInput
          value={this.state.date}
          onChange={event => {
            this.setState({ date: moment(event).utc() });
          }}
        />
      </div>
    );
  }
}

export default DatePickerExample;
