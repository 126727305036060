import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import {
  AttributeHeader,
  AttributeValue,
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import EventAccess from "../../overrides/EventAccess";
import EventCutoff from "../../overrides/EventCutoff";
import AllocationOverride from "../../overrides/AllocationOverride";

const OverrideHolder = styled.div.attrs({
  className: "mt-1 p-2"
})`
  border: 1px solid ${props => props.theme["grey"]}
    ${props => (props.disabled ? "background-color: " + props.theme["light-grey"] : "")};
`;

class EventDetailsAccessOverrideModal extends React.Component {
  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(key, value) {
    const { eventDetailsStore } = this.props.rootStore;
    eventDetailsStore.setSelectedEventAccessOverride(value);
  }

  render() {
    const { eventDetailsStore } = this.props.rootStore;
    const override = eventDetailsStore.selectedEventAccessOverride;
    return (
      <Modal
        show={eventDetailsStore.showEventAccessOverrideModal}
        onClose={() => {
          eventDetailsStore.setShowEventAccessOverrideModal(false);
        }}
        size="lg"
      >
        <ModalHeaderWithTitleAndClose
          title="Ticket Access Override"
          close={() => {
            eventDetailsStore.setShowEventAccessOverrideModal(false);
          }}
        />
        <ModalBody>
          {override ? (
            <div>
              <div className="row">
                <div className="col">
                  <AttributeHeader>Department</AttributeHeader>
                  <AttributeValue>{eventDetailsStore.selectedDepartment.departmentType.departmentName}</AttributeValue>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-lg-6">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="eventAccess"
                      checked={override.useAccess}
                      onChange={() => {
                        override.useAccess = !override.useAccess;
                      }}
                    />
                    <label className="form-check-label" htmlFor="eventAccess">
                      Event Access
                    </label>
                  </div>
                  <OverrideHolder disabled={!override.useAccess}>
                    <EventAccess
                      id="event-access"
                      access={override}
                      onUpdate={value => {
                        this.updateValue("access", value);
                      }}
                      disabled={!override.useAccess}
                      wrap={true}
                    />
                  </OverrideHolder>
                </div>
                <div className="col-xs-12 col-lg-6 mt-1 mt-lg-0">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="eventCutoff"
                      checked={override.useCutoff}
                      onChange={() => {
                        override.useCutoff = !override.useCutoff;
                      }}
                    />
                    <label className="form-check-label" htmlFor="eventCutoff">
                      Event Cutoff
                    </label>
                  </div>
                  <OverrideHolder disabled={!override.useCutoff}>
                    <EventCutoff
                      id="event-cutoff"
                      access={override}
                      onUpdate={value => {
                        this.updateValue("cutoff", value);
                      }}
                      disabled={!override.useCutoff}
                      wrap={true}
                    />
                  </OverrideHolder>
                </div>
              </div>
              <div class="row mt-2">
                <div className="col-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="useAllotment"
                      checked={override.useAllotment}
                      onChange={() => {
                        override.useAllotment = !override.useAllotment;
                      }}
                    />
                    <label className="form-check-label" htmlFor="ticketAllocation">
                      Ticket Allocation
                    </label>
                  </div>
                  <OverrideHolder disabled={!override.useAllotment}>
                    <AllocationOverride
                      disabled={!override.useAllotment}
                      allocation={override}
                      rootStore={this.props.rootStore}
                    />
                  </OverrideHolder>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </ModalBody>

        <ModalFooter>
          <div>
            <PrimaryButton
              className="mr-2"
              onClick={eventDetailsStore.saveEventAccessOverride}
              disabled={!eventDetailsStore.isValidEventAccessOverride || eventDetailsStore.isSaving}
            >
              Save Override
            </PrimaryButton>
            <LightButton
              onClick={() => {
                eventDetailsStore.setShowEventAccessOverrideModal(false);
              }}
            >
              Cancel
            </LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(EventDetailsAccessOverrideModal));
