import { action, autorun, computed, decorate, extendObservable, observable } from "mobx";
import queryString from "query-string";
import validate from "validate.js";
import { RouteConstants } from "../../constants/RouteConstants";

const DEFAULT_USER = {
  firstName: "",
  lastName: "",
  department: {
    departmentId: -1,
    departmentType: {}
  },
  role: {
    roleId: -1
  },
  email: "",
  username: "",
  statsId: "",
  active: true,
  season: new Date().getFullYear(),
  org: {},
  userAllotments: [],
  allStar: false
};

class UserEditStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      allotmentYear: new Date().getFullYear(),
      departments: [],
      user: observable.map().replace(DEFAULT_USER),
      userId: -1,
      season: -1,
      displayDeleteUserModal: false,
      isSaving: false
    };

    extendObservable(this, {
      allotmentYear: this.defaults["allotmentYear"],
      departments: this.defaults["departments"],
      user: this.defaults["user"],
      userId: this.defaults["userId"],
      season: this.defaults["season"],
      displayDeleteUserModal: this.defaults["displayDeleteUserModal"],
      isSaving: this.defaults["isSaving"],
      setAllotmentYear: action(event => {
        this.allotmentYear = event.value;
      }),
      setDepartments: action(values => {
        this.departments = values;
      }),
      setUserDepartment: action(value => {
        let department = this.departments.find(d => {
          return d.departmentId === value.value;
        });
        if (department) {
          this.user.set("department", department);
          this.currentUser.userOrgInfo[this.commonStore.currentOrgId].department = department;
          if (this.isNewUser) {
            this.user.set("userAllotments", this.convertAllotments(department.departmentAllotment));
          }
        }
      }),
      setUserIsActive: action(value => {
        this.user.set("active", value);
        if (!!this.user.get("statsId")) {
          this.user.set("manuallyManaged", !!value);
        }
      }),
      setUserRole: action(value => {
        let ids = value.map(v => v.value);
        let roles = this.commonStore.roles.filter(r => {
          return ids.includes(r.roleId);
        });
        if (roles) {
          this.user.set("roles", roles);
        }
      }),
      setUser: action(value => {
        if (value != null) {
          this.user.replace(value);
        }
      }),
      updateUserInfo: action((key, value) => {
        this.user.set(key, value);
      }),
      saveUser: action(() => {
        if (this.isNewUser) {
          this.isSaving = true;
          this.compTixApi
            .saveNewUser(this.currentUser)
            .then(data => {
              if (data) {
                let to = this.season ? "/admin/users?season=" + this.season : "/admin/users";
                this.resetUserEditStore();
                this.routerStore.history.push(to);
              }
            })
            .catch(error => {
              this.isSaving = false;
            });
        } else {
          this.isSaving = true;
          this.currentUser.userAllotments = this.currentUser.userOrgInfo[this.commonStore.currentOrgId].userAllotments
            .filter(a => a.season && a.season.year === this.allotmentYear)
            .sort((a, b) => a.season.seasonType.seasonTypeId - b.season.seasonType.seasonTypeId);
          this.compTixApi
            .updateUser(this.currentUser)
            .then(data => {
              if (data) {
                let to = this.season ? "/admin/users?season=" + this.season : "/admin/users";
                this.resetUserEditStore();
                this.routerStore.history.push(to);
              }
            })
            .catch(error => {
              this.isSaving = false;
            });
        }
      }),
      updateAllotment: action((allotment, key, value) => {
        if (value < 0) {
          return;
        }
        allotment[key] = value;
      }),
      cancel: action(() => {
        let to = this.season ? "/admin/users?season=" + this.season.seasonId : "/admin/users";
        this.resetUserEditStore();
        this.routerStore.history.push(to);
      }),
      updateFromUrlParams: action(search => {
        const pathParams = this.routerStore.getPathParams(RouteConstants.USER_EDIT);
        this.userId = pathParams["userId"] * 1 || this.defaults["id"];
        const queryParams = queryString.parse(search);
        this.season = queryParams["season"] * 1 || this.defaults["season"];
      }),
      resetUser: action(() => {
        this.user.clear();
        this.user.replace(DEFAULT_USER);
        this.userId = this.defaults["userId"];
      }),
      resetUserEditStore: action(() => {
        this.allotmentYear = this.defaults["allotmentYear"];
        this.resetUser();
        this.season = this.defaults["season"];
        this.displayDeleteUserModal = this.defaults["displayDeleteUserModal"];
        this.isSaving = this.defaults["isSaving"];
      }),
      showDeleteUserModal: action(() => {
        this.displayDeleteUserModal = true;
      }),
      hideDeleteUserModal: action(() => {
        this.displayDeleteUserModal = false;
      }),
      setOrg: action(selectedOrg => {
        let matchingOrgs = this.commonStore.orgs.filter(org => org.orgId === selectedOrg.value);
        if (matchingOrgs.length) {
          if (authStore.isGlobal && !this.currentUser.statsId) {
            this.user.set("org", matchingOrgs[0]);
          }
        }
      })
    });

    autorun(() => {
      if (this.routerStore.isUserEditTab) {
        this.updateFromUrlParams(this.routerStore.location.search);
        if (this.isNewUser) {
          this.resetUser();
          this.user.set("org", this.commonStore.currentOrg);
        } else {
          this.getUser(this.userId);
        }
      } else {
        this.resetUserEditStore();
      }
    });

    autorun(() => {
      if (this.commonStore.currentOrgId && this.commonStore.currentOrgId !== -1) {
        if (this.routerStore.isUserEditTab) {
          this.getDepartments(this.commonStore.currentOrgId);
          if (this.isNewUser) {
            this.user.set("org", this.commonStore.currentOrg);
          }
        }
      }
    });

    autorun(() => {
      if (this.routerStore.isUserEditTab) {
        let season = null;
        if (this.commonStore.userSeason) {
          season = this.commonStore.seasons.find(s => s.seasonId === this.commonStore.userSeason.seasonId);
        } else {
          this.updateFromUrlParams(this.routerStore.location.search);
          season = this.commonStore.seasons.find(s => s.seasonId === this.season);
        }
        this.season = season;
        this.allotmentYear = this.season?.year;
      }
    });
  }

  get userAllotmentsForSelectedSeason() {
    if (
      this.currentUser &&
      this.currentUser.userOrgInfo &&
      this.currentUser.userOrgInfo[this.commonStore.currentOrgId]
    ) {
      return this.currentUser.userOrgInfo[this.commonStore.currentOrgId].userAllotments
        .filter(a => a.season && a.season.year === this.allotmentYear)
        .sort((a, b) => a.season.seasonType.seasonTypeId - b.season.seasonType.seasonTypeId);
    }
    return [];
  }

  get currentUser() {
    return this.user.toJSON();
  }

  get departmentsDropdown() {
    return this.departments.map(d => ({
      label: d.departmentType.departmentName,
      value: d.departmentId
    }));
  }

  get departmentsDropdownValue() {
    if (!this.currentUser) {
      return null;
    } else {
      return this.departmentsDropdown.find(
        d =>
          d.value === this.currentUser.department.departmentId ||
          (this.currentUser.userOrgInfo &&
            d.value === this.currentUser.userOrgInfo[this.commonStore.currentOrgId].department.departmentId)
      );
    }
  }

  get userRoleDropdownValues() {
    if (!this.currentUser || !this.currentUser.roles) {
      return [];
    } else {
      let ids = this.currentUser.roles.map(r => r.roleId);
      let filter = this.commonStore.rolesDropdownList.filter(d => ids.includes(d.value));
      return filter;
    }
  }

  get userRoleDropdownOptions() {
    if (!this.currentUser) {
      return this.commonStore.rolesDropdownList;
    } else if (this.currentUser && this.authStore.isGlobal) {
      return this.commonStore.rolesDropdownList;
    } else {
      return this.commonStore.rolesDropdownList.filter(d => !d.label.toLowerCase().includes("global"));
    }
  }

  get isNewUser() {
    return this.userId === this.defaults["userId"];
  }

  get isValidSave() {
    return (
      this.currentUser.firstName.trim() &&
      this.currentUser.lastName.trim() &&
      this.currentUser.department.departmentId !== -1 &&
      this.currentUser.roles &&
      this.currentUser.roles.length > 0 &&
      (this.currentUser.email.trim() ? this.validateEmail(this.currentUser.email) : true) &&
      this.validateStatsId(this.currentUser.statsId) &&
      this.validateUsername(this.currentUser.username, this.currentUser.userId)
    );
  }

  get currentOrgValue() {
    return { value: this.currentUser.org.id, label: this.currentUser.org.orgCode };
  }

  get canEditRoles() {
    if (this.currentUser.roles) {
      return this.authStore.isGlobal || !this.currentUser.roles.some(d => d.roleName.toLowerCase().includes("global"));
    } else {
      return true;
    }
  }

  convertAllotments(allotments) {
    return allotments.map(a => {
      return {
        season: a.season,
        departmentId: a.departmentId,
        awayFamilyAllotment: a.awayFamilyAllotment,
        awayFriendsAllotment: a.awayFriendsAllotment,
        homeFamilyAllotment: a.homeFamilyAllotment,
        homeFriendsAllotment: a.homeFriendsAllotment
      };
    });
  }

  validateEmail(email) {
    return !validate({ from: email }, { from: { email: true } });
  }

  validateStatsId(statsId) {
    return statsId == null || statsId === "" || Number.isInteger(parseInt(statsId));
  }

  validateUsername(username, userId) {
    if (!userId) {
      return true;
    }
    let lowerCaseAndNumbers = /^([a-z0-9]+)$/;
    return username && username.length > 0 && lowerCaseAndNumbers.test(username);
  }

  getDepartments = orgId => {
    this.compTixApi.getDepartmentsByOrg(orgId).then(data => {
      this.setDepartments(data);
    });
  };

  getUser = userId => {
    if (userId !== this.defaults["userId"]) {
      this.compTixApi.getUserById(this.userId).then(data => {
        this.setUser(data);
        if (data.statsId) {
          this.compTixApi.getPersonInfoByStatsId(data.statsId).then(personInfo => {
            if (personInfo != null) {
              data.nickName = personInfo.nickName;
              this.setUser(data);
            }
          });
        }
      });
    }
  };

  deleteUser = () => {
    this.compTixApi.deleteUser(this.userId).then(data => {
      this.resetUserEditStore();
      this.cancel();
    });
  };
}

decorate(UserEditStore, {
  currentUser: computed,
  userAllotmentsForSelectedSeason: computed,
  departmentsDropdown: computed,
  departmentsDropdownValue: computed,
  userRoleDropdownValues: computed,
  userRoleDropdownOptions: computed,
  isNewUser: computed,
  isValidSave: computed,
  currentOrgValue: computed,
  canEditRoles: computed
});

export default UserEditStore;
