import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { BestSelect, Icon, Theme } from "best-common-react";
import { inject, observer } from "mobx-react";
import ExportSettingsTableSelect from "./ExportSettingTableSelect";
import _ from "lodash";

const Row = styled.tr`
  width: 100%
  background-color: white;
  display: table;
`;

const Styles = {
  placeholder: styles => ({
    ...styles,
    fontStyle: "italic",
    fontColor: Theme["grey"]
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: Theme.link["color"],
    "&:hover": {
      color: Theme.link["hover"],
      backgroundColor: Theme["grey-three"]
    }
  }),
  menu: styles => ({
    ...styles,
    zIndex: 2000
  }),
  menuPortal: styles => ({
    ...styles,
    zIndex: 2050
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontWeight: data.value.displayStyleName.includes("Bold") ? "bold" : "300"
    };
  },
  singleValue: (styles, { data }) => ({
    ...styles,
    fontWeight: data.value.displayStyleName.includes("Bold") ? "bold" : "300",
    fontSize: data.value.displayStyleName.includes("Uppercase Bold") ? "12px" : "14px"
  })
};

const ExportSettingsTableRow = ({ type, field, index, displayLabelColumn, rootStore }) => {
  const { exportSettingsStore } = rootStore;
  let available = [
    { label: field.reportMapping.reportField.reportFieldName, value: field.reportMappings },
    ...exportSettingsStore[type + "DropdownFields"]
  ];

  let label = exportSettingsStore[type + "Selected"][index].displayStyle.displayStyleName;
  label = label.includes("Upper") ? _.toUpper(label) : label;

  let selectedStyle = {
    value: exportSettingsStore[type + "Selected"][index].displayStyle,
    label: label
  };

  return (
    <Draggable draggableId={field.reportMapping.reportFieldReportTypeId} index={index}>
      {provided => (
        <Row {...provided.draggableProps} ref={provided.innerRef}>
          <td className="p-1">
            <div className="d-flex flex-row">
              <div className="col-1 m-auto">
                <Icon iconName="fa-grip-horizontal" {...provided.dragHandleProps} />
              </div>
              <div className="col-1 m-auto">
                <Icon iconName="fa-trash-alt" onClick={() => exportSettingsStore.deleteSelected(type, index)} />
              </div>
              <div className={displayLabelColumn ? "col-5 pl-1" : "col-10 pl-1 pr-0"}>
                <ExportSettingsTableSelect
                  type={type}
                  available={available}
                  index={index}
                  onUpdate={exportSettingsStore.updateSelected}
                />
              </div>
              {displayLabelColumn ? (
                <div className="col-5 p-0">
                  <BestSelect
                    onChange={selected => exportSettingsStore.updateSelectedStyle(type, index, selected)}
                    value={selectedStyle}
                    options={exportSettingsStore.displayStyleDropdownFields}
                    styles={Styles}
                  />
                </div>
              ) : null}
            </div>
          </td>
        </Row>
      )}
    </Draggable>
  );
};

export default inject("rootStore")(observer(ExportSettingsTableRow));
