import React from "react";
import { Accordion } from "best-common-react";

class AccordionExample extends React.Component {
  render() {
    return (
      <div className="mt-4">
        <h4>Accordion Example</h4>
        <Accordion id="1234" open={false}>
          <Accordion.Header isToggle={true}>Title</Accordion.Header>
          <Accordion.Body>body</Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

export default AccordionExample;
