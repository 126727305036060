import { action, extendObservable } from "mobx";

class LoadingStore {
  constructor() {
    this.defaults = {
      loading: false
    };

    extendObservable(this, {
      loading: this.defaults["loading"],
      setLoading: action(value => {
        this.loading = value;
      })
    });
  }
}

export default LoadingStore;
