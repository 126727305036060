import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import DepartmentFilter from "../../components/department/admin/DepartmentFilters";
import DepartmentTable from "../../components/department/admin/DepartmentTable";
import AdminProtected from "../../components/protected/AdminProtected";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";

class DepartmentAdminContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle />
        <CardContainer>
          <CardHeader>Departments</CardHeader>
          <CardBody>
            <DepartmentFilter />
            <DepartmentTable />
          </CardBody>
          <CardFooter />
        </CardContainer>
      </div>
    );
  }
}

export default AdminProtected(DepartmentAdminContainer);
