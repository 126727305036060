import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

class PermissionDisplay extends React.Component {
  displayed(role, authStore) {
    if (!!role) {
      switch (role.toUpperCase()) {
        case "BASIC":
          return true;
        case "ADMIN":
          return authStore.isAdmin;
        case "GLOBAL":
          return authStore.isGlobal;
        default:
          return false;
      }
    }
    return false;
  }

  render() {
    let { authStore } = this.props.rootStore;
    let displayed = this.displayed(this.props.permission, authStore);
    return <React.Fragment>{displayed ? <React.Fragment>{this.props.children}</React.Fragment> : null}</React.Fragment>;
  }
}

PermissionDisplay.propTypes = {
  children: PropTypes.any.isRequired,
  permission: PropTypes.string.isRequired
};

export default inject("rootStore")(observer(PermissionDisplay));
