import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, StickyFooterButtons } from "best-common-react";

class UserAdminFooter extends React.Component {
  render() {
    const { userAdminStore, userBulkAllotmentStore, commonStore } = this.props.rootStore;
    let updateDisabled = userAdminStore.userIdsToUpdateAllotment.length === 0;

    return !commonStore.currentOrg.allStarTeam ? (
      <StickyFooterButtons>
        <LightButton onClick={userAdminStore.createNewUser} id="new-user">
          Create New User
        </LightButton>
        <LightButton disabled={updateDisabled} onClick={userBulkAllotmentStore.onOpen}>
          Update Ticket Allotment
        </LightButton>
      </StickyFooterButtons>
    ) : null;
  }
}

export default inject("rootStore")(observer(UserAdminFooter));
