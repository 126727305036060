import React from "react";
import { inject, observer } from "mobx-react";
import { StickyFooterButtons, PrimaryButton, LightButton } from "best-common-react";

class VenueBulkOverrideFooter extends React.Component {
  render() {
    const { venueBulkOverrideStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <PrimaryButton disabled={!venueBulkOverrideStore.isValidSave} onClick={venueBulkOverrideStore.saveOverrides}>
          Save Overrides
        </PrimaryButton>
        <LightButton onClick={venueBulkOverrideStore.onClose}>Close</LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(VenueBulkOverrideFooter));
