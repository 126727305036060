import React from "react";
import { PrimaryOutlineButton } from "best-common-react";
import styled from "styled-components";
import { inject } from "mobx-react";

const Button = styled(PrimaryOutlineButton)`
  &&& {
    padding: 0.1rem 0.75rem;
    background: white;
    &:hover {
      background: #1066c3;
    }
  }
`;

class AssignFormatter extends React.Component {
  render() {
    const value = this.props.value;
    const { assignDigitalTicketsStore, revokeDigitalTicketsStore } = this.props.rootStore;
    let label =
      value.digitalTicketDelivery == null || value.digitalTicketDelivery?.status.toLowerCase() === "failed"
        ? "Assign"
        : "Revoke";
    if (value.digitalTicketDelivery == null) {
      return (
        <div className="p-2">
          <Button
            onClick={() => {
              assignDigitalTicketsStore.setRequestId(value.requestId);
              assignDigitalTicketsStore.setShowAssignTicketsModal(true);
            }}
          >
            {label}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="p-2">
          <Button
            onClick={() => {
              revokeDigitalTicketsStore.setRequestId(value.requestId);
              revokeDigitalTicketsStore.setForwardId(value.forwardId);
              revokeDigitalTicketsStore.setGamePk(value.gamePk);
              revokeDigitalTicketsStore.setAllTickets(value.digitalTicketDelivery?.locations);
              revokeDigitalTicketsStore.setShowRevokeTicketsModal(true);
              revokeDigitalTicketsStore.setDigitalTicketDelivery(value.digitalTicketDelivery);
            }}
          >
            {label}
          </Button>
        </div>
      );
    }
  }
}

export default inject("rootStore")(AssignFormatter);
