const getSeatAbbreviations = tickets => {
  const sectionMap = new Map();
  if (!tickets || !tickets.locations) {
    return "";
  }
  for (const ticket of tickets.locations.toJSON()) {
    let section = ticket.section;
    if (sectionMap.get(section) == null) {
      sectionMap.set(section, new Map());
    }
    let row = ticket.row;
    if (sectionMap.get(section).get(row) == null) {
      sectionMap.get(section).set(row, []);
    }
    let seat = ticket.seat;
    sectionMap
      .get(section)
      .get(row)
      .push(seat);
  }

  const sectionGroups = [];
  for (const section of sectionMap.entries()) {
    let rows = sectionMap.get(section[0]);
    for (const row of rows.entries()) {
      let str = section[0];
      str += ", R";
      str += row[0];
      str += ", ";
      let seats = sectionMap
        .get(section[0])
        .get(row[0])
        .sort(function(a, b) {
          return a - b;
        });
      let first = seats[0];
      str += first;
      if (seats.length > 1) {
        let last = seats[seats.length - 1];
        str += "-";
        str += last;
      }

      sectionGroups.push(str);
    }
  }
  return sectionGroups.join("; ");
};

export { getSeatAbbreviations };
