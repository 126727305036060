import React from "react";
import {
  BestDropdown,
  BestLabel,
  DateRangePickerWithLabel,
  SearchBox,
  DesktopTabletView,
  MobileView
} from "best-common-react";
import { inject, observer } from "mobx-react";
import TotalsBoxMobile from "./TotalsBoxMobile";
import TotalsBox from "./TotalsBox";

const UserReservationsReportFilters = ({ rootStore }) => {
  const { userReservationsReportStore, commonStore, authStore } = rootStore;
  const { totals, homeAwayBreakdown } = userReservationsReportStore;
  const isUmpire = commonStore.currentOrgId === 31;
  return (
    <div className="row m-0 mb-3">
      <div className={isUmpire ? "col-lg-6 p-0" : "col-lg-3 p-0"}>
        <BestDropdown
          id="user"
          title="User"
          options={userReservationsReportStore.userDropdown}
          value={userReservationsReportStore.selectedUserDisplay}
          onChange={userReservationsReportStore.setSelectedUser}
          isDisabled={!authStore.isAdmin}
        />
      </div>
      <div className={isUmpire ? "col-lg-6 p-0 pl-lg-3 mt-1 pb-lg-0" : "col-lg-5 col-xl-4 p-0 pl-lg-3 mt-1"}>
        <DateRangePickerWithLabel
          value={commonStore.dateRangeFilter}
          onStartChange={commonStore.setDateRangeFilterStart}
          onEndChange={commonStore.setDateRangeFilterEnd}
          label="Event Date"
        />
      </div>
      {isUmpire ? (
        <div id="convenienceDropdown" className="col-lg-6 p-0 mt-1">
          <BestDropdown
            id="convenienceFilter"
            title="Category"
            options={commonStore.categoryTypesDropdown}
            value={userReservationsReportStore.selectedCategory}
            onChange={userReservationsReportStore.setSelectedCategory}
            isDisabled={!authStore.isAdmin}
          />
        </div>
      ) : null}
      <div className={isUmpire ? "col-lg-6 p-0 pl-lg-3 mt-1" : "col-lg-4 col-xl-5 p-0 pl-lg-3 mt-1"}>
        <BestLabel>Search Reservations</BestLabel>
        <SearchBox
          id="reservation-search"
          placeholder="begin typing to filter reservations"
          value={userReservationsReportStore.searchTerm}
          onChange={search => userReservationsReportStore.setSearchTerm(search.target.value)}
        />
      </div>

      <DesktopTabletView>
        <TotalsBox
          home={homeAwayBreakdown.homeGames}
          away={homeAwayBreakdown.awayGames}
          reservations={totals.reservations}
          tickets={totals.tickets}
          value={totals.value}
        />
      </DesktopTabletView>
      <MobileView>
        <TotalsBoxMobile
          home={homeAwayBreakdown.homeGames}
          away={homeAwayBreakdown.awayGames}
          reservations={totals.reservations}
          tickets={totals.tickets}
          value={totals.value}
        />
      </MobileView>
    </div>
  );
};

export default inject("rootStore")(observer(UserReservationsReportFilters));
