import React from "react";
import { inject, observer } from "mobx-react";
import {
  AttributeHeader,
  BestSelect,
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import styled from "styled-components";

const CheckBoxContent = styled.div`
  bottom: 0;

  @media (min-width: 768px) {
    position: absolute;
  }
  @media (max-width: 767px) {
    padding-top: 1rem;
  }
`;

class VenueImportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  editImportSettings(settingObj) {
    this.setState(settingObj);
  }

  initialState() {
    return {
      pickupLocations: false,
      ticketInventory: false
    };
  }

  render() {
    const { venueEditStore } = this.props.rootStore;
    return (
      <Modal
        show={venueEditStore.showVenueImportModal}
        onClose={() => {
          this.editImportSettings(this.initialState());
          venueEditStore.setShowVenueImportModal(false);
        }}
        size="lg"
      >
        <ModalHeaderWithTitleAndClose
          title="Import From Previous Season"
          close={() => {
            this.editImportSettings(this.initialState());
            venueEditStore.setShowVenueImportModal(false);
          }}
        />
        <ModalBody>
          <div>
            <div className="row">
              <div className="col">Settings from the selected season will be added to any existing settings</div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-12">
                    <AttributeHeader>Season</AttributeHeader>
                  </div>
                </div>
                <BestSelect
                  id="venue-season"
                  defaultValue={venueEditStore.importSeasonOptions[0]}
                  options={venueEditStore.importSeasonOptions}
                  onChange={v => venueEditStore.setSelectedImportSeason(v)}
                />
              </div>
              <div className="col-12 col-md-6" style={{ paddingLeft: "30px" }}>
                <CheckBoxContent>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="pickupLocations"
                    onChange={() => this.editImportSettings({ pickupLocations: !this.state.pickupLocations })}
                  />
                  <label className="form-check-label" htmlFor="pickupLocations">
                    Pickup Locations
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="ticketInventory"
                    onChange={() => this.editImportSettings({ ticketInventory: !this.state.ticketInventory })}
                  />
                  <label className="form-check-label" htmlFor="ticketInventory">
                    Ticket Inventory
                  </label>
                </CheckBoxContent>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton
              disabled={
                !venueEditStore.importSeasonOptions.length ||
                !(this.state.ticketInventory || this.state.pickupLocations)
              }
              onClick={() => {
                venueEditStore.updateImportedVenue(this.state);
                this.editImportSettings(this.initialState());
              }}
            >
              Import
            </PrimaryButton>
            <LightButton
              onClick={() => {
                this.editImportSettings(this.initialState());
                venueEditStore.setShowVenueImportModal(false);
              }}
            >
              Cancel
            </LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(VenueImportModal));
