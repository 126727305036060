import { action, autorun, computed, extendObservable, decorate } from "mobx";
import queryString from "query-string";

class DepartmentEditStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      departmentId: -1,
      department: {
        departmentAllotment: [],
        departmentAccess: []
      },
      allotmentYear: new Date().getFullYear(),
      seasonId: -1,
      isSaving: false
    };

    extendObservable(this, {
      departmentId: this.defaults["departmentId"],
      seasonId: this.defaults["seasonId"],
      department: this.defaults["department"],
      allotmentYear: this.defaults["allotmentYear"],
      isSaving: this.defaults["isSaving"],
      setAllotmentYear: action(event => {
        this.allotmentYear = event.value;
      }),
      setDepartment: action(value => {
        this.department = value;
      }),
      updateAllotment: action((selectedAllotment, key, value) => {
        if (value < 0) {
          return;
        }
        selectedAllotment[key] = value;
      }),
      updateAccess: action(access => {
        let currentIndex = this.department.departmentAccess.findIndex(
          a => a.departmentAccessId === access.departmentAccessId
        );

        this.department.departmentAccess[currentIndex] = access;
      }),
      updateFromUrlParams: action(search => {
        const params = queryString.parse(search);
        this.departmentId = params["id"] * 1 || this.defaults["departmentId"];
        this.allotmentYear = params["year"] * 1 || this.defaults["allotmentYear"];
        this.seasonId = params["seasonId"] * 1 || this.defaults["seasonId"];
      }),
      resetStore: action(() => {
        this.departmentId = this.defaults["departmentId"];
        this.department = this.defaults["department"];
        this.allotmentYear = this.defaults["allotmentYear"];
        this.isSaving = this.defaults["isSaving"];
      }),
      save: action(() => {
        this.isSaving = true;
        this.compTixApi
          .saveDepartment(this.department)
          .then(data => {
            if (data) {
              this.resetStore();
              this.routerStore.history.push("/admin/departments?seasonId=" + this.seasonId);
            }
          })
          .catch(error => {
            this.isSaving = false;
          });
      }),
      cancel: action(() => {
        this.resetStore();
        this.routerStore.history.push("/admin/departments?seasonId=" + this.seasonId);
      })
    });

    autorun(() => {
      if (this.routerStore.isDepartmentEditTab) {
        this.updateFromUrlParams(this.routerStore.location.search);
        this.getDepartment(this.departmentId);
      } else {
        this.resetStore();
      }
    });
  }

  get departmentAccessForSelectedSeason() {
    return this.department.departmentAccess
      .filter(a => !!a.season && a.season.year === this.allotmentYear)
      .sort((a, b) => {
        var typeA = a.season.seasonType.seasonTypeId;
        var typeB = b.season.seasonType.seasonTypeId;

        if (typeA >= typeB) {
          if (typeA > typeB) {
            return 1;
          }
          return 0;
        } else {
          return -1;
        }
      });
  }

  get allotmentsForSelectedSeason() {
    return this.department.departmentAllotment
      .filter(a => a.season && a.season.year === this.allotmentYear)
      .sort((a, b) => a.season.seasonId - b.season.seasonId);
  }

  get isValidSave() {
    return this.isValidAllotments() && this.isValidAccess();
  }

  getDepartment = departmentId => {
    this.compTixApi.getDepartment(departmentId).then(data => {
      this.setDepartment(data);
    });
  };

  isValidAllotments = () => {
    let anyFalses = this.allotmentsForSelectedSeason
      .map(a => {
        return (
          a.awayFamilyAllotment >= 0 &&
          a.awayFriendsAllotment >= 0 &&
          a.homeFamilyAllotment >= 0 &&
          a.homeFriendsAllotment >= 0
        );
      })
      .filter(a => !a);
    return anyFalses.length === 0;
  };

  isValidAccess = () => {
    let anyFalses = this.departmentAccessForSelectedSeason
      .map(a => {
        return (!!a.cutoffDate || !!a.cutoffMinutesBefore) && (!!a.availableDate || !!a.availableMinutesBefore);
      })
      .filter(a => !a);
    return anyFalses.length === 0;
  };
}

decorate(DepartmentEditStore, {
  departmentAccessForSelectedSeason: computed,
  allotmentsForSelectedSeason: computed,
  isValidSave: computed
});

export default DepartmentEditStore;
