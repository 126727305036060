import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StrikeOut = styled.span.attrs({ className: "ml-2" })`
  text-decoration: line-through;
  color: ${props => props.theme["grey-two"]};
  font-weight: 300;
`;

class OverrideFormatter extends React.Component {
  render() {
    if (!this.props.value) {
      return <div />;
    }
    if (this.props.value.isPrice && !isNaN(this.props.value.override)) {
      return (
        <div>
          {this.props.value.override?.showOverride || this.props.value.override != null ? (
            <span>
              <span>${this.props.value.override.toFixed(2)}</span>{" "}
              <StrikeOut>${this.props.value.value.toFixed(2)}</StrikeOut>
            </span>
          ) : (
            <span>${this.props.value.value.toFixed(2)}</span>
          )}
        </div>
      );
    } else {
      if (this.props.value.override && this.props.value.override.showOverride) {
        return (
          <span>
            <span>{this.props.value.override.value}</span> <StrikeOut>{this.props.value.value}</StrikeOut>
          </span>
        );
      }
      if (this.props.value.override != null && this.props.value.override.value != null) {
        return (
          <span>
            <span>{this.props.value.override.value}</span> <StrikeOut>{this.props.value.value}</StrikeOut>
          </span>
        );
      }
      if (Number.isInteger(this.props.value.value) && Number.isInteger(this.props.value.override)) {
        return (
          <span>
            <span>{this.props.value.override}</span> <StrikeOut>{this.props.value.value}</StrikeOut>
          </span>
        );
      }
      return this.props.value.value;
    }
  }
}

OverrideFormatter.propTypes = {
  valueAndOverride: PropTypes.object
};

export default OverrideFormatter;
