import React from "react";
import DatePickerExample from "./DatePickerExample";
import DateTimePickerExample from "./DateTimePickerExample";
import UserResetPasswordForm from "../user/admin/UserResetPasswordForm";
import {
  BestLabel,
  BestSelect,
  BestTitle,
  FormColumn4Wide,
  Input,
  RequiredInputWithLabel,
  TextArea
} from "best-common-react";

const options1 = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" }
];

const options2 = [
  { value: "pistachio", label: "Pistachio" },
  { value: "rocky-road", label: "Rocky Road" },
  { value: "cookies-and-cream", label: "Cookies and Cream" }
];

class FormExample extends React.Component {
  // note: only using state for example purposes.  for actual implementation, use a store
  state = {
    selectedOption1: null,
    selectedOption2: null
  };
  handleChange1 = selectedOption1 => {
    this.setState({ selectedOption1 });
  };

  handleChange2 = selectedOption2 => {
    this.setState({ selectedOption2 });
  };

  render() {
    const { selectedOption1, selectedOption2 } = this.state;

    return (
      <div className="mt-4">
        <h4>Form Example</h4>
        <BestTitle>Form Subsection 1</BestTitle>
        <div className="row">
          <FormColumn4Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="field1" label="Text Field 1">
                <Input />
              </RequiredInputWithLabel>
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="field2" label="Text Field 2">
                <Input />
              </RequiredInputWithLabel>
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="field3" label="Date Picker">
                <DatePickerExample />
              </RequiredInputWithLabel>
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <RequiredInputWithLabel name="field4" label="Date Time Picker">
                <DateTimePickerExample />
              </RequiredInputWithLabel>
            </div>
          </FormColumn4Wide>
        </div>
        <BestTitle>Form Subsection 2</BestTitle>
        <div className="row">
          <FormColumn4Wide>
            <div className="form-group">
              <BestLabel name="departments" label="Departments">
                Basic Select
              </BestLabel>
              <BestSelect name="departments" value={selectedOption1} onChange={this.handleChange1} options={options1} />
            </div>
          </FormColumn4Wide>
          <FormColumn4Wide>
            <div className="form-group">
              <BestLabel name="departments" label="Departments">
                Multi-Select
              </BestLabel>
              <BestSelect
                name="departments"
                isMulti
                value={selectedOption2}
                onChange={this.handleChange2}
                options={options2}
              />
            </div>
          </FormColumn4Wide>
        </div>
        <div className="row">
          <div className="col-12">
            <BestLabel name=" textarea" label="Textarea">
              Textarea
            </BestLabel>
            <TextArea />
          </div>
        </div>
        <BestTitle>Form Subsection 3</BestTitle>
        <div className="row">
          <div className="col-md-6">
            <UserResetPasswordForm />
          </div>
        </div>
      </div>
    );
  }
}

export default FormExample;
