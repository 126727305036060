import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import { CardBody, CardContainer, CardHeader } from "best-common-react";
import Protected from "../../components/protected/Protected";
import ContactsFilters from "./ContactsFilters";
import ContactManagementTable from "./ContactManagementTable";
import EditContactModal from "./EditContactModal";
import DeleteContactModal from "./DeleteContactModal";
import ContactManagementFooter from "./ContactManagementFooter";
import NewContactManagementModal from "./NewContactManagementModal";
class ContactManagementContainer extends React.PureComponent {
  render() {
    return (
      <div>
        <TeamTitle disabled={false} />
        <CardContainer>
          <CardHeader>Manage Contacts</CardHeader>
          <CardBody>
            <ContactsFilters />
            <ContactManagementTable />
            <NewContactManagementModal />
            <EditContactModal />
            <DeleteContactModal />
            <ContactManagementFooter />
          </CardBody>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(ContactManagementContainer);
