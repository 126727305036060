import React from "react";
import { Accordion, AttributeHeader, Icon } from "best-common-react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

const IconHolder = styled.div.attrs({
  className: "d-flex justify-content-between align-items-center",
  id: "icon-holder"
})``;

const Header = styled(AttributeHeader).attrs({
  id: "attribute-header"
})`
  margin-bottom: -0.25rem;
`;

class MessagesAccordion extends React.Component {
  render() {
    const { authStore } = this.props.rootStore;
    let editButtons;
    if (authStore.isGlobal) {
      editButtons = (
        <Accordion.Header>
          <IconHolder>
            <Icon
              iconName="fa-pencil-alt"
              className="fa-lg"
              id="edit-icon"
              onClick={this.props.data.editAndDelete.onEditClick}
            />
            <Icon
              id="delete-icon"
              iconName="fa-trash-alt"
              className="fa-lg"
              onClick={this.props.data.editAndDelete.onDeleteClick}
            />
          </IconHolder>
        </Accordion.Header>
      );
    } else {
      editButtons = null;
    }

    return (
      <div>
        <Accordion>
          {editButtons}
          <Accordion.Header isToggle={true}>
            {this.props.data.message.length < 50
              ? this.props.data.message
              : this.props.data.message.substr(0, 47) + "..."}
          </Accordion.Header>
          <Accordion.Body>
            <Header>Departments</Header>
            <div>{this.props.data.departmentNames}</div>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

export default inject("rootStore")(observer(MessagesAccordion));
