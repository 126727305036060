import React from "react";
import { inject, observer } from "mobx-react";
import { DataTable } from "best-common-react";

const Columns = [
  {
    name: "Request Date",
    key: "requestDate",
    sortable: true,
    width: 185
  },
  {
    name: "Forward Date",
    key: "forwardDate",
    sortable: true,
    width: 185
  },
  {
    name: "Request ID",
    key: "requestId",
    sortable: true,
    width: 125
  },
  {
    name: "Forward ID",
    key: "forwardID",
    sortable: true
  },
  {
    name: "Section",
    key: "section",
    sortable: true,
    width: 100
  },
  {
    name: "Org",
    key: "orgCode",
    sortable: true,
    width: 75
  },
  {
    name: "Ticket Requester",
    key: "requester",
    sortable: true
  },
  {
    name: "Username",
    key: "user",
    sortable: true
  },
  {
    name: "Forwarded",
    key: "wasForwarded",
    sortable: true,
    width: 100
  }
];

const AdminSupportBody = ({ rootStore: { adminSupportStore } }) => (
  <div>
    <DataTable
      columns={Columns}
      data={adminSupportStore.currentTickets}
      sortFunc={adminSupportStore.setSortDirection}
      sortColumn={adminSupportStore.sortFilters["key"]}
      sortDirection={adminSupportStore.sortFilters["direction"]}
    />
  </div>
);

export default inject("rootStore")(observer(AdminSupportBody));
