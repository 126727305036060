import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import {
  BestDropdown,
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import TicketAllotmentTable from "../../components/tickets/allotment/TicketAllotmentTable";

const SelectedUsers = styled.span`
  position: absolute;
  bottom: 0;
  right: 1rem;
  font-size: 0.8rem;
  color: ${props => props.theme["dark-grey"]};
  font-style: italic;
`;

class UserUpdateTicketAllotmentModal extends React.Component {
  render() {
    const { userBulkAllotmentStore, userAdminStore } = this.props.rootStore;
    let selectedUserCount = userAdminStore.userIdsToUpdateAllotment.length;
    return (
      <Modal show={userBulkAllotmentStore.showModal} onClose={userBulkAllotmentStore.cancelUpdateAllotment} size="lg">
        <ModalHeaderWithTitleAndClose
          title="Update Ticket Allotment"
          close={userBulkAllotmentStore.cancelUpdateAllotment}
        />
        <ModalBody>
          <div className="row pb-2">
            <div className="col-6">
              <BestDropdown
                title="Year"
                options={userBulkAllotmentStore.years.toJSON()}
                value={userBulkAllotmentStore.selectedYear}
                onChange={userBulkAllotmentStore.onYearChange}
              />
            </div>
            <div className="col-6">
              <SelectedUsers>{selectedUserCount} users selected</SelectedUsers>
            </div>
          </div>
          <TicketAllotmentTable
            allotments={userBulkAllotmentStore.allotments}
            onAllotmentChange={userBulkAllotmentStore.onAllotmentChange}
            isRequired={false}
          />
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton
              className="mr-2"
              disabled={userBulkAllotmentStore.isSaving}
              onClick={userBulkAllotmentStore.saveAllotments}
            >
              Save
            </PrimaryButton>
            <LightButton onClick={userBulkAllotmentStore.cancelUpdateAllotment}>Cancel</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(UserUpdateTicketAllotmentModal));
