import { action, autorun, computed, decorate, extendObservable } from "mobx";
import _ from "lodash";
import Moment from "moment";
import { RouteConstants } from "../../constants/RouteConstants";

class ReportEventReservationsStore {
  constructor(authStore, commonStore, compTixApi, routerStore, loadingStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.loadingStore = loadingStore;

    this.defaults = {
      events: [],
      sortFilters: {
        direction: "ASC",
        key: "date"
      }
    };

    extendObservable(this, {
      events: this.defaults["events"],
      sortFilters: this.defaults["sortFilters"],
      setEvents: action(values => {
        this.events = values;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      exportSettings: action(() => {
        this.routerStore.history.push(RouteConstants.EXPORT_SETTINGS);
      })
    });

    autorun(() => {
      if (this.routerStore.isReportEventRegistrationTab) {
        this.getEvents();
      }
    });
  }

  get currentEvents() {
    let flatEvents = this.events.map(event => {
      let home = event.game.teams.home.team;
      let away = event.game.teams.away.team;
      let game =
        event.game.gameType === "Z" || event.game.gameType === "O" || event.game.gameType === "A"
          ? event.game.description
          : away.abbreviation + " @ " + home.abbreviation;
      let tzId = event.game.venue && event.game.venue.timeZone ? event.game.venue.timeZone.id : -1;
      let gameDate = event.game
        ? { gameDate: event.game.gameDate, gamePk: event.game.gamePk, tzId: tzId, tbd: event.game.status.startTimeTBD }
        : {};
      return {
        date: gameDate,
        gameStr: { gameStr: game, gamePk: event.game.gamePk },
        game: event.game,
        reservations: event.reservations,
        ticketsReserved: event.ticketsReserved,
        venue: event.game.venue.name,
        toPath: "reservations"
      };
    });
    return this.sort(flatEvents, this.sortFilters);
  }

  getEvents = () => {
    let start = Moment(this.commonStore.dateRangeFilter.start);
    let end = Moment(this.commonStore.dateRangeFilter.end);
    if (start.isBefore(end) && this.commonStore.currentOrgId) {
      this.loadingStore.setLoading(true);
      this.compTixApi
        .getReservationsByOrgAndDate(this.commonStore.currentOrgId, start.format("M/DD/YYYY"), end.format("M/DD/YYYY"))
        .then(data => {
          this.setEvents(data);
          this.loadingStore.setLoading(false);
        });
    }
  };

  sort = (users, searchFilters) => {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(users, "name");
    } else if (direction === "ASC") {
      return _.sortBy(users, searchFilters.key);
    } else if (direction === "DESC") {
      return _.sortBy(users, searchFilters.key).reverse();
    } else {
      return users;
    }
  };
}

decorate(ReportEventReservationsStore, {
  currentEvents: computed
});

export default ReportEventReservationsStore;
