import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Table, TableTitle } from "best-common-react";
import ExportSettingsTableHeader from "./ExportSettingsTableHeader";
import ExportSettingsTableBody from "./ExportSettingsTableBody";
import ExportSettingsTableFooter from "./ExportSettingsTableFooter";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

const FixedTable = styled(Table).attrs({ className: "table" })``;

class ExportSettingsTable extends React.Component {
  render() {
    const { type, title, displayLabelColumn = false, rootStore } = this.props;
    const { exportSettingsStore } = rootStore;
    return (
      <DragDropContext
        onDragEnd={result => {
          if (result && result.destination && result.source)
            exportSettingsStore.reorderSelected(type, result.source.index, result.destination.index);
        }}
      >
        <div className="col-xl-6">
          <TableTitle>{title}</TableTitle>
          <div className="col-xl-9 p-0">
            <FixedTable>
              <ExportSettingsTableHeader displayLabelColumn={displayLabelColumn} />
              <Droppable droppableId={type}>
                {provided => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    <ExportSettingsTableBody type={type} displayLabelColumn={displayLabelColumn} />
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </FixedTable>
            <ExportSettingsTableFooter type={type} />
          </div>
        </div>
      </DragDropContext>
    );
  }
}

export default inject("rootStore")(observer(ExportSettingsTable));
