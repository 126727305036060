import { action, extendObservable } from "mobx";
import _ from "lodash";
import { AlertConstants } from "../constants/AlertConstants";

class AlertStore {
  getAlertId() {
    return (
      "_" +
      Math.random()
        .toString(36)
        .substr(2, 9)
    );
  }

  constructor() {
    this.defaults = {
      alerts: []
    };

    extendObservable(this, {
      alerts: this.defaults["alerts"],
      addAlert: action(alert => {
        if (!alert.id) {
          alert.id = this.getAlertId();
        }
        this.alerts.push(alert);
        this.alerts = _.uniqBy(this.alerts, alert => {
          return alert.type + alert.text;
        });
      }),
      removeAlert: action(id => {
        if (id) {
          this.alerts = _.reject(this.alerts, function(a) {
            return a.id === id;
          });
        }
      })
    });
  }

  createResponseAlerts(alerts) {
    alerts.forEach(alert => {
      let type;
      switch (alert.severity) {
        case "SUCCESS":
          type = AlertConstants.TYPES.SUCCESS;
          break;
        case "INFO":
          type = AlertConstants.TYPES.INFO;
          break;
        case "ERROR":
          type = AlertConstants.TYPES.DANGER;
          break;
        case "WARNING":
        default:
          type = AlertConstants.TYPES.WARNING;
          break;
      }
      this.addAlert({
        type: type,
        text: alert.message
      });
    });
  }
}

export default AlertStore;
