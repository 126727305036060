import React from "react";
import _ from "lodash";
import { DataTable, IconFormatter } from "best-common-react";
import HigherOrderComponent from "../../utilities/HigherOrderComponent";

class DataTableExample extends React.Component {
  constructor(props) {
    super(props);
    let temp = [];
    for (let i = 0; i < 1000; i++) {
      temp.push({
        editLink: "/user/edit?id=" + i,
        changePass: "/user/changePass?id=" + i,
        col1: i,
        col3: "Cody, Dan",
        col4: i,
        col5: i,
        col6: i,
        col7: i
      });
    }
    let KeyFormatter = HigherOrderComponent(IconFormatter);
    KeyFormatter.defaultProps = {
      icon: "fa-key",
      id: "key-formatter"
    };
    let PencilFormatter = HigherOrderComponent(IconFormatter);
    PencilFormatter.defaultProps = {
      icon: "fa-pencil-alt",
      link: "/user/edit",
      id: "pencil-formatter"
    };
    const cols = [
      {
        key: "changePass",
        name: "",
        width: 35,
        resizable: false,
        locked: true,
        formatter: KeyFormatter
      },
      {
        key: "editLink",
        name: "",
        width: 35,
        resizable: false,
        locked: true,
        formatter: PencilFormatter
      },
      {
        key: "col3",
        name: "Name",
        locked: true
      },
      { key: "col4", name: "Col 4", sortable: true },
      { key: "col5", name: "Col 5", sortable: true },
      { key: "col6", name: "Col 6", sortable: true },
      { key: "col7", name: "Col 7", sortable: true }
    ];
    this.state = { data: temp, sortedData: temp, cols: cols };
  }

  onGridSort = (sortColumn, sortDirection) => {
    if (sortDirection === "NONE") {
      this.setState({ sortedData: this.state.data });
    } else {
      let data = _.sortBy(this.state.data, sortColumn);
      if (sortDirection === "ASC") {
        this.setState({ sortedData: data });
      } else if (sortDirection === "DESC") {
        this.setState({ sortedData: data.reverse() });
      }
    }
  };

  render() {
    return (
      <div className="mt-4">
        <h4>Data Table Example</h4>
        <DataTable data={this.state.sortedData} columns={this.state.cols} sortFunc={this.onGridSort} height={400} />
      </div>
    );
  }
}

export default DataTableExample;
