import React from "react";
import EventDataCard from "../../events/EventDataCard";
import { inject, observer } from "mobx-react";
import { BestDropdown, BestLabel, BestSelect, MobileView, DesktopTabletView, SearchBox } from "best-common-react";
import LoadingSpinner from "../../loading/LoadingSpinner";
import styled from "styled-components";
import { toJS } from "mobx";
import EventTotalsBox from "../../user/reports/EventTotalsBox";
import EventTotalsBoxMobile from "../../user/reports/EventTotalsBoxMobile";

const AddAll = styled.button`
  text-decoration: none !important;
`;

class EventReservationsFilter extends React.Component {
  componentDidMount() {
    const { eventReservationsStore } = this.props.rootStore;
    eventReservationsStore.setGamePk(this.props.gamePk);
  }

  componentWillUnmount() {
    const { eventReservationsStore } = this.props.rootStore;
    eventReservationsStore.setGamePk(null);
  }

  render() {
    const { eventReservationsStore } = this.props.rootStore;

    if (!eventReservationsStore.game) {
      return <LoadingSpinner size={10} />;
    }

    return (
      <div className="row m-0">
        <div className="col-12 col-md-3 col-xl-3 p-0 pr-md-2 pt-md-2">
          <EventDataCard game={eventReservationsStore.game} showVenue={false} className="p-0" />
        </div>
        <div className="col-12 col-md-2 mt-md-3 mt-2 p-0 p-md-2 pt-md-3">
          <BestDropdown
            id="organization"
            title="Organization"
            options={eventReservationsStore.orgDropdown}
            value={eventReservationsStore.selectedOrg}
            onChange={value => eventReservationsStore.setSelectedOrg(value)}
          />
        </div>
        <div className="col-12 col-md-4 mt-md-3 mt-2 p-0 p-md-2 pt-md-3">
          <div className="row">
            <div className="col py-0">
              <BestLabel htmlFor="add-all">Departments</BestLabel>
              <AddAll
                id="add-all"
                className="btn btn-link btn-sm pr-0 pb-0 justify-content-end float-right"
                onClick={() => eventReservationsStore.addAllDepartments()}
              >
                Add All Departments
              </AddAll>
            </div>
          </div>
          <BestSelect
            isMulti
            name="departments"
            id="eventMessageDepartments"
            options={eventReservationsStore.departmentOptions}
            value={toJS(eventReservationsStore.selectedDepartments)}
            onChange={value => {
              eventReservationsStore.addDepartment(value);
            }}
          />
        </div>
        <div className="col-12 col-md-3 mt-md-3 mt-2 p-0 p-md-2 pt-md-3">
          <BestLabel htmlFor="reservation-search">Search Reservations</BestLabel>
          <SearchBox
            id="reservation-search"
            placeholder="begin typing to filter reservations"
            value={eventReservationsStore.searchTerm}
            onChange={search => eventReservationsStore.setSearchTerm(search.target.value)}
          />
        </div>
        <DesktopTabletView>
          <EventTotalsBox
            reservations={eventReservationsStore.totals.reservations}
            tickets={eventReservationsStore.totals.tickets}
          />
          <div className="col-12 col-md-2 p-0 p-md-2">
            <BestDropdown
              id="accepted-status"
              title="Status"
              options={eventReservationsStore.statusOptions?.toJSON()}
              value={eventReservationsStore.selectedStatus}
              onChange={value => eventReservationsStore.setStatus(value)}
            />
          </div>
        </DesktopTabletView>
        <MobileView>
          <EventTotalsBoxMobile
            reservations={eventReservationsStore.totals.reservations}
            tickets={eventReservationsStore.totals.tickets}
          />
          <div className="col-12 col-md-2 p-0 p-md-2">
            <BestDropdown
              id="accepted-status"
              title="Status"
              options={eventReservationsStore.statusOptions?.toJSON()}
              value={eventReservationsStore.selectedStatus}
              onChange={value => eventReservationsStore.setStatus(value)}
            />
          </div>
        </MobileView>
      </div>
    );
  }
}

export default inject("rootStore")(observer(EventReservationsFilter));
