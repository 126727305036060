import styled from "styled-components";

const GameDisplay = styled.div.attrs({
  id: "game-display"
})`
  &&& {
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: -0.25rem;
    margin-top: -0.125rem;
  }
`;

export default GameDisplay;
