import React from "react";
import styled from "styled-components";

const Key = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

const Value = styled.div.attrs({ className: "text-right" })`
  font-size: 14px;
  font-weight: bold;
`;

const ValueRow = styled.tr`
  background-color: white;
`;

const BorderedTable = styled.table`
  border: solid 1px #dee2e6;
`;

const EventTotalsBoxMobile = ({ reservations, tickets }) => {
  return (
    <div className="col-lg-4 col-xl-4 col-12 p-0 pl-lg-3 mt-2">
      <div className="table-responsive">
        <BorderedTable className="table">
          <thead className="text-center">
            <tr>
              <th>Totals</th>
            </tr>
          </thead>
          <tbody>
            <ValueRow>
              <td>
                <div className="row">
                  <Key className="col text-left">Reservations </Key>
                  <Value className="col text-right">{reservations.toLocaleString()}</Value>
                </div>
              </td>
            </ValueRow>
            <ValueRow>
              <td>
                <div className="row">
                  <Key className="col pull-right">Tickets </Key>
                  <Value className="col pull-left">{tickets.toLocaleString()}</Value>
                </div>
              </td>
            </ValueRow>
          </tbody>
        </BorderedTable>
      </div>
    </div>
  );
};

export default EventTotalsBoxMobile;
