import React from "react";
import ExportSettingsTable from "./exportSettingsTable/ExportSettingsTable";

const ExportSettingsBody = () => {
  return (
    <div className="row">
      <ExportSettingsTable type="label" title="Labels" displayLabelColumn={true} />
      <ExportSettingsTable type="boxOffice" title="Box Office" />
    </div>
  );
};

export default ExportSettingsBody;
