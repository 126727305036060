import React from "react";
import styled from "styled-components";
import moment from "moment";
import { inject, observer } from "mobx-react";
import Date from "../../common/displays/Date";
import GameDisplay from "../../common/displays/GameDisplay";
import { getTeamAbbreviation } from "../../../utilities/GameUtility";

const Grey = styled.div`
  background-color: ${props => props.theme["light-grey"]};
  border-right: solid 1px ${props => props.theme["grey"]};
`;

const EventDate = styled(Date)`
  &&& {
    margin-top: 0rem;
    margin-bottom: -0.25rem;
  }
`;

const Description = styled.div.attrs({
  id: "description"
})`
  &&& {
    color: ${props => props.theme["dark-grey"]};
    font-size: 0.875rem;
  }
`;

const Matchup = styled(GameDisplay).attrs({
  id: "matchup"
})`
  &&& {
    margin-top: 0.3rem;
    margin-bottom: 0rem;
  }
`;

const GameTime = styled(Description).attrs({
  id: "game-time"
})`
  margin-top: -0.125rem;
  margin-bottom: 0rem;
`;

const Box = styled.div.attrs({
  id: "box"
})`
  border: solid 1px ${props => props.theme["grey"]};
`;

const AreYouSure = styled.div.attrs({
  id: "are-you-sure"
})`
  font-size: 1.125rem;
  font-weight: 300;
  color: ${props => props.theme["black"]};
`;

const RequestInfo = styled.div.attrs({
  id: "request-info"
})`
  color: ${props => props.theme["black"]};
  font-size: 0.875rem;
  font-weight: 300;
  margin-top: 0.3rem;
`;

const SectionInfo = styled.div.attrs({
  id: "section-info"
})`
  font-size: 0.75rem;
  font-weight: 300;
`;
const Requester = styled.span.attrs({
  id: "requester"
})`
  font-weight: bold;
`;

class ReservationsDeleteBody extends React.Component {
  timezone(tz) {
    if (tz && tz.length !== 2) {
      return tz[0] + tz[tz.length - 1];
    } else {
      return tz;
    }
  }

  render() {
    const { reservationDeleteStore } = this.props.rootStore;
    let reservation = reservationDeleteStore.reservation;
    let game = reservation.game;
    if (!game) {
      return <div>Loading...</div>;
    }
    let requester =
      reservation && reservation.requester
        ? reservation.requester.firstName + " " + reservation.requester.lastName
        : "";
    let gameTime = game.status.startTimeTBD
      ? moment(game.gameDate).utc()
      : moment(game.gameDate).utcOffset(game.venue.timeZone.id);

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <AreYouSure className="col-12">Are you sure you want to delete the following reservation?</AreYouSure>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <Requester>Requester</Requester> {requester}
            </div>
          </div>
          <Box className="row mx-0 mb-0 mt-2">
            <Grey className="col-4">
              <div className="row py-3">
                <div className="col-lg-5">
                  <EventDate>{gameTime.format("MMM D")}</EventDate>
                  <Description>{gameTime.format("ddd")}</Description>
                </div>
                <div className="col-lg-7 mt-1 mt-lg-0">
                  <Matchup>
                    {game.gameType === "Z" || game.gameType === "O" || game.gameType === "A"
                      ? game.description
                      : getTeamAbbreviation(game, false) + " @ " + getTeamAbbreviation(game, true)}
                  </Matchup>
                  {game.status.startTimeTBD ? (
                    <GameTime>TBD</GameTime>
                  ) : (
                    <GameTime>
                      {gameTime.format("h:mm a")}{" "}
                      {game.status.startTimeTBD ? "UTC" : this.timezone(game.venue.timeZone.tz)}
                    </GameTime>
                  )}
                </div>
              </div>
            </Grey>
            <div className="col-8">
              <div className="d-flex flex-column py-3">
                <RequestInfo>
                  {reservation.quantity} {reservation.relation} tickets for {reservation.recipient}
                </RequestInfo>
                <SectionInfo>
                  {reservation.section.sectionNumber + " @ $" + reservation.section.sectionPrice.toFixed(2)}
                </SectionInfo>
              </div>
            </div>
          </Box>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ReservationsDeleteBody));
