import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

const Description = styled.div`
  color: #666666;
  font-size: 0.875rem;
`;

const StyledLinkDate = styled(Link)`
  font-weight: bold;
  font-size: 1.1rem;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  font-size: 0.875rem;
`;

class EventCardMobile extends React.Component {
  timezone(tz) {
    if (tz && tz.length !== 2) {
      return tz[0] + tz[tz.length - 1];
    } else {
      return tz;
    }
  }

  render() {
    const { data } = this.props;
    if (!data.game) {
      return <div>No Game</div>;
    }

    const to = `/events/${data.game.gamePk}/${data.toPath}`;

    let gameDate =
      data.game.status.startTimeTBD || !data.game.venue.timeZone
        ? moment(data.game.gameDate).utc()
        : moment(data.game.gameDate).tz(data.game.venue.timeZone.id);
    let timezone = data.game.venue.timeZone ? this.timezone(data.game.venue.timeZone.tz) || "N/A" : "UTC";
    return (
      <div className="card" id="event-admin-mobile-card">
        <div className="card-body">
          <div className="d-flex">
            <div className="mr-5">
              <div>
                <StyledLinkDate to={to}>{gameDate.format("MMM D")}</StyledLinkDate>
              </div>
              <Description>{gameDate.format("ddd")}</Description>
            </div>
            <div>
              <div>
                <StyledLink to={to} id="game-link">
                  {data.gameStr.gameStr}
                </StyledLink>
              </div>
              <Description id="game-description">
                {data.game.status.startTimeTBD ? "TBD" : gameDate.format("h:mm a") + " " + timezone}
              </Description>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventCardMobile;
