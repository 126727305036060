import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Card, CardBody } from "reactstrap";
import { DesktopTabletView, Icon, MobileView, Input, BestSelect, RequiredInput, BestLabel } from "best-common-react";

const TicketInventoryCard = styled(Card).attrs({
  className: "mb-2"
})`
  &&& {
    border: 1px solid ${props => props.theme["grey"]};
    background-color: ${props => props.theme["light-grey"]};
    border-radius: 0;
  }
`;

const InfoIcon = styled(Icon)`
  color: ${props => props.theme["information"]};
`;

const DeleteIcon = styled(Icon)`
  top: -10px;
  right: -10px;
`;

const AddAll = styled.button`
  text-decoration: none !important;
`;

class VenueEditTicketInventory extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  validateInventoryQuantity(inventoryQuantity) {
    return !Number.isNaN(parseInt(inventoryQuantity)) && inventoryQuantity % 1 === 0;
  }

  validateSectionPrice = sectionPrice => {
    return !isNaN(sectionPrice) && sectionPrice !== "";
  };

  onChange(key, value, param) {
    let section = this.props.section;
    switch (key) {
      case "sectionNumber":
        section.sectionNumber = value;
        break;
      case "inventoryQuantity":
        section.inventoryQuantity = value;
        break;
      case "sectionCategory":
        section.sectionCategory = value;
        break;
      case "sectionPrice":
        section.sectionPrice = value;
        break;
      case "homeAwayType":
        section.homeAwayType = value;
        section.sectionCategory = param;
        break;
      case "familyDepartments":
        let currentFamilyDepartments = value.map((ds, index) => {
          if (ds.department.hasOwnProperty("departmentSectionId")) {
            return ds.department;
          } else {
            return {
              isDeleted: false,
              sequence: index,
              department: ds.department,
              departmentSectionId: null,
              relation: "family"
            };
          }
        });
        section.departments = this.createFriendDepartmentValue(section.departments).concat(currentFamilyDepartments);
        break;
      case "friendDepartments":
        let currentFriendDepartments = value.map((ds, index) => {
          if (ds.department.hasOwnProperty("departmentSectionId")) {
            return ds.department;
          } else {
            return {
              isDeleted: false,
              sequence: index,
              department: ds.department,
              departmentSectionId: null,
              relation: "friends"
            };
          }
        });
        section.departments = this.createFamilyDepartmentValue(section.departments).concat(currentFriendDepartments);
        break;
      case "isDeleted":
        section.isDeleted = value;
        break;
      default:
        break;
    }
    this.props.onChange(section);
  }

  createFamilyDepartmentValue(departments) {
    return departments
      .filter(d => !d.isDeleted && d.relation === "family")
      .map(d => {
        return {
          label: d.department.departmentName,
          value: d.department.departmentTypeId,
          departmentName: d.department.departmentName,
          relation: d.relation,
          department: d.department,
          isDeleted: d.isDeleted
        };
      });
  }

  createFriendDepartmentValue(departments) {
    return departments
      .filter(d => !d.isDeleted && d.relation === "friends")
      .map(d => {
        return {
          label: d.department.departmentName,
          value: d.department.departmentTypeId,
          departmentName: d.department.departmentName,
          relation: d.relation,
          department: d.department,
          isDeleted: d.isDeleted
        };
      });
  }

  render() {
    const { section, rootStore } = this.props;
    const { commonStore, venueEditStore } = rootStore;
    let currentFamilyDepartments = this.createFamilyDepartmentValue(section.departments);
    let currentFriendDepartments = this.createFriendDepartmentValue(section.departments);
    return (
      <TicketInventoryCard>
        <CardBody>
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-end">
                <DeleteIcon
                  id="delete-icon"
                  iconName="fa-trash-alt"
                  className="d-inline-block position-relative"
                  onClick={() => {
                    this.onChange("isDeleted", true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-12">
              <div className="row">
                <div className="col-xl-12 col-lg-6 col-md-12">
                  <div className="row form-group">
                    <div className="col-sm-3 col-form-label text-nowrap py-0">
                      <BestLabel required={true}>Section</BestLabel>
                    </div>
                    <div className="col-sm-9">
                      <RequiredInput>
                        <Input
                          id="section-number"
                          name="sectionNumber"
                          onChange={event => {
                            this.onChange("sectionNumber", event.target.value);
                          }}
                          value={section.sectionNumber}
                        />
                      </RequiredInput>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-6 col-md-12">
                  <div className="row form-group">
                    <div className="col-sm-3 col-form-label text-nowrap py-0">
                      <BestLabel required={true}>Type</BestLabel>
                    </div>
                    <div className="col-sm-9">
                      <RequiredInput>
                        <BestSelect
                          id="home-away"
                          options={commonStore.homeAwayOptions}
                          value={commonStore.getHomeAwayTypeValue(section.homeAwayType)}
                          onChange={value => {
                            this.onChange("homeAwayType", value.value, venueEditStore.sectionCategoryBoth);
                          }}
                        />
                      </RequiredInput>
                    </div>
                  </div>
                </div>
                {section.homeAwayType.homeAwayTypeName === "Umpires" && (
                  <div className="col-xl-12 col-lg-6 col-md-12">
                    <div className="row form-group">
                      <div className="col-sm-3 col-form-label text-nowrap py-0">
                        <BestLabel required={true}>Category</BestLabel>
                        <InfoIcon
                          className="pl-1"
                          iconName="fa-info-circle"
                          onClick={() => venueEditStore.toggleSectionCategoryModal(true)}
                        />
                      </div>
                      <div className="col-sm-9">
                        <RequiredInput>
                          <BestSelect
                            id="section-category"
                            options={venueEditStore.sectionCategoryOptions}
                            value={{
                              label: section.sectionCategory.sectionCategoryName,
                              value: section.sectionCategory
                            }}
                            onChange={value => {
                              this.onChange("sectionCategory", value.value);
                            }}
                          />
                        </RequiredInput>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-xl-12 col-lg-6 col-md-12">
                  <div className="row form-group">
                    <div className="col-sm-3 col-form-label text-nowrap py-0">
                      <BestLabel required={true}># of Seats</BestLabel>
                    </div>
                    <div className="col-sm-9">
                      <RequiredInput validateFunc={this.validateInventoryQuantity}>
                        <Input
                          idy="quantity"
                          value={section.inventoryQuantity}
                          onChange={event => {
                            this.onChange("inventoryQuantity", event.target.value);
                          }}
                        />
                      </RequiredInput>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-6 col-md-12">
                  <div className="row form-group">
                    <div className="col-sm-3 col-form-label text-nowrap py-0">
                      <BestLabel required={true}>Price</BestLabel>
                    </div>
                    <div className="col-sm-9">
                      <RequiredInput validateFunc={this.validateSectionPrice}>
                        <Input
                          id="section-price"
                          value={section.sectionPrice}
                          onChange={event => {
                            this.onChange("sectionPrice", event.target.value);
                          }}
                        />
                      </RequiredInput>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="d-flex align-items-center justify-content-between">
                <BestLabel required={true}>Family Ticket Allocation</BestLabel>
                <DesktopTabletView>
                  <AddAll
                    id="add-all"
                    className="btn btn-link btn-sm pr-0"
                    onClick={() => this.onChange("familyDepartments", this.props.departments)}
                  >
                    Add All Departments
                  </AddAll>
                </DesktopTabletView>
                <MobileView>
                  <div className="d-flex justify-content-end">
                    <AddAll
                      id="add-all"
                      className="btn btn-link btn-sm  pr-0 justify-content-end"
                      onClick={() => this.onChange("familyDepartments", this.props.departments)}
                    >
                      Add All Departments
                    </AddAll>
                  </div>
                </MobileView>
              </div>
              <BestSelect
                isMulti
                id="family-departments"
                options={this.props.departments}
                defaultValue={currentFamilyDepartments}
                value={currentFamilyDepartments}
                className="basic-multi-select mb-3 mb-lg-0"
                classNamePrefix="select"
                onChange={value => {
                  this.onChange("familyDepartments", value);
                }}
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="d-flex align-items-center justify-content-between">
                <BestLabel required={true}>Friend Ticket Allocation</BestLabel>
                <DesktopTabletView>
                  <AddAll
                    className="btn btn-link btn-sm pr-0"
                    onClick={() => this.onChange("friendDepartments", this.props.departments)}
                  >
                    Add All Departments
                  </AddAll>
                </DesktopTabletView>
                <MobileView>
                  <div className="d-flex justify-content-end">
                    <AddAll
                      className="btn btn-link btn-sm  pr-0"
                      onClick={() => this.onChange("friendDepartments", this.props.departments)}
                    >
                      Add All Departments
                    </AddAll>
                  </div>
                </MobileView>
              </div>
              <BestSelect
                isMulti
                id="friends-departments"
                options={this.props.departments}
                defaultValue={currentFriendDepartments}
                value={currentFriendDepartments}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={value => {
                  this.onChange("friendDepartments", value);
                }}
              />
            </div>
          </div>
        </CardBody>
      </TicketInventoryCard>
    );
  }
}

export default inject("rootStore")(observer(VenueEditTicketInventory));
