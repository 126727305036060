import React from "react";
import { inject, observer } from "mobx-react";
import { StickyFooterButtons, LightButton, DesktopTabletView } from "best-common-react";

const SystemSeasonsFooter = ({ rootStore: { systemSeasonStore } }) => (
  <StickyFooterButtons>
    <LightButton onClick={systemSeasonStore.newSeason}>Create New Season</LightButton>
    <DesktopTabletView>
      <LightButton onClick={systemSeasonStore.archiveSeasons} disabled={!systemSeasonStore.selectedSeasons.length}>
        Archive
      </LightButton>
    </DesktopTabletView>
  </StickyFooterButtons>
);

export default inject("rootStore")(observer(SystemSeasonsFooter));
