import React from "react";
import { inject, observer } from "mobx-react";
import TicketAllotmentFilters from "../../tickets/allotment/TicketAllotmentFilters";
import TicketAllotmentTable from "../../tickets/allotment/TicketAllotmentTable";
import DepartmentAccess from "./DepartmentAccess";
import { BestTitle } from "best-common-react";

class DepartmentEditBody extends React.Component {
  render() {
    const { departmentEditStore } = this.props.rootStore;
    return (
      <div id="department-edit-body">
        <div className="d-flex">
          <TicketAllotmentFilters
            allotmentYear={departmentEditStore.allotmentYear}
            onYearChange={departmentEditStore.setAllotmentYear}
          />
        </div>
        <BestTitle>Default Ticket Allotment</BestTitle>
        <div className="d-flex justify-content-md-start justify-content-sm-center justify-content-center">
          <TicketAllotmentTable
            idKey="departmentAllotmentId"
            allotments={departmentEditStore.allotmentsForSelectedSeason}
            onAllotmentChange={departmentEditStore.updateAllotment}
          />
        </div>
        {departmentEditStore.departmentAccessForSelectedSeason.map((access, index) => (
          <DepartmentAccess key={index} id={index} access={access} onUpdate={departmentEditStore.updateAccess} />
        ))}
      </div>
    );
  }
}

export default inject("rootStore")(observer(DepartmentEditBody));
