import React from "react";
import { inject, observer } from "mobx-react";
import ReservationsDeleteBody from "./ReservationsDeleteBody";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import ReservationsMultiDeleteBody from "./ReservationsMultiDeleteBody";
import PropTypes from "prop-types";

class ReservationsDeleteModal extends React.Component {
  render() {
    const { reservationDeleteStore } = this.props.rootStore;
    const { callback } = this.props;
    return (
      <Modal
        show={reservationDeleteStore.showDeleteModal}
        onClose={reservationDeleteStore.cancelReservationDelete}
        size="lg"
      >
        <ModalHeaderWithTitleAndClose
          title={reservationDeleteStore.reservationToDeleteId ? "Delete Reservation" : "Delete Reservations"}
          close={reservationDeleteStore.cancelReservationDelete}
        />
        <ModalBody>
          {reservationDeleteStore.reservationToDeleteId ? <ReservationsDeleteBody /> : <ReservationsMultiDeleteBody />}
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton
              className="mr-2"
              onClick={() => reservationDeleteStore.deleteReservation(callback)}
              disabled={false}
            >
              Delete
            </PrimaryButton>
            <LightButton onClick={reservationDeleteStore.cancelReservationDelete}>Cancel</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

// needs to return a promise
ReservationsDeleteModal.propTypes = {
  callback: PropTypes.func.isRequired
};

export default inject("rootStore")(observer(ReservationsDeleteModal));
