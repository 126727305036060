import React from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { getTeamAbbreviation } from "../../../utilities/GameUtility";
import { BestLabel, BestSelect, DataTable, PrimaryOutlineButton, RequiredIndicator } from "best-common-react";

const Columns = [
  {
    name: "Requester",
    key: "requester",
    sortable: false,
    width: 185
  },
  {
    name: "Recipient",
    key: "recipient",
    sortable: false,
    width: 185
  },
  {
    name: "Relation",
    key: "relation",
    sortable: false,
    width: 100
  },
  {
    name: "Section",
    key: "sectionAndPrice",
    sortable: false,
    width: 185
  },
  {
    name: "Quantity",
    key: "quantity",
    sortable: false,
    width: 120
  }
];
class ReservationsCopyBody extends React.Component {
  render() {
    const { reservationCopyStore } = this.props.rootStore;
    const { reservations, game } = this.props;
    let gameDate =
      game.status.startTimeTBD || !game.venue.timeZone
        ? moment(game.gameDate).utc()
        : moment(game.gameDate).tz(game.venue.timeZone.id);

    return (
      <>
        <DataTable
          columns={Columns}
          data={reservationCopyStore.reservations}
          height={Math.max(120, reservations.length * 20)}
        />
        <div className="row mt-4">
          <div className="col-6">
            <BestLabel>Current Game</BestLabel>
          </div>
          <div className="col-6">
            <BestLabel htmlFor="future-games">
              Copy To: <RequiredIndicator />
            </BestLabel>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            {gameDate.format("ddd, MMM D") +
              " " +
              getTeamAbbreviation(game, false) +
              " @ " +
              getTeamAbbreviation(game, true) +
              " " +
              gameDate.format("HH:mm z")}
          </div>
          <div className="col-6">
            <BestSelect
              id="future-games"
              options={reservationCopyStore.futureGames}
              value={reservationCopyStore.gameToCopyTo}
              onChange={value => {
                reservationCopyStore.setGameToCopyTo(value);
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default inject("rootStore")(observer(ReservationsCopyBody));
