import React from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Icon } from "best-common-react";

class DeleteEventReservationsFormatter extends React.Component {
  render() {
    const { eventReservationsStore, reservationDeleteStore, authStore } = this.props.rootStore;
    let filterElement = eventReservationsStore.currentReservations.find(r => r.id === this.props.value.id);
    let isBefore = moment().isBefore(filterElement && filterElement.game.eventCutoff);
    let hasDigitalTicket = this.props.value.digitalTicket;
    if ((isBefore || authStore.isAdmin) && !hasDigitalTicket) {
      return (
        <div id="delete-event-reservation-formatter">
          <Icon
            iconName="fa-trash-alt"
            onClick={() => {
              reservationDeleteStore.setReservationToDelete(this.props.value.id);
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default inject("rootStore")(observer(DeleteEventReservationsFormatter));
