import React from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Icon } from "best-common-react";

class DeleteReservationFormatter extends React.Component {
  render() {
    const { reservationsStore, reservationDeleteStore, authStore } = this.props.rootStore;
    let filterElement = reservationsStore.currentReservations.find(r => r.ticketRequestId === this.props.value);
    let isBefore = moment().isBefore(filterElement && filterElement.reservation.game.eventCutoff);
    if (authStore.isAdmin || (isBefore && !filterElement.reservation.game.isLocked)) {
      return (
        <div id="delete-reservation-formatter">
          <Icon
            iconName="fa-trash-alt"
            onClick={() => {
              reservationDeleteStore.setReservationToDelete(this.props.value);
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default inject("rootStore")(observer(DeleteReservationFormatter));
