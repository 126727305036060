import React from "react";
import { inject, observer } from "mobx-react";
import { StickyFooterButtons, LightButton } from "best-common-react";

const ReportEventReservationsFooter = ({ rootStore }) => {
  const { reportEventReservationsStore } = rootStore;
  return (
    <StickyFooterButtons>
      <LightButton onClick={reportEventReservationsStore.exportSettings}>Export Settings</LightButton>
    </StickyFooterButtons>
  );
};

export default inject("rootStore")(observer(ReportEventReservationsFooter));
