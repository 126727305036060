import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { AccordionTableWrapper, TableTitle, DesktopTabletView, MobileView } from "best-common-react";
import { LightButton } from "best-common-react";
import styled from "styled-components";
import EditAndDeleteFormatter from "./EditAndDeleteFormatter";
import MessagesAccordion from "./MessagesAccordion";

const StyledDiv = styled.div.attrs({ className: "col text-right" })`
  padding-top: 0.75rem;
`;

const SmLight = styled(LightButton).attrs({
  className: "btn-sm",
  id: "small-light-button"
})`
  &&& {
    width: ${props => (props.full ? "100%" : "")}
    padding: 0.4rem 0.7rem;
  }
`;

const cols = [
  { key: "editAndDelete", name: "", width: 75, formatter: EditAndDeleteFormatter },
  { key: "message", name: "Message", width: 500 },
  { key: "departmentNames", name: "Departments" }
];

class MessagesTable extends React.Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <TableTitle>Additional Information</TableTitle>
            <MobileView>
              <div>
                <SmLight disabled={!this.props.isGlobal} full={true} onClick={this.props.onClick}>
                  Add Message
                </SmLight>
              </div>
            </MobileView>
            <AccordionTableWrapper
              data={this.props.messages}
              columns={this.props.isGlobal ? cols : cols.slice(1)}
              height={this.props.messages.length * 28.5 + 50}
              accordion={MessagesAccordion}
              minWidth={1000}
            />
            <DesktopTabletView>
              <div className="row">
                <StyledDiv>
                  <SmLight disabled={!this.props.isGlobal} onClick={this.props.onClick}>
                    Add Message
                  </SmLight>
                </StyledDiv>
              </div>
            </DesktopTabletView>
          </div>
        </div>
      </div>
    );
  }
}

MessagesTable.propTypes = {
  messages: PropTypes.any,
  onClick: PropTypes.func
};
MessagesTable.defaultProps = {
  messages: []
};

export default inject("rootStore")(observer(MessagesTable));
