import React from "react";
import Protected from "../../components/protected/Protected";
import { CardBody, CardContainer, CardHeader } from "best-common-react";
import LoadingHeader from "../../components/loading/LoadingHeader";
import ReportHistoryBody from "../../components/reports/history/ReportHistoryBody";
import ReportHistoryFilters from "../../components/reports/history/ReportHistoryFilters";

function ReportHistoryContainer() {
  return (
    <div>
      <CardContainer>
        <CardHeader>
          <LoadingHeader title="Report History" />
        </CardHeader>
        <CardBody>
          <ReportHistoryFilters />
          <ReportHistoryBody />
        </CardBody>
      </CardContainer>
    </div>
  );
}

export default Protected(ReportHistoryContainer);
