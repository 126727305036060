import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, StickyFooterButtons } from "best-common-react";

class OrgSettingsFooter extends React.Component {
  render() {
    const { orgSettingsStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <LightButton
          onClick={() => orgSettingsStore.saveOrgSettings()}
          id="save-settings"
          disabled={!orgSettingsStore.isSaveEnabled}
        >
          Save HH Guests
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(OrgSettingsFooter));
