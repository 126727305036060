import React from "react";
import moment from "moment";

class UTCFormatter extends React.Component {
  render() {
    let dateTime = this.props.value;
    let momentInTZ = moment(dateTime).tz("America/New_York");
    return <div>{momentInTZ.format("M/DD/YYYY - h:mm a") + " ET"}</div>;
  }
}

export default UTCFormatter;
