import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, PrimaryButton, StickyFooterButtons } from "best-common-react";

class EditUserFooter extends React.Component {
  render() {
    const { userEditStore, authStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <PrimaryButton
          disabled={!userEditStore.isValidSave || userEditStore.isSaving}
          onClick={userEditStore.saveUser}
          id="save"
        >
          Save
        </PrimaryButton>
        {authStore.isGlobal ? (
          <LightButton onClick={userEditStore.showDeleteUserModal} id="delete-user-button">
            Delete User
          </LightButton>
        ) : null}
        <LightButton onClick={userEditStore.cancel} id="cancel">
          Cancel
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(EditUserFooter));
