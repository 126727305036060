import React from "react";
import { observer } from "mobx-react";
import { BestLabel, Input } from "best-common-react";

class AllocationOverride extends React.Component {
  constructor(props) {
    super(props);
  }

  updateField(key, value) {
    let allocation = this.props.allocation;
    allocation[key] = value;
  }

  render() {
    const { allocation, disabled } = this.props;

    return (
      <div class="d-flex flex-wrap align-items-center justify-content-between">
        <div class="flex-col col-3">
          <BestLabel htmlFor="home-family-allotment">Home - Family</BestLabel>
          <Input
            id="home-family-allotment"
            value={allocation.homeFamily}
            disabled={disabled}
            type="number"
            min="0"
            step="1"
            onChange={data => {
              if (data.target.validity.valid) {
                this.updateField("homeFamily", data.target.value);
              }
            }}
          />
        </div>
        <div class="flex-col col-3">
          <BestLabel htmlFor="home-friends-allotment">Home - Friends</BestLabel>
          <Input
            id="home-friends-allotment"
            value={allocation.homeFriends}
            disabled={disabled}
            type="number"
            min="0"
            step="1"
            onChange={data => {
              if (data.target.validity.valid) {
                this.updateField("homeFriends", data.target.value);
              }
            }}
          />
        </div>
        <div class="flex-col col-3">
          <BestLabel htmlFor="away-family-allotment">Away - Family</BestLabel>
          <Input
            id="away-family-allotment"
            value={allocation.awayFamily}
            disabled={disabled}
            type="number"
            min="0"
            step="1"
            onChange={data => {
              if (data.target.validity.valid) {
                this.updateField("awayFamily", data.target.value);
              }
            }}
          />
        </div>
        <div class="flex-col col-3">
          <BestLabel htmlFor="away-friends-allotment">Away - Friends</BestLabel>
          <Input
            id="away-friends-allotment"
            value={allocation.awayFriends}
            disabled={disabled}
            type="number"
            min="0"
            step="1"
            onChange={data => {
              if (data.target.validity.valid) {
                this.updateField("awayFriends", data.target.value);
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default observer(AllocationOverride);
