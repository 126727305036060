import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { TableTitle } from "best-common-react";
import VenueEditPickupLocationsMobile from "./VenueEditPickupLocationsMobile";

const AddButton = styled.button`
  &&& {
    background-color: white;
    border-color: #d2d2d2;
  }
`;

class VenueEditPickupMobile extends React.Component {
  render() {
    const { venueEditStore } = this.props.rootStore;
    const pickupLocations = venueEditStore.sortedPickupLocations;
    const departmentOptions = venueEditStore.departmentOptions;
    return (
      <DragDropContext
        onDragEnd={result => {
          if (result && result.destination && result.source) {
            venueEditStore.reorderPickupLocations(result.source.index, result.destination.index);
          }
        }}
      >
        <div>
          <Droppable droppableId="venuePickupLocations">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <TableTitle>Pickup Locations</TableTitle>
                <VenueEditPickupLocationsMobile
                  pickupLocations={pickupLocations}
                  departmentOptions={departmentOptions}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="d-flex justify-content-end">
            <AddButton className="btn" onClick={() => venueEditStore.addNewLocation()}>
              Add Location
            </AddButton>
          </div>
        </div>
      </DragDropContext>
    );
  }
}

export default inject("rootStore")(observer(VenueEditPickupMobile));
