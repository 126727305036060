import React from "react";
import { inject, observer } from "mobx-react";
import EventDataCard from "../../events/EventDataCard";
import ReservationEditForm from "./ReservationEditForm";
import styled from "styled-components";
import { Icon } from "best-common-react";

const WarningIcon = styled(Icon).attrs({ className: "mr-2" })`
  &&& {
    color: #ff9100;
  }
`;

const Information = styled.div.attrs({ className: "mt-2" })`
  &&& {
    color: #ff9100;
  }
`;

class ReservationEditBody extends React.Component {
  render() {
    const { reservationsEditStore, commonStore, authStore } = this.props.rootStore;
    const displayCountdown = commonStore.currentOrgId === authStore.userData.org.orgId;
    return (
      <div className="row">
        <div className="col-xs-12 col-md-5 col-lg-4 col-xl-3">
          {reservationsEditStore.currentGame && reservationsEditStore.currentGame.venue ? (
            <div>
              <EventDataCard
                game={reservationsEditStore.currentGame}
                displayCountdown={displayCountdown}
                id="reservation-edit-event-data-card"
              />
              {reservationsEditStore.message ? (
                <Information>
                  <span>
                    <WarningIcon iconName="fa-info-circle" />
                  </span>
                  {reservationsEditStore.message}
                </Information>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="col-xs-12 col-md-7 col-lg-8 col-xl-9">
          <ReservationEditForm />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ReservationEditBody));
