import React from "react";
import VenueOverrideSectionTable from "./VenueOverrideSectionTable";
import VenueOverideGameTable from "./VenueOverideGameTable";
import VenueBulkOverrideFilters from "./VenueBulkOverrideFilters";

class VenueBulkOverrideBody extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col">
          <VenueBulkOverrideFilters />
          <div className="row mt-2">
            <div className="col-xs-12 col-lg-6">
              <VenueOverrideSectionTable />
            </div>
            <div className="col-xs-12 col-lg-6 mt-3 mt-lg-0">
              <VenueOverideGameTable />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VenueBulkOverrideBody;
