import React from "react";

export default ({ value = {} }) => (
  <div>
    <span>
      <a href={"/reports/users?userId=" + value.userId + "&start=" + value.start + "&end=" + value.end}>
        {value.username}
      </a>
    </span>
  </div>
);
