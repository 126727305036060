import React from "react";
import { BestTitle, InfoCard, MobileView } from "best-common-react";
import EventCutoff from "../../events/overrides/EventCutoff";
import EventAccess from "../../events/overrides/EventAccess";

class DepartmentAccess extends React.Component {
  constructor(props) {
    super(props);
    this.updateField = this.updateField.bind(this);
  }

  updateField(key, value) {
    let access = this.props.access;
    access[key] = value;
    this.props.onUpdate(access);
  }

  render() {
    const { access, id, onUpdate } = this.props;
    return (
      <div id="department-access">
        <BestTitle>{access.season.seasonType.seasonTypeName + " Ticket Requests"}</BestTitle>
        <div className="d-flex flex-wrap">
          <InfoCard className="mr-2" title="Event Access">
            <EventAccess id={"access-" + id} access={access} onUpdate={onUpdate} />
          </InfoCard>

          <MobileView>
            <div className="mt-2" />
          </MobileView>

          <InfoCard title="Event Cutoff">
            <EventCutoff id={"cutoff-" + id} access={access} onUpdate={onUpdate} />
          </InfoCard>
        </div>
      </div>
    );
  }
}

export default DepartmentAccess;
