import React from "react";
import styled from "styled-components";

const Table = styled.table`
  height: 61px;
`;

const Gray = styled.th`
  &&& {
    background-color: #f2f3f4;
    width: 20px;
  }
`;

const TopRow = styled.tr`
  background-color: #ffffff;
  &&& {
    border-top: solid 1px #d2d2d2;
    border-left: solid 1px #d2d2d2;
    border-right: solid 1px #d2d2d2;
    height: 5px;
  }
`;

const BottomRow = styled.tr`
  background-color: #ffffff;
  &&& {
    border-bottom: solid 1px #d2d2d2;
    border-left: solid 1px #d2d2d2;
    border-right: solid 1px #d2d2d2;
    height: 5px;
  }
`;

const MiddleRow = styled.tr`
  background-color: #ffffff;
  &&& {
    border-left: solid 1px #d2d2d2;
    border-right: solid 1px #d2d2d2;
    height: 5px;
  }
`;

const FirstData = styled.td`
  &&& {
    border-left: solid 1px #d2d2d2;
    border-bottom: solid 1px #d2d2d2;
    height: 5px;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
`;

const SecondData = styled.td`
  &&& {
    border-bottom: solid 1px #d2d2d2;
    height: 5px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
  }
`;

const GameInfo = styled.td`
  &&& {
    border-bottom: solid 1px #d2d2d2;
    height: 5px;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
  }
`;

const Bold = styled.span`
  &&& {
    font-weight: bold;
  }
`;

const TotalsBox = ({ home, away, tickets, reservations, value }) => {
  const format = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 })
    .format;
  return (
    <div className="col-6 col-lg-5 col-xl-4 p-0 mt-3 table-responsive">
      <Table className="table table-borderless">
        <tbody>
          <TopRow>
            <Gray className="pb-0 pt-1 px-2">Totals</Gray>
            <FirstData className="pt-2 pb-2 px-2">Games</FirstData>
            <GameInfo className="text-right pt-2 pb-2 px-2">
              <Bold>{home + away}</Bold> (<Bold>{home}</Bold> home <Bold>{away}</Bold> away)
            </GameInfo>
          </TopRow>
          <MiddleRow>
            <Gray className="py-2 px-2" />
            <FirstData className="py-2 px-2">Reservations</FirstData>
            <SecondData className="text-right py-2 px-2">{reservations.toLocaleString()}</SecondData>
          </MiddleRow>
          <MiddleRow>
            <Gray className="py-2 px-2" />
            <FirstData className="py-2 px-2">Tickets</FirstData>
            <SecondData className="text-right py-2 px-2">{tickets.toLocaleString()}</SecondData>
          </MiddleRow>
          <BottomRow>
            <Gray className="py-2 px-2" />
            <FirstData className="py-2 px-2">Value</FirstData>
            <SecondData className="text-right py-2 px-2">{format(value)}</SecondData>
          </BottomRow>
        </tbody>
      </Table>
    </div>
  );
};

export default TotalsBox;
