import { action, autorun, computed, decorate, extendObservable } from "mobx";
import _ from "lodash";

class OrgSettingsStore {
  constructor(authStore, compTixApi, routerStore, commonStore, loadingStore) {
    this.authStore = authStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.commonStore = commonStore;
    this.loadingStore = loadingStore;

    this.defaults = {
      isSaveEnabled: false,
      orgSettings: []
    };

    extendObservable(this, {
      isSaveEnabled: this.defaults["isSaveEnabled"],
      orgSettings: this.defaults["orgSettings"],
      setOrgSettings: action(data => {
        this.orgSettings = data;
      }),
      setIsSaveEnabled: action(value => {
        this.isSaveEnabled = value;
      }),
      saveOrgSettings: action(() => {
        this.loadingStore.setLoading(true);
        this.setIsSaveEnabled(false);
        this.compTixApi.saveOrgSettings(this.orgSettings).then(data => {
          if (data) {
            this.setOrgSettings(data);
            this.loadingStore.setLoading(false);
          }
        });
      }),
      updateOrg: action((orgCode, val) => {
        let findElement = _.find(this.orgSettings, setting => setting.org.orgCode === orgCode);
        findElement.useHHDropdown = val;
        this.setIsSaveEnabled(true);
      })
    });

    autorun(() => {
      if (this.routerStore.isOrgSettingsTab) {
        this.compTixApi
          .getAllOrgSettings()
          .then(data => {
            this.setOrgSettings(data);
          })
          .catch(r => {
            console.error(r);
          });
      } else {
      }
    });
  }

  get orgSettingsData() {
    let map = this.orgSettings.toJSON().map(setting => {
      return { orgCode: setting.org.orgCode, org: setting.org, useHHDropdown: setting.useHHDropdown };
    });
    return map;
  }
}

decorate(OrgSettingsStore, {
  orgSettingsData: computed
});

export default OrgSettingsStore;
