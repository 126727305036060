import React from "react";
import { inject, observer } from "mobx-react";
import { DataTable, DateTimeFormatter } from "best-common-react";
import UTCFormatter from "../../elements/tables/UTCFormatter";
import HigherOrderComponent from "../../../utilities/HigherOrderComponent";

let DateTime = HigherOrderComponent(DateTimeFormatter);
DateTime.defaultProps = {
  format: "MM/DD/YYYY - h:mma"
};

const Columns = [
  {
    name: "Date",
    key: "date",
    formatter: UTCFormatter,
    sortable: true
  },
  {
    name: "GamePK",
    key: "gamePk",
    sortable: true
  },
  {
    name: "Type",
    key: "type",
    sortable: true
  },
  {
    name: "OrgCode",
    key: "orgCode",
    sortable: true
  },
  {
    name: "Ticket Requester",
    key: "requester",
    sortable: true
  },
  {
    name: "User",
    key: "user",
    sortable: true
  },
  {
    name: "Has Error",
    key: "status",
    sortable: true
  }
];

const ReportHistoryBody = ({ rootStore: { reportHistoryStore } }) => (
  <div>
    <DataTable
      columns={Columns}
      data={reportHistoryStore.currentHistory}
      sortFunc={reportHistoryStore.setSortDirection}
      sortColumn={reportHistoryStore.sortFilters["key"]}
      sortDirection={reportHistoryStore.sortFilters["direction"]}
    />
  </div>
);

export default inject("rootStore")(observer(ReportHistoryBody));
