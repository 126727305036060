import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import VenueEditPickupLocationTableBody from "./VenueEditPickupLocationTableBody";
import { RequiredIndicator, Table, TableHeader, TableTitle } from "best-common-react";

const AddButton = styled.button`
  &&& {
    background-color: white;
    border-color: #d2d2d2;
  }
`;

const CenteredHeader = styled.th`
  &&& {
    text-align: center;
  }
`;

class VenueEditPickupLocations extends React.Component {
  render() {
    const { venueEditStore } = this.props.rootStore;
    return (
      <DragDropContext
        onDragEnd={result => {
          if (result && result.destination && result.source) {
            venueEditStore.reorderPickupLocations(result.source.index, result.destination.index);
          }
        }}
      >
        <TableTitle>Pickup Locations</TableTitle>
        <div className="d-flex flex-row">
          <Table>
            <TableHeader>
              <tr>
                <th />
                <CenteredHeader>Digital Delivery</CenteredHeader>
                <th>
                  Pickup Location <RequiredIndicator />
                </th>
                <th>
                  Type <RequiredIndicator />
                </th>
                <th>Departments</th>
              </tr>
            </TableHeader>
            <Droppable droppableId="venuePickupLocations">
              {provided => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  <VenueEditPickupLocationTableBody />
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </Table>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <AddButton className="btn" onClick={() => venueEditStore.addNewLocation()} id="add-location">
            Add Location
          </AddButton>
        </div>
      </DragDropContext>
    );
  }
}

export default inject("rootStore")(observer(VenueEditPickupLocations));
