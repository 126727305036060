import React from "react";
import { inject, observer } from "mobx-react";
import {
  LightButton,
  MobileView,
  DesktopTabletView,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import UserDeleteBodyLarge from "./DeleteUserBodyLarge";
import UserDeleteBodyMobile from "./UserDeleteBodyMobile";

class UserDeleteModal extends React.Component {
  render() {
    const { userEditStore } = this.props.rootStore;
    const currentUser = userEditStore.currentUser;
    return (
      <Modal show={userEditStore.displayDeleteUserModal} onClose={userEditStore.hideDeleteUserModal} size="lg">
        <ModalHeaderWithTitleAndClose title="Delete User" close={userEditStore.hideDeleteUserModal} />
        <ModalBody>
          <DesktopTabletView>
            <UserDeleteBodyLarge currentUser={currentUser} />
          </DesktopTabletView>
          <MobileView>
            <UserDeleteBodyMobile currentUser={currentUser} />
          </MobileView>
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton className="mr-2" onClick={userEditStore.deleteUser} disabled={false}>
              Delete User
            </PrimaryButton>
            <LightButton onClick={userEditStore.hideDeleteUserModal}>Cancel</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(UserDeleteModal));
