import React from "react";
import { inject } from "mobx-react";
import { Icon } from "best-common-react";

class UserNameFormatter extends React.Component {
  render() {
    return (
      <div id="user-username">
        {this.props.row.username}
        {this.props.row.manuallyManaged ? <span className="fa fa-exclamation-circle ml-2" /> : null}
      </div>
    );
  }
}

export default inject("rootStore")(UserNameFormatter);
