import React from "react";
import { inject, observer } from "mobx-react";
import VenueTable from "./VenueTable";
import _ from "lodash";

class VenueTables extends React.Component {
  render() {
    const { venueAdminStore } = this.props.rootStore;
    return _.entries(venueAdminStore.currentVenues).map(venue => (
      <div key={venue[0]} id={venue[0]}>
        <VenueTable season={venue[0]} venues={_.sortBy(venue[1], "venueName")} />
      </div>
    ));
  }
}

export default inject("rootStore")(observer(VenueTables));
