import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { AccordionTableWrapper, DateTimeFormatter, IconFormatter } from "best-common-react";
import SystemSeasonAccordionMobile from "./SystemSeasonAccordionMobile";
import CheckFormatter from "../../../elements/CheckFormatter";
import HigherOrderComponent from "../../../../utilities/HigherOrderComponent";

let PencilFormatter = HigherOrderComponent(IconFormatter);
PencilFormatter.defaultProps = {
  icon: "fa-pencil-alt",
  id: "system-seasons-edit-formatter"
};

let DateTime = HigherOrderComponent(DateTimeFormatter);
DateTime.defaultProps = {
  format: "MM/DD/YYYY - h:mma"
};

const Columns = [
  {
    name: "",
    key: "editLink",
    width: 45,
    locked: true,
    formatter: PencilFormatter
  },
  {
    name: "Year",
    key: "year",
    width: 100,
    locked: true,
    sortable: true
  },
  {
    name: "Type",
    key: "type",
    width: 200,
    locked: true,
    sortable: true
  },
  {
    name: "Active",
    key: "active",
    width: 125,
    formatter: CheckFormatter,
    cellClass: "text-center"
  },
  {
    name: "Default Event Access (ET)",
    key: "defaultEventAccess",
    width: 300,
    formatter: DateTime,
    sortable: true
  },
  {
    name: "NRI Dropoff",
    key: "nriDropoff",
    formatter: DateTimeFormatter,
    sortable: true
  }
];

const SeasonCheckedHolder = styled.div.attrs(() => ({
  className: "mt-2"
}))`
  height: 1.4rem;
`;

const SeasonsChecked = styled.div`
  font-style: italic;
  font-size: .8rem;
  color ${props => props.theme["dark-grey"]};
`;

const SystemSeasonsBody = ({ rootStore: { systemSeasonStore } }) => (
  <div>
    <SeasonCheckedHolder>
      {systemSeasonStore.selectedSeasons.length > 0 && (
        <SeasonsChecked>{systemSeasonStore.selectedSeasons.length} seasons selected</SeasonsChecked>
      )}
    </SeasonCheckedHolder>
    <AccordionTableWrapper
      id="department-table"
      data={systemSeasonStore.currentSeasons}
      columns={Columns}
      height={400}
      accordion={SystemSeasonAccordionMobile}
      rowSelection={{
        showCheckbox: true,
        onRowsSelected: systemSeasonStore.updateSelected,
        onRowsDeselected: systemSeasonStore.updateSelected,
        selectBy: {
          isSelectedKey: "isSelected"
        },
        enableShiftSelect: true
      }}
      sortFunc={systemSeasonStore.setSortDirection}
      sortColumn={systemSeasonStore.sortFilters["key"]}
      sortDirection={systemSeasonStore.sortFilters["direction"]}
    />
  </div>
);

export default inject("rootStore")(observer(SystemSeasonsBody));
