import React from "react";
import TeamTitle from "../components/title/TeamTitle";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import Protected from "../components/protected/Protected";
import MyAccountBody from "../components/myAccount/MyAccountBody";
import MyAccountFooter from "../components/myAccount/MyAccountFooter";

class MyAccountContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>My Account</CardHeader>
          <CardBody>
            <MyAccountBody />
          </CardBody>
          <CardFooter>
            <MyAccountFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default Protected(MyAccountContainer);
