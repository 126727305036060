import React from "react";
import styled from "styled-components";

const Table = styled.table`
  height: 61px;
`;

const Gray = styled.th`
  &&& {
    background-color: #f2f3f4;
    width: 20px;
  }
`;

const TopRow = styled.tr`
  background-color: #ffffff;
  &&& {
    border-top: solid 1px #d2d2d2;
    border-left: solid 1px #d2d2d2;
    border-right: solid 1px #d2d2d2;
    height: 5px;
  }
`;

const BottomRow = styled.tr`
  background-color: #ffffff;
  &&& {
    border-bottom: solid 1px #d2d2d2;
    border-left: solid 1px #d2d2d2;
    border-right: solid 1px #d2d2d2;
    height: 5px;
  }
`;

const FirstData = styled.td`
  &&& {
    border-left: solid 1px #d2d2d2;
    border-bottom: solid 1px #d2d2d2;
    height: 5px;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
`;

const SecondData = styled.td`
  &&& {
    border-bottom: solid 1px #d2d2d2;
    height: 5px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
  }
`;

const EventTotalsBox = ({ tickets, reservations }) => {
  return (
    <div className="col-12 col-md-5 col-xl-3 p-0 mt-3 pr-md-2 mt-lg-0 table-responsive">
      <Table className="table table-borderless">
        <tbody>
          <TopRow>
            <Gray className="pb-0 pt-1 px-2">Totals</Gray>
            <FirstData className="py-2 px-2">Reservations</FirstData>
            <SecondData className="text-right py-2 px-2">{reservations.toLocaleString()}</SecondData>
          </TopRow>
          <BottomRow>
            <Gray className="py-2 px-2" />
            <FirstData className="py-2 px-2">Tickets</FirstData>
            <SecondData className="text-right py-2 px-2">{tickets.toLocaleString()}</SecondData>
          </BottomRow>
        </tbody>
      </Table>
    </div>
  );
};

export default EventTotalsBox;
