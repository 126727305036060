import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Accordion, AttributeHeader, AttributeValue, Icon, BestLink } from "best-common-react";

const HeaderName = styled.span.attrs({ className: "mx-3" })``;

class DepartmentAccordionMobile extends React.Component {
  render() {
    const { authStore } = this.props.rootStore;
    let header;
    if (authStore.isGlobal) {
      header = (
        <div id="department-attribute-header">
          <BestLink to={this.props.data.editLink}>
            <Icon iconName="fa-pencil-alt" id="department-edit-icon" className="fa-lg d-inline-block mx-1" />
          </BestLink>
          <HeaderName>{this.props.data.name}</HeaderName>
        </div>
      );
    } else {
      header = <HeaderName>{this.props.data.name}</HeaderName>;
    }
    return (
      <div>
        <Accordion id={this.props.index.toString()} {...this.props}>
          <Accordion.Header isToggle={true}>{header}</Accordion.Header>

          <Accordion.Body>
            <AttributeHeader>Home (Fam/Frnd)</AttributeHeader>
            <AttributeValue>{this.props.data.homeAllotment}</AttributeValue>

            <AttributeHeader>Away (Fam/Frnd)</AttributeHeader>
            <AttributeValue>{this.props.data.awayAllotment}</AttributeValue>

            <AttributeHeader>Event Access (local time)</AttributeHeader>
            <AttributeValue>{this.props.data.eventAvailable}</AttributeValue>

            <AttributeHeader>Event Cutoff (local time)</AttributeHeader>
            <AttributeValue>{this.props.data.eventCutoff}</AttributeValue>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

export default inject("rootStore")(observer(DepartmentAccordionMobile));
