import React from "react";
import { Icon } from "best-common-react";

class EditAndDeleteFormatter extends React.Component {
  render() {
    return (
      <div className="d-flex">
        <div className="flex-fill text-center">
          <Icon id={this.props.value.id} iconName="fa-pencil-alt" onClick={this.props.value.onEditClick} />
        </div>
        <div className="flex-fill text-center">
          <Icon id={this.props.value.id} iconName="fa-trash-alt" onClick={this.props.value.onDeleteClick} />
        </div>
      </div>
    );
  }
}

export default EditAndDeleteFormatter;
