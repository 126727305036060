import React from "react";
import { LightButton, PrimaryButton, StickyFooterButtons } from "best-common-react";

class StickyFooterExample extends React.Component {
  render() {
    return (
      <StickyFooterButtons>
        <PrimaryButton className="mr-2">Submit</PrimaryButton>
        <LightButton>Cancel</LightButton>
      </StickyFooterButtons>
    );
  }
}

export default StickyFooterExample;
