import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Icon } from "best-common-react";

const WarningIcon = styled(Icon)`
  &&& {
    color: #ff9100;
  }
`;

class MessageIcon extends React.Component {
  render() {
    return <WarningIcon iconName="fa-info-circle" className={this.props.className} />;
  }
}

export default inject("rootStore")(observer(MessageIcon));
