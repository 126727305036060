import React from "react";
import styled from "styled-components";

const Key = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

const Value = styled.div.attrs({ className: "text-right" })`
  font-size: 14px;
  font-weight: bold;
`;
const NotBoldValue = styled.div.attrs({ className: "text-right" })`
  font-size: 14px;
`;

const ValueRow = styled.tr`
  background-color: white;
`;

const BorderedTable = styled.table`
  border: solid 1px #dee2e6;
`;

const Bold = styled.span`
  &&& {
    font-weight: bold;
  }
`;

const TotalsBoxMobile = ({ home, away, reservations, tickets, value }) => {
  const format = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 })
    .format;

  return (
    <div className="col-lg-4 col-xl-4 col-12 p-0 pl-lg-3 mt-4">
      <div className="table-responsive">
        <BorderedTable className="table">
          <thead className="text-center">
            <tr>
              <th>Totals</th>
            </tr>
          </thead>
          <tbody>
            <ValueRow>
              <td>
                <div className="row">
                  <Key className="col-4 text-left">Games </Key>
                  <NotBoldValue className="col text-right">
                    <Bold>{home + away}</Bold> (<Bold>{home}</Bold> home <Bold>{away}</Bold> away)
                  </NotBoldValue>
                </div>
              </td>
            </ValueRow>
            <ValueRow>
              <td>
                <div className="row">
                  <Key className="col text-left">Reservations </Key>
                  <Value className="col text-right">{reservations.toLocaleString()}</Value>
                </div>
              </td>
            </ValueRow>
            <ValueRow>
              <td>
                <div className="row">
                  <Key className="col pull-right">Tickets </Key>
                  <Value className="col pull-left">{tickets.toLocaleString()}</Value>
                </div>
              </td>
            </ValueRow>
            <ValueRow>
              <td>
                <div className="row">
                  <Key className="col-4 pull-right">Value </Key>
                  <Value className="col pull-left">{format(value)}</Value>
                </div>
              </td>
            </ValueRow>
          </tbody>
        </BorderedTable>
      </div>
    </div>
  );
};

export default TotalsBoxMobile;
