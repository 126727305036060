import React from "react";
import { inject, observer } from "mobx-react";
import { StickyFooterButtons, LightButton, PrimaryButton } from "best-common-react";

const SystemSeasonEditFooter = ({ rootStore: { systemSeasonEditStore } }) => (
  <StickyFooterButtons>
    <PrimaryButton disabled={systemSeasonEditStore.isSaving} onClick={systemSeasonEditStore.save}>
      Save
    </PrimaryButton>
    <LightButton onClick={systemSeasonEditStore.cancel}>Cancel</LightButton>
  </StickyFooterButtons>
);

export default inject("rootStore")(observer(SystemSeasonEditFooter));
