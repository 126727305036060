import { action, autorun, computed, decorate, extendObservable } from "mobx";
import queryString from "query-string";
import _ from "lodash";

class VenueEditStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      venueSeasonId: -1,
      venueId: -1,
      previousSeason: new Date().getFullYear(),
      allSection: { label: "All", value: { homeAwayId: 0, homeAwayName: "All" } },
      sectionCategoryOptions: [
        { label: "Advance", value: { sectionCategoryId: 1, sectionCategoryName: "Advance" } },
        { label: "Convenience", value: { sectionCategoryId: 2, sectionCategoryName: "Convenience" } },
        { label: "Both", value: { sectionCategoryId: 3, sectionCategoryName: "Both" } }
      ],
      sectionCategoryBoth: { sectionCategoryId: 3, sectionCategoryName: "Both" },
      departments: [],
      venueSeasons: [],
      pickupLocations: [],
      sections: [],
      venueImportModal: false,
      venueImportSeason: {},
      sectionCategoryModal: false,
      isSaving: false
    };

    extendObservable(this, {
      venueSeasonId: this.defaults["venueId"],
      venueId: this.defaults["venueId"],
      venueSeasons: this.defaults["venueSeasons"],
      previousSeason: this.defaults["previousSeason"],
      pickupLocations: this.defaults["pickupLocations"],
      sectionCategoryOptions: this.defaults["sectionCategoryOptions"],
      sectionCategoryBoth: this.defaults["sectionCategoryBoth"],
      sectionsFilter: this.defaults["allSection"],
      allSection: this.defaults["allSection"],
      sections: this.defaults["sections"],
      departments: this.defaults["departments"],
      showVenueImportModal: this.defaults["venueImportModal"],
      venueImportSeason: this.defaults["venueImportSeason"],
      selectedImportSeason: this.defaults["venueImportSeason"],
      sectionCategoryModal: this.defaults["sectionCategoryModal"],
      isSaving: this.defaults["isSaving"],
      setVenueSeasons: action(value => {
        this.venueSeasons = value;
      }),
      setDepartments: action(value => {
        this.departments = value;
      }),
      setPickupLocations: action(value => {
        value.forEach(pl => {
          pl.type.label = pl.type.pickupLocationTypeName;
          pl.type.value = pl.type.homeAwayTypeId;
        });
        this.pickupLocations = value;
      }),
      reorderPickupLocations: action((startIndex, endIndex) => {
        let pickupLocations = this.sortedPickupLocations;
        const [removed] = pickupLocations.splice(startIndex, 1);
        pickupLocations.splice(endIndex, 0, removed);
        for (let i = 0; i < pickupLocations.length; i++) {
          pickupLocations[i].sequence = i + 1;
        }
        this.pickupLocations = pickupLocations;
      }),
      setSections: action(value => {
        value.forEach(s => {
          s.homeAwayType.label = s.homeAwayType.homeAwayTypeName;
          s.homeAwayType.value = s.homeAwayType.homeAwayTypeId;
        });
        this.sections = value;
      }),
      setSelectedSeason: action(value => {
        let vsId = this.venueSeasons
          .toJSON()
          .filter(venue => venue.season.seasonId === value.value && venue.venue.venueId === this.venueId);
        this.routerStore.history.push({
          pathname: this.routerStore.location.pathname,
          search: "?id=" + vsId[0].venueSeasonId + "&year=" + this.previousSeason
        });
      }),
      updateFromUrlParams: action(search => {
        const params = queryString.parse(search);
        this.venueSeasonId = params["id"] * 1 || this.defaults["venueId"];
        this.previousSeason = params["year"] * 1 || this.defaults["previousSeason"];
      }),
      save: action(() => {
        this.isSaving = true;
        this.compTixApi
          .savePickupLocations(this.pickupLocations, this.venueSeasonId)
          .then(() => {
            this.compTixApi.saveSections(this.sections, this.venueSeasonId).then(() => {
              this.routerStore.history.push("/admin/venues?year=" + this.previousSeason);
              this.isSaving = false;
            });
          })
          .catch(error => {
            this.isSaving = false;
          });
      }),
      cancel: action(() => {
        this.routerStore.history.push("/admin/venues?year=" + this.previousSeason);
      }),
      resetStore: action(() => {
        this.sectionsFilter = this.defaults["allSection"];
        this.pickupLocations = [];
        this.venueSeasons = [];
        this.sections = [];
      }),
      onPickupLocationChange: action((sequence, value) => {
        let index = this.pickupLocations.findIndex(pl => pl.sequence === sequence);
        this.pickupLocations[index] = value;
      }),
      onSectionChange: action(value => {}),
      addNewLocation: action(() => {
        let homeAwayType = this.commonStore.homeAwayOptions[0];
        this.pickupLocations.push({
          isDeleted: false,
          departments: [],
          pickupLocationId: null,
          pickupLocationName: "",
          sequence: this.calculateNextSequence(),
          type: homeAwayType,
          venueSeason: this.currentVenueSeason
        });
      }),
      addNewSection: action(() => {
        let homeAwayType = this.sectionsFilter.value.homeAwayId !== 0 ? this.sectionsFilter.value : "";
        this.sections.push({
          isDeleted: false,
          sectionNumber: "",
          inventoryQuantity: "",
          sectionPrice: "",
          homeAwayType: homeAwayType,
          sectionCategory: this.defaults["sectionCategoryBoth"],
          departments: [],
          venueSeason: this.currentVenueSeason
        });
      }),
      onFilterChange: action(value => {
        this.sectionsFilter = value;
      }),
      setShowVenueImportModal: action(value => {
        this.showVenueImportModal = value;
      }),
      setSelectedImportSeason: action(value => {
        this.selectedImportSeason = value;
      }),
      updateImportedVenue: action(value => {
        value.venueSeasonId = this.selectedImportSeason.venueSeasonId;
        this.compTixApi.importVenue(this.venueSeasonId, value).then(data => {
          if (data.pickupLocations && data.pickupLocations.length) {
            this.setPickupLocations(this.pickupLocations.concat(data.pickupLocations));
          }
          if (data.sections && data.sections.length) {
            this.setSections(this.sections.concat(data.sections));
          }
          this.setShowVenueImportModal(false);
        });
      }),
      toggleSectionCategoryModal: action(value => {
        this.sectionCategoryModal = value;
      })
    });

    autorun(() => {
      if (this.routerStore.isVenueEditTab) {
        this.updateFromUrlParams(this.routerStore.location.search);
        this.updateVenue();
      } else {
        this.resetStore();
      }
    });
  }

  updateVenue = () => {
    this.getVenues(this.commonStore.currentOrgId);
    this.updatePickupLocations(this.venueSeasonId);
    this.updateSections(this.venueSeasonId);
  };

  getVenues = orgId => {
    if (orgId) {
      this.compTixApi.getVenues(orgId).then(data => {
        this.setVenueSeasons(data);
        let venues = data.filter(vs => vs.venueSeasonId === this.venueSeasonId);
        if (venues.length) {
          this.venueId = venues[0].venue.venueId;
        }
      });
    }
  };

  get currentVenue() {
    return this.currentVenueSeason.venue;
  }

  get currentVenueSeason() {
    let match = this.venueSeasons.toJSON().filter(vs => vs.venueSeasonId === this.venueSeasonId);
    return match.length ? match[0] : {};
  }

  get currentVenueName() {
    return this.currentVenue && this.currentVenue.venueName;
  }

  get currentVenueLocation() {
    if (this.currentVenue) {
      if (this.currentVenue.city && this.currentVenue.stateAbbrev)
        return this.currentVenue.city + ", " + this.currentVenue.stateAbbrev;
    }
    return "N/A";
  }

  get getSelectedSeason() {
    if (this.currentVenueSeason && this.currentVenueSeason.season) {
      let filter = this.commonStore.seasonDropdownList.filter(season => {
        return this.currentVenueSeason.season.seasonId === season.value;
      });
      return filter[0];
    } else {
      return { label: "", value: "" };
    }
  }

  get departmentOptions() {
    return this.commonStore.departmentTypes
      ? this.commonStore.departmentTypes.map(d => ({
          label: d.departmentName,
          value: d.departmentTypeId,
          department: d
        }))
      : [{}];
  }

  get isValidPLSave() {
    return this.pickupLocations.every(pickup => {
      return (
        (pickup.pickupLocationName &&
          pickup.pickupLocationName.trim() &&
          !pickup.digitalDelivery &&
          pickup.departments.length &&
          pickup.type) ||
        (pickup.pickupLocationId == null && pickup.isDeleted) ||
        (pickup.digitalDelivery &&
          pickup.departments.length &&
          pickup.type &&
          (pickup.requireEmail || pickup.requireMobilePhone || pickup.allowEitherContactMethod))
      );
    });
  }

  get isValidSectionSave() {
    return this.sections.every(section => {
      return (
        (section.sectionNumber &&
          section.homeAwayType &&
          section.sectionCategory &&
          // This is checking for an integer value
          !isNaN(parseInt(section.inventoryQuantity)) &&
          section.inventoryQuantity % 1 === 0 &&
          section.inventoryQuantity >= 0 &&
          (section.sectionPrice || section.sectionPrice === 0) &&
          section.departments.length &&
          !isNaN(parseFloat(section.sectionPrice))) ||
        (section.sectionId == null && section.isDeleted)
      );
    });
  }

  get availableSeasons() {
    if (this.venueSeasons.length) {
      let availableVenueSeasonsForCurrentVenue = this.venueSeasons.filter(
        venueSeason => venueSeason.venue.venueId === this.venueId
      );
      let availableVenueSeasonsForCurrentVenue1 = availableVenueSeasonsForCurrentVenue.map(
        matchingVenueSeason => matchingVenueSeason.season.seasonId
      );
      return this.commonStore.activeSeasonDropdownList.filter(season => {
        return _.includes(availableVenueSeasonsForCurrentVenue1, season.value);
      });
    } else {
      return this.commonStore.activeSeasonDropdownList;
    }
  }

  get sortedPickupLocations() {
    return this.pickupLocations
      .filter(pl => !pl.isDeleted)
      .sort((a, b) => {
        return a.sequence > b.sequence;
      });
  }

  updatePickupLocations = () => {
    this.compTixApi.getPickupLocations(this.venueSeasonId).then(data => {
      this.setPickupLocations(data);
    });
  };

  updateSections = () => {
    this.compTixApi.getSections(this.venueSeasonId).then(data => {
      this.setSections(data);
    });
  };

  calculateNextSequence = () => {
    let currentSequences = this.pickupLocations.map(pl => pl.sequence);
    if (!currentSequences || currentSequences.length === 0) {
      return 0;
    } else {
      let currentMax = Math.max(...currentSequences);
      return currentMax + 1;
    }
  };

  get importSeasonOptions() {
    // get all seasons where venues have at least one section or pickup location
    let importSeasons = this.venueSeasons
      .filter(
        v => v.venue.venueId === this.currentVenue.venueId && (v.pickupLocations.length || v.venueSections.length)
      )
      .map(v => {
        let season = v.season.year + " " + v.season.seasonType.seasonTypeName;
        return { value: season, label: season, venueSeasonId: v.venueSeasonId, seasonId: v.season.seasonId };
      })
      //remove current season you are importing to
      .filter(v => v.venueSeasonId !== this.venueSeasonId)
      .sort((v1, v2) => v1.venueSeasonId - v2.venueSeasonId);

    this.setSelectedImportSeason(importSeasons[0]);

    return importSeasons;
  }

  get currentSections() {
    let id = this.sectionsFilter.value.homeAwayTypeId;
    return id > 0
      ? this.sections.filter(s => {
          if (s.homeAwayType === "") {
            return true;
          }
          return s.homeAwayType.homeAwayTypeId === id;
        })
      : this.sections;
  }
}

decorate(VenueEditStore, {
  currentVenue: computed,
  currentVenueSeason: computed,
  currentVenueName: computed,
  currentVenueLocation: computed,
  currentSections: computed,
  getSelectedSeason: computed,
  departmentOptions: computed,
  availableSeasons: computed,
  isValidPLSave: computed,
  isValidSectionSave: computed,
  sortedPickupLocations: computed,
  importSeasonOptions: computed
});

export default VenueEditStore;
