import React from "react";
import { Accordion, AttributeHeader, AttributeValue } from "best-common-react";
import styled from "styled-components";

const Header = styled(AttributeHeader)`
  margin-bottom: -0.25rem;
`;

const UserSummaryAccordionMobile = ({ index, data, ...rest }) => {
  return data.user ? (
    <div>
      <Accordion id={index} {...rest}>
        <Accordion.Header isToggle={true}>
          <div className="d-flex flex-column">{data.user}</div>
        </Accordion.Header>
        <Accordion.Body>
          <div>
            <Header>Department</Header>
            <AttributeValue>{data.department}</AttributeValue>
            <Header>Reservations</Header>
            <AttributeValue>{data.reservations}</AttributeValue>
            <Header>Tickets</Header>
            <AttributeValue>{data.tickets}</AttributeValue>
            <Header>Value</Header>
            <AttributeValue>{`$${data.value}`}</AttributeValue>
          </div>
        </Accordion.Body>
      </Accordion>
    </div>
  ) : null;
};

export default UserSummaryAccordionMobile;
