import { action, computed, extendObservable, isObservable, decorate } from "mobx";
import { AuthConstants } from "../constants/AuthConstants";
import _ from "lodash";
import { CommonConstants } from "../constants/CommonConstants";

const ENV = process.env.REACT_APP_ENV;

class AuthStore {
  constructor(routerStore, authApi) {
    this.routerStore = routerStore;
    this.authApi = authApi;

    this.defaults = {
      accessToken: "",
      isLoggingIn: false,
      loginError: false,
      loggedInSession: false,
      mobileNavOpen: false,
      refreshInProgress: false,
      refreshToken: "",
      userData: {}
    };

    extendObservable(this, {
      mobileNavOpen: this.defaults["mobileNavOpen"],
      accessToken: this.defaults["accessToken"],
      refreshToken: this.defaults["refreshToken"],
      loginError: this.defaults["loginError"],
      loggedInSession: this.defaults["loggedInSession"],
      isLoggingIn: this.defaults["isLoggingIn"],
      refreshInProgress: this.defaults["refreshInProgress"],
      userData: this.defaults["userData"],
      checkLocalStorage: action(() => {
        this.userData = localStorage.getItem(AuthConstants.KEYS.USER_DATA)
          ? JSON.parse(localStorage.getItem(AuthConstants.KEYS.USER_DATA))
          : this.defaults["userData"];
        this.accessToken = localStorage.getItem(AuthConstants.KEYS.ACCESS_TOKEN);
        this.refreshToken = localStorage.getItem(AuthConstants.KEYS.REFRESH_TOKEN);
      }),
      setLoggedIn: action(value => {
        this.accessToken = value.access_token;
        this.refreshToken = value.refresh_token;
      }),
      setLocalStorage: action(() => {
        if (this.userData) {
          localStorage.setItem(AuthConstants.KEYS.USER_DATA, JSON.stringify(this.userData));
        }
        if (this.accessToken) {
          localStorage.setItem(AuthConstants.KEYS.ACCESS_TOKEN, this.accessToken);
        }
        if (this.refreshToken) {
          localStorage.setItem(AuthConstants.KEYS.REFRESH_TOKEN, this.refreshToken);
        }
        if (this.userData && this.userData.org) {
          localStorage.setItem(CommonConstants.KEYS.SELECTED_ORG, JSON.stringify(this.userData.org));
        }
      }),
      setUserData: action(data => {
        this.userData = data;
      }),
      loginComplete: action(() => {
        this.loggedInSession = false;
      }),
      getUserInfo: action(() => {
        this.authApi.getUserInfo().then(data => {
          if (data) {
            this.setUserData(data);
            this.setLocalStorage();
            this.routerStore.history.push("/");
            this.loggedInSession = true;
          }
          this.isLoggingIn = false;
        });
      }),
      login: action((username, password) => {
        if (!this.isLoggingIn) {
          this.isLoggingIn = true;
          this.loginError = false;
          const body = {
            grant_type: "password",
            username: username,
            password: password,
            client_id: "comptix"
          };
          const encodedBody = this.serialize(body);
          this.authApi.getToken(encodedBody).then(data => {
            if (!data || data.error || (data.data && data.data.error)) {
              this.loginError = true;
              this.isLoggingIn = false;
            } else {
              this.setLoggedIn(data);
              this.getUserInfo();
            }
          });
        }
      }),
      logout: action(() => {
        localStorage.clear();
        this.loggedInSession = false;
        this.routerStore.history.push("/login");
      }),
      refresh: action(() => {
        if (this.refreshToken && !this.refreshInProgress) {
          this.refreshInProgress = true;
          const body = {
            grant_type: "refresh_token",
            refresh_token: this.refreshToken
          };
          const encodedBody = this.serialize(body);

          this.authApi.getToken(encodedBody).then(data => {
            if (data) {
              this.setLoggedIn(data);
              this.setLocalStorage();
            }
            this.refreshInProgress = false;
            this.routerStore.history.go(0);
          });
        }
      }),
      setMobileNavOpen: action(value => {
        this.mobileNavOpen = value;
      })
    });
  }

  get isProd() {
    return !!ENV && ENV.toLowerCase() === "prod";
  }

  get loggedIn() {
    return !!this.accessToken && this.accessToken.length > 0 && !!this.userData && !!this.userData.authorities;
  }

  get isAdmin() {
    let authorities = isObservable(this.userData.authorities)
      ? this.userData.authorities.toJSON()
      : this.userData.authorities;
    return (
      this.loggedIn &&
      _.some(authorities, authority => {
        return authority.authority.toLowerCase() === "global" || authority.authority.toLowerCase() === "admin";
      })
    );
  }

  get isGlobal() {
    let authorities = isObservable(this.userData.authorities)
      ? this.userData.authorities.toJSON()
      : this.userData.authorities;
    return (
      this.loggedIn &&
      _.some(authorities, authority => {
        return authority.authority.toLowerCase() === "global";
      })
    );
  }

  get isBoxOfficeAdmin() {
    let authorities = isObservable(this.userData.authorities)
      ? this.userData.authorities.toJSON()
      : this.userData.authorities;

    return (
      this.loggedIn &&
      _.some(authorities, authority => {
        return authority.authority.toLowerCase() === "box office admin";
      })
    );
  }

  get currentOrg() {
    if (this.userData && this.userData.org) {
      return this.userData.org;
    } else {
      return {};
    }
  }

  get currentUserId() {
    if (this.userData && this.userData.userId) {
      return this.userData.userId;
    } else {
      return -1;
    }
  }

  get firstCommaLast() {
    return this.userData && this.userData.firstName ? this.userData.firstName + ", " + this.userData.lastName : "";
  }

  serialize(obj) {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }
}

decorate(AuthStore, {
  isProd: computed,
  loggedIn: computed,
  isAdmin: computed,
  isGlobal: computed,
  isBoxOfficeAdmin: computed,
  currentOrg: computed,
  currentUserId: computed,
  firstCommaLast: computed
});

export default AuthStore;
