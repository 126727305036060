import { action, autorun, decorate, extendObservable, computed } from "mobx";
import Moment from "moment";
import _ from "lodash";
import { RouteConstants } from "../../constants/RouteConstants";

class SystemSeasonEditStore {
  constructor(routerStore, commonStore, comptixApi) {
    this.routerStore = routerStore;
    this.commonStore = commonStore;
    this.comptixApi = comptixApi;

    this.defaults = {
      isSaving: false,
      postseasonGameList: [],
      ngeList: [],
      season: {},
      seasonId: -1,
      seasonTypes: [],
      selectedClubs: [],
      sortFilters: {
        direction: "ASC",
        key: "team"
      }
    };

    extendObservable(this, {
      isSaving: this.defaults["isSaving"],
      postseasonGameList: this.defaults["postseasonGameList"],
      ngeList: this.defaults["ngeList"],
      selectedClubs: this.defaults["selectedClubs"],
      season: this.defaults["season"],
      seasonId: this.defaults["seasonId"],
      seasonTypes: this.defaults["seasonTypes"],
      sortFilters: this.defaults["sortFilters"],
      setPostseasonGameList: action(values => {
        this.postseasonGameList = values;
      }),
      setNgeList: action(values => {
        this.ngeList = values;
      }),
      setSelectedClubs: action(values => {
        this.selectedClubs = values;
      }),
      setSeason: action(value => {
        this.season = value;
      }),
      setSeasonId: action(value => {
        this.seasonId = value;
      }),
      setSeasonTypes: action(value => {
        this.seasonTypes = value;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      addIsSelectedGame: action(rows => {
        rows.forEach(row => {
          this.ngeList[row.index].isSelected = true;
        });
      }),
      removeIsSelectedGame: action(rows => {
        rows.forEach(row => {
          this.ngeList[row.index].isSelected = false;
        });
      }),
      setPostseasonGame: action((index, value) => {
        this.ngeList[index].gameDTO = value ? value.value : null;
      }),
      updateSeason: action((key, value) => {
        this.season[key] = value;
        if (key === "year") {
          this.season["defaultEventAccess"] = this.getDefaultEventAccessDate();
        } else if (key === "seasonType") {
          this.season["nriDropoff"] = null;
        }
      }),
      updateNgeList: action(updatedNges => {
        if (updatedNges) {
          updatedNges.forEach(nge => {
            let index = this.ngeList.findIndex(n => n.nonGameEventId === nge.nonGameEventId);
            nge.isSelected = false;
            this.ngeList[index] = nge;
          });
        }
      }),
      activateGames: action(() => {
        this.ngeList.forEach(game => {
          if (game.isSelected) {
            game.active = true;
          }
        });
        this.deselectGames();
      }),
      deactivateGames: action(() => {
        this.ngeList.forEach(game => {
          if (game.isSelected) {
            game.active = false;
          }
        });
        this.deselectGames();
      }),
      deselectGames: action(() => {
        this.ngeList = this.ngeList.map(game => ({
          ...game,
          isSelected: false
        }));
      }),
      resetStore: action(() => {
        this.season = this.defaults["season"];
        this.seasonId = this.defaults["seasonId"];
        this.seasonTypes = this.defaults["seasonTypes"];
        this.gameList = this.defaults["gameList"];
        this.ngeList = this.defaults["ngeList"];
        this.selectedClubs = this.defaults["ngeList"];
        this.sortFilters = this.defaults["sortFilters"];
      })
    });

    autorun(() => {
      if (this.routerStore.isSystemSeasonEditTab) {
        this.parseUrl();
        this.getSeasonTypes();
        if (!this.isNewSeason) {
          this.getSeason();
        }
      } else {
        this.resetStore();
      }
    });

    autorun(() => {
      if (this.isPostSeason && this.orgIdList.length) {
        this.getNgeList(this.season.year, this.orgIdList);
        this.getPostseasonGameList(this.season.year, this.orgIdList);
      }
    });
  }

  parseUrl = () => {
    let params = this.routerStore.getPathParams(RouteConstants.SYSTEM_SEASONS_EDIT);
    this.setSeasonId(params.seasonId * 1);
  };

  getSeason = () => {
    this.comptixApi.getSeason(this.seasonId).then(data => {
      this.setSeason(data);
    });
  };

  getSeasonTypes = () => {
    this.comptixApi.getSeasonTypes().then(data => {
      this.setSeasonTypes(data);
      if (this.isNewSeason) {
        this.createDefaultSeason();
      }
    });
  };

  createDefaultSeason = () => {
    let spring = this.seasonTypes.find(d => d.seasonTypeId === 1);
    let defaultEventAccess = this.getDefaultEventAccessDate();
    let season = {
      year: new Date().getFullYear(),
      seasonType: spring,
      active: true,
      nriDropoff: null,
      defaultEventAccess: defaultEventAccess
    };
    this.setSeason(season);
  };

  getDefaultEventAccessDate = () => {
    let date = new Date();
    let year = this.season.year ? this.season.year : new Date().getFullYear();
    date.setFullYear(year);
    date.setMonth(0);
    date.setDate(1);
    date.setHours(9);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  };

  save = () => {
    if (this.isNewSeason) {
      this.isSaving = true;
      this.comptixApi.createSeason(this.season).then(() => {
        this.isSaving = false;
        this.routerStore.push(RouteConstants.SYSTEM_SEASONS);
        this.commonStore.getCurrentSeasons();
      });
    } else {
      this.isSaving = true;

      this.comptixApi.updateSeason(this.season).then(() => {
        this.isSaving = false;
        if (this.isPostSeason) {
          this.updateNGEs(this.ngeList);
        } else {
          this.routerStore.push(RouteConstants.SYSTEM_SEASONS);
          this.commonStore.getCurrentSeasons();
        }
      });
    }
  };

  cancel = () => {
    this.routerStore.push(RouteConstants.SYSTEM_SEASONS);
  };

  getPostseasonGameList = (year, orgIds) => {
    this.comptixApi.getPostSeasonGamesForYearAndOrgIds(year, orgIds).then(data => {
      this.setPostseasonGameList(data);
    });
  };

  getNgeList = (year, orgIds) => {
    this.comptixApi.getNonGameEventForYearAndOrgIds(year, orgIds).then(data => {
      data = data.map(item => {
        item.isSelected = false;
        return item;
      });
      this.setNgeList(data);
    });
  };

  updateNGEs = nges => {
    this.comptixApi.updateNonGameEventMappings(nges).then(data => {
      this.updateNgeList(data);
      if (data && data.length) {
        this.routerStore.push(RouteConstants.SYSTEM_SEASONS);
        this.commonStore.getCurrentSeasons();
      }
    });
  };

  get isNewSeason() {
    return this.seasonId === -1;
  }

  get seasonTypesOptions() {
    return this.seasonTypes ? this.seasonTypes.map(s => this.createSeasonTypeOption(s)) : [];
  }

  get yearOptions() {
    let currentYear = new Date().getFullYear();
    let years = [currentYear - 1, currentYear, currentYear + 1, currentYear + 2];
    return years.map(y => ({ label: y, value: y }));
  }

  get nriDropoffDate() {
    let minDate = new Date();
    minDate.setFullYear(this.season.year);
    minDate.setMonth(0);
    minDate.setDate(1);
    let maxDate = new Date();
    maxDate.setFullYear(this.season.year);
    maxDate.setMonth(11);
    maxDate.setDate(31);
    return { minDate: Moment(minDate), maxDate: Moment(maxDate) };
  }

  get clubOptionList() {
    return this.commonStore.orgDropdownList.filter(org => org.label !== "MLB" && org.label !== "UMP");
  }

  get ngeGameList() {
    return this.sort(
      this.ngeList.map((nge, index) => ({
        index: index,
        ngeName: nge.nonGameEventName,
        active: nge.active,
        team: nge.org.orgCode,
        isSelected: nge.isSelected,
        mappedTo: nge.gameDTO
      })),
      this.sortFilters
    );
  }

  get orgIdList() {
    return this.selectedClubs.map(option => option.value);
  }

  get isPostSeason() {
    return this.season && this.season.seasonType && this.season.seasonType.seasonTypeId === 3;
  }

  get selectedActiveGames() {
    return this.ngeList.filter(nge => nge.isSelected && nge.active);
  }

  get selectedDeactiveGames() {
    return this.ngeList.filter(nge => nge.isSelected && !nge.active);
  }

  get existingGamePks() {
    return this.ngeList.map(nge => (nge.gameDTO ? nge.gameDTO.gamePk : null)).filter(gamePk => !!gamePk);
  }

  createSeasonTypeOption(seasonType) {
    return seasonType ? { label: seasonType.seasonTypeName, value: seasonType } : { value: "", label: "" };
  }

  sort(ngeList, searchFilters) {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return ngeList;
    } else if (direction === "ASC") {
      return _.sortBy(ngeList, searchFilters.key);
    } else if (direction === "DESC") {
      return _.sortBy(ngeList, searchFilters.key).reverse();
    } else {
      return ngeList;
    }
  }

  getSlug = game => {
    let away = this.getAbbreviation(game.teams.away);
    let home = this.getAbbreviation(game.teams.home);
    return away.length && home.length ? `${away} @ ${home}` : "TBD @ TBD";
  };

  postseasonGameOptions = game => {
    return this.postseasonGameList
      .filter(g => {
        let gamePk = g.gamePk;
        return this.existingGamePks.indexOf(gamePk) < 0 || (game && game.gamePk === gamePk);
      })
      .map(game => {
        let gameDateStr;
        if (game.gameDate) {
          if (game.status.startTimeTBD) {
            gameDateStr =
              Moment(game.gameDate)
                .utc()
                .format("M/DD/YYYY") + " - TBD";
          } else {
            gameDateStr = Moment(game.gameDate)
              .tz(game.venue.timeZone.id)
              .format("M/DD/YYYY - h:mm a zz");
          }
        } else {
          gameDateStr = "";
        }

        let label = `${gameDateStr} - ${this.getSlug(game)}`;
        return {
          label: label,
          value: game
        };
      });
  };

  getAbbreviation = team => {
    return team && team.team && team.team.abbreviation ? team.team.abbreviation : "";
  };
}

decorate(SystemSeasonEditStore, {
  isNewSeason: computed,
  nriDropoffDate: computed,
  seasonTypesOptions: computed,
  yearOptions: computed,
  clubOptionList: computed,
  ngeGameList: computed,
  orgIdList: computed,
  isPostSeason: computed,
  selectedActiveGames: computed,
  selectedDeactiveGames: computed,
  existingGamePks: computed
});

export default SystemSeasonEditStore;
