import React from "react";
import { inject, observer } from "mobx-react";
import {
  BestLabel,
  BestSelect,
  Input,
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";

class DeleteContactModal extends React.Component {
  render() {
    const { commonStore, contactManagementStore } = this.props.rootStore;
    return (
      <div>
        <Modal
          show={contactManagementStore.showDeleteContactModal || false}
          onClose={() => {
            contactManagementStore.setShowDeleteContactModal(false);
            contactManagementStore.resetStore();
          }}
          size="lg"
        >
          <ModalHeaderWithTitleAndClose
            title="Delete Contact"
            close={() => {
              contactManagementStore.setShowDeleteContactModal(false);
              contactManagementStore.resetStore();
            }}
          />
          <ModalBody>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 form-group">
                <div className="d-flex justify-content-between align-items-end">
                  <BestLabel>First Name</BestLabel>
                </div>
                <div>{contactManagementStore.selectedContact.firstName}</div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 form-group">
                <div className="d-flex justify-content-between align-items-end">
                  <BestLabel>Last Name</BestLabel>
                </div>
                <div>{contactManagementStore.selectedContact.lastName}</div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 form-group">
                <div className="d-flex justify-content-between align-items-end">
                  <BestLabel>Relation</BestLabel>
                </div>
                <div>{contactManagementStore.selectedContact.relation}</div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 form-group">
                <div className="d-flex justify-content-between align-items-end">
                  <BestLabel>Phone</BestLabel>
                </div>
                <div>{contactManagementStore.selectedContact.phone}</div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 form-group">
                <div className="d-flex justify-content-between align-items-end">
                  <BestLabel>Email</BestLabel>
                </div>
                <div>{contactManagementStore.selectedContact.email}</div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div>
              <PrimaryButton
                onClick={() => contactManagementStore.deleteContact(contactManagementStore.selectedContact)}
              >
                Delete
              </PrimaryButton>
              <LightButton
                onClick={() => {
                  contactManagementStore.setShowDeleteContactModal(false);
                  contactManagementStore.resetStore();
                }}
              >
                Cancel
              </LightButton>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default inject("rootStore")(observer(DeleteContactModal));
