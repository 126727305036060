import React from "react";
import { Accordion } from "best-common-react";

class AccordionTableMobileEx extends React.Component {
  render() {
    return (
      <div>
        <Accordion id={this.props.index.toString()}>
          <Accordion.Header isToggle={true}>
            <div>{this.props.index}</div>
          </Accordion.Header>
          <Accordion.Body>
            <div>{JSON.stringify(this.props.data)}</div>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

export default AccordionTableMobileEx;
