import React from "react";
import PermissionDisplay from "../protected/PermissionDisplay";

class PermissionDisplayExample extends React.Component {
  render() {
    return (
      <div>
        <PermissionDisplay permission="BASIC">
          <div>Basic</div>
        </PermissionDisplay>
        <PermissionDisplay permission="ADMIN">
          <div>Admin</div>
        </PermissionDisplay>
        <PermissionDisplay permission="GLOBAL">
          <div>Global</div>
        </PermissionDisplay>
      </div>
    );
  }
}

export default PermissionDisplayExample;
