import React from "react";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { BestTitle, DataTable, BestDropdown, FormColumn4Wide, LightButton, BestSelect } from "best-common-react";
import CheckFormatter from "../../../elements/CheckFormatter";

const SystemSeasonEditPostseason = ({ rootStore: { systemSeasonEditStore } }) => {
  const onRowsSelected = rows => {
    systemSeasonEditStore.addIsSelectedGame(rows.map(row => row.row));
  };

  const onRowsDeselected = rows => {
    systemSeasonEditStore.removeIsSelectedGame(rows.map(row => row.row));
  };

  let MappedToFormatter = ({ row, value }) => {
    let options = systemSeasonEditStore.postseasonGameOptions(value);
    let finalVal = value ? options.find(option => option.value.gamePk === value.gamePk) : {};

    return (
      <BestSelect
        value={finalVal}
        options={options}
        onChange={val => {
          systemSeasonEditStore.setPostseasonGame(row.index, val);
        }}
        portal={document.body}
        clearable={true}
        styles={{
          container: {
            height: "30px",
            fontSize: "12px"
          },
          control: {
            height: "30px",
            minHeight: "30px"
          },
          valueContainer: {
            padding: "0 8px 0 8px",
            marginTop: "-7px"
          }
        }}
      />
    );
  };

  const NgeTableColumns = [
    {
      name: "Non Game Event",
      key: "ngeName",
      sortable: true
    },
    {
      name: "Home",
      key: "team",
      width: 100,
      sortable: true
    },
    {
      name: "Active",
      key: "active",
      width: 100,
      formatter: CheckFormatter,
      cellClass: "text-center",
      sortable: true
    },
    {
      name: "Mapped To",
      key: "mappedTo",
      width: 400,
      formatter: MappedToFormatter
    }
  ];

  return (
    <>
      {systemSeasonEditStore.isPostSeason && (
        <div>
          <BestTitle>Postseason Events</BestTitle>
          <div className="row">
            <FormColumn4Wide>
              <BestDropdown
                isMulti
                onChange={systemSeasonEditStore.setSelectedClubs}
                id="club-select"
                title="Club"
                values={toJS(systemSeasonEditStore.selectedClubs)}
                options={systemSeasonEditStore.clubOptionList}
              />
            </FormColumn4Wide>
          </div>
          <div className="row mt-3">
            <div className="col-xs-12 col-lg-10">
              <div>
                <DataTable
                  data={systemSeasonEditStore.ngeGameList}
                  height={300}
                  columns={NgeTableColumns}
                  sortFunc={systemSeasonEditStore.setSortDirection}
                  sortColumn={systemSeasonEditStore.sortFilters["key"]}
                  sortDirection={systemSeasonEditStore.sortFilters["direction"]}
                  rowHeight={35}
                  rowSelection={{
                    showCheckbox: true,
                    onRowsSelected: onRowsSelected,
                    onRowsDeselected: onRowsDeselected,
                    selectBy: {
                      isSelectedKey: "isSelected"
                    },
                    enableShiftSelect: true
                  }}
                />
              </div>
              <div className="mt-2">
                <div className="d-flex justify-content-end">
                  <LightButton
                    className="mr-2 btn-sm"
                    onClick={systemSeasonEditStore.activateGames}
                    disabled={systemSeasonEditStore.selectedDeactiveGames.length === 0}
                  >
                    Activate
                  </LightButton>
                  <LightButton
                    className="btn-sm"
                    onClick={systemSeasonEditStore.deactivateGames}
                    disabled={systemSeasonEditStore.selectedActiveGames.length === 0}
                  >
                    Deactivate
                  </LightButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default inject("rootStore")(observer(SystemSeasonEditPostseason));
