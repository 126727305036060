import React from "react";
import styled from "styled-components";
import { AccordionHeader, AttributeHeader, AttributeValue } from "best-common-react";

const Text = styled.div.attrs({ className: "mb-4" })`
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
`;

const Body = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  background: ${props => (props.color ? props.color : props.theme["almost-white"])};
  padding: ${props => (props.padding ? "10px" : "0px")};
`;

class UserDeleteBodyMobile extends React.Component {
  render() {
    const currentUser = this.props.currentUser;
    return (
      <div>
        <Text>Are you sure you want to delete the following user?</Text>
        <AccordionHeader>{currentUser.lastName + ", " + currentUser.firstName}</AccordionHeader>
        <Body padding={true}>
          <AttributeHeader>Username</AttributeHeader>
          <AttributeValue>{currentUser.username}</AttributeValue>

          <AttributeHeader>Organization</AttributeHeader>
          <AttributeValue>{currentUser.org.orgName}</AttributeValue>
          <AttributeHeader>Department</AttributeHeader>
          <AttributeValue>{currentUser.department.departmentType.departmentName}</AttributeValue>
        </Body>
      </div>
    );
  }
}

export default UserDeleteBodyMobile;
