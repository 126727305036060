const updateStart = dateRange => {
  if (dateRange["start"] == null || dateRange["end"] == null) {
    return dateRange;
  }
  if (dateRange["start"] > dateRange["end"]) {
    dateRange["end"] = new Date(dateRange["start"]);
    dateRange["end"].setDate(dateRange["end"].getDate() + 7);
  }
  return dateRange;
};

const updateEnd = dateRange => {
  if (dateRange["start"] == null || dateRange["end"] == null) {
    return dateRange;
  }
  if (dateRange["start"] > dateRange["end"]) {
    dateRange["start"] = new Date(dateRange["end"]);
    dateRange["start"].setDate(dateRange["start"].getDate() - 7);
  }
  return dateRange;
};

export { updateStart, updateEnd };
