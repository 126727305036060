import React from "react";
import { inject, observer } from "mobx-react";
import LoadingSpinner from "../../../loading/LoadingSpinner";

const SystemSeasonHeader = () => (
  <div className="d-flex">
    <div className="mr-2">Seasons</div>
    <LoadingSpinner size={10} />
  </div>
);

export default inject("rootStore")(observer(SystemSeasonHeader));
